@import "../../assets/sass/var";

.attendanceCount {
  width: 100%;
  text-align: right;

  .count {
    margin-right: 7px;
    display: inline-flex;
    align-items: center;
    span {
      height: 16px;
      width: 16px;
      line-height: 13px;
      margin: 0px 5px;
    }
  }
  .countLarge {
    //for Turnout tab
    margin-right: 7px;
    display: inline-flex;
    align-items: center;
    span {
      height: 16px;
      width: 16px;
      line-height: 13px;
      margin: 0px 5px;
      @media screen and (min-width: $smallTVScreen) {
        height: 28px;
        width: 28px;
        margin: 0px 8px;
      }
    }
  }
}
