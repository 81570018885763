@import "../../../assets/sass/_var.scss";
@import "../../../assets/sass/_mixin.scss";

button {
  background-color: transparent;
  box-sizing: border-box;
  background-repeat: no-repeat;
  border-radius: $radius;
  text-align: center;
  font-family: "Source Sans Pro";
  //text-transform: uppercase;
  font-size: $f14px;
  line-height: $f18px;
  margin: 7px 0 7px 7px;
  cursor: pointer;
  border: 1px solid;
  @include theme-aware("border-color", "btnPrimaryBorderColour");
  &[type="submit"] {
    font-size: $f18px;
    float: right;
    @include theme-aware("color", "green1");
  }
  @media screen and (max-width: $smallScreen-max) {
    min-height: 30px;
    min-width: 84px;
  }

  &:focus {
    outline: 0;
  }

  &.btn_primary {
    font-size: $f18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: $f23px;
    color: $mGreen; // link txt green
    min-height: 48px;
    width: 100%;
    transition: ease all 0.2s;
    // background-color: transparent;
    @include theme-aware("background-color", "btnPrimaryBgColour");

    &.login {
      height: 48px;
      max-width: 305px;
    }
    @media screen and (min-width: $mediumScreen-max) {
      &:hover {
        background: $mGreen;
        color: #fff;
      }
    }
    &.red {
      color: $red;
      @media screen and (min-width: $mediumScreen-max) {
        &:hover {
          background: $red;
          color: #fff;
        }
      }
    }
    &.new_message {
      position: absolute;
      right: -10px;
      bottom: -10px;
      background: $mGreen;
      border-radius: 8px;
      background-image: url(../../../assets/img/icons/Post_white.svg);
      background-position: calc(100% - 15px) 50%;
      background-repeat: no-repeat;
      background-size: 20px;
      font-weight: 600;
      min-height: 52px;
      min-width: 52px;
      height: 52px;
      width: auto;
      color: #fff;
      padding-right: 50px;
      z-index: 11;

      &:hover {
        color: $mGreen;
        background: #fff;
        border-color: $mGreen;
        background-image: url(../../../assets/img/icons/Post.svg);
        background-position: calc(100% - 15px) 50%;
        background-repeat: no-repeat;
        background-size: 20px;
      }

      @media (max-width: $smallScreen-max) {
        right: 10px;
        bottom: 10px;
      }
    }
  }
  &.btn_secondary {
    color: $mGreen; // link txt green
    font-size: $f14px;
    line-height: $f18px;
    height: 30px;
    min-width: 78px;
    transition: ease all 0.2s;
    @include theme-aware("background-color", "cardBg2");
    @include theme-aware("color", "green1");
    @include theme-aware("border-color", "grey9");

    @media screen and (min-width: $mediumScreen-max) {
      &:hover {
        background: $mGreen;
        color: #fff;
      }
    }
    &.btn_solid {
      background: $mGreen;
      color: #fff;
    }
    &.red {
      border: 1px solid $red;
      color: $red;
      @media screen and (min-width: $mediumScreen-max) {
        &:hover {
          background: $red;
          color: #fff;
        }
      }
    }
    &.icon_add {
      background-position: 80% 50%;
      padding-right: 30px;
      background-repeat: no-repeat;
    }
    &.green {
      background: $mGreen;
      color: #fff;

      &:hover {
        color: $mGreen;
        border-color: $mGreen;
        background: #fff;
      }
    }
    &:disabled {
      pointer-events: none;
      color: #bdbdbd;
      cursor: not-allowed;
      border-color: #d6d7d9; // same on light and dark mode
    }
  }

  &.btn_tertiary {
    height: 32px;
    width: 185px;
    border-radius: 5px;
    border-style: solid;
    @include theme-aware("border-width", "invisibleBorder1");
    @include theme-aware("border-color", "grey5");
    @include theme-aware("background-color", "grey7");
    align-items: center;
    @include theme-aware("color", "txt");
    font-size: $f14px;
    text-transform: uppercase;
    font-weight: bold;
  }
  &.green {
    background: $mGreen;
    color: #fff;
  }
  &.btn_sqaure {
    color: $mGreen; // link txt green
    font-size: $f16px;
    font-weight: 600;
    width: 30px;
    height: 30px;
    background-position: 50%;
    @include theme-aware("background-color", "cardBg2");
    @media screen and (max-width: $tabletPortrait) {
      width: 40px;
      height: 40px;
    }
    @media screen and (min-width: $mediumScreen-max) {
      &:hover {
        background-color: $mGreen;
        color: #fff;
        background-position: 50%;
        background-repeat: no-repeat;
      }
    }
  }
  &:disabled,
  &.disabled {
    pointer-events: none;
    color: #bdbdbd;
    cursor: not-allowed;
    border-color: #d6d7d9; // same on light and dark mode
  }
  &.btn_restartEvent {
    @include theme-aware("color", "txt");
    @include theme-aware("background-color", "tileBg");
    border-radius: 25px;
    padding: 12px;
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: $f16px;
    border-color: transparent;
    transition: ease all 0.2s;

    &::before {
      content: url(../../../assets/img/icons/refresh.svg);
      display: block;
      margin-top: 4px;
      width: 20px;
      height: 20px;
      padding-right: 10px;
    }

    &:hover {
      @include theme-aware("background-color", "hovergreen");
      color: #fff;

      &::before {
        content: url(../../../assets/img/icons/refresh-white.svg);
      }
    }
  }
  &.btn_refreshEvent {
    @include theme-aware("border-color", "txt2");
    @include theme-aware("color", "txt");
    @include theme-aware("background-color", "tileBg");
    border-radius: 25px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: $f14px;
    transition: ease all 0.2s;
    cursor: pointer;
    pointer-events: none;

    > div {
      height: 42px;
      margin-top: -3px;
    }

    &._refresh {
      padding: 6px 8px;
      pointer-events: auto;
      &::before {
        content: url(../../../assets/img/icons/refresh.svg);
        display: block;
        margin-top: 4px;
        width: 20px;
        height: 20px;
        padding-right: 10px;
      }

      &:hover {
        @include theme-aware("background", "hovergreen");
        color: #fff;

        &::before {
          content: url(../../../assets/img/icons/refresh-white.svg);
        }
      }

      &.mobile {
        @include theme-aware("background-color", "tileBg");
        padding: 0 !important;
        pointer-events: none;
        background: none !important;
        width: 80px;
        transition: ease width 0.2s, ease background-color 0.2s,
          ease background 0.2s;

        &:hover {
          @include theme-aware("background-color", "tileBg");
        }

        &::before {
          display: none;
        }
      }

      @media (max-width: $smallScreen-max) and (min-width: $tabletPortrait) {
        // // background: transparent !important;
        // background: url(../../../assets/img/icons/refresh-white.svg) !important;
        // pointer-events: auto;
        // background-repeat: no-repeat !important;
        // background-position: center !important;
        @include theme-aware("background-color", "tileBg");

        &:hover {
          background: transparent;
        }
      }
    }

    @media (max-width: $tabletPortrait) {
      min-width: 32px !important;
      margin-left: 16px;
      // background: transparent !important;
      background: url(../../../assets/img/icons/refresh-white.svg) !important;
      pointer-events: auto;
      height: 32px !important;
      background-repeat: no-repeat !important;
      background-position: center !important;

      > div {
        width: 50px;
        height: 32px;
        margin-top: -12px;
      }

      &:hover {
        background: transparent;
      }
    }
  }
  &.btn_closeEvent {
    @include theme-aware("border-color", "txt2");
    @include theme-aware("background-color", "tileBg");
    color: $red;
    border-radius: 25px;
    margin: 7px;
    height: 42px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: $f14px;
    transition: ease all 0.2s;
    cursor: pointer;
    pointer-events: none;

    > div {
      height: 42px;
      margin-top: -3px;
    }

    &._close {
      padding: 6px 8px;
      pointer-events: auto;
      &::before {
        content: url(../../../assets/img/icons/stop_red.svg);
        display: block;
        width: 15px;
        height: 15px;
        padding-right: 6px;
        padding-left: 6px;
        margin-top: -1px;
      }

      &:hover {
        @include theme-aware("background", "hoverred");
        color: #fff;

        &::before {
          content: url(../../../assets/img/icons/stop_white.svg);
        }
      }

      &.mobile {
        @include theme-aware("background-color", "tileBg");
        padding: 0 !important;
        pointer-events: none;
        background: none !important;
        width: 80px;
        transition: ease width 0.2s, ease background-color 0.2s,
          ease background 0.2s;

        &:hover {
          @include theme-aware("background-color", "tileBg");
        }

        &::before {
          display: none;
        }
      }

      @media (max-width: $smallScreen-max) and (min-width: $tabletPortrait) {
        @include theme-aware("background-color", "tileBg");

        &:hover {
          background: transparent;
        }
      }
    }

    @media (max-width: $tabletPortrait) {
      min-width: 32px !important;
      margin-right: 16px;
      background: url(../../../assets/img/icons/stop_white.svg) !important;
      pointer-events: auto;
      height: 32px !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 14px !important;

      > div {
        width: 50px;
        height: 32px;
        margin-top: -12px;
      }

      &:hover {
        background: transparent;
      }
    }
  }
  &.icon {
    &_settings {
      background-image: url(../../../assets/img/icons/settings.svg);
      background-position: 90% 50%;
      background-size: 15px 15px;
      padding-right: 40px;
    }
    &_search {
      background-image: url(../../../assets/img/icons/search-green.svg);
      background-position: 60% 50%;
      padding-right: 3.5rem;
    }
    &_add {
      background-image: url(../../../assets/img/icons/add-green.svg);
    }
    &_reduce {
      background-image: url(../../../assets/img/icons/reduce-green.svg);
    }
    &_remove {
      background-image: url(../../../assets/img/icons/remove-red.svg);
    }
    &_sound {
      background-size: 15px 15px;
      background-image: url(../../../assets/img/icons/sound.svg);
    }
    &_edit {
      background-image: url(../../../assets/img/icons/edit-green.svg);
    }
    &_option {
      max-width: 40px !important;
      width: 40px;
      height: 40px;
      border: 0;
      background-repeat: no-repeat;
      background-position: 50%;
      background-image: url(../../../assets/img/icons/icon-optionMenu.svg);
    }
    &_lock {
      background-image: url(../../../assets/img/icons/locked.svg);
    }
    &_close {
      @media screen and (min-width: 375px) {
        background-image: url(../../../assets/img/icons/close.svg);
        background-position: 90% 50%;
        padding-right: 25px;
        background-size: 15px 15px;
      }
      @media screen and (max-width: 374px) {
        padding: 1px 5px;
        min-width: auto;
        margin-left: 0;
      }
    }
    &_closeRed {
      margin: 0;
      float: right;
      min-height: 15px;
      height: 15px;
      width: 15px;
      min-width: 15px;
      margin: 15px;
      border: 0 none;
      background-size: 13px 13px;
      background-position: 50% 50%;
      background-image: url(../../../assets/img/icons/close_red.svg);
    }
    &_hideNav {
      background-image: url(../../../assets/img/icons/hide_nav_icon.svg);
      padding-right: 35px;
      background-position: 80% 50%;
    }
    &_reporting {
      background-image: url(../../../assets/img/icons/reporting_icon.svg);
      padding-right: 35px;
      background-position: 92% 50%;
    }
    &_dashboard {
      background-image: url(../../../assets/img/icons/dashboard.svg);
      padding-right: 35px;
      background-position: 92% 50%;
    }
    &_mapExpand {
      background-image: url(../../../assets/img/icons/map-expand.svg);
      text-align: left;
      background-size: 16px;
      background-position: 90% 50%;
      padding-right: 35px;

      &:hover {
        background-repeat: no-repeat;
        background-image: url(../../../assets/img/icons/map-expand-white.svg);
        background-size: 16px;
        background-position: 90% 50%;
        color: #fff;
        fill: #fff;
      }
    }
    &_mapColapse {
      background-image: url(../../../assets/img/icons/map-colapse.svg);
      text-align: left;
      background-size: 16px;
      background-position: 90% 50%;
      padding-right: 35px;

      &:hover {
        background-repeat: no-repeat;
        background-image: url(../../../assets/img/icons/map-colapse-white.svg);
        background-size: 16px;
        background-position: 90% 50%;
        color: #fff;
        fill: #fff;
      }
    }
  }
  // BUTTON HOVER
  @media screen and (min-width: $mediumScreen-max) {
    &.icon {
      &_search:hover {
        background-repeat: no-repeat;
        background-position: 60% 50%;
        background-image: url(../../../assets/img/icons/search-white.svg);
      }
      &_add:hover {
        background-image: url(../../../assets/img/icons/add-white.svg);
      }
      &_reduce:hover {
        background-image: url(../../../assets/img/icons/reduce-white.svg);
      }
      &_remove:hover {
        background-color: $red;
        background-image: url(../../../assets/img/icons/remove-white.svg);
      }
      &_sound:hover {
        background-image: url(../../../assets/img/icons/sound-white.svg);
      }
      &_edit:hover {
        background-image: url(../../../assets/img/icons/edit-white.svg);
      }
    }
  }
}
.btn {
  transition: ease all 0.2s;

  &_noOutline {
    background-color: transparent;
    border: none;
    height: 35px;
    min-width: 35px;
    transition: ease all 0.2s;

    &.no_text {
      min-width: 35px !important;
      background-position: 50% 50%;
      height: 35px;
      width: 35px;
      min-height: 35px;
      padding: 0;
    }
  }

  &_outline {
    // station, event, you, location btn
    background-color: #fff;
    @include theme-aware("background-color", "bg");
    @include theme-aware("border-color", "grey2");
    @include theme-aware("color", "green1");
    height: 30px;
    min-width: 78px;
    transition: ease all 0.2s;

    &:hover {
      background-color: $dgreen;
      color: #fff;
      fill: #fff;
    }

    &.white {
      color: #fff;
      background: $dgrey;
      border: solid 1px #fff;

      &:hover {
        background: #fff;
        border: solid 1px $dgrey;
        color: $dgrey;
      }
    }

    &.white_outline {
      color: #fff;
      background: transparent;
      border: solid 1px #fff;
      transition: ease-in-out all 0.3s;

      &:hover {
        background: #fff;
        border: solid 1px #fff;
        color: $dgrey;

        &._close {
          background-image: url(../../../assets/img/icons/close.svg);
        }
      }

      &._close {
        font-weight: 600;
        display: block;
        margin-top: 10px;
        height: 34px;
        padding-right: 1.8rem;
        border-radius: 5px 5px 0 0;
        background-image: url(../../../assets/img/icons/close_white.svg);
        background-repeat: no-repeat;
        background-position: 85% 50%;
        background-size: 12px 12px;
        display: flex;
        align-items: center;
      }
    }

    &.red {
      border-color: $red;
      color: $red;

      &:hover {
        background-color: $red;
        color: #fff;
        fill: #fff;
      }
    }

    &.green {
      border-color: $dgreen;
      color: $dgreen;

      &:hover {
        background-color: $dgreen;
        color: #fff;
        fill: #fff;
      }
    }

    &.grey {
      border-color: $dgrey;
      color: $dgrey;

      &:hover {
        background-color: $dgrey;
        color: #fff;
        fill: #fff;
      }

      &.no_text {
        @include theme-aware("border-color", "grey9");
        @include theme-aware("background-color", "grey8");
        min-width: 35px !important;
        background-position: 50% 50%;
        height: 35px;
        width: 35px;
        min-height: 35px;
        padding: 0;

        &:hover {
          background-color: $dgrey;
          color: #fff;
          fill: #fff;
        }
      }
    }
  }
  &_solid {
    border: 0 none;
    background-position: center;
    // transition: background 0.5s;
    transition: ease background-colour 0.2s;
    @media screen and (min-width: $mediumScreen-max) {
      &:hover {
        background-color: $dgreen;
        color: #fff;
        fill: #fff;
      }
    }
    @include theme-aware("background-color", "grey3");
    @include theme-aware("color", "txt3");
    border: 1px solid;
    @include theme-aware("border-color", "grey1");
  }
  &_nav {
    color: $dgreen;
    background-color: transparent;
    margin: 10px 0px 0px 0px;

    font-size: $f18px;
    font-weight: 600;
    letter-spacing: 0.5px;
    line-height: $f44px;
  }
}

.toggle {
  position: relative;
  float: right;
  width: 48px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    padding: 1px;
    box-shadow: 0 0 1px 1px #fff, 0 0 1px 2px $grey;
    border-radius: 34px;
    height: 100%;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $grey;
    -webkit-transition: 0.3s;
    transition: 0.3s;

    &:before {
      border-radius: 50%;
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: #5a5f65;
      -webkit-transition: 0.3s;
      transition: 0.3s;
    }
  }

  input:checked + .slider {
    background-color: $mGreen;
    &:before {
      background-color: #fff;
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(23px);
    -ms-transform: translateX(23px);
    transform: translateX(23px);
  }
}

.radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  //line-height: 20px;
  flex: auto;
  line-height: $f30px;
  //height: 20px;
  // width: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    //top: 0;
    top: 6px;
    left: 5px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 0 2px #fff, 0 0 0 3px #8c919a;
    background-color: $grey;
    border-radius: 50%;
    transition: ease all 0.1s;
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
  }
  // @media (min-width: $smallScreen-max) {
  //   &:hover input ~ .checkmark {
  //     background-color: #ccc;
  //   }
  // }
}

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  line-height: $f30px;

  &.disabled {
    cursor: not-allowed;
    input:checked ~ .checkmark {
      background-color: $lgrey;
      box-shadow: 0 0 1px 2px $lgrey, 0 0 1px 3px $lgrey;
      cursor: not-allowed;
    }

    span {
      color: $lgrey;
    }
  }

  .checkboxDescription {
    margin-bottom: 0px;
    margin-top: -5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    //top: 0;
    top: 6px;
    left: 5px;
    height: 18px;
    width: 18px;
    box-shadow: 0 0 0 2px #fff, 0 0 0 3px #8c919a;
    background-color: $grey;
    border-radius: 5px;
    transition: ease all 0.1s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
    box-shadow: 0 0 0 2px $mGreen, 0 0 0 3px #8c919a;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  // @media (min-width: $smallScreen-max) {
  //   &:hover input ~ .checkmark {
  //     background-color: #ccc;
  //   }
  // }
}
.checkbox_small {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 13px;
  line-height: 2px;
  padding: 10px 0 0 25px;

  &.disabled {
    cursor: not-allowed;
    input:checked ~ .checkmark {
      background-color: $lgrey;
      box-shadow: 0 0 1px 2px $lgrey, 0 0 1px 3px $lgrey;
      cursor: not-allowed;
    }

    span {
      color: $lgrey;
    }
  }

  .checkboxDescription {
    margin-bottom: 0px;
    margin-top: -5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    //top: 0;
    top: 6px;
    left: 5px;
    height: 10px;
    width: 10px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 5px;
    transition: ease all 0.1s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 30%;
      top: 0px;
      width: 2.5px;
      height: 5px;
      border: solid white;
      border-width: 0 2.2px 2.2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
    box-shadow: 0 0 1px 2px $mGreen, 0 0 1px 3px $mGreen;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  // @media (min-width: $smallScreen-max) {
  //   &:hover input ~ .checkmark {
  //     background-color: #ccc;
  //   }
  // }
}
.backTop-holder {
  display: none;
}
@media (max-width: $smallScreen-max) {
  .backTop-holder {
    display: block;
    z-index: $backTop;
    position: fixed;
    bottom: -10px;
    right: 35px;

    .scroll {
      display: block;
      margin-top: $f16px;
      height: 2.5rem;
      border-radius: 5px 5px 0 0;
      color: #fff;
      background-color: #007454;
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.5);
      border: 0 none;
      background-image: url(../../../assets/img/icons/totop.svg);
      background-position: 20% 50%;
      display: flex;
      align-items: center;
      transition: ease-in-out all 0.3s;
      transform: translateY(34px);

      span {
        margin-left: 35px;
        font-size: $f10px;
        font-weight: 600;
        line-height: $f10px;
        text-align: left;
      }
    }
    &.visible {
      .scroll {
        transform: translateY(0px);
      }
    }
    &.hidden {
      display: block;
    }
  }
}
