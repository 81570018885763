@import "../../assets/sass/_var.scss";
@import "../../assets/sass/mixin";

.message_holder {
  max-height: calc(100% - 52px);
  overflow-y: auto;
}
.message_holder,
.loading_holder {
  padding: 0 24px;

  @media (min-width: $smallScreen-max) {
    padding: 0;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  }
}

@media (max-width: $smallScreen-max) {
  .message_holder {
    max-height: calc(100% - 52px);
    // overflow: inherit;
  }

  &.scrollable {
    overflow-y: auto;
  }

  &.unscrollable {
    overflow-y: hidden;
  }
}

.paginationLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
}
.fullPageLoader {
  width: 100%;
  height: 100%;
}
.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading_holder {
  &.loading_thread {
    margin-top: 52px;
  }
  height: calc(100% - 52px);

  .loadingContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .noMessages {
      text-align: center;
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      padding: 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

.newMessageLoader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .noMessages {
    padding: 8px 0;
  }
}

.buttonHeader {
  padding: 3px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    margin-left: 0;
  }
}
.buttonWrapper {
  > button {
    height: 32px;
    @media screen and (max-width: $smallScreen) {
      margin: 7px 1px -5px !important;
    }
  }
}

.SASChat {
  text-align: center;
  vertical-align: top;
  padding-bottom: 50px;
  position: relative;
  z-index: 10;

  @media (min-width: $smallScreen-max) {
    height: calc(100vh - 100px);
  }

  @media (max-width: $smallScreen-max) {
    .chat_content_holder {
      padding: 0;
      margin: 0;

      .buttonHeader {
        padding: 3px 20px;
      }
    }
  }

  .filterControls {
    text-align: left;
    @media screen and (max-width: 1299px) {
      padding: 5px;
    }

    label {
      white-space: nowrap;
      margin: 7px 10px 5px;
      font-size: 0.875rem;

      @media screen and (min-width: $tabletPortrait) {
        &:first-child {
          padding-right: 15px;
          border-right: 1px solid $grey;
        }
      }
    }
    > button {
      border: 1px solid #007454;
    }

    .filterButtons {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      // .closeBtn {
      //   display: none;
      // }

      // @media (max-width: 1125px) {
      //   .closeBtn {
      //     display: block;
      //   }
      // }
    }
  }
}

.userSearchWrapper {
  button {
    margin: 0;
  }
}

.showAllUsersButton {
  font-size: 1rem;
  font-weight: 600;
  margin-top: -5px;
  padding: 0 0 8px;
  border-bottom: solid 1px $grey;
}

// ==================== START FILTER AND SORT STYLING ===================== */
.contentHeader {
  position: relative;
  min-height: 52px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  button {
    margin-bottom: 0;

    @media (max-width: $smallScreen-max) {
      margin-top: 0;
    }
  }

  .filterButtonIsActive {
    background-color: #fff;
  }

  .buttonWrapper {
    z-index: 10;
    min-width: 180px;
  }

  .controls {
    display: flex;
    font-weight: 600;
    font-size: $f14px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    z-index: 9;
    position: absolute;
    top: 42px;
    left: 0;
    padding: 0 16px 16px;
  }
}

// Filter controls styling
.filterControls {
  width: 100%;
  .dropDownWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: solid 1px $grey;
    // padding-bottom: 8px;
    margin-bottom: 8px;

    .dropDown {
      width: 100%;
      align-items: center;
      padding-bottom: 8px;
      border-bottom: none;

      span {
        width: 100px;
      }

      .dropdown {
        max-width: 100% !important;
        width: 100% !important;
        // min-height: 40px !important;
        // border: 1px solid #8c919a !important;
        // border-radius: 5px !important;
        // background-color: #ffffff !important;
        margin-bottom: 4px !important;
        margin-top: 4px !important;
        // @include theme-aware("color", "txt");
        // font-size: 1rem !important;
        // line-height: 1.25rem !important;
        padding-left: 8px !important;
        padding-right: 8px !important;

        input {
          height: auto !important;
        }
      }
    }
  }

  .dateSelectorWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    .dateSelector {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 6px;

      &:nth-child(2) {
        padding-top: 14px;
      }

      input {
        font-size: 16px;
        line-height: 16px;
        font-family: "Source Sans Pro", sans-serif;
        padding-right: 6px;
      }
      span {
        width: 100px;
      }
    }
  }

  .filterButtonWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    button[type="submit"] {
      background: $mGreen;
      border-color: $mGreen;
      color: #fff !important;
      font-size: $f14px;
      line-height: $f18px;
      height: 30px;
      min-width: 78px;
      transition: ease all 0.2s;
    }
  }
}

.chat_content_holder {
  height: 100%;
}

.filterSection {
  text-align: right;
  margin-top: 7px;
  //margin-bottom: 7px;

  button {
    height: 32px;
    margin: 0;

    &:first-child {
      background-color: $grey;
      margin: 0;
      margin-right: 4px;

      &:hover {
        @include theme-aware("color", "txt");
        background-image: url(../../assets/img/icons/Filter.svg);
        cursor: default;
      }

      &.isActive {
        background-color: #fff;
        z-index: 1;
        position: relative;
      }
    }
  }
}

.userSearchWrapper {
  height: 100%;
  @media (max-width: $smallScreen-max) {
    margin: 0px 20px;
  }

  .userSearchHeader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: auto;
    min-height: 52px;
    padding-right: 16px;
    width: 100%;

    .searchParametersContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .content,
    .searchParameters {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;

      button {
        background-position-x: 7%;
        padding-left: 30px;
        padding-right: 7px;
      }
    }

    .searchParametersContainer:not(:empty) {
      border-radius: 5px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
      padding: 4px;
      margin-bottom: -20px;
      z-index: 9;
    }

    .addToSearchParameters {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      max-height: 120px;
      padding: 0 4px;

      button {
        right: 0;
        margin-left: auto;
        background-position-x: 15%;
        padding-left: 22px;
        padding-right: 7px;
        min-width: 55px;
      }

      input {
        font-size: $f14px;
        line-height: $f18px;
        padding: 5px 8px;
        margin-right: 8px;
      }
    }

    .searchParameters:not(:empty) {
      flex-wrap: wrap;
      max-height: 120px;
      overflow-y: scroll;
    }

    .content {
      justify-content: space-between;
    }

    .searchParameterItem {
      display: inline-block;
      align-items: center;
      height: 30px;
      padding: 6px 8px;
      border: 1px solid $grey;
      border-radius: 5px;
      margin: 4px;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100%);
      overflow: hidden;

      svg {
        width: 10px;
        height: 10px;
        margin-right: 8px;
      }
    }
  }

  .resultsWrapper {
    position: relative;
    padding-top: 20px;
    height: auto;
    max-height: 600px;
    margin-bottom: 0;
  }
  .error {
    border: solid 1px red !important;
    border-radius: 5px !important;
  }
  .searchbar {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    max-height: 120px;

    button {
      right: 0;
      margin-left: auto;
      background-position-x: 15% !important;
      padding-left: 23px !important;
      padding-right: 14px !important;
      min-width: 55px;
      font-size: $f16px;
      line-height: $f30px;
      height: 42px;
    }
    input {
      margin-right: 8px;
    }
  }
  .searchbox {
    border: 1px solid #8c919a;
    border-radius: 5px;
    color: #5a5f65;
    font-size: $f16px;
    line-height: $f30px;
    padding: 5px 8px;
    width: 100%;
  }
  .label {
    color: #5a5f65;
    font-size: $f14px;
    font-weight: 600;
    line-height: $f18px;
    margin: 0;
    margin-bottom: 5px;
    padding-top: 19px;
  }
  .divider {
    margin: 8px 0;
    border-bottom: solid 1px $grey;
    width: 100%;
    display: block;
  }
  .dropdownHolder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .dropdownLabel {
      font-weight: 600;
      text-align: left;
      margin: 5px 10px;
      font-size: 16px;
      width: 7em;
    }

    > span {
      line-height: $f44px;
      word-wrap: break-word;
    }
    > div {
      &:nth-child(2),
      &:nth-child(3) {
        padding-top: 0 !important;
      }

      margin: 9px 3px 9px 0px !important;
    }
    // @media (max-width: $smallScreen) {
    //   > div {
    //     margin: 9px !important;
    //   }
    // }
  }

  .btnHolder {
    display: flex;
    align-items: center;
  }

  ul {
    margin: 0;

    padding: 0;
    list-style: none;
  }
}

.searchResults {
  margin: 0;
  height: auto;
  max-height: 233px;
  padding: 0;
  list-style: none;
  // Use this for a list of search results
  margin-bottom: 10px;
  // border-top: 1px solid $grey;
  overflow-y: auto;
  // max-height: 45%;
  // height: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  // &.includesShowAllButton {
  //   max-height: calc(45% - 40px);
  // }
  li {
    font-size: $f16px;
    font-weight: 600;
    // line-height: $f30px;
    padding: 4px 0;
  }
}

.chatHeader {
  align-items: center;
  display: flex;
  width: 100%;
  text-align: left;
  padding-bottom: 4px;

  @media screen and (max-width: $smallScreen-max) {
    height: 45px;
  }
  // enter your msg / reply to text above text box
  label {
    flex: auto;
    font-size: $f16px;
    line-height: $f20px;
    text-transform: initial;
    font-weight: normal;
    @include theme-aware("color", "txt");
  }
  // character limit text
  span {
    font-size: $f12px;
    line-height: $f20px;
    padding-top: 3px;
  }
}

.messageInput_holder {
  padding: 8px 0;
  border-radius: 5px;

  input {
    min-height: 35px;
  }

  textarea {
    min-height: 250px;
  }

  textarea,
  input {
    font-family: "Source Sans Pro", sans-serif;
    width: 100%;
    border-radius: 5px;
    @include theme-aware("color", "txt");
    background-color: #ffffff;
    font-size: $f16px;
    line-height: $f20px;
    padding: 12px;
    border: 1px solid #8c919a;
  }

  .messageBody {
    padding-top: 8px;
  }

  .postTxtBtn {
    padding-top: 8px;
    display: flex;
    width: 100%;
    p {
      flex: auto;
      font-size: $f12px;
      line-height: $f15px;
      margin: 0;
      margin-top: 8px;
    }
  }
}

.searchDropdownWrapper {
  min-width: 50%;
  font-size: 16px;
  width: calc(100% - 10rem);
}

// ==================== END FILTER AND SORT STYLING ===================== */

@media screen and (min-width: $mediumScreen-max) {
  .SASChat {
    padding: 0 15px;
    max-width: $extraLargeScreen;
    border-radius: 8px;
    background-color: #fafafa;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  }
}

@media screen and (max-width: $mediumScreen-max) {
  .SASChat {
    height: calc(100vh - 98px);
    padding-bottom: 0;
  }

  .chat_content_holder {
    height: 100%;
  }

  .chat_content_holder {
    .message_holder {
      padding: 0;
      height: 100%;
    }
  }
}

.noNewMessage {
  font-size: $f12px;
  margin-right: 10px;
}
