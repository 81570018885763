@import "../../assets/sass/_var.scss";

/* GENERAL */

.holder {
  padding: 14px;
  &.dashboard {
    .iconright {
      border: 1px solid $grey;
      opacity: 0.5;
      pointer-events: none;
    }
    .body .cell {
      color: $green;
      &:after {
        display: block;
        content: url(../../assets/img/icons/Threshold-NotMet.svg);
        width: 18px;
        height: 18px;
        margin: auto;
      }
      &.thresholdMet:after {
        display: block;
        content: url(../../assets/img/icons/Threshold-Met.svg);
        width: 18px;
        height: 18px;
        margin: auto;
      }
    }
  }
}

/* GRID */
.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.rowMiddle {
  align-items: center;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}

.colStart {
  justify-content: flex-start;
  text-align: left;
}

.colCenter {
  justify-content: center;
  text-align: center;
}

.colEnd {
  justify-content: flex-end;
  text-align: right;
}

/* Calendar */

.calendar {
  width: 100%;
  border-bottom: 1px solid $grey;

  .header {
    padding: 14px 0;
    font-size: $f18px;
    font-weight: 600;
    line-height: $f27px;
    border-bottom: 1px solid $grey;
    .colCenter {
      min-width: 50%;
    }
    .icon {
      cursor: pointer;
      font-size: 0;
      width: 30px;
      height: 30px;
      border: 1px solid #5a5f65;
      position: relative;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;

      &.iconLeft:before,
      &.iconRight:after {
        display: block;
        margin: auto;
        width: 20px;
        height: 20px;
        padding: 6px;
      }
      &.iconLeft:before {
        content: url(../../assets/img/icons/chevron_left.svg);
      }
      &.iconRight {
        float: right;
      }
      &.iconRight:after {
        content: url(../../assets/img/icons/chevron_right.svg);
      }
      &.disabled {
        border: 1px solid $grey;
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  // .calendar .header .calendar .header .iconRight:after {
  //   display: block;
  //   content: url(../../assets/img/icons/chevron_right.svg);
  // }

  .days {
    // @include theme-aware("color", "txt");
    height: 50px;
    font-size: $f16px;
    line-height: 50px;
    text-align: center;
  }
  .body {
    .cell {
      height: 50px;
      text-align: center;
      cursor: pointer;
      &.NoRepeat,
      &.repeat,
      &.both {
        color: $green;
      }

      &.NoRepeat:after {
        content: url(../../assets/img/icons/time.svg);
        display: block;
      }
      &.repeat:after {
        content: url(../../assets/img/icons/repeating.svg);
        display: block;
      }
      &.both:after {
        content: url(../../assets/img/icons/BlendedAvailablity.svg);
        display: block;
      }
      &:hover {
        border-radius: 7px;
      }
      .number {
        height: 30px;
        width: 37.8px;
        //color: #518015;
        font-size: $f20px;
        font-weight: 600;
        line-height: $f25px;
        text-align: center;
      }
      .bg {
        font-weight: 700;
        line-height: 1;
        color: var(--main-color);
        opacity: 0;
        font-size: 8em;
        position: absolute;
        top: -0.2em;
        right: -0.05em;
        transition: 0.25s ease-out;
        letter-spacing: -0.07em;
      }
    }
    .cell:hover .bg,
    .selected .bg {
      opacity: 0.05;
      transition: 0.5s ease-in;
    }
    .selected {
      border: 3px solid #5a5f65;
      border-radius: 7px;
    }

    .today {
      border: 3px solid #fff;
      border-radius: 7px;
      //background-color: #f5f5f5;
      &.selected {
        border: 3px solid #5a5f65;
        border-radius: 7px;
      }
    }

    .col {
      flex-grow: 0;
      flex-basis: calc(100% / 7);
      width: calc(100% / 7);
    }
  }
}
.disabled {
  color: $grey;
  pointer-events: none;
  &:after {
    opacity: 0.5;
  }
  span {
    color: $grey;
  }
}

.noMessages {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
