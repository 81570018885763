@import "../../assets/sass/var";
@import "../../assets/sass/mixin";

.pagerMessage {
  width: 100%;
  position: relative;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: solid 1px transparent;
  @include theme-aware("color", "txt");
  transition: ease all 0.2s;

  &.activeEvent {
    border-width: 3px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
  }

  .fadeInPagerItem {
    opacity: 0.01;
  }

  .fadeInPagerItem-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }

  &.emergency {
    border-color: $red;
  }

  &.nonEmergency {
    border-color: $purple;
  }

  &.admin {
    border-color: $blue;
  }

  &.ended {
    border-color: transparent;
    @include theme-aware("color", "txt");

    .ribbon {
      // background: $txt !important;
      @include theme-aware("background", "txt");
    }
  }

  &.deleted {
    transition: left ease 0.5s;
    left: -110vw;
  }

  span {
    cursor: pointer;
  }

  &:first-child {
    margin: 0 0 12px;
  }

  .AttendanceBanner {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 13px;
    min-height: 25px;

    .icon {
      width: 16px;
      height: 16px;
      margin: 0 16px 0 0;
    }

    .heading {
      font-weight: 600;
      text-transform: uppercase;
      font-size: $f14px;
      color: $green;
    }
    .notAttending {
      font-weight: 600;
      text-transform: uppercase;
      font-size: $f14px;
      color: $red;
    }
    .other {
      font-weight: 600;
      text-transform: uppercase;
      font-size: $f14px;
      color: $yellow;
    }
    .headingTurnout {
      //for Turnout tab TV screens
      @media screen and (min-width: $smallTVScreen) {
        font-size: x-large;
      }
      font-size: $f14px;
      font-weight: 600;
      text-transform: uppercase;
      color: $green;
    }
    .notAttendingTurnout {
      //for Turnout tab TV screens
      @media screen and (min-width: $smallTVScreen) {
        font-size: x-large;
      }
      font-weight: 600;
      text-transform: uppercase;
      font-size: $f14px;
      color: $red;
    }
    .otherTurnout {
      //for Turnout tab TV screens
      @media screen and (min-width: $smallTVScreen) {
        font-size: x-large;
      }
      font-weight: 600;
      text-transform: uppercase;
      font-size: $f14px;
      color: $yellow;
    }

    &.endedEvent {
      .heading {
        @include theme-aware("color", "txt");
      }

      span:first-child {
        @include theme-aware("background-color", "txt");
      }
    }

    &.ended {
      background-color: $bg;
      width: calc(100% + 2rem);
      margin-left: -1rem;
      padding-left: calc(45px + 1rem);
      border-bottom: none;
    }
  }

  .MessagesBanner {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: solid 1px $grey;
    margin: 10px 0 0 0;
    padding: 5px 0 5px 45px;
    min-height: 25px;

    .content {
      font-weight: 600;
      text-transform: uppercase;
      font-size: $f14px;

      &.newMessage {
        display: flex;
        align-items: center;

        .chatIcon {
          color: #fff;
          font-weight: bold;
          background: linear-gradient(90deg, #00aff0 0.12%, #007dc8 99.69%);
          border-radius: 50%;
          height: 14px;
          width: 14px;
          padding: 2px;
          text-align: center;
          margin-right: 12px;
        }
      }
    }
  }

  // .ribbon {
  //   position: absolute;
  //   height: 100%;
  //   top: 0;
  //   left: 0;
  //   min-width: 7px;
  //   border-top-left-radius: 5px;
  //   border-bottom-left-radius: 5px;

  //   &.emergency {
  //     background: $emg;
  //   }

  //   &.nonEmergency {
  //     background: $nonemg;
  //   }

  //   &.admin {
  //     background: $admin;
  //   }
  // }

  .ribbonEnded {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    min-width: 7px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: $grey;
  }

  .pagerContent {
    width: 100%;
    text-align: left;

    &.deleteConfirmationMenu {
      min-height: 80px;
    }

    .pagerHeader {
      display: flex;
      flex-direction: column;
      min-height: 30px;

      &.emergency {
        .top {
          background: $emg;

          .alertType {
            color: #fff;
          }
        }

        .topTurnout {
          //for Turnout tab
          border-bottom: solid 1px $lred;

          .alertType,
          .timeStamp {
            color: $lred;
          }
        }

        &.ended {
          .top {
            border-bottom: solid 2px $red;
          }
        }
      }

      &.nonEmergency {
        .top {
          background: $nonemg;
        }

        .topTurnout {
          //for Turnout tab
          border-bottom: solid 1px $purple;

          .alertType,
          .timeStamp {
            color: $purple;
          }
        }

        &.ended {
          .top {
            border-bottom: solid 2px #93328e;
          }
        }
      }

      &.admin {
        .top {
          background: $admin;
        }
        .topTurnout {
          //for Turnout tab
          border-bottom: solid 1px $blue;

          .alertType,
          .timeStamp {
            color: $blue;
          }
        }

        &.ended {
          .top {
            border-bottom: solid 2px $blue;
          }
        }
      }

      &.ended {
        .top {
          background: #fff;

          .alertType {
            .emergency {
              color: #dc1b20;
            }

            .urgent {
              color: $yellow;
            }

            .nonEmergency {
              color: #93328e;
            }

            .admin {
              color: #007faf;
            }

            .update {
              @include theme-aware("color", "txt");
            }
          }

          .timeStamp {
            @include theme-aware("color", "txt");
          }
        }
      }

      .top,
      .topTurnout {
        //for Turnout tab
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        align-items: center;
        border-radius: 4px 4px 0 0;

        &.isDashboard {
          padding-right: 8px;
        }

        .activeArrow {
          position: absolute;
          top: 0;
          right: 3px;
          width: 8px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 8px;
            height: auto;
          }
        }
      }

      .bottom {
        display: flex;
        justify-content: flex-start;
        margin: 5px 0 0 50px;
        width: 100%;
        max-width: fit-content;

        img {
          margin-right: 5px;
        }
      }

      .agencyLogo {
        margin: 10px 0 -15px 5px;
        height: 35px;
        width: 35px;
        padding: 3px;
        text-align: center;
        background: #fff;
        border-radius: 5px;

        img {
          height: 100%;
          width: auto;
        }
      }
      .agencyLogoTurnout {
        //for Turnout tab
        margin: 10px 0 -15px 5px;
        height: 35px;
        width: 35px;
        padding: 3px;
        text-align: center;
        background: #fff;
        border-radius: 5px;

        img {
          height: 100%;
          width: auto;
        }
        @media (min-width: $admin) {
          height: 50px;
          width: 50px;
        }

        @media (min-width: $smallTVScreen) {
          height: 50px;
          width: 50px;
        }
      }

      .alertType {
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        margin-left: 10px;
      }
    }

    .pagerGroup {
      font-weight: 600;
      // margin-left: 5px;
    }
    .pagerGroupBold {
      font-weight: 700;
      //margin: 5px 0;
      //margin-left: 50px;
    }
    .timeStamp {
      display: flex;
      justify-content: flex-end;
      font-size: $f14px;
      margin-left: auto;
      font-weight: 600;
      // margin-right: 16px;

      .time {
        padding-right: 10px;
        @media (max-width: $smallScreen-max) {
          color: #fff;
        }
      }

      span {
        white-space: nowrap;
      }
    }

    .timeStampTurnout {
      //for Turnout tab
      display: flex;
      justify-content: flex-end;
      font-size: $f14px;
      margin-left: auto;
      font-weight: 600;
      // margin-right: 16px;

      .time {
        padding-right: 10px;
        @media (max-width: $smallScreen-max) {
          color: #fff;
        }
      }

      span {
        white-space: nowrap;
      }

      @media (min-width: $smallTVScreen) {
        font-size: x-large;
      }
    }
    .footerWrapperTurnout {
      //for Turnout tab
      display: flex;
      justify-content: space-between;
      margin: 10px 16px 16px 25px;

      .timer {
        width: 100%;
        font-size: $f14px;
        display: flex;
        @media screen and (min-width: $smallTVScreen) {
          font-size: xx-large;
        }
        .stopwatch {
          display: flex;
          .stopwatch_display {
            font-weight: 600;
            padding-right: 12px;
          }
        }
      }

      .inactiveIcon {
        span {
          border-radius: 5px;
          padding: 3px 6px;
          font-weight: 600;
        }
      }
    }

    .timeStampPagerHistory {
      margin-right: -10px;
      display: flex;
      justify-content: flex-end;
      font-size: $f14px;
      margin-left: auto;
      font-weight: 600;
      color: #fff;

      .time {
        padding-right: 12px;
      }

      span {
        white-space: nowrap;
      }
    }

    .pagerDetails {
      line-height: 1.25rem;
      margin: 6px 25px 0;
      display: flex;
      overflow-wrap: anywhere;
      word-wrap: break-word;

      span {
        user-select: text;
        width: 100%;
        overflow-wrap: anywhere;
      }
    }
    .pagerTurnoutDetails {
      //for Turnout tab
      line-height: 1.25rem;
      margin: 6px 25px 0;
      display: flex;
      overflow-wrap: anywhere;
      word-wrap: break-word;

      span {
        user-select: text;
        width: 100%;
        overflow-wrap: anywhere;
      }
      @media (min-width: $smallTVScreen) {
        line-height: 2.25rem;
      }
    }

    .pagerDetailsUpdate {
      padding-top: 0.5rem;
      line-height: 1.25rem;
    }

    .footerWrapper {
      display: flex;
      justify-content: space-between;
      margin: 10px 16px 16px 25px;

      .timer {
        width: 100%;
        font-size: $f14px;
        display: flex;

        .stopwatch {
          display: flex;
          .stopwatch_display {
            font-weight: 600;
            padding-right: 12px;
          }
        }
      }

      .inactiveIcon {
        span {
          border-radius: 5px;
          padding: 3px 6px;
          font-weight: 600;
        }
      }
    }
    .respond {
      width: 100%;
      font-size: $f14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        height: 16px;
        width: 16px;
        margin-left: 6px;
      }
    }
  }

  .menulink {
    margin: -14px;
  }

  .menulink_dark {
    margin: -14px -19px -14px -13px;
  }

  .menuButtons {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  .confirmDeleteButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      margin-bottom: 10px;
    }
  }

  &.desktop {
    .pagerContent {
      display: flex;
      justify-content: space-between;

      .AttendanceBanner {
        margin-right: -1px;
        width: calc(100% - 1px);
      }

      .pagerHeader {
        display: flex;
        // flex-direction: row;
        min-width: 190px;
        padding-bottom: 6px;

        .content {
          display: flex;
        }

        &.emergency {
          background: $emg;

          .alertType {
            color: #fff;
          }

          &.ended {
            .alertType {
              width: 100%;
              > span {
                padding-bottom: 6px;
                border-bottom: solid 2px $red;
              }
            }
          }
        }

        &.nonEmergency {
          background: $nonemg;

          &.ended {
            .alertType {
              width: 100%;
              > span {
                padding-bottom: 6px;
                border-bottom: solid 2px #93328e;
              }
            }
          }
        }

        &.admin {
          background: $admin;

          &.ended {
            .alertType {
              width: 100%;
              > span {
                padding-bottom: 6px;
                border-bottom: solid 2px $blue;
              }
            }
          }
        }

        &.ended {
          // background: #fff;

          .alertType {
            .emergency {
              color: #dc1b20;
            }

            .urgent {
              color: $yellow;
            }

            .nonEmergency {
              color: #93328e;
            }

            .admin {
              color: #007faf;
            }

            .update {
              @include theme-aware("color", "txt");
            }
          }

          .timeStamp {
            @include theme-aware("color", "txt");
          }
        }

        .agencyLogo {
          margin: 6px;
        }

        .alertType {
          display: flex;
          flex-direction: column;
          padding-top: 8px;
          margin-left: 6px;

          .timer {
            padding-top: 10px;
            .stopwatch_display {
              font-size: $f18px;
              letter-spacing: 2.6px;
            }
            .heading {
              font-weight: normal;
            }
          }
        }

        .inactiveIcon {
          span {
            border-radius: 5px;
            padding: 3px 6px;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }
      .pagerInformation {
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        padding: 6px 6px 6px 0;

        .pagerGroup,
        .pagerGroupBold {
          margin-top: 0;
          margin-left: 25px;
          display: flex;
          flex-direction: row;
          img {
            margin-right: 5px;
          }
        }

        .pagerDetails {
          margin-bottom: 0;
          margin: 0 25px;
        }
      }
      .pagerTime {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px 12px 12px;

        .timeStamp {
          margin-right: 0;
        }
      }
    }
  }
}
