@import "../../assets/sass/_var.scss";

.stopwatch {
  text-align: center;
  height: 100%;
  .heading {
    font-size: $f16px;
    color: #fff;
    letter-spacing: 0.05rem;
  }
  .stopwatch_display {
    font-size: $f22px;
    letter-spacing: 0.08rem;
    font-weight: bold;
    color: #fff;
    padding-bottom: 5px;
  }
  .homeTimer {
    @media (max-width: $smallScreen-max) {
      margin: 0px 10px 0px 0px;
    }
  }
}

.dashboardHeader {
  span {
    color: #fff;
  }
}
.turnoutHeader {
  //for Turnout tab
  width: 100%;
  display: flex;
  justify-content: center;
  -webkit-text-stroke: 1px #000;
  span {
    font-size: 5.3rem;
    font-weight: 600;
    &.whiteText {
      color: #fff;
    }
    &.blackText {
      color: #000;
    }

    &.resetText {
      color: inherit;
    }

    &.expandedView {
      font-size: 4vw;
    }
  }

  @media screen and (min-width: $smallTVScreen) {
    margin: 0;
    span {
      font-size: 10.3rem;
    }
  }
}

.pagerMessage {
  display: flex;
  flex-direction: column;

  @media (min-width: $smallScreen-max) {
    .stopwatch {
      text-align: left;

      .stopwatch_display {
        font-size: $f18px;
        letter-spacing: 2.6px;
        padding-bottom: 0;
      }
      .heading {
        padding-top: 0;
        font-size: $f16px;
        font-weight: normal;
      }
    }
  }

  @media (max-width: $smallScreen-max) {
    width: 100%;
    margin-left: 0;

    .stopwatch {
      display: flex;
      align-items: center;

      .stopwatch_display {
        font-size: $f14px;
        font-weight: 600;
        padding-right: 4px;
        padding-bottom: 0;
      }

      .heading {
        font-size: $f14px;
        //white-space: nowrap;
        text-align: center;
      }
    }
  }

  @media (max-width: 345px) {
    .stopwatch {
      text-align: left;
      flex-direction: column;
      margin-right: auto;
    }
  }
}

.alertBanner {
  .stopwatch {
    display: flex;

    .stopwatch_display {
      font-size: $f18px;
      letter-spacing: 2.6px;
      padding-bottom: 0;
    }
    .heading {
      padding-top: 0;
      padding-left: 4px;
      font-size: $f18px;
      font-weight: normal;
    }
  }

  @media (max-width: 400px) {
    .stopwatch {
      flex-direction: column;
    }
  }
}

@media (min-width: $smallScreen-max) {
  .stopwatch {
    .heading {
      padding-top: 5px;
      font-size: $f20px;
      letter-spacing: 0.08rem;
    }

    .stopwatch_display {
      font-size: $f30px;
    }
  }
}

.mobileView {
  width: 100%;
  margin-left: 0;

  .stopwatch {
    display: flex;
    align-items: center;

    .stopwatch_display {
      font-size: $f14px;
      font-weight: 600;
      padding-right: 4px;
      color: #000;
      padding-bottom: 0;
    }

    .heading {
      font-size: $f14px;
      color: #000;
      white-space: nowrap;
    }
  }
}

.refreshTimer {
  border: 1px solid $bgrey;
  background-color: #fff;
  width: 143px;
  padding: 2px 7px;
  font-size: $f12px;
  border-radius: 5px;

  .heading {
    font-weight: bold;
  }
}
