@import "../../assets/sass/_var.scss";

.eventOverviewWrapper {
  position: relative;
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &_bottomScreen {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $mediumScreen-max) {
      top: 20%;
    }
  }
}

.eventOverview {
  height: calc(100vh - 82px);
}

.EventOverview {
  text-align: center;

  &_sections {
    min-height: 500px;
    vertical-align: top;
    padding-bottom: 14px;
    position: relative;
    z-index: 10;
    margin-top: 0 !important;
  }
  @media screen and (min-width: $smallScreen-max) {
    &_sections {
      padding: 0 15px;
      margin: 0 20px 20px;
      max-width: $extraLargeScreen;
      border-radius: 8px;
      background-color: #fafafa;
      // height: calc(100vh - 310px);
      // height: 100%;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    }
  }

  @media screen and (min-width: $extraLargeScreen) {
    &_sections {
      margin: 20px auto;
    }
  }
}

.EventDetails_section,
.Attendance_section {
  max-height: calc(100% - 52px);
  overflow-y: auto;
  overflow-x: hidden;
}

// Event detail styling
.EventDetails_section {
  margin: 0px auto;

  @media (max-width: $smallScreen) {
    margin-top: 0 !important;
  }

  .pagerEvents {
    padding-top: 20px;

    @media screen and (max-width: $tabletPortrait) {
      padding-top: 20px;
    }
  }

  .EventDetails_header {
    margin-top: 5px;
    margin-bottom: 22px;
    @media screen and (min-width: $smallScreen-max) {
      margin-bottom: 16px;
    }
  }
}

#addressVerification {
  float: right;
  span {
    margin: 0px;
  }
}
