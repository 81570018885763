@import "assets/sass/main";
@import "assets/sass/var";

html {
  touch-action: manipulation;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.fullscreen-map-mode {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hidden {
  display: none;
}

.pageHeader {
  height: 78px;
}

.appstoreLogosiOS {
  display: inline-block;
  margin-top: 10px;

  img {
    height: 45px;
  }
}

.appstoreLogosAndroid {
  img {
    height: 65px;
    margin-top: 0px;
    margin-left: -10px;
  }
}

.pageHeader {
  margin-top: 0;
  height: 44px;
  background: linear-gradient(90deg, #e92c21 0%, #a4241f 100%);
  text-align: center;
  //position:absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.termsofuse {
  padding: 16px !important;
  margin: 42px auto;
  display: block;
  max-width: 600px;
  @media (max-width: 425px) {
    margin: 42px 20px;
  }
  h2 {
    border-bottom: 1px solid $grey;

    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
  }
  p {
    font-size: 16px;
    line-height: 20px;
  }
  .holder {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid $grey;
    label {
      margin-left: 4px;
      line-height: $f18px;
    }
    button {
      margin: 0;
      margin-top: 22px;

      height: 48px;
      //border: 1px solid #5a5f65;
    }
  }
  .termsOfUseContent {
    label {
      margin: 12px 0;
    }
  }
}
