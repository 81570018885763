/*

@license
dhtmlxScheduler v.5.3.11 Standard

To use dhtmlxScheduler in non-GPL projects (and get Pro version of the product), please obtain Commercial/Enterprise or Ultimate license on our site https://dhtmlx.com/docs/products/dhtmlxScheduler/#licensing or contact us at sales@dhtmlx.com

(c) XB Software Ltd.
*/

.dhtmlx_message_area {
  position: fixed;
  right: 5px;
  width: 250px;
  z-index: 11;
}

.dhtmlx-info {
  min-width: 120px;
  padding: 4px 4px 4px 20px;
  font-family: Tahoma;
  z-index: 14;
  overflow: hidden;
  margin: 5px;
  margin-bottom: 10px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &.hidden {
    height: 0;
    padding: 0;
    border-width: 0;
    margin: 0;
    overflow: hidden;
  }
}

.dhtmlx_modal_box {
  overflow: hidden;
  display: inline-block;
  position: fixed;
  min-width: 300px;
  width: 330px;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 14px #888;
  border: 1px solid #fff;
  z-index: 18;
  border-radius: 6px;
  font-family: Tahoma, san-serif;
}

.dhtmlx_popup_text {
  margin: 15px 15px 5px 15px;
  font-size: 14px;
  color: #000;
  min-height: 30px;
  border-radius: 6px;
}

.dhtmlx_popup_title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-width: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAoCAMAAAAIaGBFAAAAhFBMVEVwcHBubm5sbGxqampoaGhmZmZlZWVjY2NhYWFfX19dXV1bW1taWlpYWFhWVlZUVFRSUlJRUVFPT09NTU1LS0tJSUlHR0dGRkZERERCQkJAQEA+Pj49PT09PT0+Pj5AQEBBQUFDQ0NERERGRkZHR0dJSUlKSkpMTExMTEw5OTk5OTk5OTkny8YEAAAAQklEQVQImQXBCRJCAAAAwKVSQqdyjSPXNP7/QLsIhA6OTiJnF7GrRCpzc/fw9PKW+/gqlCq1RqvTG/yMJrPF6m/bAVEhAxxnHG0oAAAAAElFTkSuQmCC);
  background-image: -webkit-linear-gradient(
    top,
    #707070 1%,
    #3d3d3d 70%,
    #4c4c4c 97%,
    #393939 97%
  );
  background-image: -moz-linear-gradient(
    top,
    #707070 1%,
    #3d3d3d 70%,
    #4c4c4c 97%,
    #393939 97%
  );
}

.dhtmlx-info,
.dhtmlx_button,
.dhtmlx_popup_button {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  cursor: pointer;
}

.dhtmlx_popup_text {
  overflow: hidden;
}

.dhtmlx_popup_controls {
  border-radius: 6px;
  padding: 5px 5px 9px 5px;
}

.dhtmlx_button {
  height: 30px;
  line-height: 28px;
  display: inline-block;
  margin: 0 5px;
  border-radius: 6px;
  color: #fff;
  border: 1px solid #838383;
  box-shadow: none;
}

.dhtmlx_popup_button {
  height: 30px;
  line-height: 28px;
  display: inline-block;
  margin: 0 5px;
  border-radius: 6px;
  color: #fff;
  border: 1px solid #838383;
  box-shadow: none;
  min-width: 120px;
  background: #fff;
}

.dhtmlx_button input {
  border: 1px solid #fff;
  background: #fff;
  background-image: none;
  border-radius: 6px;
  font-size: 15px;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  text-shadow: none;
  color: #444;
  font-weight: 400;
  padding: 0;
  margin: 0;
  vertical-align: top;
  height: 28px;
  line-height: 28px;
}

.dhtmlx_popup_button {
  div {
    border: 1px solid #fff;
    background: #fff;
    background-image: none;
    border-radius: 6px;
    font-size: 15px;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    text-shadow: none;
    color: #444;
    font-weight: 400;
    padding: 0;
    margin: 0;
    vertical-align: top;
    height: 28px;
    line-height: 28px;
  }

  &.dhtmlx_ok_button {
    div {
      background: #22a1bc;
      border: 1px solid #22a1bc;
      color: #fff;
      text-shadow: 0 -1px 0 #6f6f6f;
      outline: 0;
    }

    border: 1px solid #22a1bc;
    background-color: #22a1bc;
    outline: 0;
  }
}

.dhtmlx_button input {
  &:active,
  &:focus {
    background: #fff;
    background-image: none;
    color: #444;
    box-shadow: none;
    outline: 0;
  }
}

.dhtmlx_popup_button {
  div {
    &:active,
    &:focus {
      background: #fff;
      background-image: none;
      color: #444;
      box-shadow: none;
      outline: 0;
    }
  }

  &.dhtmlx_cancel_button div:active {
    background-color: transparent;
    border-color: transparent;
    outline: 0;
  }
}

div.dhx_modal_cover {
  background-color: #000;
  cursor: default;
  opacity: 0.2;
  position: fixed;
  z-index: 17;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  zoom: 1;
}

.dhtmlx-info img,
.dhtmlx_modal_box img {
  float: left;
  margin-right: 20px;
}

.dhtmlx-alert-error .dhtmlx_popup_title,
.dhtmlx-confirm-error .dhtmlx_popup_title {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAsCAIAAAArRUU2AAAATklEQVR4nIWLuw2AMBBDjVuQiBT2oWbRDATrnB0KQOJoqPzRe3BrHI6dcBASYREKovtK6/6DsDOX+stN+3H1YX9ciRgnYq5EWYhS2dftBIuLT4JyIrPCAAAAAElFTkSuQmCC);
}

.dhtmlx-alert-error,
.dhtmlx-confirm-error {
  border: 1px solid red;
}

.dhtmlx_popup_title {
  color: #fff;
  text-shadow: 1px 1px #000;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
}

.dhtmlx-error {
  font-size: 14px;
  color: #000;
  box-shadow: 0 0 10px #888;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #fff;
}

.dhtmlx-info {
  font-size: 14px;
  color: #000;
  box-shadow: 0 0 10px #888;
  padding: 0;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #fff;

  div {
    padding: 5px 10px 5px 10px;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #b8b8b8;
  }
}

.dhtmlx_message_area .dhtmlx-error {
  background-color: #d81b1b;
  border: 1px solid #ff3c3c;
  box-shadow: 0 0 10px #000;

  div {
    background-color: #d81b1b;
    border: 1px solid #940000;
    color: #fff;
  }
}

.dhtmlx_modal_box.dhtmlx-error .dhtmlx_popup_title {
  background: #d81b1b;
  border: 1px solid #ff3c3c;
  color: #fff;
}

.dhx_cal_event {
  .dhx_body,
  .dhx_footer,
  .dhx_header,
  .dhx_title {
    background-color: #1796b0;
    border: 1px solid transparent;
    color: #fff;
    overflow: hidden;
    width: 100%;
  }
}

.dhx_move_denied .dhx_cal_event {
  .dhx_header,
  .dhx_title {
    cursor: default;
  }
}

.dhx_cal_event {
  .dhx_header {
    height: 1px;
    margin: 0 0 0 1px;
    border-width: 1px 1px 0 1px;
    cursor: pointer;
  }

  .dhx_title {
    height: 12px;
    border-width: 1px 1px 0 1px;
    border-bottom-style: dotted;
    border-radius: 5px 4px 0 0;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    text-align: center;
    background-position: right;
    background-repeat: no-repeat;
    padding: 1px 0 0 0;
    cursor: pointer;
  }
}

.dhx_cal_container_rtl .dhx_cal_event .dhx_title {
  text-align: center;
  padding: 1px 0 0 0;
}

.dhx_cal_event {
  .dhx_body,
  &.dhx_cal_select_menu .dhx_body {
    border-width: 0 1px 1px 1px;
    padding: 5px 5px 8px 5px;
    border-radius: 0 0 4px 4px;
    font-weight: 400;
    line-height: normal;
  }
}

.dhx_cal_container_rtl .dhx_cal_event .dhx_body {
  padding: 5px 5px 8px 5px;
}

.dhx_cal_event.dhx_cal_select_menu {
  -webkit-box-shadow: 0 0 1px #fff;
  -moz-box-shadow: 0 0 1px #fff;
  box-shadow: 0 0 1px #fff;
}

.dhx_resize_denied {
  cursor: default !important;

  .dhx_event_resize {
    cursor: default !important;
  }
}

.dhx_cal_event {
  .dhx_event_resize {
    cursor: s-resize;
    cursor: ns-resize;
  }

  .dhx_footer,
  .dhx_select_menu_footer {
    height: 5px;
    margin: -6px 0 0 2px;
    border-width: 0;
    position: relative;
    top: auto;

    // background: #1796b0 url(imgs_dhx_terrace/resizing.png) no-repeat center center;
  }

  .dhx_resize_denied.dhx_event_resize.dhx_footer {
    height: 0;
  }
}

.dhx_cal_event_line {
  background-color: #1796b0;
  border: 1px solid transparent;
  border-radius: 2px;
  font-family: Arial, san-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  height: 20px;
  padding: 0 0 0 10px;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
}

.dhx_cal_container_rtl .dhx_cal_event_line {
  padding: 0 9px 0 0;
}

.dhx_cal_event_line_start {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.dhx_cal_container_rtl .dhx_cal_event_line_start {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.dhx_cal_event_line_end {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.dhx_cal_container_rtl {
  .dhx_cal_event_line_end {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .dhx_cal_event_line_start {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .dhx_cal_event_line_end {
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }
}

.dhx_cal_event_clear {
  height: 20px;
  padding: 0 0 0 2px;
  color: #0e64a0;
  font-family: Tahoma, san-serif;
  font-size: 8pt;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  font-weight: 400;
}

.dhx_cal_event.dhx_cal_editor {
  border: 1px solid #1796b0;
  border-top-style: solid;
  z-index: 10;
  position: absolute;
  overflow: hidden;
}

textarea.dhx_cal_editor {
  width: 100%;
  height: 100%;
  border: 0 solid #000;
  padding: 0;
  margin: 0;
  overflow: auto;
  font-family: Arial, san-serif;
  font-size: 12px;
}

div.dhx_menu_head {
  background-image: url(../../../src/assets/img/controls.png);
  background-position: 0 -43px;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-top: 1px;
  border: none;
  cursor: default;
}

.dhx_cal_container_rtl div.dhx_menu_head {
  margin-left: 0;
  margin-right: 5px;
}

div.dhx_menu_icon {
  background-image: url(../../../src/assets/img/controls.png);
  width: 20px;
  height: 20px;
  margin-left: -5px;
  margin-top: 0;
  border: none;
  cursor: pointer;
}

.dhx_cal_container_rtl div.dhx_menu_icon {
  margin-left: 0;
  margin-right: -3px;
}

div {
  &.icon_details {
    background-position: 0 0;
  }

  &.icon_edit {
    background-position: -22px 0;
  }

  &.icon_save {
    background-position: -84px -1px;
  }

  &.icon_cancel {
    background-position: -62px 0;
  }

  &.icon_delete {
    background-position: -42px 0;
  }
}

.dhx_month_link {
  position: absolute;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: right;
  cursor: pointer;
  padding-right: 10px;
  font-size: 8pt;
  font-family: Tahoma, san-serif;

  a {
    color: #00f;

    &:hover {
      text-decoration: underline;
      color: #0000b3;
    }
  }
}

.dhx_global_tip {
  font-family: Tahoma, Helvetica;
  text-align: center;
  font-size: 20px;
  position: fixed;
  top: 60px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #000;
  z-index: 14;
  padding: 20px 30px;
  width: 190px;

  div {
    font-size: 30px;
  }
}

@media (-moz-touch-enabled) {
  .dhx_cal_container {
    user-select: none;
    -moz-user-select: none;
  }
}

.dhx_cal_data div {
  &.dhx_cal_event_clear,
  &.dhx_cal_event_line {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.dhx_multi_day div {
  &.dhx_cal_event_clear,
  &.dhx_cal_event_line {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 550px) {
  .dhx_cal_event_clear,
  .dhx_cal_event_line {
    padding-left: 2px;
  }
}

.dhx_unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -moz-user-select: -moz-none;

  div {
    -webkit-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none;
  }
}

.dhx_cal_light {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #cecece;
  color: #2e2e2e;
  font-family: Tahoma, san-serif;
  font-size: 8pt;
  box-shadow: 5px 5px 5px #888;
  position: absolute;
  z-index: 15;
  width: 580px;
}

.dhx_cal_light_rtl {
  box-shadow: -5px 5px 5px #888;
}

.dhx_cal_ltitle {
  padding: 2px 0 2px 5px;
  height: 30px;
  line-height: 30px;
  border-bottom: 1px solid #cecece;
  background: 0 0;
  margin: 0;
  border-radius: 0;
  overflow: hidden;
  white-space: nowrap;
}

.dhx_cal_ltext.dhx_cal_template {
  position: relative;
}

.dhx_cal_ltitle span {
  white-space: nowrap;
}

.dhx_time {
  font-weight: 700;
  padding: 0 0 0 10px;
  font-size: 8pt;
  display: inline-block;
  margin: 0;
}

.dhx_cal_light_rtl .dhx_time {
  padding: 0 10px 0 0;
}

.dhx_cal_light_wide {
  width: 650px;
}

.dhx_mark {
  position: relative;
  top: 3px;

  // background-image: url(imgs/controls.gif);
  background-position: 0 -43px;
  padding-left: 10px;
}

.dhx_ie6 .dhx_mark {
  background-position: 6px -41px;
}

.dhx_cal_light {
  select {
    font-family: Tahoma, san-serif;
    font-size: 8pt;
    color: #2e2e2e;
    padding: 2px 2px 2px 2px;
    margin: 0;
  }

  .dhx_title {
    padding: 0 0 0 13px;
    font-size: 8pt;
    text-transform: none;
    color: inherit;
    display: inline-block;
    margin: 0;
  }
}

.dhx_cal_larea {
  border: 1px solid transparent;
  background-color: #fff;
  overflow: hidden;
}

.dhx_cal_light_wide .dhx_cal_larea {
  border-top-width: 0;
  margin: 0 0 0 3px;
}

.dhx_cal_light_rtl .dhx_cal_larea {
  margin: 0 3px 0 0;
}

.dhx_cal_light_wide .dhx_wrap_section {
  border-top: 0;
  border-bottom: 1px solid #cecece;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  padding: 5px 0;
  margin: 0;
}

.dhx_cal_lsection {
  background-color: #fff;
  color: #747473;
  font-weight: 700;
  font-family: Arial, san-serif;
  font-size: 18px;
  padding: 5px 0 5px 10px;
}

.dhx_cal_light_wide .dhx_cal_lsection {
  border: 0;
  float: left;
  text-align: right;
  width: 82px;
  width: 100px;
  min-height: 20px;
  line-height: normal;
  height: auto;
  font-size: 13px;
  padding: 5px 0 0 10px;
  margin: 0;
}

.dhx_cal_lsection .dhx_fullday {
  float: right;
  margin-right: 5px;
  color: #747373;
  font-family: Arial, san-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  vertical-align: top;
  cursor: pointer;

  input[type="checkbox"] {
    margin: 3px 3px 3px 4px;
  }
}

.dhx_cal_ltext {
  overflow: hidden;
  float: left;
  float: none;
  width: auto;
  height: auto;
  padding: 2px 0 2px 10px;
}

.dhx_readonly {
  height: 15px;
}

.dhx_cal_ltext textarea {
  background-color: #f9f9f9;
  overflow: auto;
  color: #2e2e2e;
  height: 100%;
  width: 100%;
  outline: none !important !important;
  resize: none;
  border: 1px solid #cecece;
  line-height: 20px;
  font-family: inherit;
  box-sizing: border-box;
  padding: 2px 2px 2px 2px;
}

.dhx_section_time {
  background-color: transparent;
  white-space: nowrap;
}

.dhx_cal_light_wide .dhx_section_time {
  padding: 2px 0 0 0 !important;
  height: 20px !important;
}

.dhx_section_time {
  text-align: center;
}

.dhx_cal_light_wide .dhx_section_time {
  background: 0 0;
}

.dhx_btn_set {
  padding: 0;
  float: left;
  margin: 12px 0 0 20px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 5px;
  text-transform: none;
  color: #747473;
}

.dhx_right_btn_set {
  margin-right: 20px;
}

.dhx_btn_set {
  div {
    float: left;
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    vertical-align: middle;
    cursor: pointer;
  }

  border: 1px solid #cecece;
  background-color: transparent;
  color: #454544;
  float: right;
  float: left;
}

.dhx_cancel_btn_set {
  border: 1px solid #cecece;
  background-color: transparent;
  color: #454544;
  float: right;
  float: left;
}

.dhx_cal_light_rtl {
  .dhx_btn_set,
  .dhx_cancel_btn_set {
    float: right;
  }
}

.dhx_save_btn {
  // background-image: url(imgs/controls.gif);
  background-position: -84px 0;
  width: 21px;
}

.dhx_cancel_btn {
  // background-image: url(imgs/controls.gif);
  background-position: -63px 0;
  width: 20px;
}

.dhx_delete_btn {
  // background-image: url(imgs/controls.gif);
  background-position: -42px 0;
  width: 20px;
}

.dhx_delete_btn_set {
  border: 1px solid #ff8831;
  background-color: #ff8831;
  color: #fff;
  text-shadow: 0 -1px 0 #93755f;
  margin: 12px 20px 0 20px;
}

.dhx_save_btn_set {
  border: 1px solid #cecece;
  color: #fff;
  text-shadow: 0 -1px 0 #6f6f6f;
  background-color: #22a1bc;
}

.dhx_cal_cover {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 14;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.1;
}

.dhx_custom_button {
  padding: 0 3px 0 3px;
  color: #747473;
  font-family: Arial, san-serif;
  font-size: 8pt;
  background-color: #fff;
  border: 1px solid #cecece;
  font-weight: 400;
  margin-right: 5px;
  margin-top: 0;
  cursor: pointer;

  div {
    cursor: pointer;
    float: left;
    height: 21px;
    line-height: 21px;
    vertical-align: middle;
  }
}

.dhx_cal_light_wide {
  .dhx_cal_lsection .dhx_fullday {
    float: none;
    margin: 0;
    font-weight: 700;
    font-size: 13px;
    cursor: pointer;
  }

  .dhx_cal_larea {
    width: 730px;
  }

  width: 738px;

  .dhx_cal_checkbox label {
    padding-left: 0;
  }
}

.dhx_wrap_section .dhx_cal_wide_checkbox input[type="checkbox"] {
  margin-top: 8px;
  margin-left: 14px;
}

.dhx_cal_light_rtl
  .dhx_wrap_section
  .dhx_cal_wide_checkbox
  input[type="checkbox"] {
  margin: 8px 14px 0 0;
}

.dhx_cal_light input {
  font-family: Tahoma, san-serif;
  font-size: 8pt;
  color: #747473;
}

#input_1518604462804 {
  margin: 0 0 0 11px;
  border-bottom-width: 0;
  border-bottom: 0;
}

.dhx_custom_button {
  float: right;
  height: 21px;
  width: 90px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.dhx_cal_light_wide {
  .dhx_custom_button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 6px;
  }

  .dhx_repeat_right {
    margin-right: 55px;
  }
}

.dhx_cal_light_rtl {
  direction: rtl;

  .dhx_cal_ltitle {
    padding: 2px 5px 2px 0;
  }

  .dhx_mark {
    padding: 0 10px 0 0;
  }

  .dhx_title {
    padding: 0 13px 0 0;
  }

  .dhx_wrap_section {
    padding: 5px 0;

    .dhx_cal_ltext.dhx_cal_template {
      direction: ltr;
      float: right;
      margin-right: 15px !important;
    }
  }

  .dhx_cal_lsection {
    float: right;
    text-align: left;
    margin: 0;
  }

  .dhx_cal_ltext {
    padding: 2px 10px 2px 0;

    textarea {
      padding: 2px 2px 2px 2px;
    }
  }

  .dhx_section_time {
    padding: 2px 0 0 0 !important;
  }

  select {
    padding: 2px 2px 2px 2px;
    margin: 0;
  }

  .dhx_custom_button {
    left: 15px;
    right: auto;
    right: unset;
  }
}

@media only screen and (max-width: 1023px) {
  .dhx_cal_overflow_container {
    overflow: auto !important;
  }

  .dhx_cal_light.dhx_cal_light_responsive {
    width: 98vw !important;
    height: auto !important;
    left: 1% !important;
    top: 1.953vw !important;
    font-size: 3.906vw !important;
    line-height: 3.906vw !important;
    padding-bottom: 2vw;

    .dhx_btn_set {
      margin-bottom: 1.953vw !important;
      font-size: 3.906vw;
      height: auto !important;
      padding: 1.953vw 1.563vw 1.953vw 1.563vw;

      div {
        height: 2.93vw;
        line-height: 2.93vw;
        padding: 0 1.563vw;
      }
    }

    .dhx_cal_larea {
      width: auto !important;
      margin-left: 0 !important;
      height: 100% !important;

      label {
        display: inline;
      }
    }

    .dhx_cal_lsection {
      font-size: 3.906vw !important;
      width: 23.438vw !important;
      margin-left: 3vw;
      line-height: 3.906vw !important;
      margin-right: 0;

      .dhx_custom_button {
        padding: 0.977vw 4.492vw 0.977vw 1.563vw;
        min-width: 25.391vw !important;
        min-height: 5.371vw;
      }
    }

    .dhx_cal_ltitle {
      height: 2rem !important;
      line-height: 2rem !important;
      margin-bottom: 0.5rem;

      span {
        font-size: 3.906vw !important;
      }
    }

    .dhx_cal_lsection div.dhx_custom_button,
    .dhx_cal_radio,
    .dhx_cal_template,
    .dhx_wrap_section .dhx_section_time select,
    select,
    textarea {
      font-size: 3.5vw;
      line-height: 3.5vw;
    }

    .dhx_cal_lsection div.dhx_custom_button label,
    .dhx_cal_radio label,
    .dhx_cal_template label,
    .dhx_wrap_section .dhx_section_time select label,
    select label,
    textarea label {
      margin: 1vw 3vw 1vw 0.5vw;
      vertical-align: top;
    }

    .dhx_section_time select,
    select {
      min-height: 5.859vw;
    }

    .dhx_cal_ltext {
      margin-left: 26.5vw !important;
      height: 100% !important;
      min-height: 4.883vw;
    }

    .dhx_repeat_center,
    .dhx_repeat_divider,
    .dhx_repeat_left,
    .dhx_repeat_right {
      height: 11.23vw;
    }

    .dhx_wrap_section .dhx_section_time {
      padding-left: 28.5vw !important;
      width: auto !important;
      min-height: 19.531vw !important;

      select {
        float: left;
        margin: 1.465vw 0.5vw 1.465vw 0.5vw !important;
      }

      .dhx_lightbox_time_select {
        width: 14.648vw;
        padding-left: 1.953vw !important;
      }

      .dhx_lightbox_day_select {
        min-width: 7.813vw !important;
      }

      .dhx_lightbox_year_select {
        min-width: 13.672vw !important;
      }

      .dhx_lightbox_month_select {
        width: 20.508vw !important;
      }
    }

    .dhx_cal_wide_checkbox {
      height: 100% !important;
    }

    .dhx_multi_select_control {
      height: 100% !important;
      vertical-align: middle;

      input {
        vertical-align: middle;
      }
    }

    input {
      width: 4.883vw;
      height: 4.883vw;
    }

    .dhx_custom_button {
      div {
        height: auto;
      }

      .dhx_custom_button_recurring {
        background-size: 7.813vw !important;
        width: 6.836vw !important;
        height: 6.836vw !important;
        background-repeat: no-repeat !important;
        background-position: (-25px) -1px !important;
        padding-top: 0 !important;
      }
    }

    .dhx_wrap_section {
      min-height: 7.813vw !important;
      max-width: 92.773vw !important;
      margin: 0.977vw 0;
    }

    .dhx_form_repeat {
      margin: 6.836vw 0 0 23.438vw !important;

      form {
        height: 48.828vw !important;
      }

      div {
        width: 70vw !important;
      }

      .dhx_repeat_left br {
        content: " ";
      }

      input {
        top: 1.465vw !important;
      }

      select {
        width: 14.648vw !important;
      }

      label {
        padding-right: 1.465vw;
        white-space: nowrap;
      }

      .dhx_repeat_date,
      .dhx_repeat_text {
        font-size: 2.93vw !important;
        line-height: 2.93vw !important;
      }

      form {
        font-size: 2.93vw !important;
        line-height: 2.93vw !important;

        select {
          font-size: 2.93vw !important;
          line-height: 2.93vw !important;
        }
      }

      label {
        font-size: 2.93vw !important;
        line-height: 2.93vw !important;
      }

      .dhx_repeat_center,
      .dhx_repeat_divider,
      .dhx_repeat_left,
      .dhx_repeat_right {
        height: auto;
        float: none;
      }

      .dhx_repeat_center {
        height: auto !important;
        padding-bottom: 10px;
      }

      .dhx_repeat_left {
        padding-bottom: 10px;
      }

      .dhx_repeat_text {
        width: 6.836vw !important;
        height: 5.859vw !important;
        border: 1px solid #cecece;
        background-color: #f9f9f9;
      }

      .dhx_repeat_checkbox {
        width: 2.93vw !important;
        height: 2.93vw !important;
      }

      .dhx_repeat_days label {
        font-size: 2.441vw !important;
        line-height: 2.5vw !important;
      }

      .dhx_repeat_right {
        height: auto !important;
        float: left;

        .dhx_repeat_date {
          width: 19.531vw !important;
          height: 7.813vw !important;
          padding-left: 1.465vw !important;
          margin-top: 1.465vw;
        }
      }

      .dhx_repeat_divider {
        border-top: 1px solid #cecece;
        border-left: 0;
        width: 70vw;
        height: 1px;
      }
    }

    .dhx_fullday input {
      vertical-align: middle;
    }

    .combo {
      height: auto !important;
    }

    .dhx_custom_button .dhx_custom_button_recurring {
      background-position: (-14px) -1px !important;
      width: 4.836vw !important;
      height: 4.836vw !important;
    }
  }

  .dhx_cal_light_rtl.dhx_cal_light_responsive {
    .dhx_cal_larea {
      margin-right: 0 !important;
    }

    .dhx_cal_lsection {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .dhx_cal_ltext {
      margin-right: 23.438vw !important;
      margin-left: 0 !important;
    }

    .dhx_wrap_section {
      .dhx_cal_wide_checkbox input[type="checkbox"] {
        margin: 0.781vw 1.367vw 0 0;
      }

      .dhx_section_time {
        padding-right: 23.438vw !important;
        padding-left: 0 !important;

        select {
          float: right;
        }

        .dhx_lightbox_time_select {
          padding-right: 1.953vw !important;
          padding-left: 0 !important;
        }

        span {
          display: none;
        }
      }
    }

    .dhx_form_repeat {
      margin: 6.836vw 23.438vw 0 0 !important;

      label {
        padding-left: 1.465vw;
        padding-right: 0 !important;
      }

      .dhx_repeat_right {
        float: left;

        .dhx_repeat_date {
          padding-right: 1.465vw !important;
          padding-left: 0 !important;
        }
      }
    }

    .dhx_multi_select_control {
      &.dhx_multi_select_control_vertical {
        float: right;
      }

      float: auto;
      float: unset;
    }
  }
}

.dhx_minical_popup {
  position: absolute;
  z-index: 16;
  width: 251px;
  height: 175px;
}

.dhx_scale_bar_header {
  position: absolute;
  border-bottom: 1px dotted #a39588;
  width: 100%;
}

.dhx_expand_icon {
  position: absolute;
  top: 0;
  right: 0;

  // background-image: url(imgs/collapse_expand_icon.gif);
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-position: 0 18px;
  z-index: 4;
}

.dhx_scheduler_agenda .dhx_cal_data {
  // background-image: url(imgs/databg.png);
}

.dhx_agenda_area {
  width: 100%;
  overflow-y: auto;

  // background-image: url(imgs/databg.png);
}

.dhx_agenda_line {
  height: 21px;
  clear: both;
  overflow: hidden;

  div {
    float: left;
    width: 188px;
    border-right: 1px dotted #8894a3;
    text-align: center;
    line-height: 21px;
    overflow: hidden;
  }
}

.dhx_cal_container_rtl .dhx_agenda_line {
  div {
    float: right;
  }

  .description_header {
    padding-right: 15px;
    float: right;
  }
}

.dhx_cal_header div {
  &.dhx_agenda_line {
    div,
    span {
      font-weight: 400;
      color: #747473;
    }
  }

  &.dhx_map_line {
    font-weight: 400;
    color: #747473;
  }
}

.dhx_cal_container_rtl {
  .dhx_cal_header div.dhx_map_line {
    float: right;
  }

  .dhx_agenda_area .dhx_agenda_line div {
    border-left: 0 dotted #000;
  }
}

.dhx_agenda_area .dhx_agenda_line div {
  border-right: 0 dotted #000;
}

.dhx_v_border {
  position: absolute;
  left: 187px;
  top: 0;
  border-right: 1px dotted #8894a3;
  width: 1px;
  height: 100%;
}

.dhx_cal_container_rtl .dhx_v_border {
  right: 186px;
  left: unset;
}

.dhx_agenda_line .dhx_event_icon {
  width: 20px;
  border-width: 0;

  // background: url(imgs/icon.png) no-repeat;
  background-position: 5px 4px;
  cursor: pointer;
}

.dhx_cal_container_rtl .dhx_agenda_line .dhx_event_icon {
  background-position-x: 2px;
}

.dhx_agenda_line span {
  padding-left: 5px;
  line-height: 21px;
}

.dhx_cal_container_rtl .dhx_agenda_line span {
  padding-left: 0;
  padding-right: 5px;
}

.dhx_year_body {
  border-left: 1px solid #cecece;
}

.dhx_year_week {
  position: relative;
}

.dhx_scale_bar_last {
  border-right: 1px solid #cecece;
}

.dhx_cal_container_rtl {
  .dhx_year_body {
    border-left: 0;
    border-right: 1px solid #cecece;
  }

  .dhx_year_box .dhx_scale_bar_last {
    border-left: 1px solid #cecece;
  }
}

.dhx_year_month {
  height: 18px;
  padding-top: 3px;
  border: 1px solid #cecece;
  text-align: center;
  vertical-align: middle;
}

.dhx_year_body {
  .dhx_after .dhx_month_head,
  .dhx_before .dhx_month_head {
    color: #e2e3e6;

    a {
      color: #e2e3e6;
    }
  }

  .dhx_month_body {
    height: 0;
    overflow: hidden;
  }
}

.dhx_month_head.dhx_year_event {
  background-color: #ffe763;
  color: "";
}

.dhx_year_body {
  .dhx_after .dhx_month_head,
  .dhx_before .dhx_month_head {
    cursor: default;
  }
}

.dhx_year_tooltip {
  border: 1px solid #bbb;

  // background-image: url(imgs/databg.png);
  position: absolute;
  z-index: 12;
  width: 300px;
  height: auto;
  font-family: Tahoma;
  font-size: 8pt;
  overflow: hidden;
}

.dhx_tooltip_line {
  line-height: 20px;
  height: 20px;
  overflow: hidden;

  .dhx_event_icon {
    width: 20px;
    height: 20px;
    padding-right: 10px;
    float: left;
    border-width: 0;
    position: relative;

    // background: url(imgs/icon.png) no-repeat;
    background-position: 5px 4px;
    cursor: pointer;
  }
}

.dhx_tooltip_date {
  float: left;
  width: auto;
  padding-left: 5px;
  text-align: center;
}

.dhx_text_disabled {
  color: #2e2e2e;
  font-family: Tahoma, san-serif;
  font-size: 8pt;
}

.dhx_mini_calendar {
  -moz-box-shadow: 5px 5px 5px #888;
  -khtml-box-shadow: 5px 5px 5px #888;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -user-select: none;

  .dhx_month_head {
    cursor: pointer;
  }

  .dhx_calendar_click {
    background-color: #c2d5fc;
  }
}

.dhx_cal_navline div.dhx_minical_icon {
  width: 18px;
  height: 18px;
  left: 190px;
  top: 1px;
  cursor: pointer;

  // background: url(imgs_dhx_terrace/calendar.gif) no-repeat;
}

.dhx_grid_rtl {
  direction: rtl;
}

.dhx_matrix_scell,
.dhx_timeline_scale_header {
  height: 100%;
  font-family: Tahoma, san-serif;
  font-size: 8pt;
  color: inherit;
  font-weight: 500;
}

.dhx_matrix_cell,
.dhx_matrix_scell {
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #cecece;
  border-right: 1px solid #cecece;
}

.dhx_cal_container_rtl {
  .dhx_matrix_cell,
  .dhx_matrix_scell {
    border-right: unset;
    border-left: 1px solid #cecece;
  }
}

.dhx_timeline_scale_header {
  border-right: 1px solid #cecece;
}

.dhx_cal_container_rtl .dhx_timeline_scale_header {
  border-right: unset;
  border-left: 1px solid #cecece;
}

.dhx_cal_data .dhx_timeline_table_wrapper div {
  box-sizing: border-box;
}

.dhx_matrix_cell {
  background-color: #fff;
}

.dhx_matrix_line {
  overflow: hidden;
}

.dhx_matrix_cell div,
.dhx_matrix_scell div {
  overflow: hidden;
  text-align: center;
  height: auto;
}

.dhx_cal_lsection .dhx_readonly {
  font-size: 9pt;
  font-size: 8pt;
  padding: 2px;
  color: #887a2e;
}

.dhx_cal_event_line .dhx_event_resize {
  cursor: w-resize;
  cursor: ew-resize;

  // background: url(imgs_dhx_terrace/resize_dots.png) repeat-y;
  position: absolute;
  top: 0;
  width: 4px;
  height: 100%;
}

.dhx_event_resize_start {
  left: 0;
}

.dhx_cal_container_rtl .dhx_event_resize_start {
  left: auto;
  left: unset;
  right: 0;
}

.dhx_event_resize_end {
  right: 0;
}

.dhx_cal_container_rtl .dhx_event_resize_end {
  right: auto;
  right: unset;
  left: 0;
}

.dhx_data_table.folder .dhx_matrix_cell {
  background-color: #969394;
  cursor: pointer;
}

.dhx_matrix_scell {
  &.folder {
    background-color: #969394;
    cursor: pointer;
  }

  .dhx_scell_level0 {
    padding-left: 5px;
  }

  .dhx_scell_level1 {
    padding-left: 20px;
  }

  .dhx_scell_level2 {
    padding-left: 35px;
  }

  .dhx_scell_level3 {
    padding-left: 50px;
  }

  .dhx_scell_level4 {
    padding-left: 65px;
  }

  &.folder {
    border-right: 0;
    font-weight: 700;
    text-align: left;

    .dhx_scell_expand {
      float: left;
      width: 10px;
      padding-right: 3px;
      position: relative;
    }

    .dhx_scell_name {
      float: left;
      width: auto;
      color: inherit;
      text-transform: none;
      font-weight: inherit;
    }
  }

  &.item .dhx_scell_name {
    padding-left: 15px;
    text-align: left;
  }
}

.dhx_cal_container_rtl .dhx_matrix_scell {
  .dhx_scell_level0 {
    padding-left: 0;
    padding-right: 5px;
  }

  .dhx_scell_level1 {
    padding-left: 0;
    padding-right: 20px;
  }

  .dhx_scell_level2 {
    padding-left: 0;
    padding-right: 35px;
  }

  .dhx_scell_level3 {
    padding-left: 0;
    padding-right: 50px;
  }

  .dhx_scell_level4 {
    padding-left: 0;
    padding-right: 65px;
  }

  &.folder {
    .dhx_scell_expand {
      float: right;
      padding-right: 0;
      padding-left: 3px;
    }

    .dhx_scell_name {
      float: right;
    }
  }

  &.item .dhx_scell_name {
    padding-left: 0;
    padding-right: 15px;
    text-align: right;
  }
}

.dhx_data_table.folder .dhx_matrix_cell {
  border-right: 0;
}

.dhx_section_timeline {
  overflow: hidden;
  padding: 4px 0 2px 10px;

  select {
    width: 552px;
  }
}

.dhx_map_area {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  // background-image: url(imgs/databg.png);
}

.dhx_map_line .dhx_event_icon {
  width: 20px;
  border-width: 0;

  // background: url(imgs/icon.png) no-repeat;
  background-position: 5px 4px;
  cursor: pointer;
}

.dhx_cal_container_rtl .dhx_map_line .dhx_event_icon {
  margin-right: 3px;
}

.dhx_map_line {
  height: 21px;
  clear: both;
  overflow: hidden;
}

.dhx_map {
  position: absolute;
}

.dhx_map_line {
  div,
  .headline_description {
    float: left;
    border-right: 1px dotted #8894a3;
    text-align: center;
    line-height: 21px;
    overflow: hidden;
  }

  .dhx_map_description {
    float: left;
    border-right: 0 dotted #8894a3;
    text-align: center;
    line-height: 21px;
    overflow: hidden;
  }

  .headline_date,
  .headline_description {
    border-left: 0;
  }
}

.dhx_cal_container_rtl .dhx_map_line {
  div {
    float: right;
  }

  .headline_date,
  .headline_description {
    float: right;
    border-left: 1px solid #cecece;
    border-right: 0;
  }
}

.dhx_map_line .line_description {
  float: left;
  border-right: 1px dotted #8894a3;
  text-align: left;
  padding-left: 5px;
  line-height: 21px;
  overflow: hidden;
}

.dhx_cal_container_rtl .dhx_map_line .line_description {
  float: right;
  text-align: right;
  padding-left: unset;
}

.dhx_map_line.highlight {
  background-color: #c4c5cc;
}

.dhx_map_area .dhx_map_line div {
  border-right: 0 dotted #8894a3;
}

.dhtmlXTooltip.tooltip {
  -moz-box-shadow: 3px 3px 3px #888;
  -webkit-box-shadow: 3px 3px 3px #888;
  -o-box-shadow: 3px 3px 3px #888;
  box-shadow: 3px 3px 3px #888;
  background-color: #fff;
  cursor: default;
  padding: 10px;
  position: fixed;
  z-index: 9;
  opacity: 1;
  font-family: Arial, san-serif;
}

.dhtmlXTooltip_rtl {
  direction: rtl;
}

.dhx_tooltip_rtl {
  direction: rtl;

  .dhx_tooltip_date {
    float: right;
    padding: 0 5px 0 0;
  }

  .dhx_tooltip_line {
    margin: 0 0 0 12px;
    padding: 0 4px 0 0;

    .dhx_event_icon.icon_details {
      float: right;
      padding: 0 0 0 10px;
    }
  }
}

.dhx_cal_checkbox label {
  padding-left: 5px;
}

.dhx_cal_light {
  .radio {
    padding: 2px 0 2px 10px;

    input,
    label {
      line-height: 15px;
    }

    input {
      vertical-align: middle;
      margin: 0;
      padding: 0;
    }

    label {
      vertical-align: middle;
      padding-right: 10px;
    }
  }

  .combo {
    padding: 4px;
  }
}

.dhx_cal_light_wide {
  .combo > div,
  .dhx_combo_box {
    width: 608px !important;
    left: 10px;
  }
}

.dhx_wa_column {
  float: left;
}

.dhx_cal_container_rtl .dhx_wa_column {
  float: right;
}

.dhx_wa_column_last .dhx_wa_day_cont {
  border-left: 1px solid #cecece;
}

.dhx_cal_container_rtl .dhx_wa_column_last .dhx_wa_day_cont {
  border-right: 1px solid #cecece;
}

.dhx_wa_scale_bar {
  font-family: Tahoma, san-serif;
  padding-left: 10px;
  font-size: 11px;
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.dhx_cal_container_rtl .dhx_wa_scale_bar {
  padding-left: 0;
  padding-right: 10px;
}

.dhx_wa_day_data {
  background-color: #fcfefc;
  overflow-y: auto;
}

.dhx_wa_ev_body {
  border-bottom: 1px solid #cecece;
  font-size: 12px;
  padding: 5px 0 5px 7px;
}

.dhx_cal_container_rtl .dhx_wa_ev_body {
  padding: 5px 7px 5px 0;
}

.dhx_wa_ev_body_rtl {
  direction: rtl;
}

.dhx_wa_dnd {
  font-family: Tahoma, san-serif;
  position: absolute;
  padding-right: 7px;
  color: #887ae2 !important;
  background-color: #ffe763 !important;
  border: 1px solid #b7a543;
}

.dhx_wa_ev_body.dhx_cal_event_selected {
  background-color: #9cc1db;
  color: #fff;
}

.dhx_second_scale_bar {
  border-bottom: 1px solid #cecece;
  padding-top: 2px;
}

.dhx_cal_header div.dhx_grid_line div {
  border-left: 1px solid #cecece;
}

.dhx_cal_container_rtl .dhx_cal_header div.dhx_grid_line div {
  border-right: 1px solid #cecece;
  border-left: 0;
}

.dhx_grid_area {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fcfefc;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;
  }

  td {
    table-layout: fixed;
    text-align: center;
  }
}

.dhx_grid_line {
  height: 21px;
  clear: both;
  overflow: hidden;

  div {
    float: left;
    cursor: default;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    line-height: 21px;
    overflow: hidden;
  }
}

.dhx_cal_container_rtl .dhx_grid_line div {
  float: right;
}

.dhx_grid_area td,
.dhx_grid_line div {
  padding-left: 8px;
  padding-right: 8px;
}

.dhx_grid_area tr.dhx_grid_event {
  height: 21px;
  overflow: hidden;
  margin: 0 0 1px 0;

  td {
    border-bottom: 1px solid #eceef4;
  }
}

.dhx_cal_container_rtl {
  .dhx_grid_area tr.dhx_grid_event td {
    border-bottom: 1px solid #eceef4;
    direction: ltr;
  }

  .dhx_map_line div.dhx_map_event_time {
    float: right;
    direction: ltr;
  }
}

.dhx_grid_area {
  tr.dhx_grid_event {
    &:nth-child(2n) td,
    &:nth-child(2n + 1) td {
      border-bottom-width: 0;
      border-bottom-style: none;
    }

    &:nth-child(2n) {
      background-color: #eceef4;
    }
  }

  .dhx_grid_dummy {
    table-layout: auto;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.dhx_grid_v_border {
  position: absolute;
  border-right: 1px solid #a4bed4;
  width: 1px;
  height: 100%;
}

.dhx_cal_container_rtl .dhx_grid_v_border {
  border-left: 1px solid #a4bed4;
  border-right: 0;
}

.dhx_grid_event_selected {
  background-color: #9cc1db !important;
  color: #fff !important;
}

.dhx_grid_sort_desc .dhx_grid_view_sort {
  background-position: 0 -55px;
}

.dhx_grid_sort_asc .dhx_grid_view_sort {
  background-position: 0 -66px;
}

.dhx_grid_view_sort {
  width: 10px;
  height: 10px;
  position: absolute;
  border: none !important;
  top: 5px;
  background-repeat: no-repeat;

  // background-image: url(imgs/images.png);
}

.dhx_marked_timespan {
  position: absolute;
  width: 100%;
  margin-left: 0;
}

.dhx_time_block {
  position: absolute;
  width: 100%;
  background: silver;
  opacity: 0.4;
  z-index: 1;
}

.dhx_time_block_reset {
  opacity: 1;
}

.dhx_scheduler_month .dhx_marked_timespan,
.dhx_mini_calendar .dhx_marked_timespan {
  display: none;
}

.dhx_now_time {
  width: 100%;
  border-bottom: 2px solid red;
}

.dhx_scheduler_month .dhx_now_time {
  border-bottom: 0;
  border-left: 2px solid red;
}

.dhx_matrix_now_time {
  border-left: 2px solid red;
  opacity: 0.5;
}

.dhx_now_time {
  opacity: 0.5;
}

.dhx_cal_quick_info {
  border: 2px solid #888;
  border-radius: 5px;
  position: absolute;
  z-index: 8;
  font-family: Tahoma, san-serif;
  font-size: 8pt;
  background-color: rgba(50, 50, 50, 0.5);
  padding: 0 0 0 7px;
  width: 300px;
  transition: left 0.5s ease, right 0.5s;
  -moz-transition: left 0.5s ease, right 0.5s;
  -webkit-transition: left 0.5s ease, right 0.5s;
  -o-transition: left 0.5s ease, right 0.5s;
}

.dhx_no_animate {
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none;
}

.dhx_cal_quick_info.dhx_qi_left .dhx_qi_big_icon {
  float: right;
}

.dhx_quick_info_rtl.dhx_cal_quick_info.dhx_qi_left .dhx_qi_big_icon {
  float: left;
}

.dhx_cal_qi_title {
  padding: 5px 0 10px 5px;
  color: #fff;
  letter-spacing: 1px;
}

.dhx_cal_container_rtl .dhx_cal_qi_title {
  padding: 5px 18px 10px 0;
  text-align: right;
}

.dhx_cal_qi_tdate {
  font-size: 14px;
}

.dhx_cal_qi_tcontent {
  font-size: 18px;
  font-weight: 700;
}

.dhx_cal_qi_content {
  border: 1px solid #888;
  background-color: #fefefe;
  padding: 16px 8px;
  font-size: 14px;
  color: #444;
  width: 275px;
  overflow: hidden;
}

.dhx_quick_info_rtl .dhx_cal_qi_content {
  padding: 16px 8px;
  direction: rtl;
}

.dhx_qi_big_icon {
  min-width: 60px;
  padding: 5px 10px 5px 5px;
  margin: 5px 9px 5px 0;
  background-color: #1796b0;
  border-bottom: 1px solid #666;
  border-right: 1px solid #666;
  border-radius: 5px;
  line-height: 20px;
  color: #fff;
  vertical-align: middle;
  cursor: pointer;
  float: left;
}

.dhx_quick_info_rtl .dhx_qi_big_icon {
  padding: 5px 5px 5px 10px;
  margin: 5px 0 5px 9px;
}

.dhx_cal_qi_controls div {
  float: left;
  height: 20px;
  text-align: center;
  line-height: 20px;
}

.dhx_quick_info_rtl {
  .dhx_cal_qi_controls div {
    float: right;
  }

  &.dhx_qi_right .dhx_cal_qi_controls {
    padding-right: 15px;
  }
}

.dhx_qi_big_icon .dhx_menu_icon {
  margin: 0 8px 0 0;
}

.dhx_quick_info_rtl .dhx_qi_big_icon .dhx_menu_icon {
  margin: 0 0 0 8px;
}

.dhx_drag_marker {
  width: 100%;
  opacity: 0.5;
  background-color: #ffe763;
  position: absolute;
  box-sizing: border-box !important;
  border-top: 1px solid #b6b6b6;
  border-bottom: 1px solid #b6b6b6;
}

.dhx_focus_slot {
  background: #ffe763;
  position: absolute;
  pointer-events: none;
  opacity: 0.3;
}

.dhx_cal_container :focus {
  outline-style: solid;
  outline-style: auto;
}

.dhx_no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.dhx_drag_placeholder {
  z-index: 10;
  opacity: 0.8;

  .dhx_cal_select_menu {
    display: none;
  }
}

.dhx_timeline_scale_header {
  position: absolute;
  z-index: 2;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  background-color: #fff;
}

.dhx_timeline_label_wrapper {
  overflow: hidden;
  background-color: #fff;
  position: absolute;
  z-index: 1;
}

.dhx_timeline_label_col {
  position: relative;
}

.dhx_timeline_label_row {
  position: absolute;
  left: 0;
  background-color: inherit;
}

.dhx_timeline_data_wrapper {
  position: absolute;
  z-index: 0;
  left: 0;
  width: 100%;
}

.dhx_timeline_data_row {
  position: relative;
}

.dhx_timeline_data_cell {
  position: absolute;
  top: 0;
  height: 100%;
}

.dhx_timeline_table_wrapper {
  .dhx_marked_timespan {
    z-index: 1;
  }

  .dhx_time_block {
    z-index: 2;
  }

  .dhx_cal_event_line {
    z-index: 1;
  }

  .dhx_timeline_scrollable_data {
    overflow-x: auto;
  }
}

.dhtmlx_modal_box * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.dhx_cal_data {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  div,
  table * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.dhx_cal_header {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  div {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.dhx_cal_navline div {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.dhx_cal_quick_info,
.dhx_multi_day {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  div {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.dhx_tooltip_line div {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.dhx_cal_light {
  .dhx_cal_lsection,
  .dhx_wrap_section {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.dhx_form_repeat label {
  margin-bottom: 0;
}

.dhx_cal_data {
  div.dhx_scale_hour {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  table {
    .dhx_matrix_cell,
    .dhx_matrix_scell {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

.dhx_cal_event div {
  line-height: normal;
}

.dhx_cal_data table {
  border-collapse: separate;
}

.dhx_cal_light .dhx_cal_radio input[type="radio"] {
  margin: 3px 3px 0 5px;
}

.dhx_cal_light_rtl .dhx_cal_radio input[type="radio"] {
  margin: 3px 5px 0 3px;
}

.dhx_cal_light .dhx_cal_radio label {
  display: inline-block;
  margin: 0;
  position: relative;
  top: -3px;
}

.dhx_cal_light_rtl .dhx_cal_radio label {
  margin: 0;
}

.dhx_cal_event div {
  line-height: normal;
}

.dhx_cal_container {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  font-family: Arial, san-serif;
  font-size: 12px;

  div {
    -moz-user-select: none;
    -moz-user-select: -moz-none;
  }
}

.dhx_cal_navline {
  height: 20px;
  position: absolute;
  z-index: 3;
  width: 750px;
  color: #2f3a48;

  div {
    position: absolute;
    white-space: nowrap;
    top: 14px;
  }

  .dhx_cal_date {
    border: 0;
    font-size: 18px;
    font-weight: 400;
    font-family: Arial, san-serif;
    width: 100%;
    text-align: center;
    left: 0;
    padding: 1px 0 0 0;
    color: #454544;
    z-index: -1;
  }
}

.dhx_cal_button .dhx_left_bg {
  width: 1px;
  overflow: hidden;
  height: 17px;
  z-index: 5;
  top: 0;
}

.dhx_cal_prev_button {
  cursor: pointer;
  right: 61px;
  background-color: none;

  // background-image: url(imgs_dhx_terrace/arrow_left.png);
  background-position: center center;
  background-repeat: no-repeat;
  height: 30px;
  width: 46px;
  border: 1px solid #cecece;
  border-radius: 5px 0 0 5px;
}

.dhx_cal_today_button {
  cursor: pointer;
  text-align: center;
  font-size: inherit;
  font-weight: 700;
  color: #747473;
  right: 123px;
  background-color: none;
  background-image: none;
  background-position: -62px 0;
  background-repeat: no-repeat;
  height: 30px;
  width: 80px;
  border: 1px solid #cecece;
  border-radius: 5px;
  text-decoration: none;
  text-transform: none;
}

.dhx_cal_next_button {
  cursor: pointer;
  right: 14px;
  background-color: none;

  // background-image: url(imgs_dhx_terrace/arrow_right.png);
  background-position: center center;
  background-repeat: no-repeat;
  height: 30px;
  width: 46px;
  border: 1px solid #cecece;
  border-radius: 0 5px 5px 0;
}

.dhx_cal_tab {
  width: 60px;
  height: 30px;
  background-color: none;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  font-weight: 700;
  padding-top: 0;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid #cecece;
  color: #747473;
  font-size: inherit;

  &.active {
    text-decoration: none;
    cursor: default;
    font-weight: 700;
    font-size: inherit;
    color: #454544;
    border: 1px solid #cecece;
    border-bottom: 1;
    background-color: #f0ede7;
    z-index: 25;
  }
}

.dhx_cal_tab_first {
  border-radius: 5px 0 0 5px;
  border-right: 0;
}

.dhx_cal_tab_last {
  border-radius: 0 5px 5px 0;
}

.dhx_cal_date,
.dhx_cal_next_button,
.dhx_cal_prev_button,
.dhx_cal_tab,
.dhx_cal_today_button {
  line-height: 30px;
}

.dhx_cal_header {
  position: absolute;
  overflow: hidden;
  left: 10px;
  background: #fff;
  border-top: 1px solid #cecece;
  border-right: 1px solid #cecece;
  z-index: 2;
}

.dhx_cal_container_rtl .dhx_cal_header {
  left: unset;
}

.dhx_cal_data {
  -webkit-tap-highlight-color: transparent;
  border-top: 1px solid #cecece;
  position: absolute;
  width: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.dhx_cal_container_rtl .dhx_cal_data {
  direction: rtl;
}

.dhx_cal_data,
.dhx_cal_event,
.dhx_cal_event_clear,
.dhx_cal_event_line {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.dhx_scale_bar {
  position: absolute;
  text-align: center;
  background-color: #fff;
  padding: 2px 0 0 0;
  border-left: 1px solid #cecece;
  font-size: 11px;
  font-weight: inherit;
  line-height: 16px;
  color: #767676;
}

.scheduler_container_resize_watcher {
  background: 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  border: 0;
  box-sizing: border-box;
  opacity: 0;
}

.dhx_scale_holder {
  position: absolute;
  border-right: 1px solid #cecece;

  // background-image: url(imgs_dhx_terrace/databg.png);
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

.dhx_cal_container_rtl {
  .dhx_scale_holder {
    border-left: 1px solid #cecece;
    border-right: 0;
  }

  .dhx_cal_header {
    border-right: 0;
    border-left: 1px solid #cecece;
  }

  .dhx_scale_bar {
    border-left: 0;
    border-right: 1px solid #cecece;
  }

  .dhx_month_head,
  .dhx_month_body {
    border-right: 0;
    border-left: 1px solid #cecece;
  }
}

.dhx_scale_holder_now {
  position: absolute;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  position: absolute;
  border-right: 1px solid #cecece;

  // background-image: url(imgs_dhx_terrace/databg_now.png);
}

.dhx_cal_container_rtl .dhx_scale_holder_now {
  border-right: 0;
  border-left: 1px solid #cecece;
}

.dhx_scale_hour {
  border-bottom: 1px solid #cecece;
  background-color: #fff;
  text-align: center;
  line-height: 44px;
  color: #767676;
  font-size: 11px;
  font-weight: inherit;
  overflow: hidden;
}

.dhx_month_head {
  height: 21px;
  padding: 0 5px 0 0;
  font-size: 12px;
  font-weight: inherit;
  line-height: 21px;
  color: #362d26;
  border-right: 1px solid #cecece;
  background-color: #fff;
  text-align: right;
}

.dhx_cal_container_rtl .dhx_month_head {
  text-align: left;
  padding: 0 0 0 5px;
}

.dhx_after .dhx_month_head,
.dhx_before .dhx_month_head {
  background-color: #fff;
  color: #bbb;
}

.dhx_now .dhx_month_head {
  background-color: #fff3a1;
  font-weight: 400;
}

.dhx_scale_hour_border {
  border-left: 1px dotted #8894a3;
}

.dhx_month_body {
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
  background-color: #fff;
}

.dhx_after .dhx_month_body,
.dhx_before .dhx_month_body {
  background-color: #fff;
}

.dhx_now {
  .dhx_month_body,
  .dhx_month_head {
    background-color: #fff3a1;
  }
}

.dhx_scale_ignore {
  display: none;
}

.dhx_cal_drag {
  position: absolute;
  z-index: 13;
  background-color: #ffe763;
  border: 1px solid #b7a543;
  opacity: 0.5;
}

.dhx_loading {
  position: absolute;
  width: 128px;
  height: 15px;

  // background-image: url(imgs/loading.gif);
  z-index: 13;
}

.dhx_multi_day,
.dhx_multi_day_icon {
  background-color: #fff;
  border-right: 1px solid #cecece;
}

.dhx_multi_day {
  position: absolute;
  border-top: 1px solid #cecece;
  background-color: #fff;
  border-right: none;
  box-shadow: none;
}

.dhx_cal_container_rtl .dhx_multi_day {
  direction: rtl;
}

.dhx_multi_day_icon,
.dhx_multi_day_icon_small {
  background-color: #fff;
  background-position: center center;
  border-bottom: 1px solid #cecece;
  border-right: 1px solid #cecece;
  background-repeat: no-repeat;
}

.dhx_cal_container_rtl {
  .dhx_multi_day_icon,
  .dhx_multi_day_icon_small {
    border-right: 0;
    border-left: 1px solid #cecece;
  }
}

.dhx_multi_day_icon {
  // background-image: url(imgs_dhx_terrace/clock_big.gif);
}

.dhx_multi_day_icon_small {
  // background-image: url(imgs_dhx_terrace/clock_small.gif);
}

.dhtmlxLayoutPolyContainer_dhx_skyblue .dhx_cal_container {
  background-color: #d0e5ff;
}

.dhx_month_body_border,
.dhx_month_head_border,
.dhx_scale_bar_border,
.dhx_scale_hour_border {
  border-left: 1px dotted #8894a3;
}

.dhx_cal_navline {
  .dhx_cal_export {
    width: 18px;
    height: 18px;
    margin: 2px;
    cursor: pointer;
    top: 0;

    &.pdf {
      left: 2px;

      // background-image: url(imgs/export_pdf.png);
    }

    &.ical {
      left: 24px;

      // background-image: url(imgs/export_ical.png);
    }
  }

  &.dhx_cal_navline_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1vw;
    box-sizing: border-box;

    .dhx_cal_tab {
      box-sizing: border-box;
      height: 32px;
    }

    .dhx_cal_tab_last {
      margin-right: 14px;
    }

    .dhx_cal_tab {
      border-left-style: none;
      box-shadow: -1px 0 0 #cecece;
    }

    .dhx_cal_date {
      width: auto;
      flex-grow: 1;
    }

    div {
      position: static;
    }

    .dhx_cal_navbar_row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 1vw;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
    }

    .dhx_cal_prev_button + .dhx_cal_next_button {
      border-left-style: none;
    }

    .dhx_cal_navbar_rows_container {
      display: flex;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      flex-grow: 1;
      height: 100%;
    }

    .dhx_cal_line_break,
    .dhx_cal_line_spacer {
      display: block;
      width: auto;
      flex-grow: 1;
    }

    .dhx_cal_tab_standalone,
    .dhx_cal_today_button {
      margin: 0 7px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_tab {
      min-width: 4vw;
      font-size: 1.3vw;
      box-sizing: content-box;
      padding: 0 0.5vw;
      width: auto;

      &.active {
        min-width: 4vw;
        font-size: 1.3vw;
        box-sizing: content-box;
        padding: 0 0.5vw;
        width: auto;
      }
    }

    .dhx_cal_today_button {
      min-width: 4vw;
      font-size: 1.3vw;
      box-sizing: content-box;
      padding: 0 0.5vw;
      width: auto;
    }

    .dhx_cal_next_button,
    .dhx_cal_prev_button {
      height: 2.5vw;
      line-height: 2.5vw;
    }

    .dhx_cal_tab {
      height: 2.5vw;
      line-height: 2.5vw;

      &.active {
        height: 2.5vw;
        line-height: 2.5vw;
      }
    }

    .dhx_cal_today_button {
      height: 2.5vw;
      line-height: 2.5vw;
    }

    .dhx_cal_tab_last {
      margin-right: 2vw;
    }
  }
}

@media only screen and (max-width: 840px) {
  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_tab {
      min-width: 4vw;
      font-size: 1.5vw;
      box-sizing: content-box;
      padding: 0 0.5vw;
      width: auto;

      &.active {
        min-width: 4vw;
        font-size: 1.5vw;
        box-sizing: content-box;
        padding: 0 0.5vw;
        width: auto;
      }
    }

    .dhx_cal_today_button {
      min-width: 4vw;
      font-size: 1.5vw;
      box-sizing: content-box;
      padding: 0 0.5vw;
      width: auto;
    }

    .dhx_cal_next_button,
    .dhx_cal_prev_button {
      height: 4vw;
      line-height: 4vw;
    }

    .dhx_cal_tab {
      height: 4vw;
      line-height: 4vw;

      &.active {
        height: 4vw;
        line-height: 4vw;
      }
    }

    .dhx_cal_today_button {
      height: 4vw;
      line-height: 4vw;
    }

    .dhx_cal_tab_last {
      margin-right: 1vw;
    }

    .dhx_cal_date {
      font-size: 2.5vw;
    }
  }
}

@media only screen and (max-width: 480px) {
  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_tab {
      font-size: 0.8rem;
      padding: 0 2vw;

      &.active {
        font-size: 0.8rem;
        padding: 0 2vw;
      }
    }

    .dhx_cal_today_button {
      font-size: 0.8rem;
      padding: 0 2vw;
    }

    .dhx_cal_next_button,
    .dhx_cal_prev_button {
      height: 6vw;
      line-height: 6vw;
    }

    .dhx_cal_tab {
      height: 6vw;
      line-height: 6vw;

      &.active {
        height: 6vw;
        line-height: 6vw;
      }
    }

    .dhx_cal_today_button {
      height: 6vw;
      line-height: 6vw;
    }

    .dhx_cal_next_button,
    .dhx_cal_prev_button {
      width: 2rem;
    }

    .dhx_cal_date {
      font-size: 4vw;
    }
  }
}

.dhx_cal_touch_active {
  overscroll-behavior: none;
}

.dhx_form_repeat {
  padding: 0;
  margin: 0;
  padding-left: 5px;
  font-family: Tahoma, Verdana;
  font-size: 11px;
  line-height: 24px;

  input {
    padding: 0;
    margin: 0;
    padding-left: 5px;
    font-family: Tahoma, Verdana;
    font-size: 11px;
    line-height: 24px;
  }

  overflow: hidden;
}

.dhx_cal_light_wide .dhx_form_repeat {
  background-color: transparent;
}

.dhx_repeat_center,
.dhx_repeat_divider,
.dhx_repeat_left,
.dhx_repeat_right {
  height: 115px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.dhx_repeat_center {
  padding: 10px 0 0 10px;
  float: left;
}

.dhx_repeat_left {
  padding: 10px 0 0 10px;
  float: left;
  width: 105px;
}

.dhx_repeat_center {
  width: 345px;
  padding-top: 22px;
}

.dhx_repeat_divider {
  float: left;
  border-left: 1px dotted #dcc43e;
  width: 1px;
}

.dhx_repeat_right {
  float: right;
  width: 173px;
  padding: 17px 3px 0 10px;
}

.dhx_cal_light_rtl {
  .dhx_repeat_center,
  .dhx_repeat_divider,
  .dhx_repeat_left {
    float: none;
    display: inline-block;
    vertical-align: top;
  }

  .dhx_repeat_right {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-right: 0;
  }
}

input.dhx_repeat_text {
  height: 16px;
  width: 27px;
  margin: 0 4px 0 4px;
  line-height: 18px;
  padding: 0 0 0 2px;
}

.dhx_cal_light_rtl input.dhx_repeat_text {
  padding: 0 2px 0 0;
}

.dhx_form_repeat select {
  height: 20px;
  width: 87px;
  padding: 0 0 0 2px;
  margin: 0 4px 0 4px;
}

.dhx_cal_light_rtl .dhx_form_repeat select {
  padding: 0 2px 0 0;
}

input.dhx_repeat_date {
  height: 18px;
  width: 80px;
  padding: 0 0 0 2px;
  margin: 0 4px 0 4px;
  background-repeat: no-repeat;
  background-position: 64px 0;
  border: 1px #7f9db9 solid;
  line-height: 18px;
}

.dhx_cal_light_rtl input.dhx_repeat_date {
  padding: 0 2px 0 0;
}

input[type="radio"].dhx_repeat_radio {
  margin: 5px 4px 0 0;
  display: inline-block;
  position: relative;
  top: 2px;
}

.dhx_cal_light_rtl input[type="radio"].dhx_repeat_radio {
  margin: 5px 0 0 4px;
}

input.dhx_repeat_checkbox {
  margin: 4px 4px 0 0;
}

.dhx_repeat_days {
  td {
    padding-right: 5px;
  }

  label {
    font-size: 10px;
  }
}

.dhx_custom_button {
  width: 90px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.dhx_custom_button_recurring {
  // background-image: url(imgs_dhx_terrace/but_repeat.gif);
  background-position: -5px 20px;
  width: 20px;
  margin-right: 10px;
}

.dhx_cal_light_rec {
  width: 640px;

  .dhx_cal_larea {
    width: 632px;
  }

  &.dhx_cal_light_wide {
    width: 816px;

    .dhx_cal_larea {
      width: 808px;
    }
  }
}

.dhx_cal_event {
  .dhx_header,
  &.dhx_cal_select_menu .dhx_footer {
    display: none;
  }

  &.dhx_cal_editor {
    border: 1px solid transparent;
  }
}

div {
  &.dhx_menu_head,
  &.dhx_menu_icon {
    background-image: url(../../../src/assets/img/controls.png);
  }
}

.dhx_cal_tab.active {
  text-shadow: 0 1px 0 #fff;
}

.dhx_cal_tab_standalone {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px !important;
  padding: 0 5px;
}

.dhx_cal_larea {
  margin-left: 0;
  border: 1px solid transparent;
}

.dhx_cal_light_wide .dhx_cal_lsection .dhx_fullday {
  color: #747473;
}

.dhx_cal_lsection {
  color: #747473;

  .dhx_fullday {
    color: #747473;
  }
}

.dhx_cal_light {
  border: 1px solid #cecece;
}

.dhx_section_time {
  background-color: transparent;
}

.dhx_btn_set div:first-child,
.dhx_cancel_btn,
.dhx_delete_btn,
.dhx_save_btn {
  display: none;
}

.dhx_cal_ltitle span {
  float: left;
}

.dhx_cal_light_rtl .dhx_cal_ltitle span {
  float: none;
}

.dhx_mark {
  display: none;
}

.dhx_close_icon {
  float: right;
  width: 9px;
  height: 9px;

  // background: url(imgs_dhx_terrace/close_icon.png) no-repeat center center;
  padding: 10px;
  margin-top: 1px;
}

.dhx_cal_light_wide .dhx_cal_ltext.dhx_cal_template {
  line-height: 22px;
}

.dhx_cal_ltext textarea {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dhx_cal_container.dhx_mini_calendar {
  box-sizing: border-box;
  border: 1px solid #cecece;
  box-shadow: 2px 2px 5px #ccc;
  border-radius: 5px;
}

.dhx_mini_calendar {
  .dhx_year_month {
    border: 1px solid #cecece;
    font-family: Arial;
  }

  .dhx_month_body,
  .dhx_month_head,
  .dhx_scale_bar,
  .dhx_year_body,
  .dhx_year_month {
    border-color: transparent;
  }

  .dhx_year_body {
    padding-top: 1px;
  }

  .dhx_scale_bar {
    border-width: 0;
  }

  .dhx_year_week {
    border-bottom: 1px solid #cecece;
    padding-top: 1px;
  }

  .dhx_month_head {
    padding-right: 0;
    margin-right: 1px;
    text-align: center;
  }

  .dhx_cal_next_button,
  .dhx_cal_prev_button {
    border: 0;
    height: 20px;
  }
}

.dhx_cal_navline div.dhx_minical_icon {
  left: 210px;
  top: 14px;
  width: 30px;
  height: 30px;
  background-position: 3px 5px;
}

.dhx_second_scale_bar {
  border-bottom: 1px solid #cecece;
}

.dhx_repeat_divider {
  border-left: 1px solid #cecece;
}

.dhx_custom_button {
  background-color: #fff;
  border: 1px solid #cecece;
  color: #747473;
}

.dhx_agenda_line div,
.dhx_v_border {
  border-right: 1px solid #cecece;
}

.dhx_cal_container_rtl {
  .dhx_agenda_line div,
  .dhx_v_border {
    border-right: 0;
    border-left: 1px solid #cecece;
  }
}

.dhx_year_month {
  border: 1px solid #cecece;
}

.dhx_scale_bar_last {
  border-right: 1px solid #cecece;
}

.dhx_year_body {
  border-left: 1px solid #cecece;
}

.dhx_expand_icon {
  top: -3px;
}

.dhx_cal_header {
  .dhx_cal_next_button,
  .dhx_cal_prev_button {
    width: 20px;
    height: 20px;
    top: 0 !important;
    border: 0;
  }

  .dhx_cal_next_button {
    right: 1px !important;
    border-left: 1px solid #cecece;
  }

  .dhx_cal_prev_button {
    left: 1px !important;
    border-right: 1px solid #cecece;
  }
}

.dhx_cal_date,
.dhx_cal_next_button,
.dhx_cal_prev_button,
.dhx_cal_tab,
.dhx_cal_today_button {
  line-height: 30px;
}

.dhx_map_line {
  .headline_date,
  .headline_description {
    border: 0;
  }

  .headline_date {
    border-right: 1px solid #cecece;
  }
}

.dhtmlXTooltip.tooltip {
  border-left: 1px solid #cecece;
  border-top: 1px solid #cecece;
  color: #747473;
  font-size: 12px;
  line-height: 16px;
}

.dhx_wa_scale_bar {
  border-top: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.dhx_wa_column_last .dhx_wa_day_cont {
  border-left: 1px solid #cecece;
}

.dhx_wa_ev_body {
  border-bottom: 1px solid #cecece;
}

.dhx_wa_scale_bar {
  background-color: #f0ede7;
}

.dhx_wa_ev_body.dhx_cal_event_selected {
  background-color: #fff3a1;
  color: #362d26;
}

.dhx_wa_dnd {
  background-color: #fddb93 !important;
  color: #747473 !important;
  border: 1px solid #ccb177;
}

.dhx_text_disabled {
  color: #2e2e2e;
}

.dhx_cal_ltext .dhx_text_disabled {
  line-height: 22px;
}

.dhx_grid_v_border {
  border-right-color: #cecece;
}

.dhx_cal_container_rtl .dhx_grid_v_border {
  border-left-color: #cecece;
}

.dhx_month_body_border,
.dhx_month_head_border,
.dhx_scale_bar_border,
.dhx_scale_hour_border {
  border-left: 1px solid #cecece;
}

.dhx_cal_navline .dhx_cal_export {
  width: 32px;
  height: 32px;
  margin: 2px;
  cursor: pointer;
  top: 12px;

  &.pdf {
    left: auto;
    right: 249px;

    // background-image: url(imgs_dhx_terrace/export_pdf.png);
  }

  &.ical {
    left: auto;
    right: 210px;

    // background-image: url(imgs_dhx_terrace/export_ical.png);
  }
}

.dhx_mini_calendar {
  padding: 5px;

  .dhx_calendar_click,
  .dhx_year_event {
    border-radius: 7px;
  }

  .dhx_month_head {
    margin: 2px 2px;
  }

  .dhx_year_month {
    line-height: 20px;
    height: 25px;
    font-size: 14px;
  }

  .dhx_cal_prev_button,
  .dhx_cal_next_button {
    top: 8px !important;
  }
}

/*

@license
dhtmlxScheduler v.5.3.11 Standard

To use dhtmlxScheduler in non-GPL projects (and get Pro version of the product), please obtain Commercial/Enterprise or Ultimate license on our site https://dhtmlx.com/docs/products/dhtmlxScheduler/#licensing or contact us at sales@dhtmlx.com

(c) XB Software Ltd.

*/
@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,600);

.dhtmlx_message_area {
  position: fixed;
  right: 5px;
  width: 250px;
  z-index: 11;
}

.dhtmlx-info {
  min-width: 120px;
  padding: 4px 4px 4px 20px;
  font-family: Tahoma;
  z-index: 14;
  overflow: hidden;
  margin: 5px;
  margin-bottom: 10px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  &.hidden {
    height: 0;
    padding: 0;
    border-width: 0;
    margin: 0;
    overflow: hidden;
  }
}

.dhtmlx_modal_box {
  overflow: hidden;
  display: inline-block;
  position: fixed;
  min-width: 300px;
  width: 341px;
  text-align: center;
  background: #fff;
  background: -webkit-linear-gradient(top, #fff 1%, #fff 99%);
  box-shadow: 0 0 14px #888;
  border: none;
  z-index: 18;
  border-radius: 2px;
  font-family: Roboto, Arial;
}

.dhtmlx_popup_text {
  margin: 28px 15px 5px 15px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  min-height: 30px;
  border-radius: 6px;
}

.dhtmlx_popup_title {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-width: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAoCAMAAAAIaGBFAAAAhFBMVEVwcHBubm5sbGxqampoaGhmZmZlZWVjY2NhYWFfX19dXV1bW1taWlpYWFhWVlZUVFRSUlJRUVFPT09NTU1LS0tJSUlHR0dGRkZERERCQkJAQEA+Pj49PT09PT0+Pj5AQEBBQUFDQ0NERERGRkZHR0dJSUlKSkpMTExMTEw5OTk5OTk5OTkny8YEAAAAQklEQVQImQXBCRJCAAAAwKVSQqdyjSPXNP7/QLsIhA6OTiJnF7GrRCpzc/fw9PKW+/gqlCq1RqvTG/yMJrPF6m/bAVEhAxxnHG0oAAAAAElFTkSuQmCC);
  background-image: -webkit-linear-gradient(
    top,
    #707070 1%,
    #3d3d3d 70%,
    #4c4c4c 97%,
    #393939 97%
  );
  background-image: -moz-linear-gradient(
    top,
    #707070 1%,
    #3d3d3d 70%,
    #4c4c4c 97%,
    #393939 97%
  );
}

.dhtmlx-info,
.dhtmlx_button,
.dhtmlx_popup_button {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  cursor: pointer;
}

.dhtmlx_popup_text {
  overflow: hidden;
}

.dhtmlx_popup_controls {
  border-radius: 6px;
  padding: 5px 15px 20px 5px;
}

.dhtmlx_button {
  height: 30px;
  line-height: 28px;
  display: inline-block;
  margin: 0 5px;
  border-radius: 2px;
  color: #0288d1;
  border: 1px solid transparent;
  box-shadow: none;
}

.dhtmlx_popup_button {
  height: 30px;
  line-height: 28px;
  display: inline-block;
  margin: 0 5px;
  border-radius: 2px;
  color: #0288d1;
  border: 1px solid transparent;
  box-shadow: none;
  min-width: 82px;
  background: #fff;
}

.dhtmlx_button input {
  border: 1px solid #fff;
  background: #fff;
  background-image: none;
  border-radius: 2px;
  font-size: 15px;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  text-shadow: none;
  color: #0288d1;
  font-weight: 500;
  padding: 0;
  margin: 0;
  vertical-align: top;
  height: 28px;
  line-height: 28px;
}

.dhtmlx_popup_button {
  div {
    border: 1px solid #fff;
    background: #fff;
    background-image: none;
    border-radius: 2px;
    font-size: 15px;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    text-shadow: none;
    color: #0288d1;
    font-weight: 500;
    padding: 0;
    margin: 0;
    vertical-align: top;
    height: 28px;
    line-height: 28px;
  }

  &.dhtmlx_ok_button {
    div {
      background: #ff584c;
      border: 1px solid #ff584c;
      color: #fff;
      text-shadow: none;
      outline: 0;
    }

    border: 1px solid #ff584c;
    background-color: #ff584c;
    outline: 0;
  }
}

.dhtmlx_button input {
  &:active,
  &:focus {
    background: #ff584c;
    background-image: none;
    color: #0288d1;
    box-shadow: none;
    outline: 0;
  }
}

.dhtmlx_popup_button {
  div {
    &:active,
    &:focus {
      background: #ff584c;
      background-image: none;
      color: #0288d1;
      box-shadow: none;
      outline: 0;
    }
  }

  &.dhtmlx_cancel_button div:active {
    background-color: #d9edf8;
    border-color: none;
    outline: 0;
  }
}

div.dhx_modal_cover {
  background-color: #000;
  cursor: default;
  opacity: 0.4;
  position: fixed;
  z-index: 17;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  zoom: 1;
}

.dhtmlx-info img,
.dhtmlx_modal_box img {
  float: left;
  margin-right: 20px;
}

.dhtmlx-alert-error .dhtmlx_popup_title,
.dhtmlx-confirm-error .dhtmlx_popup_title {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAsCAIAAAArRUU2AAAATklEQVR4nIWLuw2AMBBDjVuQiBT2oWbRDATrnB0KQOJoqPzRe3BrHI6dcBASYREKovtK6/6DsDOX+stN+3H1YX9ciRgnYq5EWYhS2dftBIuLT4JyIrPCAAAAAElFTkSuQmCC);
}

.dhtmlx-alert-error,
.dhtmlx-confirm-error {
  border: 1px solid red;
}

.dhtmlx_popup_title {
  color: #fff;
  text-shadow: 1px 1px #000;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
}

.dhtmlx-error {
  font-size: 14px;
  color: #000;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
  border: none;
}

.dhtmlx-info {
  font-size: 14px;
  color: #000;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  padding: 0;
  background-color: #fff;
  border-radius: 2px;
  border: none;

  div {
    padding: 11px !important;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid #fff;
  }
}

.dhtmlx_message_area .dhtmlx-error {
  background-color: #d81b1b;
  border: 1px solid #ff3c3c;
  box-shadow: 0 0 10px #000;

  div {
    background-color: #d81b1b;
    border: 1px solid #940000;
    color: #fff;
  }
}

.dhtmlx_modal_box.dhtmlx-error .dhtmlx_popup_title {
  background: #d81b1b;
  border: 1px solid #ff3c3c;
  color: #fff;
}

.dhx_cal_event {
  .dhx_body,
  .dhx_footer,
  .dhx_header,
  .dhx_title {
    background-color: #0288d1;
    border: 1px solid transparent;
    color: #fff;
    overflow: hidden;
    width: 100%;
  }
}

.dhx_move_denied .dhx_cal_event {
  .dhx_header,
  .dhx_title {
    cursor: default;
  }
}

.dhx_cal_event {
  .dhx_header {
    height: 1px;
    margin: 0 0 0 1px;
    border-width: 1px 1px 0 1px;
    cursor: pointer;
  }

  .dhx_title {
    height: 18px;
    border-width: 1px 1px 0 1px;
    border-bottom-style: dotted;
    border-radius: 0;
    font-size: 12px;
    line-height: normal;
    font-weight: 400;
    text-align: left;
    background-position: right;
    background-repeat: no-repeat;
    padding: 4px 0 0 8px;
    cursor: pointer;
  }
}

.dhx_cal_container_rtl .dhx_cal_event .dhx_title {
  text-align: right;
  padding: 4px 8px 0 0;
}

.dhx_cal_event {
  .dhx_body,
  &.dhx_cal_select_menu .dhx_body {
    border-width: 0 1px 1px 1px;
    padding: 2px 5px 8px 8px;
    border-radius: 0;
    font-weight: 500;
    line-height: 20px;
  }
}

.dhx_cal_container_rtl .dhx_cal_event .dhx_body {
  padding: 2px 8px 8px 5px;
}

.dhx_cal_event.dhx_cal_select_menu {
  -webkit-box-shadow: 0 0 1px #fff;
  -moz-box-shadow: 0 0 1px #fff;
  box-shadow: 0 0 1px #fff;
}

.dhx_resize_denied {
  cursor: default !important;

  .dhx_event_resize {
    cursor: default !important;
  }
}

.dhx_cal_event {
  .dhx_event_resize {
    cursor: s-resize;
    cursor: ns-resize;
  }

  .dhx_footer,
  .dhx_select_menu_footer {
    height: 6px;
    margin: 0 0 0 1px;
    border-width: 0;
    position: relative;
    top: 0;
    background: url(../../../src/assets/img/icons/drag-horizontal.svg) no-repeat
      center center;
  }

  .dhx_resize_denied.dhx_event_resize.dhx_footer {
    height: 0;
  }
}

.dhx_cal_event_line {
  background-color: #0288d1;
  border: 1px solid transparent;
  border-radius: 2px;
  font-family: Roboto, Arial;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  height: 20px;
  padding: 0 0 1px 10px;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
}

.dhx_cal_container_rtl .dhx_cal_event_line {
  padding: 0 9px 0 0;
}

.dhx_cal_event_line_start,
.dhx_cal_container_rtl .dhx_cal_event_line_start {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.dhx_cal_event_line_end {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.dhx_cal_container_rtl {
  .dhx_cal_event_line_end,
  .dhx_cal_event_line_start {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  .dhx_cal_event_line_end {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
}

.dhx_cal_event_clear {
  height: 20px;
  padding: 1px 0 0 11px;
  color: #0288d1;
  font-family: Roboto, Arial;
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
  font-weight: 400;
}

.dhx_cal_event.dhx_cal_editor {
  border: 1px solid transparent;
  border-top-style: solid;
  z-index: 10;
  position: absolute;
  overflow: hidden;
}

textarea.dhx_cal_editor {
  width: 100%;
  height: 100%;
  border: 0 solid #000;
  padding: 6px 0 0 8px;
  margin: 0;
  overflow: auto;
  font-family: Roboto, Arial;
  font-size: 14px;
}

div.dhx_menu_head {
  background-image: none;
  background-position: 0 -43px;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  margin-top: 1px;
  border: none;
  cursor: default;
}

.dhx_cal_container_rtl div.dhx_menu_head {
  margin-left: 5px;
  margin-right: 0;
}

div.dhx_menu_icon {
  //   background-image: url(imgs_dhx_material/controls.png);
  width: 20px;
  height: 20px;
  margin-left: 0;
  margin-top: 0;
  border: none;
  cursor: pointer;
}

.dhx_cal_container_rtl div.dhx_menu_icon {
  margin-left: 0;
  margin-right: 1px;
}

div {
  &.icon_details {
    background-position: 0 0;
  }

  &.icon_edit {
    background-position: -22px 0;
  }

  &.icon_save {
    background-position: -84px -1px;
  }

  &.icon_cancel {
    background-position: -62px 0;
  }

  &.icon_delete {
    background-position: -42px 0;
  }
}

.dhx_month_link {
  position: absolute;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  text-align: right;
  cursor: pointer;
  padding-right: 10px;
  font-size: 14px;
  font-family: Roboto, Arial;

  a {
    color: #1b5dfb;

    &:hover {
      text-decoration: underline;
      color: #033dc6;
    }
  }
}

.dhx_global_tip {
  font-family: Tahoma, Helvetica;
  text-align: center;
  font-size: 20px;
  position: fixed;
  top: 60px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  color: #000;
  z-index: 14;
  padding: 20px 30px;
  width: 190px;

  div {
    font-size: 30px;
  }
}

@media (-moz-touch-enabled) {
  .dhx_cal_container {
    user-select: none;
    -moz-user-select: none;
  }
}

.dhx_cal_data div {
  &.dhx_cal_event_clear,
  &.dhx_cal_event_line {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.dhx_multi_day div {
  &.dhx_cal_event_clear,
  &.dhx_cal_event_line {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 550px) {
  .dhx_cal_event_clear,
  .dhx_cal_event_line {
    padding-left: 2px;
  }
}

.dhx_unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -moz-user-select: -moz-none;

  div {
    -webkit-user-select: none;
    -moz-user-select: none;
    -moz-user-select: -moz-none;
  }
}

.dhx_cal_light {
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border-radius: 2px;
  border: 0 solid transparent;
  color: #2e2e2e;
  font-family: Roboto, Arial;
  font-size: 14px;
  box-shadow: none;
  position: absolute;
  z-index: 15;
  width: 580px;
}

.dhx_cal_light_rtl {
  box-shadow: none;
}

.dhx_cal_ltitle {
  padding: 3px 15px 1px 15px;
  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #0288d1;
  background: #0288d1;
  margin: 0 0 20px 0;
  border-radius: 2px 2px 0 0;
  overflow: hidden;
  white-space: nowrap;
}

.dhx_cal_ltext.dhx_cal_template {
  position: relative;
}

.dhx_cal_ltitle span {
  white-space: nowrap;
}

.dhx_time {
  font-weight: 500;
  padding: 0;
  font-size: 14px;
  display: inline-block;
  margin: 0;
}

.dhx_cal_light_rtl .dhx_time {
  padding: 0;
}

.dhx_cal_light_wide {
  width: 650px;
}

.dhx_mark {
  position: relative;
  top: 3px;

  // background-image: url(imgs/controls.gif);
  background-position: 0 -43px;
  padding-left: 10px;
}

.dhx_ie6 .dhx_mark {
  background-position: 6px -41px;
}

.dhx_cal_light {
  select {
    font-family: Roboto, Arial;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    padding: 2px 2px 2px 8px;
    margin: 0 8px 16px 0;
  }

  .dhx_title {
    padding: 0 0 0 13px;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    display: inline-block;
    margin: 0;
  }
}

.dhx_cal_larea {
  border: none;
  background-color: #fff;
  overflow: hidden;
}

.dhx_cal_light_wide .dhx_cal_larea {
  border-top-width: 0;
  margin: 0;
}

.dhx_cal_light_rtl .dhx_cal_larea {
  margin: 0;
}

.dhx_cal_light_wide .dhx_wrap_section {
  border-top: 0;
  border-bottom: none;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  padding: 0;
  margin: 0 0 16px 0;
}

.dhx_cal_lsection {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 500;
  font-family: Roboto, Arial;
  font-size: 18px;
  padding: 5px 0 5px 10px;
}

.dhx_cal_light_wide .dhx_cal_lsection {
  border: 0;
  float: left;
  text-align: right;
  width: 82px;
  min-height: 20px;
  line-height: 20px;
  height: auto;
  font-size: 14px;
  padding: 3px 0 0 0;
  margin: 0 0 0 20px;
}

.dhx_cal_lsection .dhx_fullday {
  float: right;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.75);
  font-family: Roboto, Arial;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  vertical-align: top;
  cursor: pointer;

  input[type="checkbox"] {
    margin: 3px 3px 3px 4px;
  }
}

.dhx_cal_ltext {
  overflow: hidden;
  float: left;
  float: none;
  width: auto;
  height: auto;
  padding: 4px 0 0 4px;
}

.dhx_readonly {
  height: 15px;
}

.dhx_cal_ltext textarea {
  background-color: #fff;
  overflow: auto;
  color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  outline: none !important !important;
  resize: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  line-height: 20px;
  font-family: inherit;
  box-sizing: border-box;
  padding: 4px 0 0 8px;
}

.dhx_section_time {
  background-color: transparent;
  white-space: nowrap;
}

.dhx_cal_light_wide .dhx_section_time {
  padding: 2px 0 0 117px !important;
  height: 20px !important;
}

.dhx_section_time {
  text-align: center;
}

.dhx_cal_light_wide .dhx_section_time {
  background: 0 0;
}

.dhx_btn_set {
  padding: 0;
  float: left;
  margin: 0 16px 0 0;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
  text-transform: uppercase;
  color: #0288d1;
}

.dhx_right_btn_set {
  margin-right: 16px;
}

.dhx_btn_set {
  div {
    float: left;
    height: 30px;
    line-height: 30px;
    padding: 0 16px;
    vertical-align: middle;
    cursor: pointer;
  }

  border: 1px solid #fff;
  background-color: #fff;
  color: #0288d1;
  float: right;
  float: left;
}

.dhx_cancel_btn_set {
  border: 1px solid #fff;
  background-color: #fff;
  color: #0288d1;
  float: right;
  float: left;
}

.dhx_cal_light_rtl {
  .dhx_btn_set,
  .dhx_cancel_btn_set {
    float: right;
  }
}

.dhx_save_btn {
  // background-image: url(imgs/controls.gif);
  background-position: -84px 0;
  width: 21px;
}

.dhx_cancel_btn {
  // background-image: url(imgs/controls.gif);
  background-position: -63px 0;
  width: 20px;
}

.dhx_delete_btn {
  // background-image: url(imgs/controls.gif);
  background-position: -42px 0;
  width: 20px;
}

.dhx_delete_btn_set {
  border: 1px solid #fff;
  background-color: #fff;
  color: #ff584c;
  text-shadow: none;
  margin: 0 20px 0 20px;
}

.dhx_save_btn_set {
  border: 1px solid #0288d1;
  color: #fff;
  text-shadow: none;
  background-color: #0288d1;
}

.dhx_cal_cover {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 14;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.4;
}

.dhx_custom_button {
  padding: 0 3px 0 3px;
  color: rgba(0, 0, 0, 0.75);
  font-family: Roboto, Arial;
  font-size: 8pt;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  font-weight: 400;
  margin-right: 5px;
  margin-top: 0;
  cursor: pointer;

  div {
    cursor: pointer;
    float: left;
    height: 21px;
    line-height: 21px;
    vertical-align: middle;
  }
}

.dhx_cal_light_wide {
  .dhx_cal_lsection .dhx_fullday {
    float: none;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }

  .dhx_cal_larea {
    width: 100%;
  }

  width: 738px;

  .dhx_cal_checkbox label {
    padding-left: 0;
  }
}

.dhx_wrap_section .dhx_cal_wide_checkbox input[type="checkbox"] {
  margin-top: 8px;
  margin-left: 14px;
}

.dhx_cal_light_rtl
  .dhx_wrap_section
  .dhx_cal_wide_checkbox
  input[type="checkbox"] {
  margin: 8px 14px 0 0;
}

.dhx_cal_light input {
  font-family: Roboto, Arial;
  font-size: 8pt;
  color: #747373;
}

#input_1518604462804 {
  margin: 0 0 0 11px;
  border-bottom-width: 0;
  border-bottom: 0;
}

.dhx_custom_button {
  float: right;
  height: 21px;
  width: 90px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.dhx_cal_light_wide {
  .dhx_custom_button {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: 6px;
  }

  .dhx_repeat_right {
    margin-right: 55px;
  }
}

.dhx_cal_light_rtl {
  direction: rtl;

  .dhx_cal_ltitle {
    padding: 3px 15px 1px 15px;
  }

  .dhx_mark {
    padding: 0 10px 0 0;
  }

  .dhx_title {
    padding: 0 13px 0 0;
  }

  .dhx_wrap_section {
    padding: 0;

    .dhx_cal_ltext.dhx_cal_template {
      direction: ltr;
      float: right;
      margin-right: 15px !important;
    }
  }

  .dhx_cal_lsection {
    float: right;
    text-align: left;
    margin: 0 20px 0 0;
  }

  .dhx_cal_ltext {
    padding: 4px 4px 0 0;

    textarea {
      padding: 4px 8px 0 0;
    }
  }

  .dhx_section_time {
    padding: 2px 117px 0 0 !important;
  }

  select {
    padding: 2px 8px 2px 2px;
    margin: 0 0 16px 8px;
  }

  .dhx_custom_button {
    left: 15px;
    right: auto;
    right: unset;
  }
}

@media only screen and (max-width: 1023px) {
  .dhx_cal_overflow_container {
    overflow: auto !important;
  }

  .dhx_cal_light.dhx_cal_light_responsive {
    width: 98vw !important;
    height: auto !important;
    left: 1% !important;
    top: 1.953vw !important;
    font-size: 3.906vw !important;
    line-height: 3.906vw !important;
    padding-bottom: 2vw;

    .dhx_btn_set {
      margin-bottom: 1.953vw !important;
      font-size: 3.906vw;
      height: auto !important;
      padding: 1.953vw 1.563vw 1.953vw 1.563vw;

      div {
        height: 2.93vw;
        line-height: 2.93vw;
        padding: 0 1.563vw;
      }
    }

    .dhx_cal_larea {
      width: auto !important;
      margin-left: 0 !important;
      height: 100% !important;

      label {
        display: inline;
      }
    }

    .dhx_cal_lsection {
      font-size: 3.906vw !important;
      width: 23.438vw !important;
      margin-left: 3vw;
      line-height: 3.906vw !important;
      margin-right: 0;

      .dhx_custom_button {
        padding: 0.977vw;
        min-width: 25.391vw !important;
        min-height: 5.371vw;
      }
    }

    .dhx_cal_ltitle {
      height: 2rem !important;
      line-height: 2rem !important;
      margin-bottom: 0.5rem;

      span {
        font-size: 3.906vw !important;
      }
    }

    .dhx_cal_lsection div.dhx_custom_button,
    .dhx_cal_radio,
    .dhx_cal_template,
    .dhx_wrap_section .dhx_section_time select,
    select,
    textarea {
      font-size: 3.5vw;
      line-height: 3.5vw;
    }

    .dhx_cal_lsection div.dhx_custom_button label,
    .dhx_cal_radio label,
    .dhx_cal_template label,
    .dhx_wrap_section .dhx_section_time select label,
    select label,
    textarea label {
      margin: 1vw 3vw 1vw 0.5vw;
      vertical-align: top;
    }

    .dhx_section_time select,
    select {
      min-height: 5.859vw;
    }

    .dhx_cal_ltext {
      margin-left: 26.5vw !important;
      height: 100% !important;
      min-height: 4.883vw;
    }

    .dhx_repeat_center,
    .dhx_repeat_divider,
    .dhx_repeat_left,
    .dhx_repeat_right {
      height: 11.23vw;
    }

    .dhx_wrap_section .dhx_section_time {
      padding-left: 28.5vw !important;
      width: auto !important;
      min-height: 19.531vw !important;

      select {
        float: left;
        margin: 1.465vw 0.5vw 1.465vw 0.5vw !important;
      }

      .dhx_lightbox_time_select {
        width: 18.555vw;
        padding-left: 5.859vw !important;
      }

      .dhx_lightbox_day_select {
        min-width: 7.813vw !important;
      }

      .dhx_lightbox_year_select {
        min-width: 12.695vw !important;
      }

      .dhx_lightbox_month_select {
        width: 17.578vw !important;
      }
    }

    .dhx_cal_wide_checkbox {
      height: 100% !important;
    }

    .dhx_multi_select_control {
      height: 100% !important;
      vertical-align: middle;

      input {
        vertical-align: middle;
      }
    }

    input {
      width: 4.883vw;
      height: 4.883vw;
    }

    .dhx_custom_button {
      div {
        height: auto;
      }

      .dhx_custom_button_recurring {
        background-size: 7.813vw !important;
        width: 6.836vw !important;
        height: 6.836vw !important;
        background-repeat: no-repeat !important;
        background-position: (-25px) -1px !important;
        padding-top: 0 !important;
      }
    }

    .dhx_wrap_section {
      min-height: 7.813vw !important;
      max-width: 92.773vw !important;
      margin: 0.977vw 0;
    }

    .dhx_form_repeat {
      margin: 6.836vw 0 0 23.438vw !important;

      form {
        height: 48.828vw !important;
      }

      div {
        width: 75vw !important;
      }

      .dhx_repeat_left br {
        content: " ";
      }

      input {
        top: 1.465vw !important;
      }

      select {
        width: 14.648vw !important;
      }

      label {
        padding-right: 0;
        white-space: nowrap;
      }

      .dhx_repeat_date,
      .dhx_repeat_text {
        font-size: 2.93vw !important;
        line-height: 2.93vw !important;
      }

      form {
        font-size: 2.93vw !important;
        line-height: 2.93vw !important;

        select {
          font-size: 2.93vw !important;
          line-height: 2.93vw !important;
        }
      }

      label {
        font-size: 2.93vw !important;
        line-height: 2.93vw !important;
      }

      .dhx_repeat_center,
      .dhx_repeat_divider,
      .dhx_repeat_left,
      .dhx_repeat_right {
        height: auto;
        float: none;
      }

      .dhx_repeat_center {
        height: auto !important;
        padding-bottom: 10px;
      }

      .dhx_repeat_left {
        padding-bottom: 10px;
      }

      .dhx_repeat_text {
        width: 6.836vw !important;
        height: 5.859vw !important;
        border: 1px solid rgba(0, 0, 0, 0.08);
        background-color: #fff;
      }

      .dhx_repeat_checkbox {
        width: 2.93vw !important;
        height: 2.93vw !important;
      }

      .dhx_repeat_days label {
        font-size: 2.441vw !important;
        line-height: 2.5vw !important;
      }

      .dhx_repeat_right {
        height: auto !important;
        float: left;

        .dhx_repeat_date {
          width: 19.531vw !important;
          height: 7.813vw !important;
          padding-left: 1.465vw !important;
          margin-top: 1.465vw;
        }
      }

      .dhx_repeat_divider {
        border-top: 1px solid #cecece;
        border-left: 0;
        width: 70vw;
        height: 1px;
      }
    }

    .dhx_fullday input {
      vertical-align: middle;
    }

    .combo {
      height: auto !important;
    }

    .dhx_custom_button .dhx_custom_button_recurring {
      background-position: (-14px) -1px !important;
      width: 4.836vw !important;
      height: 4.836vw !important;
    }
  }

  .dhx_cal_light_rtl.dhx_cal_light_responsive {
    .dhx_cal_larea {
      margin-right: 0 !important;
    }

    .dhx_cal_lsection {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .dhx_cal_ltext {
      margin-right: 23.438vw !important;
      margin-left: 0 !important;
    }

    .dhx_wrap_section {
      .dhx_cal_wide_checkbox input[type="checkbox"] {
        margin: 0.781vw 1.367vw 0 0;
      }

      .dhx_section_time {
        padding-right: 23.438vw !important;
        padding-left: 0 !important;

        select {
          float: right;
        }

        .dhx_lightbox_time_select {
          padding-right: 5.859vw !important;
          padding-left: 0 !important;
        }

        span {
          display: none;
        }
      }
    }

    .dhx_form_repeat {
      margin: 6.836vw 23.438vw 0 0 !important;

      label {
        padding-left: 0;
        padding-right: 0 !important;
      }

      .dhx_repeat_right {
        float: left;

        .dhx_repeat_date {
          padding-right: 1.465vw !important;
          padding-left: 0 !important;
        }
      }
    }

    .dhx_multi_select_control {
      &.dhx_multi_select_control_vertical {
        float: right;
      }

      float: auto;
      float: unset;
    }
  }
}

.dhx_minical_popup {
  position: absolute;
  z-index: 16;
  width: 251px;
  height: 175px;
}

.dhx_scale_bar_header {
  position: absolute;
  border-bottom: 1px dotted #a39588;
  width: 100%;
}

.dhx_expand_icon {
  position: absolute;
  top: 0;
  right: 0;

  //   background-image: url(imgs/collapse_expand_icon.gif);
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-position: 0 18px;
  z-index: 4;
}

.dhx_scheduler_agenda .dhx_cal_data {
  background-image: none;
}

.dhx_agenda_area {
  width: 100%;
  overflow-y: auto;
  background-image: none;
}

.dhx_agenda_line {
  height: 21px;
  clear: both;
  overflow: hidden;

  div {
    float: left;
    width: 188px;
    border-right: 1px dotted #8894a3;
    text-align: center;
    line-height: 21px;
    overflow: hidden;
  }
}

.dhx_cal_container_rtl .dhx_agenda_line {
  div {
    float: right;
  }

  .description_header {
    padding-right: 15px;
    float: right;
  }
}

.dhx_cal_header div {
  &.dhx_agenda_line {
    div,
    span {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.54);
    }
  }

  &.dhx_map_line {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
  }
}

.dhx_cal_container_rtl {
  .dhx_cal_header div.dhx_map_line {
    float: right;
  }

  .dhx_agenda_area .dhx_agenda_line div {
    border-left: 0 dotted #000;
  }
}

.dhx_agenda_area .dhx_agenda_line div {
  border-right: 0 dotted #000;
}

.dhx_v_border {
  position: absolute;
  left: 187px;
  top: 0;
  border-right: 1px dotted #8894a3;
  width: 1px;
  height: 100%;
}

.dhx_cal_container_rtl .dhx_v_border {
  right: 186px;
  left: unset;
}

.dhx_agenda_line .dhx_event_icon {
  width: 20px;
  border-width: 0;

  //   background: url(imgs/icon.png) no-repeat;
  background-position: 5px 4px;
  cursor: pointer;
}

.dhx_cal_container_rtl .dhx_agenda_line .dhx_event_icon {
  background-position-x: 2px;
}

.dhx_agenda_line span {
  padding-left: 5px;
  line-height: 21px;
}

.dhx_cal_container_rtl .dhx_agenda_line span {
  padding-left: 0;
  padding-right: 5px;
}

.dhx_year_body {
  border-left: 1px solid #e0e0e0;
}

.dhx_year_week {
  position: relative;
}

.dhx_scale_bar_last {
  border-right: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl {
  .dhx_year_body {
    border-left: 0;
    border-right: 1px solid #e0e0e0;
  }

  .dhx_year_box .dhx_scale_bar_last {
    border-left: 1px solid #e0e0e0;
  }
}

.dhx_year_month {
  height: 18px;
  padding-top: 3px;
  border: 1px solid #e0e0e0;
  text-align: center;
  vertical-align: middle;
}

.dhx_year_body {
  .dhx_after .dhx_month_head,
  .dhx_before .dhx_month_head {
    color: rgba(0, 0, 0, 0.38);

    a {
      color: rgba(0, 0, 0, 0.38);
    }
  }

  .dhx_month_body {
    height: 0;
    overflow: hidden;
  }
}

.dhx_month_head.dhx_year_event {
  background-color: #0288d1;
  color: #fff;
}

.dhx_year_body {
  .dhx_after .dhx_month_head,
  .dhx_before .dhx_month_head {
    cursor: default;
  }
}

.dhx_year_tooltip {
  border: 1px solid #bbb;
  background-image: none;
  position: absolute;
  z-index: 12;
  width: 300px;
  height: auto;
  font-family: Tahoma;
  font-size: 8pt;
  overflow: hidden;
}

.dhx_tooltip_line {
  line-height: 20px;
  height: 20px;
  overflow: hidden;

  .dhx_event_icon {
    width: 20px;
    height: 20px;
    padding-right: 10px;
    float: left;
    border-width: 0;
    position: relative;

    // background: url(imgs/icon.png) no-repeat;
    background-position: 5px 4px;
    cursor: pointer;
  }
}

.dhx_tooltip_date {
  float: left;
  width: auto;
  padding-left: 5px;
  text-align: center;
}

.dhx_text_disabled {
  color: #2e2e2e;
  font-family: Roboto, Arial;
  font-size: 14px;
}

.dhx_mini_calendar {
  -moz-box-shadow: 5px 5px 5px #888;
  -khtml-box-shadow: 5px 5px 5px #888;
  -moz-user-select: -moz-none;
  -webkit-user-select: none;
  -user-select: none;

  .dhx_month_head {
    cursor: pointer;
  }

  .dhx_calendar_click {
    background-color: #0288d1;
  }
}

.dhx_cal_navline div.dhx_minical_icon {
  width: 18px;
  height: 18px;
  left: 190px;
  top: 1px;
  cursor: pointer;

  //   background: url(imgs_dhx_material/calendar.svg) no-repeat;
}

.dhx_grid_rtl {
  direction: rtl;
}

.dhx_matrix_scell,
.dhx_timeline_scale_header {
  height: 100%;
  font-family: Roboto, Arial;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
}

.dhx_matrix_cell,
.dhx_matrix_scell {
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl {
  .dhx_matrix_cell,
  .dhx_matrix_scell {
    border-right: unset;
    border-left: 1px solid #e0e0e0;
  }
}

.dhx_timeline_scale_header {
  border-right: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl .dhx_timeline_scale_header {
  border-right: unset;
  border-left: 1px solid #e0e0e0;
}

.dhx_cal_data .dhx_timeline_table_wrapper div {
  box-sizing: border-box;
}

.dhx_matrix_cell {
  background-color: #fff;
}

.dhx_matrix_line {
  overflow: hidden;
}

.dhx_matrix_cell div,
.dhx_matrix_scell div {
  overflow: hidden;
  text-align: center;
  height: auto;
}

.dhx_cal_lsection .dhx_readonly {
  font-size: 9pt;
  font-size: 8pt;
  padding: 2px;
  color: #887a2e;
}

.dhx_cal_event_line .dhx_event_resize {
  cursor: w-resize;
  cursor: ew-resize;

  //   background: url(imgs_dhx_material/drag-vertical.svg) no-repeat center center;
  position: absolute;
  top: 0;
  width: 4px;
  height: 100%;
}

.dhx_event_resize_start {
  left: 0;
}

.dhx_cal_container_rtl .dhx_event_resize_start {
  left: auto;
  left: unset;
  right: 0;
}

.dhx_event_resize_end {
  right: 0;
}

.dhx_cal_container_rtl .dhx_event_resize_end {
  right: auto;
  right: unset;
  left: 0;
}

.dhx_data_table.folder .dhx_matrix_cell {
  background-color: #ebeced;
  cursor: pointer;
}

.dhx_matrix_scell {
  &.folder {
    background-color: #ebeced;
    cursor: pointer;
  }

  .dhx_scell_level0 {
    padding-left: 5px;
  }

  .dhx_scell_level1 {
    padding-left: 20px;
  }

  .dhx_scell_level2 {
    padding-left: 35px;
  }

  .dhx_scell_level3 {
    padding-left: 50px;
  }

  .dhx_scell_level4 {
    padding-left: 65px;
  }

  &.folder {
    border-right: 0;
    font-weight: 700;
    text-align: left;

    .dhx_scell_expand {
      float: left;
      width: 10px;
      padding-right: 3px;
      position: relative;
    }

    .dhx_scell_name {
      float: left;
      width: auto;
      color: #0288d1;
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  &.item .dhx_scell_name {
    padding-left: 15px;
    text-align: left;
  }
}

.dhx_cal_container_rtl .dhx_matrix_scell {
  .dhx_scell_level0 {
    padding-left: 0;
    padding-right: 5px;
  }

  .dhx_scell_level1 {
    padding-left: 0;
    padding-right: 20px;
  }

  .dhx_scell_level2 {
    padding-left: 0;
    padding-right: 35px;
  }

  .dhx_scell_level3 {
    padding-left: 0;
    padding-right: 50px;
  }

  .dhx_scell_level4 {
    padding-left: 0;
    padding-right: 65px;
  }

  &.folder {
    .dhx_scell_expand {
      float: right;
      padding-right: 0;
      padding-left: 3px;
    }

    .dhx_scell_name {
      float: right;
    }
  }

  &.item .dhx_scell_name {
    padding-left: 0;
    padding-right: 15px;
    text-align: right;
  }
}

.dhx_data_table.folder .dhx_matrix_cell {
  border-right: 0;
}

.dhx_section_timeline {
  overflow: hidden;
  padding: 4px 0 2px 10px;

  select {
    width: 552px;
  }
}

.dhx_map_area {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  //   background-image: url(imgs/databg.png);
}

.dhx_map_line .dhx_event_icon {
  width: 20px;
  border-width: 0;

  //   background: url(imgs/icon.png) no-repeat;
  background-position: 5px 4px;
  cursor: pointer;
}

.dhx_cal_container_rtl .dhx_map_line .dhx_event_icon {
  margin-right: 3px;
}

.dhx_map_line {
  height: 21px;
  clear: both;
  overflow: hidden;
}

.dhx_map {
  position: absolute;
}

.dhx_map_line {
  div,
  .headline_description {
    float: left;
    border-right: 1px dotted #8894a3;
    text-align: center;
    line-height: 21px;
    overflow: hidden;
  }

  .dhx_map_description {
    float: left;
    border-right: 0 dotted #8894a3;
    text-align: center;
    line-height: 21px;
    overflow: hidden;
  }

  .headline_date,
  .headline_description {
    border-left: 0;
  }
}

.dhx_cal_container_rtl .dhx_map_line {
  div {
    float: right;
  }

  .headline_date,
  .headline_description {
    float: right;
    border-left: 1px solid #e0e0e0;
    border-right: 0;
  }
}

.dhx_map_line .line_description {
  float: left;
  border-right: 1px dotted #8894a3;
  text-align: left;
  padding-left: 5px;
  line-height: 21px;
  overflow: hidden;
}

.dhx_cal_container_rtl .dhx_map_line .line_description {
  float: right;
  text-align: right;
  padding-left: unset;
}

.dhx_map_line.highlight {
  background-color: #c4c5cc;
}

.dhx_map_area .dhx_map_line div {
  border-right: 0 dotted #8894a3;
}

.dhtmlXTooltip.tooltip {
  -moz-box-shadow: 3px 3px 3px #888;
  -webkit-box-shadow: 3px 3px 3px #888;
  -o-box-shadow: 3px 3px 3px #888;
  box-shadow: 3px 3px 3px #888;
  background-color: #fff;
  cursor: default;
  padding: 10px;
  position: fixed;
  z-index: 9;
  opacity: 1;
  font-family: Roboto, Arial;
}

.dhtmlXTooltip_rtl {
  direction: rtl;
}

.dhx_tooltip_rtl {
  direction: rtl;

  .dhx_tooltip_date {
    float: right;
    padding: 0 5px 0 0;
  }

  .dhx_tooltip_line {
    margin: 0 0 0 12px;
    padding: 0 4px 0 0;

    .dhx_event_icon.icon_details {
      float: right;
      padding: 0 0 0 10px;
    }
  }
}

.dhx_cal_checkbox label {
  padding-left: 5px;
}

.dhx_cal_light {
  .radio {
    padding: 2px 0 2px 10px;

    input,
    label {
      line-height: 15px;
    }

    input {
      vertical-align: middle;
      margin: 0;
      padding: 0;
    }

    label {
      vertical-align: middle;
      padding-right: 10px;
    }
  }

  .combo {
    padding: 4px;
  }
}

.dhx_cal_light_wide {
  .combo > div,
  .dhx_combo_box {
    width: 608px !important;
    left: 10px;
  }
}

.dhx_wa_column {
  float: left;
}

.dhx_cal_container_rtl .dhx_wa_column {
  float: right;
}

.dhx_wa_column_last .dhx_wa_day_cont {
  border-left: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl .dhx_wa_column_last .dhx_wa_day_cont {
  border-right: 1px solid #e0e0e0;
}

.dhx_wa_scale_bar {
  font-family: Roboto, Arial;
  padding-left: 10px;
  font-size: 11px;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl .dhx_wa_scale_bar {
  padding-left: 0;
  padding-right: 10px;
}

.dhx_wa_day_data {
  background-color: #fcfefc;
  overflow-y: auto;
}

.dhx_wa_ev_body {
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  padding: 5px 0 5px 7px;
}

.dhx_cal_container_rtl .dhx_wa_ev_body {
  padding: 5px 7px 5px 0;
}

.dhx_wa_ev_body_rtl {
  direction: rtl;
}

.dhx_wa_dnd {
  font-family: Roboto, Arial;
  position: absolute;
  padding-right: 7px;
  color: #887ae2 !important;
  background-color: #ffe763 !important;
  border: 1px solid #b7a543;
}

.dhx_wa_ev_body.dhx_cal_event_selected {
  background-color: #9cc1db;
  color: #fff;
}

.dhx_second_scale_bar {
  border-bottom: 1px solid #e0e0e0;
  padding-top: 2px;
}

.dhx_cal_header div.dhx_grid_line div {
  border-left: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl .dhx_cal_header div.dhx_grid_line div {
  border-right: 1px solid #e0e0e0;
  border-left: 0;
}

.dhx_grid_area {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fcfefc;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;
  }

  td {
    table-layout: fixed;
    text-align: center;
  }
}

.dhx_grid_line {
  height: 21px;
  clear: both;
  overflow: hidden;

  div {
    float: left;
    cursor: default;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
    line-height: 21px;
    overflow: hidden;
  }
}

.dhx_cal_container_rtl .dhx_grid_line div {
  float: right;
}

.dhx_grid_area td,
.dhx_grid_line div {
  padding-left: 8px;
  padding-right: 8px;
}

.dhx_grid_area tr.dhx_grid_event {
  height: 21px;
  overflow: hidden;
  margin: 0 0 1px 0;

  td {
    border-bottom: 1px solid #eceef4;
  }
}

.dhx_cal_container_rtl {
  .dhx_grid_area tr.dhx_grid_event td {
    border-bottom: 1px solid #eceef4;
    direction: ltr;
  }

  .dhx_map_line div.dhx_map_event_time {
    float: right;
    direction: ltr;
  }
}

.dhx_grid_area {
  tr.dhx_grid_event {
    &:nth-child(2n) td,
    &:nth-child(2n + 1) td {
      border-bottom-width: 0;
      border-bottom-style: none;
    }

    &:nth-child(2n) {
      background-color: #eceef4;
    }
  }

  .dhx_grid_dummy {
    table-layout: auto;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.dhx_grid_v_border {
  position: absolute;
  border-right: 1px solid #a4bed4;
  width: 1px;
  height: 100%;
}

.dhx_cal_container_rtl .dhx_grid_v_border {
  border-left: 1px solid #a4bed4;
  border-right: 0;
}

.dhx_grid_event_selected {
  background-color: #9cc1db !important;
  color: #fff !important;
}

.dhx_grid_sort_desc .dhx_grid_view_sort {
  background-position: 0 -55px;
}

.dhx_grid_sort_asc .dhx_grid_view_sort {
  background-position: 0 -66px;
}

.dhx_grid_view_sort {
  width: 10px;
  height: 10px;
  position: absolute;
  border: none !important;
  top: 5px;
  background-repeat: no-repeat;

  //   background-image: url(imgs/images.png);
}

.dhx_marked_timespan {
  position: absolute;
  width: 100%;
  margin-left: 0;
}

.dhx_time_block {
  position: absolute;
  width: 100%;
  background: silver;
  opacity: 0.4;
  z-index: 1;
}

.dhx_time_block_reset {
  opacity: 1;
}

.dhx_scheduler_month .dhx_marked_timespan,
.dhx_mini_calendar .dhx_marked_timespan {
  display: none;
}

.dhx_now_time {
  width: 100%;
  border-bottom: 2px solid #ff5722;
}

.dhx_scheduler_month .dhx_now_time {
  border-bottom: 0;
  border-left: 2px solid #ff5722;
}

.dhx_matrix_now_time {
  border-left: 2px solid #ff5722;
  opacity: 0.5;
}

.dhx_now_time {
  opacity: 0.5;
}

.dhx_cal_quick_info {
  border: 1px solid rgba(50, 50, 50, 0.19);
  border-radius: 2px;
  position: absolute;
  z-index: 8;
  font-family: Roboto, Arial;
  font-size: 14px;
  background-color: #fff;
  padding: 0;
  width: 300px;
  transition: left 0.5s ease, right 0.5s;
  -moz-transition: left 0.5s ease, right 0.5s;
  -webkit-transition: left 0.5s ease, right 0.5s;
  -o-transition: left 0.5s ease, right 0.5s;
}

.dhx_no_animate {
  transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: none;
}

.dhx_cal_quick_info.dhx_qi_left .dhx_qi_big_icon {
  float: right;
}

.dhx_quick_info_rtl.dhx_cal_quick_info.dhx_qi_left .dhx_qi_big_icon {
  float: left;
}

.dhx_cal_qi_title {
  padding: 10px 0 10px 10px;
  color: #fff;
  letter-spacing: unset;
}

.dhx_cal_container_rtl .dhx_cal_qi_title {
  padding: 10px 18px 10px 0;
  text-align: right;
}

.dhx_cal_qi_tdate {
  font-size: 14px;
}

.dhx_cal_qi_tcontent {
  font-size: 18px;
  font-weight: 500;
}

.dhx_cal_qi_content {
  border: none;
  background-color: #fefefe;
  padding: 24px 8px 16px 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.75);
  width: 275px;
  overflow: hidden;
}

.dhx_quick_info_rtl .dhx_cal_qi_content {
  padding: 24px 10px 16px 8px;
  direction: rtl;
}

.dhx_qi_big_icon {
  min-width: 60px;
  padding: 5px 10px 5px 9px;
  margin: 5px 9px 5px 0;
  background-color: transparent;
  border-bottom: none;
  border-right: none;
  border-radius: 2px;
  line-height: 20px;
  color: #0288d1;
  vertical-align: middle;
  cursor: pointer;
  float: left;
}

.dhx_quick_info_rtl .dhx_qi_big_icon {
  padding: 5px 5px 9px 10px;
  margin: 5px 0 5px 9px;
}

.dhx_cal_qi_controls div {
  float: left;
  height: 20px;
  text-align: center;
  line-height: 20px;
}

.dhx_quick_info_rtl {
  .dhx_cal_qi_controls div {
    float: right;
  }

  &.dhx_qi_right .dhx_cal_qi_controls {
    padding-right: 15px;
  }
}

.dhx_qi_big_icon .dhx_menu_icon {
  margin: 0 8px 0 0;
}

.dhx_quick_info_rtl .dhx_qi_big_icon .dhx_menu_icon {
  margin: 0 0 0 8px;
}

.dhx_drag_marker {
  width: 100%;
  opacity: 0.5;
  background-color: #dedede;
  position: absolute;
  box-sizing: border-box !important;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}

.dhx_focus_slot {
  background: #bdbdbd;
  position: absolute;
  pointer-events: none;
  opacity: 0.3;
}

.dhx_cal_container :focus {
  outline-style: solid;
  outline-style: auto;
}

.dhx_no_select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}

.dhx_drag_placeholder {
  z-index: 10;
  opacity: 0.8;

  .dhx_cal_select_menu {
    display: none;
  }
}

.dhx_timeline_scale_header {
  position: absolute;
  z-index: 2;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  background-color: #fff;
}

.dhx_timeline_label_wrapper {
  overflow: hidden;
  background-color: #fff;
  position: absolute;
  z-index: 1;
}

.dhx_timeline_label_col {
  position: relative;
}

.dhx_timeline_label_row {
  position: absolute;
  left: 0;
  background-color: inherit;
}

.dhx_timeline_data_wrapper {
  position: absolute;
  z-index: 0;
  left: 0;
  width: 100%;
}

.dhx_timeline_data_row {
  position: relative;
}

.dhx_timeline_data_cell {
  position: absolute;
  top: 0;
  height: 100%;
}

.dhx_timeline_table_wrapper {
  .dhx_marked_timespan {
    z-index: 1;
  }

  .dhx_time_block {
    z-index: 2;
  }

  .dhx_cal_event_line {
    z-index: 1;
  }

  .dhx_timeline_scrollable_data {
    overflow-x: auto;
  }
}

.dhtmlx_modal_box * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.dhx_cal_data {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  div,
  table * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.dhx_cal_header {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  div {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.dhx_cal_navline div {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.dhx_cal_quick_info,
.dhx_multi_day {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;

  div {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.dhx_tooltip_line div {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.dhx_cal_light {
  .dhx_cal_lsection,
  .dhx_wrap_section {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.dhx_form_repeat label {
  margin-bottom: 0;
}

.dhx_cal_data {
  div.dhx_scale_hour {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  table {
    .dhx_matrix_cell,
    .dhx_matrix_scell {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}

.dhx_cal_event div {
  line-height: normal;
}

.dhx_cal_data table {
  border-collapse: separate;
}

.dhx_cal_light .dhx_cal_radio input[type="radio"] {
  margin: 0 0 0 5px;
}

.dhx_cal_light_rtl .dhx_cal_radio input[type="radio"] {
  margin: 0 5px 0 0;
}

.dhx_cal_light .dhx_cal_radio label {
  display: inline-block;
  margin: 3px 30px 0 5px;
  position: relative;
  top: -2px;
}

.dhx_cal_light_rtl .dhx_cal_radio label {
  margin: 3px 5px 0 30px;
}

.dhx_cal_event div {
  line-height: normal;
}

.dhx_cal_container {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  font-family: Roboto, Arial;
  font-size: 14px;

  div {
    -moz-user-select: none;
    -moz-user-select: -moz-none;
  }
}

.dhx_cal_navline {
  height: 20px;
  position: absolute;
  z-index: 3;
  width: 750px;
  color: rgba(0, 0, 0, 0.75);

  div {
    position: absolute;
    white-space: nowrap;
    top: 14px;
  }

  .dhx_cal_date {
    border: 0;
    font-size: 20px;
    font-weight: 500;
    font-family: Roboto, Arial;
    width: 100%;
    text-align: center;
    left: 0;
    padding: 1px 0 0 0;
    color: rgba(0, 0, 0, 0.75);
    z-index: -1;
  }
}

.dhx_cal_button .dhx_left_bg {
  width: 1px;
  overflow: hidden;
  height: 17px;
  z-index: 5;
  top: 0;
}

.dhx_cal_prev_button {
  cursor: pointer;
  right: 148px;
  background-color: none;

  //   background-image: url(imgs_dhx_material/arrow_left.svg);
  background-position: center center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  border: 1px solid transparent;
  border-radius: 5px 0 0 5px;
}

.dhx_cal_today_button {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #0288d1;
  right: 57px;
  background-color: none;
  background-image: none;
  background-position: -62px 0;
  background-repeat: no-repeat;
  height: 30px;
  width: 80px;
  border: 1px solid transparent;
  border-radius: 0;
  text-decoration: none;
  text-transform: uppercase;
}

.dhx_cal_next_button {
  cursor: pointer;
  right: 16px;
  background-color: none;

  //   background-image: url(imgs_dhx_material/arrow_right.svg);
  background-position: center center;
  background-repeat: no-repeat;
  height: 30px;
  width: 30px;
  border: 1px solid transparent;
  border-radius: 0 5px 5px 0;
}

.dhx_cal_tab {
  width: 88px;
  height: 29px;
  background-color: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  padding-top: 1px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid #0288d1;
  color: #0288d1;
  font-size: inherit;

  &.active {
    text-decoration: none;
    cursor: default;
    font-weight: 500;
    font-size: inherit;
    color: #fff;
    border: 1px solid #0288d1;
    border-bottom: 1;
    background-color: #0288d1;
    z-index: 25;
  }
}

.dhx_cal_tab_first {
  border-radius: 2px 0 0 2px;
  border-right: 0;
}

.dhx_cal_tab_last {
  border-radius: 0 2px 2px 0;
}

.dhx_cal_date,
.dhx_cal_next_button,
.dhx_cal_prev_button,
.dhx_cal_tab,
.dhx_cal_today_button {
  line-height: 30px;
}

.dhx_cal_header {
  position: absolute;
  overflow: hidden;
  left: 10px;
  background: #fff;
  border-top: 1px solid transparent;
  border-right: 1px solid transparent;
  z-index: 2;
}

.dhx_cal_container_rtl .dhx_cal_header {
  left: unset;
}

.dhx_cal_data {
  -webkit-tap-highlight-color: transparent;
  border-top: 1px solid transparent;
  position: absolute;
  width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.dhx_cal_container_rtl .dhx_cal_data {
  direction: rtl;
}

.dhx_cal_data,
.dhx_cal_event,
.dhx_cal_event_clear,
.dhx_cal_event_line {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.dhx_scale_bar {
  position: absolute;
  text-align: center;
  background-color: #fff;
  padding: 2px 0 0 0;
  border-left: 1px solid #e0e0e0;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.54);
}

.scheduler_container_resize_watcher {
  background: 0 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
  border: 0;
  box-sizing: border-box;
  opacity: 0;
}

.dhx_scale_holder {
  position: absolute;
  border-right: 1px solid #e0e0e0;

  //   background-image: url(imgs_dhx_material/databg.png);
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
}

.dhx_cal_container_rtl {
  .dhx_scale_holder {
    border-left: 1px solid #e0e0e0;
    border-right: 0;
  }

  .dhx_cal_header {
    border-right: 0;
    border-left: 1px solid transparent;
  }

  .dhx_scale_bar {
    border-left: 0;
    border-right: 1px solid #e0e0e0;
  }

  .dhx_month_head,
  .dhx_month_body {
    border-right: 0;
    border-left: 1px solid #e0e0e0;
  }
}

.dhx_scale_holder_now {
  position: absolute;
  -ms-interpolation-mode: nearest-neighbor;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  position: absolute;
  border-right: 1px solid #e0e0e0;

  //   background-image: url(imgs_dhx_material/databg.png);
}

.dhx_cal_container_rtl .dhx_scale_holder_now {
  border-right: 0;
  border-left: 1px solid #e0e0e0;
}

.dhx_scale_hour {
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
  text-align: center;
  line-height: 44px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
}

.dhx_month_head {
  height: 21px;
  padding: 0 8px 0 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.54);
  border-right: 1px solid #e0e0e0;
  background-color: #fff;
  text-align: right;
}

.dhx_cal_container_rtl .dhx_month_head {
  text-align: left;
  padding: 0 0 0 8px;
}

.dhx_after .dhx_month_head,
.dhx_before .dhx_month_head {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.38);
}

.dhx_now .dhx_month_head {
  background-color: #fff3a1;
  font-weight: 400;
}

.dhx_scale_hour_border {
  border-left: 1px dotted #8894a3;
}

.dhx_month_body {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
}

.dhx_after .dhx_month_body,
.dhx_before .dhx_month_body {
  background-color: #fff;
}

.dhx_now {
  .dhx_month_body,
  .dhx_month_head {
    background-color: #f7f7f7;
  }
}

.dhx_scale_ignore {
  display: none;
}

.dhx_cal_drag {
  position: absolute;
  z-index: 13;
  background-color: #ffe763;
  border: 1px solid #b7a543;
  opacity: 0.5;
}

.dhx_loading {
  position: absolute;
  width: 128px;
  height: 15px;

  // background-image: url(imgs/loading.gif);
  z-index: 13;
}

.dhx_multi_day,
.dhx_multi_day_icon {
  background-color: #fff;
  border-right: 1px solid transparent;
}

.dhx_multi_day {
  position: absolute;
  border-top: 1px solid transparent;
  background-color: transparent;
  border-right: none;
  box-shadow: none;
}

.dhx_cal_container_rtl .dhx_multi_day {
  direction: rtl;
}

.dhx_multi_day_icon,
.dhx_multi_day_icon_small {
  background-color: transparent;
  background-position: center center;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
  background-repeat: no-repeat;
}

.dhx_cal_container_rtl {
  .dhx_multi_day_icon,
  .dhx_multi_day_icon_small {
    border-right: 0;
    border-left: 1px solid transparent;
  }
}

.dhx_multi_day_icon {
  //   background-image: url(imgs_dhx_material/clock_big.png);
}

.dhx_multi_day_icon_small {
  //   background-image: url(imgs_dhx_material/clock_small.png);
}

.dhtmlxLayoutPolyContainer_dhx_skyblue .dhx_cal_container {
  background-color: #d0e5ff;
}

.dhx_month_body_border,
.dhx_month_head_border,
.dhx_scale_bar_border,
.dhx_scale_hour_border {
  border-left: 1px dotted #8894a3;
}

.dhx_cal_navline {
  .dhx_cal_export {
    width: 18px;
    height: 18px;
    margin: 2px;
    cursor: pointer;
    top: 0;

    &.pdf {
      left: 2px;

      // background-image: url(imgs/export_pdf.png);
    }

    &.ical {
      left: 24px;

      // background-image: url(imgs/export_ical.png);
    }
  }

  &.dhx_cal_navline_flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1vw;
    box-sizing: border-box;

    .dhx_cal_tab {
      box-sizing: border-box;
      height: 32px;
    }

    .dhx_cal_tab_last {
      margin-right: 14px;
    }

    .dhx_cal_tab {
      border-left-style: none;
      box-shadow: -1px 0 0 #0288d1;
    }

    .dhx_cal_date {
      width: auto;
      flex-grow: 1;
    }

    div {
      position: static;
    }

    .dhx_cal_navbar_row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      padding: 0 1vw;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
    }

    .dhx_cal_prev_button + .dhx_cal_next_button {
      border-left-style: none;
    }

    .dhx_cal_navbar_rows_container {
      display: flex;
      align-items: center;
      flex-direction: column;
      box-sizing: border-box;
      flex-grow: 1;
      height: 100%;
    }

    .dhx_cal_line_break,
    .dhx_cal_line_spacer {
      display: block;
      width: auto;
      flex-grow: 1;
    }

    .dhx_cal_tab_standalone,
    .dhx_cal_today_button {
      margin: 0 7px;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_tab {
      min-width: 4vw;
      font-size: 1.3vw;
      box-sizing: content-box;
      padding: 0 0.5vw;
      width: auto;

      &.active {
        min-width: 4vw;
        font-size: 1.3vw;
        box-sizing: content-box;
        padding: 0 0.5vw;
        width: auto;
      }
    }

    .dhx_cal_today_button {
      min-width: 4vw;
      font-size: 1.3vw;
      box-sizing: content-box;
      padding: 0 0.5vw;
      width: auto;
    }

    .dhx_cal_next_button,
    .dhx_cal_prev_button {
      height: 2.5vw;
      line-height: 2.5vw;
    }

    .dhx_cal_tab {
      height: 2.5vw;
      line-height: 2.5vw;

      &.active {
        height: 2.5vw;
        line-height: 2.5vw;
      }
    }

    .dhx_cal_today_button {
      height: 2.5vw;
      line-height: 2.5vw;
    }

    .dhx_cal_tab_last {
      margin-right: 2vw;
    }
  }
}

@media only screen and (max-width: 840px) {
  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_tab {
      min-width: 4vw;
      font-size: 1.5vw;
      box-sizing: content-box;
      padding: 0 0.5vw;
      width: auto;

      &.active {
        min-width: 4vw;
        font-size: 1.5vw;
        box-sizing: content-box;
        padding: 0 0.5vw;
        width: auto;
      }
    }

    .dhx_cal_today_button {
      min-width: 4vw;
      font-size: 1.5vw;
      box-sizing: content-box;
      padding: 0 0.5vw;
      width: auto;
    }

    .dhx_cal_next_button,
    .dhx_cal_prev_button {
      height: 4vw;
      line-height: 4vw;
    }

    .dhx_cal_tab {
      height: 4vw;
      line-height: 4vw;

      &.active {
        height: 4vw;
        line-height: 4vw;
      }
    }

    .dhx_cal_today_button {
      height: 4vw;
      line-height: 4vw;
    }

    .dhx_cal_tab_last {
      margin-right: 1vw;
    }

    .dhx_cal_date {
      font-size: 2.5vw;
    }
  }
}

@media only screen and (max-width: 480px) {
  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_tab {
      font-size: 0.8rem;
      padding: 0 2vw;

      &.active {
        font-size: 0.8rem;
        padding: 0 2vw;
      }
    }

    .dhx_cal_today_button {
      font-size: 0.8rem;
      padding: 0 2vw;
    }

    .dhx_cal_next_button,
    .dhx_cal_prev_button {
      height: 6vw;
      line-height: 6vw;
    }

    .dhx_cal_tab {
      height: 6vw;
      line-height: 6vw;

      &.active {
        height: 6vw;
        line-height: 6vw;
      }
    }

    .dhx_cal_today_button {
      height: 6vw;
      line-height: 6vw;
    }

    .dhx_cal_next_button,
    .dhx_cal_prev_button {
      width: 2rem;
    }

    .dhx_cal_date {
      font-size: 4vw;
    }
  }
}

.dhx_cal_touch_active {
  overscroll-behavior: none;
}

.dhx_form_repeat {
  padding: 0;
  margin: 0;
  padding-left: 5px;
  font-family: Tahoma, Verdana;
  font-size: 11px;
  line-height: 24px;

  input {
    padding: 0;
    margin: 0;
    padding-left: 5px;
    font-family: Tahoma, Verdana;
    font-size: 11px;
    line-height: 24px;
  }

  overflow: hidden;
}

.dhx_cal_light_wide .dhx_form_repeat {
  background-color: transparent;
}

.dhx_repeat_center,
.dhx_repeat_divider,
.dhx_repeat_left,
.dhx_repeat_right {
  height: 115px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.dhx_repeat_center {
  padding: 10px 0 0 10px;
  float: left;
}

.dhx_repeat_left {
  padding: 10px 0 0 10px;
  float: left;
  width: 105px;
}

.dhx_repeat_center {
  width: 345px;
  padding-top: 22px;
}

.dhx_repeat_divider {
  float: left;
  border-left: 1px dotted #dcc43e;
  width: 1px;
}

.dhx_repeat_right {
  float: right;
  width: 173px;
  padding: 17px 3px 0 10px;
}

.dhx_cal_light_rtl {
  .dhx_repeat_center,
  .dhx_repeat_divider,
  .dhx_repeat_left {
    float: none;
    display: inline-block;
    vertical-align: top;
  }

  .dhx_repeat_right {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-right: 0;
  }
}

input.dhx_repeat_text {
  height: 16px;
  width: 27px;
  margin: 0 4px 0 4px;
  line-height: 18px;
  padding: 0 0 0 2px;
}

.dhx_cal_light_rtl input.dhx_repeat_text {
  padding: 0 2px 0 0;
}

.dhx_form_repeat select {
  height: 20px;
  width: 87px;
  padding: 0 0 0 2px;
  margin: 0 4px 0 4px;
}

.dhx_cal_light_rtl .dhx_form_repeat select {
  padding: 0 2px 0 0;
}

input.dhx_repeat_date {
  height: 18px;
  width: 80px;
  padding: 0 0 0 2px;
  margin: 0 4px 0 4px;
  background-repeat: no-repeat;
  background-position: 64px 0;
  border: 1px #7f9db9 solid;
  line-height: 18px;
}

.dhx_cal_light_rtl input.dhx_repeat_date {
  padding: 0 2px 0 0;
}

input[type="radio"].dhx_repeat_radio {
  margin: 5px 4px 0 0;
  display: inline-block;
  position: relative;
  top: 2px;
}

.dhx_cal_light_rtl input[type="radio"].dhx_repeat_radio {
  margin: 5px 0 0 4px;
}

input.dhx_repeat_checkbox {
  margin: 4px 4px 0 0;
}

.dhx_repeat_days {
  td {
    padding-right: 5px;
  }

  label {
    font-size: 10px;
  }
}

.dhx_custom_button {
  width: 90px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border-radius: 5px;
}

.dhx_custom_button_recurring {
  background-image: none;
  background-position: -5px 20px;
  width: 20px;
  margin-right: 10px;
}

.dhx_cal_light_rec {
  width: 640px;

  .dhx_cal_larea {
    width: 632px;
  }

  &.dhx_cal_light_wide {
    width: 816px;

    .dhx_cal_larea {
      width: 808px;
    }
  }
}

.dhtmlXTooltip,
.dhtmlx_message_area,
.dhtmlx_modal_box,
.dhx_cal_container,
.dhx_cal_light {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dhx_cal_event {
  .dhx_header,
  &.dhx_cal_select_menu .dhx_footer {
    display: none;
  }
}

.dhx_cal_event_line.dhx_cal_event_drag {
  box-shadow: 0 0 1px 0 #fff;
}

.dhx_cal_event.dhx_cal_event_drag {
  box-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.47);
}

.dhx_btn_set {
  float: left/right;

  div:first-child {
    display: none;
  }
}

.dhx_cancel_btn,
.dhx_delete_btn,
.dhx_save_btn,
.dhx_mark {
  display: none;
}

.dhx_cal_event {
  .dhx_footer,
  .dhx_select_menu_footer {
    display: none;
  }
}

.dhx_cal_container {
  .dhx_multi_day {
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
  }

  &.dhx_scheduler_month .dhx_cal_data {
    border-top-color: transparent;
  }
}

.dhx_cal_event.dhx_cal_select_menu .dhx_body {
  overflow: unset;
}

.dhx_cal_container b {
  font-weight: 500;
}

.dhx_cal_event_clear {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dhx_scheduler_unit .dhx_cal_header {
  overflow: visible;
}

.dhx_cal_navline {
  .dhx_cal_today_button {
    border-radius: 2px;

    &:hover {
      border: 1px solid transparent;
      background-color: rgba(0, 0, 0, 0.1);
      transition: all 0.1s ease-in-out;
    }
  }

  .dhx_cal_prev_button,
  .dhx_cal_next_button {
    height: 30px;
    width: 30px;
    top: 14px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
    }
  }
}

.dhx_scale_holder_now {
  background-color: #f7f7f7;
}

.dhx_cal_navline .dhx_cal_export {
  width: 32px;
  height: 32px;
  margin: 2px;
  cursor: pointer;
  top: 13px;

  &.pdf {
    left: auto;
    right: 200px;

    // background-image: url(imgs_dhx_material/export_pdf.png);
  }
}

.dhx_cal_tab {
  &:hover {
    background-color: #edf8ff;
  }

  &.active:hover {
    color: #fff;
    background-color: #0288d1;
  }
}

.dhx_cal_navline.dhx_cal_navline_flex {
  .dhx_cal_tab_standalone,
  .dhx_cal_today_button {
    margin: 0;
  }
}

.dhx_cal_data div {
  .dhx_body,
  .dhx_cal_event,
  .dhx_footer,
  .dhx_title,
  .dhx_body div,
  .dhx_cal_event div,
  .dhx_footer div,
  .dhx_title div {
    box-sizing: border-box;
  }
}

.dhx_cal_event {
  &.dhx_cal_select_menu .dhx_event_move.dhx_title {
    display: none;
  }

  &.dhx_cal_event_drag .dhx_event_resize.dhx_footer,
  &.dhx_cal_event_selected .dhx_event_resize.dhx_footer,
  &:hover .dhx_event_resize.dhx_footer {
    display: block;
    background-color: transparent;
    margin-top: -11px;
  }
}

.dhx_scale_holder .dhx_cal_event {
  .dhx_body,
  .dhx_footer,
  .dhx_header,
  .dhx_title {
    width: 100%;
    box-sizing: border-box;
  }

  .dhx_body {
    overflow: visible;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dhx_cal_event.dhx_cal_select_menu .dhx_body {
  border-width: 0;
  padding: 6px 0 12px 0 !important;
  border-radius: 0;
  font-weight: 500;
  line-height: 20px;
  box-sizing: content-box;
}

div.dhx_menu_icon {
  margin: 0 1px 5px;
}

.dhx_cal_event {
  &.dhx_cal_event_cascade {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.3);
  }

  &.dhx_cal_editor {
    box-shadow: none;
    background-color: #0288d1;
    min-height: 24px;
    box-shadow: 1px 1px 0 0 rgba(255, 255, 255, 0.47);

    > div {
      min-height: 19px;
    }
  }
}

.dhx_cal_scale_placeholder {
  position: absolute;
  z-index: 2;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  left: 0;
}

.dhx_matrix_scell.folder .dhx_scell_expand {
  margin: 0 12px;
}

.dhx_cal_header div {
  &.dhx_bottom_scale_container .dhx_scale_bar,
  &.dhx_second_cal_header .dhx_scale_bar {
    border-left-style: solid;
    border-left-color: #e0e0e0;
  }
}

.dhx_cal_container_rtl .dhx_cal_header div {
  &.dhx_bottom_scale_container .dhx_scale_bar,
  &.dhx_second_cal_header .dhx_scale_bar {
    border-left: 0;
    border-right-style: solid;
    border-right-color: #e0e0e0;
  }
}

.dhx_scale_bar.dhx_second_scale_bar {
  border-bottom: 0;
  border-bottom-style: solid;
  border-bottom-color: #e0e0e0;
}

.dhx_matrix_scell.folder {
  border-right: 1px solid transparent;

  &.closed .dhx_scell_expand:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 13px;

    // background: #ebeced url(imgs_dhx_material/tree_arrow.svg) no-repeat;
    background-position: 3px center;
  }

  &.opened .dhx_scell_expand:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 13px;
    transform: rotate(-90deg);

    // background: #ebeced url(imgs_dhx_material/tree_arrow.svg) no-repeat;
    background-position: 3px center;
  }
}

.dhx_row_folder {
  .dhx_data_table {
    .dhx_matrix_cell {
      background-color: #ebeced;
      cursor: pointer;
      border-bottom-color: transparent;
      border-right-color: transparent;
    }

    &.folder .dhx_matrix_cell {
      border-bottom-color: transparent;
      border-right-color: transparent;
    }
  }

  .dhx_matrix_scell {
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
}

.dhx_cal_header {
  .dhx_second_scale_bar {
    border-top: 1px solid #e0e0e0;
    padding: 1px 0 0 0;
  }

  div .dhx_scale_bar {
    border-top: 1px solid transparent;
  }
}

.dhx_cal_container
  .dhx_cal_header.dhx_second_cal_header
  .dhx_scale_bar.dhx_second_scale_bar {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl
  .dhx_cal_header.dhx_second_cal_header
  .dhx_scale_bar.dhx_second_scale_bar {
  border-left: unset;
  border-right: 1px solid #e0e0e0;
}

.dhx_cal_container .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar {
  border-left: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl .dhx_cal_header.dhx_second_cal_header .dhx_scale_bar {
  border-left: unset;
  border-right: 1px solid #e0e0e0;
}

.dhx_cal_header.dhx_second_cal_header {
  border-right: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl .dhx_cal_header.dhx_second_cal_header {
  border-right: unset;
  border-left: 1px solid #e0e0e0;
}

.dhx_cal_header .dhx_scale_bar.dhx_second_scale_bar:last-child {
  border-right: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl
  .dhx_cal_header
  .dhx_scale_bar.dhx_second_scale_bar:last-child {
  border-left: unset;
  border-right: 1px solid #e0e0e0;
}

.dhx_scale_bar.undefined:last-child {
  border-right: 1px solid #e0e0e0 !important;
}

.dhx_cal_header .dhx_scale_bar a,
td .dhx_month_head a {
  text-decoration: none;
  color: #1b5dfb;
}

.dhx_cal_header .dhx_scale_bar a:hover,
td .dhx_month_head a:hover {
  text-decoration: underline;
}

.dhx_cal_data table .dhx_cal_event_line {
  box-sizing: border-box;
  text-overflow: ellipsis;
}

.dhx_cal_event_line {
  box-sizing: border-box;
  text-overflow: ellipsis;

  .dhx_event_resize {
    padding-bottom: 1px;
    width: 10px;
    display: none;
  }

  &.dhx_cal_event_drag .dhx_event_resize,
  &.dhx_cal_event_selected .dhx_event_resize,
  &:hover .dhx_event_resize {
    display: block;
  }
}

.dhtmlXTooltip.tooltip {
  border-left: 1px dotted #e0e0e0;
  border-top: 1px dotted #e0e0e0;
  font-family: Roboto, Arial;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  z-index: 10;

  b {
    font-weight: 500;
  }

  &[role="tooltip"] {
    font-size: 14px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-color: transparent;
  }
}

.dhx_cal_header {
  .dhx_cal_next_button,
  .dhx_cal_prev_button {
    width: 20px;
    height: 20px;
    border: none;
    top: 0 !important;
    right: 7px !important;
  }

  .dhx_cal_next_button:hover,
  .dhx_cal_prev_button:hover {
    // background-image: url(imgs_dhx_material/arrow_left_hov.svg);
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }

  .dhx_cal_next_button:hover {
    // background-image: url(imgs_dhx_material/arrow_right_hov.svg);
  }
}

.dhtmlx_popup_title {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-width: 0;
  background: 0 0;
  color: rgba(0, 0, 0, 0.75);
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  text-shadow: none;
  line-height: 42px;
}

.dhtmlx_modal_box .dhtmlx_popup_controls {
  text-align: left;

  .dhtmlx_popup_button {
    &.dhtmlx_cancel_button,
    &.dhtmlx_ok_button {
      float: right;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

.dhtmlx_popup_controls::after {
  content: "";
  display: table;
  clear: both;
}

.dhtmlx_popup_button {
  &.dhtmlx_cancel_button {
    margin-right: 10px;
  }

  &.dhtmlx_ok_button:active div {
    color: #fff;
  }
}

.dhtmlx_message_area .dhtmlx-error {
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2), 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  background: #ff584c;
  border: 1px solid transparent;
  border-radius: 0;

  div {
    background: #ff584c;
    border: 1px solid transparent;
    border-radius: 0;
  }
}

.dhx_minical_icon {
  left: 291px !important;
  top: 10px !important;
}

.dhx_year_month {
  height: 30px;
}

.dhx_cal_navline div.dhx_minical_icon {
  left: 210px;
  top: 16px;
  width: 40px !important;
  height: 40px !important;
  background-position: center center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    transition: all 50ms ease;
  }
}

.dhx_cal_container.dhx_mini_calendar {
  box-sizing: border-box;
  border: none;
  box-shadow: 0 2px 10px 0 #aaa;
  border-radius: 0;
  font-family: Roboto, Arial;
  padding: 5px;
}

.dhx_mini_calendar {
  .dhx_year_month {
    border: 1px solid #e0e0e0;
    color: rgba(0, 0, 0, 0.75);
    line-height: 20px;
    font-size: 14px;
    font-weight: 500;
  }

  .dhx_cal_next_button,
  .dhx_cal_prev_button {
    border: 0;
  }

  .dhx_year_week {
    border-bottom: 1px solid #e0e0e0;
    padding-top: 1px;
  }

  .dhx_scale_bar {
    font-family: Roboto, Arial;
    font-size: 12px;
    font-weight: 500;
    border-width: 0;
  }

  .dhx_year_body {
    padding-top: 1px;
  }

  .dhx_month_body,
  .dhx_month_head,
  .dhx_scale_bar,
  .dhx_year_body,
  .dhx_year_month {
    border-color: transparent;
  }

  .dhx_month_head {
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.54);
    margin: 2px 2px;
    border-radius: 50%;
    padding: 5px 0 0 1px;
    text-align: center;
    box-sizing: border-box;
    height: 30px;
    width: 30px;
  }
}

.dhx_after > .dhx_month_head {
  color: rgba(0, 0, 0, 0.38);
}

.dhx_minical_popup {
  height: auto;

  .dhx_cal_container.dhx_mini_calendar .dhx_cal_next_button {
    width: 25px;
    height: 25px;
    right: 10px !important;
    top: 5px !important;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 50%;
    }
  }
}

.dhx_cal_container.dhx_mini_calendar .dhx_cal_prev_button {
  width: 25px;
  height: 25px;
  left: 10px !important;
  top: 5px !important;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
}

.dhx_mini_calendar div.dhx_month_head {
  &.dhx_calendar_click {
    background-color: #e5e5e5;
    color: rgba(0, 0, 0, 0.75);
  }

  &.dhx_year_event {
    background-color: rgba(2, 136, 209, 0.2);
    color: #0288d1;

    &.dhx_calendar_click {
      background-color: #e5e5e5;
      color: #0288d1;
    }
  }
}

.dhtmlx_modal_box.dhtmlx-alert {
  .dhtmlx_edit_series_button {
    float: right;
    text-align: center;
    text-transform: uppercase;
    padding: 0 16px;
    border: 1px solid #0288d1;
    background-color: #0288d1;

    div {
      border: 1px solid #0288d1;
      background-color: #0288d1;
      color: #fff;
    }

    &:hover {
      border-color: #015785;
      background-color: #015785;
      transition: all 0.1s ease-in-out;

      div {
        background-color: #015785;
        border-color: #015785;
        transition: all 0.1s ease-in-out;
      }
    }
  }

  .dhtmlx_edit_occurrence_button {
    width: auto;
    float: right;
    text-align: center;
    text-transform: uppercase;
    padding: 0 16px;
    border: 1px solid #fff;
    background-color: #fff;

    div {
      border: 1px solid #fff;
      background-color: #fff;
      color: #0288d1;
    }

    &:hover {
      background-color: #d9edf8;
      transition: all 0.1s ease-in-out;
      border: 1px solid #d9edf8;

      div {
        background-color: #d9edf8;
        transition: all 0.1s ease-in-out;
        border: 1px solid #d9edf8;
      }
    }
  }
}

.dhx_cal_quick_info {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

.dhx_cal_qi_title {
  background-color: #0288d1;
}

.dhx_cal_qi_tdate {
  margin-top: 4px;
}

.dhx_qi_big_icon {
  .dhx_menu_icon {
    display: none;
  }

  border: 1px solid transparent;
  font-weight: 500;

  &:hover {
    border: 1px solid rgba(255, 88, 76, 0.01);
    background-color: #d9edf8;
    transition: all 0.1s ease-in-out;
  }

  &.icon_delete {
    &:hover {
      border: 1px solid rgba(255, 88, 76, 0.01);
      background-color: rgba(255, 88, 76, 0.3);
    }

    color: #ff584c;
  }
}

.dhx_cal_qi_controls div {
  float: unset;
}

.dhx_quick_info_rtl .dhx_cal_qi_controls div {
  float: left;
}

.dhx_cal_qi_controls {
  div.dhx_qi_big_icon {
    display: inline-block;
  }

  text-align: right;
  direction: rtl;
}

.dhx_qi_right .dhx_cal_qi_controls {
  padding-right: 10px;
}

.dhx_cal_quick_info.dhx_qi_left .dhx_qi_big_icon.dhx_qi_left {
  .dhx_cal_qi_content,
  .dhx_cal_qi_title {
    padding-left: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .dhx_cal_qi_controls {
    padding-right: 0;
    text-align: right;

    .dhx_qi_big_icon {
      float: unset;
    }
  }
}

.dhx_cal_qi_controls div.dhx_qi_big_icon {
  text-transform: uppercase;
  border-right-color: transparent;
  border-bottom-color: transparent;

  div {
    float: unset;
  }
}

.dhx_cal_light {
  width: 458px;
  height: auto !important;
  padding: 0 0 20px 0;
}

.dhx_cal_light_rec.dhx_cal_light_wide {
  width: 458px;
}

.dhx_cal_light {
  .combo > div,
  input,
  select,
  textarea {
    border-radius: 2px;
  }

  input {
    border: 1px solid #a9a9a9;
    padding: 2px 5px 2px 2px;
  }

  .dhx_section_timeline {
    padding: 4px 0 2px 19px;

    select {
      width: 100%;
      margin: 0;
    }
  }
}

.dhx_cal_title {
  border-radius: 2px 2px 0 0;
  margin: 0 0 20px 0;
}

.dhx_cal_ltext textarea {
  font-size: 14px;
}

.dhx_cal_header .dhx_scale_bar {
  border-left-color: transparent;
}

.dhx_cal_container_rtl .dhx_cal_header .dhx_scale_bar,
.dhx_timeline_scale_header {
  border-right-color: transparent;
}

.dhx_cal_container_rtl .dhx_timeline_scale_header {
  border-left-color: transparent;
}

.dhx_timeline_scale_header.dhx_timeline_second_scale {
  border-right-color: #e0e0e0;
}

.dhx_cal_container_rtl .dhx_timeline_scale_header.dhx_timeline_second_scale {
  border-left-color: #e0e0e0;
}

textarea.dhx_cal_editor {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  font-weight: 500;
  resize: none;
}

.dhx_cal_light.dhx_cal_light_wide .dhx_cal_larea,
.dhx_cal_light_rec.dhx_cal_light_wide .dhx_cal_larea {
  height: auto !important;
  width: 100% !important;
}

.dhx_section_time select:nth-child(1) {
  margin: 0 0 16px 4px;
}

.dhx_cal_light_readonly .dhx_section_time {
  color: #2e2e2e;
}

.dhx_section_time {
  select.dhx_lightbox_time_select {
    width: 102px;
    box-sizing: border-box;
    padding: 0 0 0 34px !important;

    // // background: url(imgs_dhx_material/time.svg) no-repeat 8px 5px, url(imgs_dhx_material/menu-down.svg) no-repeat 100% 3px;
  }

  &.dhx_lightbox_minical {
    select.dhx_lightbox_time_select {
      //   background: url(imgs_dhx_material/menu-down.svg) no-repeat 100% 3px;
      padding: 0 0 0 5px !important;
      width: 62px;
    }

    span {
      display: none;
    }
  }

  select.dhx_lightbox_day_select {
    min-width: 46px !important;

    // background: url(imgs_dhx_material/menu-down.svg) no-repeat 100% 3px;
    box-sizing: border-box;
  }
}

.dhx_cal_light_rtl .dhx_section_time select {
  &.dhx_lightbox_day_select,
  &.dhx_lightbox_month_select,
  &.dhx_lightbox_year_select {
    background-position-x: left;
  }

  &.dhx_lightbox_time_select {
    // // background: url(imgs_dhx_material/time.svg) no-repeat 75px 5px, url(imgs_dhx_material/menu-down.svg) no-repeat left !important;
  }
}

.dhx_section_time select {
  &.dhx_lightbox_month_select {
    width: 98px !important;

    // background: url(imgs_dhx_material/menu-down.svg) no-repeat 100% 3px;
  }

  &.dhx_lightbox_year_select {
    min-width: 58px !important;
    max-width: 58px !important;
    padding-left: 3px !important;

    // background: url(imgs_dhx_material/menu-down.svg) no-repeat 100% 3px;
  }

  &:nth-child(6) {
    margin: 0 0 16px 4px;
  }
}

.dhx_cal_light_rtl .dhx_section_time select {
  &:nth-child(1),
  &:nth-child(6) {
    margin: 0 4px 16px 0;
  }
}

.dhx_section_time select:last-child {
  margin: 0 0 16px 6px;
}

.dhx_cal_light_rtl .dhx_section_time select:last-child {
  margin: 0 6px 16px 0;
}

.dhx_section_time {
  color: #fff;

  select {
    float: left;
    height: 32px;
    box-sizing: border-box;
    margin: 0 0 16px 6px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    padding: 0 0 0 4px !important;
  }
}

.dhx_cal_light_rtl {
  .dhx_section_time select {
    float: right;
    margin: 0 6px 16px 0;
    padding: 0 4px 0 0 !important;
  }

  select.dhx_lightbox_time_select {
    padding: 0 34px 0 0 !important;
  }
}

.dhx_time {
  color: #fff;
}

.dhx_in_move {
  background-color: #0288d1;
  color: #fff;
}

.dhx_cal_light {
  select {
    box-sizing: border-box;
    height: 32px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    // background: url(imgs_dhx_material/menu-down.svg) no-repeat 100% 3px;
    border: 1px solid rgba(0, 0, 0, 0.08);
  }

  &.dhx_cal_light_rtl select {
    // background: url(imgs_dhx_material/menu-down.svg) no-repeat left;
  }
}

.dhx_multi_select_control {
  padding-top: 3px;
  padding-left: 15px;
  line-height: 32px;
}

.dhx_cal_light_rtl .dhx_multi_select_control {
  padding: 3px 15px 0 0;
  float: right;
}

.dhx_multi_select_control label {
  margin-right: 12px;
}

.dhx_cal_light_rtl .dhx_multi_select_control label {
  margin: 0 0 0 12px;
  float: right;
}

.dhx_multi_select_control.dhx_multi_select_control_vertical {
  padding-top: 6px;
  line-height: 25px;
}

.dhx_cal_light .combo {
  min-height: 32px !important;
}

.dhx_cal_light_wide {
  .combo > div {
    top: -3px;
    width: 319px !important;
    margin: 0;
    height: 32px !important;
    position: relative;
    left: 19px;
    font-size: 14px;
    font-family: Roboto, Arial;
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
  }

  .dhx_combo_box {
    width: 319px !important;
    margin: 0;
    height: 32px !important;
    position: relative;
    left: 19px;
    font-size: 14px;
    font-family: Roboto, Arial;
    border: 1px solid rgba(0, 0, 0, 0.08) !important;
  }
}

.dhx_cal_light
  div.dhxcombo_dhx_terrace
  div.dhxcombo_select_button
  div.dhxcombo_select_img {
  background-position: center 10px !important;
}

.dhxcombolist_dhx_terrace {
  width: 319px !important;
}

.dhx_cal_light .dhxcombo_dhx_terrace input.dhxcombo_input {
  width: 321px !important;
  box-sizing: border-box;
  outline: hidden !important;
  border: none !important;
  height: 32px !important;
  padding-left: 8px;
}

.dhxcombolist_dhx_terrace div.dhxcombo_option div.dhxcombo_option_text {
  padding-left: 8px !important;
}

.dhx_cal_light_wide .dhx_wrap_section {
  padding-right: 15px;
}

.dhx_cal_light_rtl .dhx_wrap_section {
  padding: 0 0 0 15px;
}

.dhx_cal_light .dhx_wrap_section .dhx_cal_lsection {
  min-height: 25px !important;
  line-height: 18px;
  padding-top: 10px;
}

.dhx_cal_lsection .dhx_fullday {
  vertical-align: middle;

  input[type="checkbox"] {
    margin: 0 3px 0 0;
    vertical-align: middle;
  }
}

.dhx_cal_light .dhx_wrap_section .dhx_section_time {
  height: 100% !important;
  line-height: 32px;

  &:after {
    content: " ";
    clear: both;
    display: table;
  }
}

.dhx_cal_light_rtl {
  .dhx_wrap_section .dhx_section_time {
    line-height: 0;
  }

  .dhx_section_time {
    white-space: normal;
  }
}

.dhx_cancel_btn_set:hover {
  background-color: #d9edf8;
  transition: all 0.1s ease-in-out;
  border: 1px solid #d9edf8;
}

.dhx_delete_btn_set {
  float: left;

  &:hover {
    border: 1px solid rgba(255, 88, 76, 0.01);
    background-color: rgba(255, 88, 76, 0.3);
  }
}

.dhx_cal_light_rtl .dhx_delete_btn_set {
  float: right;
}

.dhx_custom_btn_info_set:hover {
  background-color: #d9edf8;
  transition: all 0.1s ease-in-out;
  border: 1px solid #d9edf8;
}

.dhx_cal_ltext {
  min-height: 32px !important;
  font-size: 14px;
  margin: 0 0 0 15px;
  line-height: 32px;
  font-family: Roboto, Arial;

  input[type="text"] {
    height: 26px;
    margin: 0 0 0 4px;
    font-size: 14px;
    font-family: Roboto, Arial;
    color: rgba(0, 0, 0, 0.8);
    border-color: rgba(0, 0, 0, 0.08);
  }
}

.dhx_cal_light_wide .dhx_cal_ltext {
  margin: 0 0 0 117px;
}

.dhx_cal_light_rtl .dhx_cal_ltext {
  margin: 0 117px 0 0;
}

.dhx_multi_select_custom {
  min-height: 52px !important;
  padding: 5px 0 0 15px;

  label {
    font-size: 14px;
    font-family: Roboto, Arial;
    width: 35% !important;
    display: inline-block;
    margin: 0 0 5px 0;
  }
}

.dhx_form_repeat {
  margin: 30px 0 0 117px;
  padding-left: 0;

  form {
    font-size: 14px;
    font-family: Roboto, Arial;
    color: rgba(0, 0, 0, 0.8);
    padding: 0 0 0 5px;

    select {
      box-sizing: border-box;
      height: 32px;
      font-size: 12px;
      font-family: Roboto, Arial;
      color: rgba(0, 0, 0, 0.8);
      border-color: rgba(0, 0, 0, 0.08);
    }

    label {
      font-size: 14px;
      font-family: Roboto, Arial;
      display: inline-block;
      margin: 0 0 5px 0;

      input[type="checkbox"] {
        margin: 0 5px 0 0;
      }
    }
  }
}

.dhx_cal_light_rtl .dhx_form_repeat {
  margin: 30px 117px 0 0;
  padding-left: unset;
  padding-right: 0;

  form {
    padding: 0 5px 0 0;

    label input[type="checkbox"] {
      margin: 0 0 0 5px;
    }
  }
}

.dhx_section_time input.dhx_readonly {
  height: 26px;
  float: left;
  border-color: #e0e0e0;
  width: 77px;
  margin: 0 4px 16px 4px;
  text-align: center;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;

  &:nth-last-child(2) {
    margin-left: 12px;
  }
}

.dhx_lightbox_minical.dhx_section_time select {
  margin: 0 0 16px 3px;
}

.dhtmlx_cancel_button:hover {
  background-color: #d9edf8;
  transition: all 0.1s ease-in-out;

  div {
    background-color: #d9edf8;
    border-color: #d9edf8;
    transition: all 0.1s ease-in-out;
  }
}

.dhtmlx_popup_button.dhtmlx_ok_button:hover {
  border-color: #f10;
  transition: all 0.1s ease-in-out;

  div {
    background-color: #f10;
    border-color: #f10;
    transition: all 0.1s ease-in-out;
  }
}

.dhx_left_btn_set.dhx_btn_set {
  ~ .dhx_left_btn_set.dhx_btn_set {
    margin: 0 0 0 16px;
  }

  margin: 0 0 0 20px;
}

.dhx_right_btn_set.dhx_btn_set {
  ~ .dhx_right_btn_set.dhx_btn_set {
    margin: 0 15px 0 0;
  }

  margin: 0 15px 0 0;
}

.dhx_save_btn_set:hover {
  border-color: #0299eb;
  transition: all 0.1s ease-in-out;

  div {
    background-color: #0299eb;
    border-color: #0299eb;
    transition: all 0.1s ease-in-out;
  }
}

.dhx_cal_ltext.dhx_cal_radio {
  padding: 4px 0 0 0;
  overflow: auto;
}

.dhx_section_time.dhx_section_time_calendar select {
  width: 64px;
}

.dhx_repeat_left {
  width: 325px !important;
  height: 50px !important;
  border-bottom: 1px solid #cecece;

  br {
    display: none;
  }

  label {
    width: 24% !important;
  }
}

.dhx_repeat_center {
  width: 325px !important;
  height: 105px !important;
  padding: 10px 0 0 0 !important;
  border-bottom: 1px solid #cecece;

  #dhx_repeat_week table.dhx_repeat_days {
    width: 100% !important;
    border-collapse: collapse;

    td {
      width: 24% !important;

      &:nth-child(3) {
        width: 28% !important;
      }

      label {
        box-sizing: border-box;
        height: 20px !important;
        line-height: 20px;
        font-size: 12px;
        width: 100% !important;

        input {
          position: relative;
          top: 2px;
        }
      }
    }
  }

  .dhx_repeat_month_label {
    width: 65px !important;
  }

  .dhx_repeat_year_label {
    width: 60px !important;
  }
}

.dhx_repeat_left {
  padding-left: 0;
}

.dhx_repeat_right {
  float: left;
  width: 325px !important;
  padding: 10px 0 0 0 !important;

  label:nth-child(3) {
    width: 49px !important;
  }

  input.dhx_repeat_date {
    margin-left: 10px;
    box-sizing: border-box;
    height: 32px;
    font-size: 14px;
    font-family: Roboto, Arial;
    border-color: rgba(0, 0, 0, 0.08) !important;
  }
}

.dhx_cal_light_rtl .dhx_repeat_right input.dhx_repeat_date {
  margin-left: 0;
  margin-right: 10px;
}

.dhx_cal_light_wide .dhx_repeat_right {
  margin-right: 15px !important;
}

.dhx_cal_light_rtl {
  .dhx_repeat_right {
    margin-right: 0 !important;
  }

  .dhx_repeat_divider {
    display: none;
  }
}

.dhx_repeat_divider {
  display: none;
}

input.dhx_repeat_text {
  height: 32px !important;
  width: 28px !important;
  margin: 0 6px 5px 4px !important;
  line-height: 30px !important;
  padding: 0 !important;
  text-align: center !important;
  font-size: 12px !important;
  font-family: Roboto, Arial !important;
  box-sizing: border-box !important;
  border-color: rgba(0, 0, 0, 0.08) !important;
}

.dhx_cal_light_rtl input.dhx_repeat_text {
  margin: 0 4px 5px 6px !important;
}

.dhx_repeat_center .dhx_repeat_month select {
  margin: 0 6px 0 0;
  box-sizing: border-box;
  height: 32px !important;
}

.dhx_wrap_section .dhx_cal_wide_checkbox {
  line-height: 32px;

  input[type="checkbox"] {
    margin-top: 8px;
    margin-left: 19px;
    vertical-align: middle;
  }
}

.dhx_cal_light_rtl
  .dhx_wrap_section
  .dhx_cal_wide_checkbox
  input[type="checkbox"] {
  margin: 8px 19px 0 0;
}

.dhx_cal_light .dhx_cal_radio input[type="radio"] {
  position: relative;
  top: -1px;
}

.dhx_cal_lsection div.dhx_custom_button {
  padding: 0;
  color: #0288d1;
  font-family: Roboto, Arial;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0);
  margin: 0 20px 0 0;
  width: auto;
  height: 32px;
  font-weight: 500;
  text-align: center;
  border-radius: 2px;

  &:hover {
    background-color: #d9edf8;
  }

  .dhx_custom_button_recurring {
    display: none;
  }

  div {
    float: none;
    height: 32px;
    line-height: 32px;
    text-transform: uppercase;
    padding: 0 16px 0 16px;
    border-radius: 2px;
  }
}

@media only screen and (max-width: 1023px) {
  .dhx_cal_light_rtl.dhx_cal_light_responsive
    .dhx_wrap_section
    .dhx_cal_wide_checkbox
    input[type="checkbox"] {
    margin: 0.781vw 1.855vw 0 0;
  }

  .dhx_cal_light.dhx_cal_light_responsive {
    .dhx_wrap_section {
      .dhx_cal_lsection {
        min-height: 2.441vw !important;
        line-height: 1.758vw;
        padding-top: 0.977vw;
      }

      .dhx_section_time {
        line-height: 3.125vw;
      }

      .dhx_cal_wide_checkbox input[type="checkbox"] {
        margin-top: 0.781vw;
        margin-left: 1.855vw;
        vertical-align: middle;
      }
    }

    .dhx_section_time select.dhx_lightbox_time_select,
    select {
      background-position-y: 0.977vw !important;
      background-size: 3.906vw !important;
    }

    .dhx_section_time select.dhx_lightbox_time_select {
      padding-left: 3.32vw !important;

      // //   background: url(imgs_dhx_material/time.svg) no-repeat 0.781vw 0.488vw, url(imgs_dhx_material/menu-down.svg) no-repeat 100% 0.293vw;
    }

    .dhx_cal_template {
      padding-top: 0.977vw;
    }

    .dhx_cal_radio {
      min-height: 5.859vw !important;
    }

    select,
    .dhx_cal_lsection div.dhx_custom_button {
      height: 3.125vw;
    }

    .dhx_custom_button .dhx_custom_button_recurring + div {
      height: 100%;
      line-height: 5vw;
    }

    .dhx_multi_select_control {
      padding-top: 0.293vw;
      padding-left: 1.465vw;
      line-height: 3.125vw;

      &.dhx_multi_select_control_vertical {
        padding-top: 0.586vw;
        line-height: 2.441vw;
      }
    }

    .dhx_form_repeat {
      .dhx_repeat_left {
        height: 14.766vw !important;

        label {
          width: 23% !important;
        }
      }

      .dhx_repeat_center {
        .dhx_repeat_month_label {
          width: auto !important;
        }

        label {
          margin-right: 0.488vw !important;
        }

        .dhx_repeat_year_label {
          width: 13.672vw !important;
        }
      }

      .dhx_repeat_right label:nth-child(3) {
        width: 13.672vw !important;
      }
    }
  }

  .dhx_cal_light_rtl.dhx_cal_light_responsive {
    .dhx_section_time select.dhx_lightbox_time_select {
      // //   background: url(imgs_dhx_material/time.svg) no-repeat 135px 25px, url(imgs_dhx_material/menu-down.svg) no-repeat left !important;
      background-position-y: 0.977vw !important;
      background-size: 3.906vw !important;
    }

    .dhx_form_repeat .dhx_repeat_center label {
      margin-left: 0.488vw !important;
      margin-right: 0 !important;
    }
  }
}

.dhx_year_box .dhx_year_month {
  color: #0288d1;
  font-family: Roboto, Arial;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

.dhx_scheduler_year .dhx_cal_data {
  padding: 25px 0;
  box-sizing: border-box;
  text-align: center;

  &::before {
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 3px;
    content: " ";
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }
}

.dhx_year_box {
  box-sizing: border-box;
  text-align: center;
  margin: 15px 35px 18px;
  margin-top: 15px !important;

  .dhx_year_week {
    text-align: left;

    > div {
      position: static;
      display: inline-block;
    }
  }

  position: static !important;
  display: inline-block;
  width: 224px !important;
  height: auto !important;
  overflow: hidden;

  .dhx_year_month {
    border: none;
    padding: 0;
  }

  .dhx_year_grid {
    .dhx_year_week {
      margin: 0 0 7px 0;

      .dhx_scale_bar {
        width: 32px !important;
        font-size: 12px;
        box-sizing: border-box;
        text-align: center;
        border-color: transparent;

        &:nth-child(1) {
          left: 0;
        }

        &:nth-child(2) {
          left: 32px !important;
        }

        &:nth-child(3) {
          left: 64px !important;
        }

        &:nth-child(4) {
          left: 96px !important;
        }

        &:nth-child(5) {
          left: 128px !important;
        }

        &:nth-child(6) {
          left: 160px !important;
        }

        &:nth-child(7) {
          left: 192px !important;
        }
      }
    }

    .dhx_year_body {
      border: none;

      table {
        padding: 0;
        margin: 0;
        box-sizing: border-box;

        tbody {
          padding: 0;
          margin: 0;
          box-sizing: border-box;

          .dhx_before,
          td {
            width: 32px;
            height: 32px;
            text-align: center;
            box-sizing: border-box;
            padding: 2px;
          }

          .dhx_before .dhx_month_head,
          td .dhx_month_head {
            border: none;
            width: 28px;
            height: 28px;
            text-align: center;
            border-radius: 50%;
            padding: 0;
            font-size: 14px;
            line-height: 28px;
          }

          .dhx_before .dhx_month_head.dhx_year_event,
          td .dhx_month_head.dhx_year_event {
            background-color: #0288d1;
            color: #fff;
            font-weight: 500;
            cursor: pointer;
          }

          .dhx_before .dhx_month_body,
          td .dhx_month_body {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1790px) {
  .dhx_year_box {
    margin: 15px 20px;
  }
}

@media (max-width: 1350px) {
  .dhx_year_box {
    margin: 15px 10px;
  }
}

div {
  &.dhx_year_tooltip {
    border: none;
    box-sizing: border-box;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    background: #fff;
    font-family: Roboto, Arial;
    font-size: 14px;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.8);

    .dhx_tooltip_line {
      min-height: 20px;
      height: auto;
      line-height: 20px;
      margin: 14px 12px 14px 0;
      overflow: visible;
      position: relative;
      box-sizing: border-box;
      padding: 0 0 0 48px;

      .dhx_event_icon.icon_details {
        // background: url(imgs_dhx_material/agenda-calendar.svg) no-repeat center center;
        width: 40px;
        height: 40px;
        position: absolute;
        top: -10px;
        left: 4px;
        padding: 0;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
          border-radius: 50%;
        }

        float: left;
      }

      .dhx_tooltip_date {
        float: right;
      }
    }
  }

  &.dhx_tooltip_rtl .dhx_tooltip_line {
    margin: 14px 0 14px 12px;
    padding: 0 48px 0 0;

    .dhx_tooltip_date {
      float: left;
    }

    .dhx_event_icon.icon_details {
      left: 0;
      right: 4px;
    }
  }
}

.dhx_cal_header div {
  &.dhx_agenda_line {
    div,
    span {
      border-color: transparent;
    }
  }

  &.dhx_grid_line div {
    border-color: transparent;
  }

  &.dhx_map_line div {
    &.headline_date,
    &.headline_description {
      border-color: transparent;
    }
  }
}

.dhx_cal_container_rtl .dhx_cal_header div.dhx_grid_line div {
  border-right-color: transparent;
}

.dhx_scheduler_agenda .dhx_cal_data {
  border-top: 1px solid #fff;
  background: #fff;
}

.dhx_map_area {
  background-image: none;
}

.dhx_agenda_area div[role="row"],
.dhx_grid_area div[role="row"],
.dhx_map_area div[role="row"] {
  box-sizing: border-box;
  height: 36px;
  border-bottom: 1px solid transparent;
}

.dhx_agenda_area .dhx_event_icon.icon_details,
.dhx_map_area .dhx_event_icon.icon_details {
  border: none;
}

.dhx_agenda_area .dhx_v_border,
.dhx_grid_v_border,
.dhx_map_area .dhx_v_border {
  border-right: 1px solid #e0e0e0;
}

.dhx_cal_container_rtl {
  .dhx_grid_v_border,
  .dhx_map_area .dhx_v_border {
    border-left: 1px solid #e0e0e0;
    border-right: unset;
  }
}

.dhx_agenda_area div[role="row"]:nth-child(even),
.dhx_grid_area tr.dhx_grid_event:nth-child(even),
.dhx_map_area div[role="row"]:nth-child(even) {
  background-color: #ededed;
}

.dhx_agenda_area div[role="row"]:nth-child(odd),
.dhx_grid_area tr.dhx_grid_event:nth-child(odd),
.dhx_map_area div[role="row"]:nth-child(odd) {
  background-color: #fff;
}

.dhx_agenda_line div.dhx_agenda_event_time,
.dhx_grid_area tr.dhx_grid_event,
.dhx_map_line div.dhx_map_event_time {
  line-height: 36px;
  height: 36px;
  color: rgba(0, 0, 0, 0.75);
}

.dhx_cal_container_rtl {
  .dhx_map_line div.dhx_map_event_time {
    float: right;
    direction: ltr;
  }

  .dhx_agenda_event_time {
    direction: ltr;
  }
}

.dhx_agenda_area .dhx_agenda_line .dhx_event_icon,
.dhx_map_area .dhx_map_line .dhx_event_icon {
  //   background: url(imgs_dhx_material/agenda-calendar.svg) no-repeat center center;
  height: 33px;
  width: 33px;
  margin: 2px 0 0 3px;
  background-size: 20px 20px;
  border-radius: 50%;
}

.dhx_cal_container_rtl .dhx_map_area .dhx_map_line .dhx_event_icon {
  margin: 2px 3px 0 0;
  float: right;
}

.dhx_agenda_area .dhx_agenda_line .dhx_event_icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.dhx_map_area .dhx_map_line {
  .dhx_event_icon:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .line_description {
    box-sizing: border-box;
    line-height: 36px;
    height: 100%;
    color: rgba(0, 0, 0, 0.75);
  }
}

.dhx_agenda_area .dhx_agenda_line span {
  display: inline-block;
  line-height: 36px;
  height: 100%;
  color: rgba(0, 0, 0, 0.75);
}

.dhx_cal_container_rtl .dhx_agenda_line span {
  padding: 0;
}

.dhx_scheduler_week_agenda {
  .dhx_wa_column {
    font-family: Roboto, Arial;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.75);
  }

  .dhx_wa_scale_bar {
    font-family: inherit;
    font-size: 14px;
    font-weight: 500;
    color: inherit;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    background-color: #ededed;
    padding-left: 7px;
  }
}

.dhx_cal_container_rtl.dhx_scheduler_week_agenda .dhx_wa_scale_bar {
  padding-right: 7px;
}

.dhx_scheduler_week_agenda .dhx_wa_ev_body {
  border-bottom: 1px solid #e0e0e0;
  padding: 5px 0 5px 7px;
}

.dhx_cal_container_rtl.dhx_scheduler_week_agenda .dhx_wa_ev_body {
  padding: 5px 7px 5px 0;
}

.dhx_wa_column.dhx_wa_column_last .dhx_wa_day_cont {
  border-left: 1px solid #e0e0e0;
}

.dhx_scheduler_week_agenda .dhx_cal_event_selected {
  background-color: #0288d1;
  color: #fff;
}

.dhx_cal_ltitle {
  text-overflow: ellipsis;
}

.dhx_expand_icon {
  //   background-image: url(imgs_dhx_material/collapse_expand_icon.png);
}

@media only screen and (max-width: 550px) {
  .dhx_cal_event {
    .dhx_body,
    .dhx_title,
    &.dhx_cal_select_menu .dhx_body {
      padding-left: 2px;
    }
  }

  .dhx_cal_container_rtl .dhx_cal_event {
    .dhx_body,
    .dhx_title {
      padding-right: 2px;
    }
  }
}
