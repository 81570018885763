@import "../../../assets/sass/_var.scss";
@import "../../../assets/sass/mixin";

.Accordion {
  &_header {
    text-align: left;
    min-height: 44px;
    font-size: $f20px;
    //font-size: $f18px;
    font-weight: 600;
    line-height: $f44px;
    //margin: 0 15px;
    border-bottom: 1px solid $grey;
    cursor: pointer;

    span {
      // for the screen reader
      font-size: 0;
      line-height: 0;
    }
    .logo_holder {
      height: 30px;
      width: 30px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      text-align: center;
      &:empty {
        display: none;
      }

      img {
        height: 30px;
      }
    }

    p {
      margin: 0;
      &:empty {
        display: none;
      }
    }
    .txt {
      margin-top: 10px;
      font-size: $f16px;
      font-weight: 600;
      line-height: $f20px;
      border-top: 1px solid $grey;
      padding-top: 16px;
    }
    .subtxt {
      font-size: $f14px;
      line-height: $f18px;
      padding-top: 10px;
    }
    .txt + .subtxt {
      padding-top: 0;
    }
    .navigationPreference {
      display: none;
    }

    .navigationPreferenceOpen {
      display: block;
      padding-bottom: 8px;
      //border-bottom: solid 1px $grey;
      .buttons {
        padding: 16px 0 6px;
      }
    }
  }
  &_content {
    padding-bottom: 12px;
    max-height: 0;
    overflow: hidden;
    animation-name: show;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    border-bottom: solid 1px $grey;
    @include theme-aware("color", "txt2");

    p {
      white-space: pre-line;
    }

    button {
      float: right;
    }
  }
}

@keyframes show {
  from {
    max-height: 0;
  }
  to {
    max-height: 20000px;
  }
}
