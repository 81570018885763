@import "../../assets/sass/_var.scss";
@import "../../assets/sass/mixin";

.noView {
  p {
    text-align: center;
  }
}
.inactiveIcon {
  span {
    border-radius: 5px;
    padding: 3px 6px;
    font-weight: 600;
  }
}
.fullPageLoader {
  width: 100%;
  height: calc(100vh - 85px);
}
.DashboardHeader {
  display: flex;
  width: calc(100% + 16px);
  padding: 4px;
  padding-left: 31px;
  margin-left: -16px;

  .dropdownHolder {
    background-color: #fff;
    padding: 0px;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    max-width: 180px;
    width: 20%;

    .title {
      text-align: left;
      text-transform: uppercase;
      font-size: $f12px;
      line-height: $f14px;
      display: block;
      padding: 4px 12px;
      border-bottom: 1px solid $grey;
      width: 100%;
    }
    + div {
      width: 80%;
    }
  }
  .row {
    display: flex;
    width: 100%;
    &:first-child {
      padding-top: 12px;
    }
    ul {
      border: 0 none !important;
    }
    li span {
      top: -18px !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    .title {
      text-transform: uppercase;
      font-size: $f14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: $f22px;
      text-align: right;

      width: 15%;
      white-space: nowrap;
      max-width: 100px;
      padding-right: 10px;

      display: inline-block;
    }
  }
}
.fullScreenWrapper {
  &.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    .dashboardWrapper {
      height: calc(100vh - 40px);
    }

    .showFullScreenContainer {
      display: block;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .showNavButton {
        cursor: pointer;
        height: 44px;
        z-index: 9;

        .button {
          display: flex;
          font-size: $f16px;
          font-weight: 600;
          color: $mGreen;
          line-height: 40px;

          span {
            margin-top: 1px;
          }
        }

        img {
          margin-left: 8px;
          width: 15px;
          height: 40px;
        }
      }
    }
    .buttonContainer {
      display: none;
    }
    .content_holder_no_shadow {
      margin-top: 40px !important;
    }
    .dashboardNav {
      top: -40px;
      height: 40px;
    }
  }
}
.showFullScreenContainer {
  display: none;
}
.dashboardWrapper {
  position: relative;
  height: calc(100vh - 140px);

  // &.availabilityContainer {
  //   background: red;
  // }
}
.fullScreenMap {
  .activeEventWrapper {
    position: absolute;
    top: 0;
    left: -35%;
    width: 135%;
  }
}
.dashboardNav {
  position: absolute;
  top: -56px;
  right: 0;
  width: 100%;
  content: "";
  display: block;
  height: 56px;
  box-shadow: inset 0 -10px 10px -5px rgba(0, 0, 0, 0.05);

  .buttonContainer {
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 54px;
    padding: 0 16px;
  }

  .tabs {
    width: 25%;
  }
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
}

.content_holder_no_shadow {
  height: 100%;
  position: relative;
  box-shadow: none !important;
  border-radius: 0px !important;
  margin-top: 56px !important;
  padding-right: 0 !important;

  .noAvailability {
    position: relative;
    height: 100%;
  }
}

.pagerEventsColumn {
  position: relative;
  min-width: 385px;
}

.loadingEvents {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}

// .eventsHeader {
//   // .eventFilterSection {
//   //   button {
//   //     height: 32px;
//   //     margin: 0;

//   //     &:first-child {
//   //       background-color: $grey;
//   //       margin: 0;
//   //       margin-right: 4px;

//   //       &:hover {
//   //         @include theme-aware("color", "txt");
//   //         background-image: url(../../assets/img/icons/Filter.svg);
//   //         cursor: default;
//   //       }

//   //       &.isActive {
//   //         background-color: #fff;
//   //         z-index: 1;
//   //         position: relative;
//   //       }
//   //     }
//   //   }
//   // }

//   @media screen and (min-width: $mediumScreen-max) {
//     // .eventFilterSection {
//     //   background: $lgrey;
//     // }
//     .eventFilterSection,
//     .filterControls {
//       display: flex;
//     }
//     .holder {
//       flex: auto;
//     }
//     .multi .desktopTxt {
//       display: inline-block;
//     }
//   }
// }

// .filterControls {
//   text-align: left;
//   @media screen and (max-width: 1299px) {
//     background-color: #fff;
//     padding: 5px;
//   }

//   label {
//     white-space: nowrap;
//     margin: 7px 10px 5px;
//     font-size: 0.875rem;

//     @media screen and (min-width: $tabletPortrait) {
//       &:first-child {
//         padding-right: 15px;
//         border-right: 1px solid $grey;
//       }
//     }
//   }
//   > button {
//     border: 1px solid #007454;
//   }

//   .filterButtons {
//     display: flex;
//     width: 100%;
//     align-items: center;
//     justify-content: flex-end;

//     // .closeBtn {
//     //   display: none;
//     // }

//     // @media (max-width: 1125px) {
//     //   .closeBtn {
//     //     display: block;
//     //   }
//     // }
//   }
// }

.content_wrapper {
  height: calc(100% - 70px);
  display: flex;

  .pagerEvents {
    height: 100%;
    max-height: calc(100% - 55px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  .dashboardContent {
    height: 100%;
    padding-bottom: 0px;

    .eventsHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 55px;
    }

    .refreshTimer {
      border: 1px solid $bgrey;
      background-color: #fff;
      width: 143px;
      padding: 2px 7px;
      font-size: $f12px;
      border-radius: 5px;

      .heading {
        font-weight: bold;
      }
    }
  }

  .eventDetailsSection {
    position: relative;
  }
}

.noGroupWrapper {
  width: 100%;
  .noGroupsMessage {
    text-align: center;
  }
}

.activeEventHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;

  .buttons {
    display: flex;
    padding-left: 3px;
  }
  .attendance {
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    > div {
      text-align: left !important;
    }
  }
}

.eventDetails {
  width: 100%;
  display: flex;
  // height: calc(100% - 55px);

  .eventDetailsMap {
    width: 100%;
    height: 100%;
  }

  .eventDetailsAttendance {
    height: 100%;
    width: 400px;
    overflow-y: auto;
    max-height: 100%;

    &.eventLockedState {
      margin-top: -55px;
      height: calc(100% + 55px);

      &.locked {
        margin-top: 0;
      }
    }

    .attendance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      > div {
        text-align: left !important;
      }
    }
  }
}

.pagerEventLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.activeEventWrapper {
  height: 100%;
  border: solid 1px transparent;

  &.eventLockedState {
    &.locked {
      &.emergency {
        border: solid 1px $red;
      }

      &.urgent {
        border: solid 1px $yellow;
      }

      &.nonEmergency {
        border: solid 1px #93328e;
      }

      &.admin {
        border: solid 1px #007faf;
      }

      &.update {
        border: solid 1px;
        @include theme-aware("border-color", "txt");
      }

      // .eventDetails {
      //   height: calc(100% - 113px);
      // }
    }
  }
}

.pagerHeader {
  display: flex;
  flex-direction: column;
  min-height: 30px;
  border-bottom: solid 1px $grey;
  padding-bottom: 12px;

  &.emergency {
    .top {
      background: $emg;

      .alertType {
        color: #fff;
      }
    }

    &.ended {
      .top {
        border-bottom: solid 2px $red;
      }
    }
  }

  &.nonEmergency {
    .top {
      background: $nonemg;
    }

    &.ended {
      .top {
        border-bottom: solid 2px #93328e;
      }
    }
  }

  &.admin {
    .top {
      background: $admin;
    }

    &.ended {
      .top {
        border-bottom: solid 2px $blue;
      }
    }
  }

  &.ended {
    .top {
      background: transparent;

      .alertType {
        .emergency {
          color: #dc1b20;
        }

        .urgent {
          color: $yellow;
        }

        .nonEmergency {
          color: #93328e;
        }

        .admin {
          color: #007faf;
        }

        .update {
          @include theme-aware("color", "txt");
        }
      }

      .timeStamp {
        @include theme-aware("color", "txt");
      }
    }

    // .timer {
    //   span {
    //     color: #000 !important;
    //   }
    // }
  }

  .top {
    display: flex;
    width: 100%;
    align-items: center;

    .controls {
      margin-left: auto;
      margin-left: auto;
      margin-bottom: -30px;
      padding-right: 16px;
      z-index: 2;
    }
  }

  .bottom {
    display: flex;
    width: 100%;
  }

  .agencyLogo {
    margin: 10px 0 -15px 5px;
    height: 35px;
    width: 35px;
    padding: 3px;
    text-align: center;
    background: #fff;
    border-radius: 5px;

    img {
      height: 100%;
      width: auto;
    }
  }

  .alertType {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    margin-left: 10px;
    margin-right: 16px;
  }

  .timer {
    margin-right: 16px;
  }
}

.pagerGroup {
  font-weight: 600;
  margin: 5px 0;
  color: #000;
  margin-left: 50px;
}

.timeStamp {
  display: flex;
  justify-content: flex-end;
  font-size: $f14px;
  font-weight: 600;
  color: #fff;
  margin-right: 16px;

  .time {
    padding-right: 10px;
  }

  span {
    white-space: nowrap;
  }
}

.controlsAndDetails {
  display: flex;
  align-items: center;

  .buttons {
    display: flex;
    min-width: 280px;
  }

  .pagerDetails {
    padding-right: 16px;
    word-break: break-all;
  }
}

.filterControlsWrapper {
  z-index: 9;
  position: absolute;
  top: 52px;
  left: 0;
  right: 0;
  width: 100%;

  .filterControls {
    height: 100%;
    margin: auto;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    padding-top: 0;
    text-align: left;
    background-color: #fff;
    padding: 16px;
    margin-right: 15px;

    p {
      border-bottom: 1px solid $grey;
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 30px;
      margin: 0;
    }

    .holder {
      border-bottom: 1px solid $grey;
      display: flex;
      flex-direction: column;
      width: 100%;

      &:first-child {
        border-bottom: 1px solid $grey;
      }

      label {
        margin-top: 12px;
        min-height: 33px;
        white-space: nowrap;
        margin: 7px 10px 5px;
        font-size: 0.875rem;
      }
    }

    .filterButtons {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      button {
        margin: 0;
        margin-top: 15px;
      }
    }

    .dropDownItem {
      border-bottom: 1px solid $grey;
      min-height: 33px;
      display: flex;
      align-items: center;
      width: 100%;
      border-right: none;

      &:last-child {
        border-bottom: none;
      }

      .dropdownLabel {
        flex: auto;
        margin-top: 8px;
        margin-bottom: 8px;
        line-height: 32px;
        min-width: 90px;
        font-size: 14px;
        font-weight: 600;
        margin-left: 16px;
        white-space: nowrap;
      }

      .dropdown {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        min-height: 32px;
        border-radius: 0;
        background-color: transparent;
        border: 0 none;
        font-size: 0.875rem;
        display: block;
        margin-top: 9px;
        margin-bottom: 7px;
        cursor: text;
        width: 100%;
      }

      .multi {
        display: flex;
        width: calc(100% - 1px);
        margin: 4px 0;
        padding: 4px;
        font-size: $f14px;
        //line-height: 26px;
        background-repeat: no-repeat;
        background-position: 95% 50%;

        white-space: nowrap;
        @media screen and (max-width: $tabletPortrait) {
          line-height: 32px;
          font-weight: normal;
        }
        .mobileTxt,
        .desktopTxt {
          display: none;
        }

        > span {
          flex: auto;
        }

        i {
          font-size: 1.25rem;
          @include theme-aware("color", "txt");
        }
      }
    }

    .buttonWrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
    }

    // label {
    //   white-space: nowrap;
    //   margin: 7px 10px 5px;
    //   font-size: 0.875rem;

    //   @media screen and (min-width: $tabletPortrait) {
    //     &:first-child {
    //       padding-right: 15px;
    //       border-right: 1px solid $grey;
    //     }
    //   }
    // }
    // > button {
    //   border: 1px solid #007454;
    // }

    // .filterButtons {
    //   display: flex;
    //   width: 100%;
    //   align-items: center;
    //   justify-content: flex-end;

    // }
  }

  // .dropdownLabel {
  //   flex: auto;
  //   margin-top: 8px;
  //   margin-bottom: 8px;
  //   line-height: 30px;
  //   font-size: 14px;
  //   font-weight: 600;
  //   margin-left: 16px;
  //   white-space: nowrap;

  //   @media screen and (min-width: $mediumScreen-max) {
  //     display: none;
  //     line-height: 26px;
  //   }
  //   @media screen and (max-width: $smallScreen) {
  //     line-height: 32px;
  //     min-width: 90px;
  //   }
  // }

  .holder.disabled {
    pointer-events: none;
    color: #ccc;
  }

  // p + .holder {
  //   border-bottom: 1px solid;
  // }
  // .holder {
  //   //margin: 5px auto;
  //   //padding: 5px;

  //   label:first-child {
  //     width: 210px;
  //     max-width: 210px;
  //     margin-right: 15px;
  //   }
  // }
  // }
  // @media screen and (min-width: $tabletPortrait) and (max-width: 1299px) {
  // .filterControls {
  //   .holder {
  //     display: flex;
  //     text-align: left;

  //     label {
  //       flex: auto;
  //     }
  //   }

  //   .dropdown-holder {
  //     width: 100%;
  //   }
  // }
  // }
  // @media screen and (max-width: $tabletLandscape) {
  // .filterControls {
  //   padding: 18px;
  //   padding-top: 0;
  //   // width: calc(100% - 40px);
  //   p {
  //     border-bottom: 1px solid $grey;
  //     font-size: $f14px;
  //     font-weight: 600;
  //     letter-spacing: 0.5px;
  //     line-height: 30px;
  //     margin: 0;
  //   }
  //   > button {
  //     height: 40px;
  //   }
  // }
  // .multi .mobileTxt {
  //   display: block;
  // }
  // }
  // @media screen and (min-width: $mediumScreen-max) {
  //   .filterControls {
  //     p {
  //       display: none;
  //     }
  //     label {
  //       flex: auto;
  //     }
  //     > button {
  //       max-width: 150px;
  //       margin: 5px;
  //     }
  //   }
  // }
  // @media screen and (min-width: $mediumScreen-max) and (max-width: 1299px) {
  //   .filterControls {
  //     width: calc(100% - 40px);
  //   }
  // }
  // @media screen and (max-width: 1299px) {
  //   .filterControls {
  //     margin: auto;
  //     border-radius: 5px;
  //     box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  //   }
  // }
  // @media screen and (min-width: 1300px) {
  //   padding: 0 15px;
  //   margin: 0 1rem 1rem;
  //   max-width: $extraLargeScreen;
  //   border-radius: 8px;
  //   box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);

  //   .filterControls button {
  //     background-color: #fff;
  //     font-size: $f14px;
  //     text-transform: uppercase;
  //     color: rgba(0, 0, 0, 0.87);
  //     margin: 0px;
  //     color: $mGreen;
  //     padding: 2px 10px;

  //     &:hover {
  //       background-color: $mGreen;
  //       color: #fff;
  //     }
  //   }
  // }

  // @media screen and (min-width: 1300px) {
  //   margin: 0 auto 1rem;

  //   .filterControls label:first-child {
  //     margin-right: 0;
  //   }
  // }
}

.radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  line-height: 20px;
  flex: auto;
  height: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 5px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 50%;
    transition: ease all 0.1s;
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
  }
}

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  color: #5a5f65;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 5px;
    height: 18px;
    width: 18px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 5px;
    transition: ease all 0.1s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
    box-shadow: 0 0 1px 2px $mGreen, 0 0 1px 3px $mGreen;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}
