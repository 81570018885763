@import "../../assets/sass/_var.scss";
@import "../../assets/sass/_mixin.scss";

.content{
  font-size: 0.875rem;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
    font-weight: 600;
    font-size: $f16px;
    margin: 10px 0px 0px;
  }
  span {
    line-height: 1.125rem;
    text-align: left;
    font-weight: normal;
  }
  a {
    word-break: break-word;
  }
}
