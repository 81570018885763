@import "./_var";
@import "./_layout";
@import "./_semantic-UI";
@import "./_mixin";

@import "../../components/AvailabilityDashboard/AvailabilityDashboard.module.scss";
// * Original Plugin CSS Styles
@import "../../components/Scheduler/dhtmlxScheduler.module.scss";
@import "../../components/Scheduler/Scheduler.module.scss";
@import "../../components/Scheduler/SchedulerModal.module.scss";

*,
html {
  // placeholder
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

html {
  font-size: 16px;
}

@media screen and (-webkit-min-device-pixel-ratio: 2),
  screen and (min-resolution: 2dppx) {
  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
}

body {
  @include theme-aware("background-color", "bg");
  @include theme-aware("color", "txt");

  font-size: 16px;
  line-height: 16px;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-transition: none !important;
  transition: none !important;

  .styled__Container-idui-popover__sc-1mloye0-0 {
    @include theme-aware("background-color", "bg5");

    &::before {
      @include theme-aware("border-color", "bg5");
    }
  }

  &.body_locked {
    overflow: hidden;
    position: fixed;
  }
}
/* turnout printing settings */
@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    margin: 0;
      padding: 0;
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}
@page :first {
  margin-top: 0mm; /* Set the top margin to zero for the first page */
}
.fullWidth {
  width: 100%;
}

#SASAPPLOGO {
  width: 51px;
  height: 45px;
  position: absolute;
  top: 25px;
  left: 0;
  right: 0;
  margin: auto;
}

.LoginView_login__1Ef67 #SASAPPLOGO {
  width: 101.95px;
  height: 90px;
  top: 42px;
}

input,
textarea {
  -webkit-user-select: text;
  user-select: text;
  -webkit-user-callout: default;
  -webkit-touch-callout: default;
}

h2 {
  font-size: $f24px;
  font-weight: 600;
  margin-top: 0;

  // @media screen and
  line-height: $f27px;

  //margin-bottom: 14px;
}

h3 {
  font-size: $f20px;
  font-weight: 600;
  line-height: $f27px;
  margin-top: 0;
}

h4 {
  font-size: $f14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: $f24px;
  text-align: left;
  text-transform: uppercase;
  margin: 4px 0;
}

p,
li {
  font-size: $f14px;
  line-height: $f18px;
  text-align: left;
  font-weight: normal;
}

p {
  margin: 10px 0px;
}

p,
span {
  cursor: inherit;
}

// Text colors
.blueTxt {
  color: $blue;
}

.pinkTxt {
  color: $pink;
}

.purpleTxt {
  color: $purple;
}

.greenTxt {
  color: #007454; // link txt green
}

.redTxt {
  color: $red; // link txt red
}

// Bg color
.lgreenBg {
  background-color: $lgreen;
}

.greenBg {
  background-color: $green;
}

.yellowBg {
  background-color: $yellow;
}

.redBg {
  background-color: $red;
}

.greyBg {
  background-color: $grey;
}

.blueBg {
  background-color: $blue;
}

.pinkBg {
  background-color: $pink;
}

.purpleBg {
  background-color: $purple;
}

// Alert Background Colours
.EMERGENCY {
  @include theme-aware("background", "emg1");

  border-style: solid;

  @include theme-aware("border-width", "invisibleBorder");
  @include theme-aware("border-image", "emg2");

  &::before {
    display: block;
    content: "";

    @include theme-aware("border-top-width", "invisibleBorder");

    border-top-style: solid;

    @include theme-aware("border-top-color", "emg3");

    top: -5px;
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0px;
    right: 0px;
  }

  .colouredTxt {
    @include theme-aware("color", "emg3");
  }

  .colouredBorder {
    @include theme-aware("border-color", "emg4");

    &::after {
      @include theme-aware("background-color", "emg4");
    }
  }
}

.NONEMERGENCY,
.NON-EMERGENCY {
  @include theme-aware("background", "nonemg1");

  border-style: solid;

  @include theme-aware("border-width", "invisibleBorder");
  @include theme-aware("border-image", "nonemg2");

  &::before {
    display: block;
    content: "";

    @include theme-aware("border-top-width", "invisibleBorder");

    border-top-style: solid;

    @include theme-aware("border-top-color", "nonemg3");

    top: -5px;
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0px;
    right: 0px;
  }

  .colouredTxt {
    @include theme-aware("color", "nonemg3");
  }

  .colouredBorder {
    @include theme-aware("border-color", "nonemg4");

    &::after {
      @include theme-aware("background-color", "nonemg4");
    }
  }
}

.successMessage {
  color: $green;
}

.failMessage {
  color: $red;
}

.ADMIN {
  @include theme-aware("background", "admin1");

  border-style: solid;

  @include theme-aware("border-width", "invisibleBorder");
  @include theme-aware("border-image", "admin2");

  &::before {
    display: block;
    content: "";

    @include theme-aware("border-top-width", "invisibleBorder");

    border-top-style: solid;

    @include theme-aware("border-top-color", "admin3");

    top: -5px;
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0px;
    right: 0px;
  }

  .colouredTxt {
    @include theme-aware("color", "admin3");
  }

  .colouredBorder {
    @include theme-aware("border-color", "admin4");

    &::after {
      @include theme-aware("background-color", "admin4");
    }
  }
}

.ENDED {
  @include theme-aware("background", "ended1");

  border-style: solid;

  @include theme-aware("border-width", "invisibleBorder");
  @include theme-aware("border-image", "ended2");

  &::before {
    display: block;
    content: "";

    @include theme-aware("border-top-width", "invisibleBorder");

    border-top-style: solid;

    @include theme-aware("border-top-color", "ended3");

    top: -5px;
    position: absolute;
    width: 100%;
    height: 5px;
    left: 0px;
    right: 0px;
  }

  .colouredTxt {
    @include theme-aware("color", "ended3");
  }

  .colouredBorder {
    @include theme-aware("border-color", "ended4");

    &::after {
      @include theme-aware("background-color", "ended4");
    }
  }
}

.relative {
  position: relative;

  > button {
    position: absolute;
    right: 15px;
  }
}

.HomePageView,
.PagerHistoryView {
  .ui.search.dropdown .menu,
  .ui.selection.dropdown .menu {
    min-height: 170px;
  }
}

.HomePageView {
  @include theme-aware("background-color", "bg3");

  @media (max-width: $tabletPortrait) {
    height: calc(100vh - 147px);
  }

  @media (min-width: $tabletPortrait) and (max-width: $smallScreen-max) {
    height: calc(100vh - 184px);
  }

  @media (min-width: $tabletLandscape) and (max-width: $mediumScreen-max) {
    height: calc(100vh - 154px);
  }

  @media (min-width: $mediumScreen-max) {
    height: calc(100vh - 135px);
    margin: 0 auto;
  }
}

.PagerHistoryView {
  @include theme-aware("background-color", "bg3");

  @media (max-width: $tabletPortrait) {
    height: calc(100vh - 95px);
  }

  @media (min-width: $tabletPortrait) and (max-width: $smallScreen-max) {
    height: calc(100vh - 113px);
  }

  @media (min-width: $tabletLandscape) {
    height: calc(100vh - 83px);
    margin: 0 auto;
  }

  // @media (min-width: $mediumScreen-max) {
  //   height: calc(100vh - 135px);
  //   margin: 0 auto;
  // }

  // @media (min-width: $smallScreen-max) {
  //   height: calc(100vh - 115px);
  //   margin: 0 auto;
  // }
}

.infinite-scroll-component__outerdiv {
  max-height: 100% !important;
  height: 100% !important;
  margin-left: 3px;
}

.infinite-scroll-component {
  height: 100% !important;
  overflow: inherit !important;
}

.HomePageView .infinite-scroll-component__outerdiv {
  max-height: 100% !important;
  height: 100% !important;
  width: calc(100% + 9px) !important;
  margin-top: 3px;

  .infinite-scroll-component {
    height: auto !important;
    overflow: inherit !important;
  }
}

.PagerHistoryView .infinite-scroll-component__outerdiv {
  max-height: 100% !important;
  height: 100% !important;
  width: calc(
    100% + 5px
  ) !important; // to lower the pixels to stop the page having a scrollbar sidebars
  .infinite-scroll-component {
    height: auto !important;
    overflow: inherit !important;
  }
}

.inLineloadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingContainer {
  position: absolute;

  //top: 80px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  //width: 100vw;
  //height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.box_default {
  &.special {
    @media screen and (min-width: $mediumScreen-max) {
      margin-right: 44px;
    }

    box-shadow: none;
  }
}

.col-one-third {
  &:nth-child(1) {
    .minheight {
      min-height: 390px;

      .title {
        margin-top: 0;
        padding-top: 18px;
        text-align: center;
      }
    }
  }

  &:nth-child(2),
  &:nth-child(3) {
    .maxheight {
      // QA NOT NEEDED
      // height: 65vh;
      // overflow-y: auto;
      // overflow-x: visible;
      ul {
        padding-top: 0;
      }
    }
  }

  &:nth-child(3) {
    .Accordion_Accordion_header__2I8Y8 {
      margin-top: 20px;

      //height: 30px;
    }

    // .accordion_header {
    //   font-size: $f14px;
    //   font-weight: 600;
    //   line-height: $f18px;
    //   text-transform: uppercase;
    // }
    .Accordion_Accordion_content__1bCHy {
      border: 0 none;
    }
  }
}

.Threshold {
  height: calc(100% - 110px) !important;
}

@media (min-width: $mediumScreen-max) and (max-width: 1190px) {
  .Threshold {
    height: calc(100% - 152px) !important;
  }
}

@media (max-width: $tabletPortrait) {
  &.availability {
    .tabs {
      height: 100%;
    }

    .tab-content {
      height: calc(100% - 52px);
      overflow-y: scroll;
    }
  }

  &.settings {
    .tabs {
      height: 100%;
    }

    .tab-content {
      height: calc(100% - 52px);
      overflow-y: scroll;
    }
  }
}

@media (max-width: 319px) {
  &.settings {
    .tab-content {
      height: calc(100% - 88px);
    }
  }
}

.Sub-Section-titles {
  + .maxheight {
    padding: 0 16px;
    margin: 0 -15px;
  }
}

.btn-reset {
  border: 0 none;

  span {
    @include theme-aware("background-color", "cardBg");
    @include theme-aware("color", "txt4");
  }
}

.API_error_text {
  color: #dc1b20;
}

.fadeaway {
  color: #007454;
  animation-fill-mode: forwards;
  animation-name: fade;
  animation-duration: 8s;

  &.error {
    color: $red;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes fade {
  0% {
    opacity: 1;
    max-height: 100px;
  }

  85% {
    opacity: 1;
  }

  90% {
    max-height: 100px;
  }

  100% {
    opacity: 0;
    max-height: 0px;
  }
}

.flex {
  display: flex;
  align-items: center;

  > span,
  > button,
  div {
    flex: auto;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.Sub-Section-titles + .toggleHolder {
  margin-top: -16px;
}

p.label,
.toggleHolder {
  font-weight: 600;
}

.settingOptions .availabileForContainer {
  max-width: 100%;
}

form {
  ul.padding {
    padding: 0;
    margin: 0;

    li {
      padding: 4px 0;

      &:first-child {
        padding: 0;
      }
    }
  }

  ul.addTimeSlot {
    border-top: 1px solid $grey;
    margin-bottom: 16px;
    font-size: $f16px;
    font-weight: 600;
    line-height: 30px;

    input:not([type="checkbox"]) {
      width: auto;
    }

    li {
      border-bottom: 1px solid $grey;
      padding: 5px 0;

      p.description {
        font-size: $f14px;
        line-height: $f18px;
      }

      &.noline {
        border-bottom: 0 none;
        padding: 8px;

        label {
          font-size: $f16px;
          font-weight: 400;
        }
      }

      &:last-child {
        line-height: 52px;
        border-top: 1px solid $grey;
      }
    }

    .primaryText {
      font-size: $f16px;
      min-width: 80px;
      font-weight: 600;
      white-space: nowrap;
      padding-right: 10px;
    }

    button {
      max-width: 80px;
    }

    .ui.fluid.search.selection.dropdown,
    .ui.selection.dropdown {
      max-height: 30px;
      min-height: 30px;
      padding: 7px;
      border: 0 none;

      .text {
        margin-left: 5px;
      }
    }
  }

  button.btn_addtime {
    margin-left: 0;
    float: none;
  }
}

.box_default ul.addTimeSlot .ui.selection.dropdown > .dropdown.icon {
  padding: 0;
}

.toggleHolder {
  height: 52px;
  line-height: 52px;
  top: 16px;

  label {
    margin-top: 17px;
  }

  + button {
    margin-left: 0;
  }
}

.repeating:before {
  display: inline-block;
  padding-right: 8px;
  vertical-align: middle;
  content: url(../../assets/img/icons/repeating.svg);
}

ul.repeatWeek {
  padding: 0;

  li {
    padding: 10px 0;
    font-size: $f16px;
    line-height: $f20px;
  }
}

.tab-content ul.settingOptions {
  margin-top: 0;
}

ul.settingOptions {
  margin-top: -10px;
  margin-bottom: -10px;

  // Use this for Text with action btns next to them
  .flex {
    border-bottom: 1px solid $grey;

    .primaryText {
      width: 70px;
    }
  }

  li {
    list-style-type: none;
    border-bottom: 1px solid $grey;

    &:last-child {
      border-bottom: 0;
    }

    .holder {
      display: flex;

      img {
        max-height: 33px;
      }
    }

    .logoHolder {
      width: 50px;
      padding-right: 8px;
      text-align: center;
    }

    > div {
      padding: 7px 0;

      &:first-child {
        display: flex;
      }

      &.show {
        display: block;
        padding-left: 8px;
      }

      &.hide {
        display: none;
      }

      div,
      button {
        flex: auto;
      }

      button {
        margin-top: 0;
        margin-bottom: 0;
        max-width: 88px;
      }
    }

    .largeText {
      font-size: $f18px;
      font-weight: 600;
      letter-spacing: 0.64px;
      line-height: $f24px;
      flex: 1;
      margin-top: 0;

      + div {
        max-width: 84px;

        button {
          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            float: right;
          }
        }
      }
    }

    .primaryText,
    .subText {
      display: block;
    }

    .primaryText {
      font-size: $f16px;
      font-weight: 600;
    }

    .subText {
      font-size: $f14px;
      line-height: $f16px;
    }

    button {
      flex: auto;
      align-self: center;
    }
  }

  .GroupDetails_center__1Hz6o,
  .GroupDetails_option__3EhdI {
    .primaryText {
      font-size: $f14px;
    }

    .subText {
      font-size: $f12px;
      line-height: $f18px;
    }
  }
}

.unreadMessageIndicator::before {
  content: "";
  position: absolute;
  display: block;
  height: 14px;
  width: 14px;
  background: linear-gradient(90deg, #00aff0 0.12%, #007dc8 99.69%);
  border-radius: 50%;
  top: -4px;
  left: -4px;
}

.unreadMessageIndicatorTemp::before {
  content: "";
  position: absolute;
  display: block;
  height: 14px;
  width: 14px;
  background: linear-gradient(90deg, #00aff0 0.12%, #007dc8 99.69%);
  border-radius: 50%;
  top: -4px;
  left: -4px;
  animation: fadeOut 0.5s 1;
  -webkit-animation: fadeOut 0.5s 1;
  animation-fill-mode: forwards;
  animation-delay: 2.5s;
  -webkit-animation-delay: 2.5s; /* Safari and Chrome */
  -webkit-animation-fill-mode: forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// Button styles
@import "../../components/UI/Button/Button.module.scss";

// Icon styles
@import "../../components/UI/Icon/Icon.module.scss";

// Map styles
@import "../../components/Map/DefaultMap.module.scss";

// calendar
// .Calendar_cell__1OJWn {
//   &.NoRepeat,
//   &.Repeat,
//   &.RepeatNoRepeat {
//     color: $green;
//   }
//   &.NoRepeat:after {
//     content: url(../../assets/img/icons/time.svg);
//     display: block;
//   }
//   &.Repeat:after {
//     content: url(../../assets/img/icons/repeating.svg);
//     display: block;
//   }
//   &.RepeatNoRepeat:after {
//     content: url(../../assets/img/icons/BlendedAvailablity.svg);
//     display: block;
//   }
// }
.week {
  padding: 14px;

  .btn_holder {
    display: flex;

    button {
      max-width: 33%;

      :first-child {
        margin-left: 0;
      }

      &.icon_remove {
        padding-right: 20px;
        background-position: 90% 50%;

        &:hover {
          background-position: 90% 50%;
          background-repeat: no-repeat;
          background-image: url(../../assets/img/icons/remove-white.svg);
        }
      }
    }
  }
}

.availabileForContainer {
  width: 100%;
  margin-bottom: 10px;
}

.timeslot {
  flex: auto;
  font-size: $f14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: $f24px;

  &:before {
    content: url(../../assets/img/icons/time.svg);
    display: inline-block;
    padding-right: 8px;
    vertical-align: middle;
  }

  &.repeating:before {
    content: url(../../assets/img/icons/repeating.svg);
  }

  + div {
    max-width: 95px;

    button {
      min-width: 40px;
    }

    button:last-child {
      float: right;
    }
  }
}

// Error  banner
.connection_error {
  z-index: 101 !important;
}

// Loading State
.loading_bar_wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

//SAS Chat message hover state
@media (min-width: $smallScreen-max) {
  .chatMessageHoverState {
    cursor: pointer;

    span {
      cursor: pointer;
    }

    &:hover {
      @include theme-aware("background", "chatBg2");
    }
  }

  .chatMessageActiveState {
    cursor: pointer;
    @include theme-aware("background-color", "chatBg2");
    font-weight: bold;

    span {
      cursor: pointer;
    }
    // &::after {
    //   content: url(../img/icons/right-arrow-solid.svg);
    //   position: absolute;
    //   width: 15px;
    //   height: auto;
    //   right: -15px;
    //   top: calc(50% - 15px);
    // }
  }
}

.timeStamp {
  margin-left: 40px;
  font-size: $f12px;
  line-height: $f24px;

  .time {
    padding-right: 20px;
  }
}
.enableCopyPaste {
  user-select: text;
}
.dashboardTime {
  color: #fff;
}

// boxes
.box {
  &_default {
    border-radius: $radius;
    box-shadow: $shadow;

    @include theme-aware("background-color", "cardBg");

    padding: 0 16px 16px;
    margin-bottom: 12px;
  }
}

.btn_holder {
  display: flex;

  button {
    flex: auto;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.settings,
.profile_settings {
  position: relative;

  .btn_holder {
    padding-left: 3px;

    // padding-top: 16px;
  }
}

.settings.notifications {
  .notifications_back {
    position: absolute;
    top: 8px;
    right: 35px;
  }
}

@media (max-width: $smallScreen-max) {
  .settings.notifications {
    .Sub-Section-titles {
      h3 {
        width: calc(100% - 90px);
      }
    }

    .notifications_back {
      position: absolute;
      top: 20px;
      right: 30px;
    }
  }
}

// placeholder
.sronly {
  font-size: 0;
  height: 0;
}

.close {
  background-image: url(../../assets/img/icons/downarrow.svg);
  background-repeat: no-repeat;
  background-position: 95% 50%;
}

.open {
  background-image: url(../../assets/img/icons/uparrow.svg);
  background-repeat: no-repeat;
  background-position: 95% 50%;
}

.Accordion_content.logo {
  width: 100%;

  .content_with_logo {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    // width: 100%;
    .logo_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

.box_default {
  .Accordion_Accordion_header__2I8Y8,
  .Accordion_Accordion_header__slSAa {
    margin: 0;
  }
}

.toggle_group {
  .Accordion_Accordion_header__2I8Y8 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $f16px;
    font-weight: 600;
    line-height: $f20px;
    height: auto;
    min-height: 44px;
    padding: 8px 0;
    color: rgba(92, 94, 101, 0.85);

    &.open {
      color: rgba(92, 94, 101, 1);
      border-bottom: 0 none;

      .txt,
      .subtxt {
        @include theme-aware("color", "txt");
      }
    }
  }

  &.agencies {
    .Accordion_Accordion_header__2I8Y8.isLogo,
    .Accordion_Accordion_header__2I8Y8.isDynamic {
      flex-direction: column;

      .accordion_header {
        width: 100%;

        @include theme-aware("color", "txt2");
      }

      .accordion_content {
        width: 100%;
        height: 0;
      }

      &.open {
        background-position: 95% 35%;

        .accordion_content {
          height: auto;
        }
      }
    }

    .Accordion_Accordion_header__2I8Y8.isDynamic.open {
      background-position: 95% 15%;
    }

    .Accordion_Accordion_header__2I8Y8.close {
      color: rgba(92, 94, 101, 1);
    }

    .Accordion_txt__3gzbg,
    .Accordion_subtxt__2BzD5 {
      display: none;
    }

    .open {
      .Accordion_txt__3gzbg,
      .Accordion_subtxt__2BzD5 {
        display: block;
      }
    }

    .link_account {
      padding: 0 0 8px;
      width: 100%;
      border-bottom: solid 1px $grey;

      .login {
        height: 32px;
        max-width: 100%;
        margin: 5px 0;
      }
    }
  }

  .setPosition {
    position: relative;
    padding-bottom: 12px;
  }

  .Accordion_Accordion_content__1bCHy {
    position: relative;
    max-height: 280px;
    min-height: 100px;

    &:after {
      content: "";
      bottom: 0;
      position: absolute;
      height: 5px;
      width: calc(100% + 30px);
      left: -15px;

      // background: -moz-linear-gradient(
      //   top,
      //   rgba(233, 233, 233, 0.1) 0%,
      //   rgba(90, 95, 101, 0.1) 100%
      // );
      // background: -webkit-linear-gradient(
      //   top,
      //   rgba(233, 233, 233, 0.1) 0%,
      //   rgba(90, 95, 101, 0.1) 100%
      // );
      // background: linear-gradient(
      //   to bottom,
      //   rgba(233, 233, 233, 0.1) 0%,
      //   rgba(90, 95, 101, 0.1) 100%
      // );
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ae9e9e9', endColorstr='#1a5a5f65',GradientType=0 );
    }
  }

  // .close,
  // .open {
  //   background-image: none;
  //   margin: 0;
  // }
  ul {
    //margin: 10px 0;
    margin: 0;
    padding-left: 4px;
    list-style: none;

    li {
      padding: 4px 0;
    }
  }
}

.radio_holder {
  ul {
    margin: 0 0 10px 0;
    padding-left: 3px;
    list-style: none;

    li {
      padding: 12px 0;
    }
  }
}

#groupSettings {
  .box_default {
    padding-bottom: 0;
  }

  .header_edit {
    text-align: left;
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 2.75rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .Accordion_Accordion_header__2I8Y8 {
    border-bottom: 0 none;
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: flex;
      margin: 0;
      padding: 0;

      @include theme-aware("color", "txt");

      font-size: $f16px;
      font-weight: 600;
      height: 52px;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $grey;

      &:first-child {
        border-top: none;
      }

      @media screen and (max-width: $tabletPortrait) {
        height: 60px;
      }

      span {
        width: calc(100% - 70px);
      }

      button {
        width: 84px;
        margin-top: 10px;

        @media screen and (min-width: $tabletLandscape) {
          min-height: 30px !important; //fix later
          width: 70px;
        }
      }
    }
  }
}

// Icon styles
@import "../../components/UI/Tabs/Tab.module.scss";

.col-one-third.settings,
.col-one-third.profile_settings {
  @media (max-width: $smallScreen-max) {
    .Section-titles {
      display: none;
    }
  }
  @media (min-width: $tabletLandscape-min) and (max-width: $tabletLandscape-max) {
    .Section-titles {
      display: none;
    }
  }

  @media (min-width: $smallScreen-max) {
    .availabilityContainer {
      display: none;
    }
  }
}

.toggle_heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $f16px;
  font-weight: 600;
  height: 44px;
}

.Section-titles {
  min-height: 52px;
  padding-left: 16px;
  text-align: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.btn_holder {
    padding-left: 0;
  }

  h2 {
    margin: 0;
    display: inline-block;
    border-bottom: 0 none;
    padding-bottom: 0px;
  }

  div {
    button {
      margin: 7px 1px;

      &:last-child {
        margin-left: 1px;
        margin-right: 0;
      }
    }
  }
}

.Sub-Section-titles {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;

  @include theme-aware("color", "txt2");

  letter-spacing: 0;
  text-align: left;
  border-bottom: 1px solid $grey;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.navigation {
    margin-bottom: 8px;
  }

  h3 {
    font-size: $f20px;
    line-height: $f46px;
    display: inline-block;
    margin-bottom: 0px;
  }
}

.Labels {
  font-size: $f16px;
  line-height: $f20px;
  text-align: left;
  padding: 10px 0px 15px 0px;
  text-decoration: none;
}

// placeholder animation to fake new message
// .EventOverview_EventOverview_sections__27iys {
//   .EventChat_chatMessage__1PEvz:first-child {
//     height: 0;
//     padding-top: 0;
//     padding-bottom: 0;
//     margin: 0;
//     overflow: hidden;
//     animation-name: grow;
//     animation-delay: 5s;
//     animation-duration: 0.2s;
//     animation-timing-function: linear;
//     animation-fill-mode: forwards;
//     animation-iteration-count: 1;
//   }
//   @keyframes grow {
//     from {
//     }
//     to {
//       opacity: 1;
//       height: 90px;
//       padding: 10px;
//       margin-bottom: 8px;
//     }
//   }
// }
//placeholder
.EventHeader_eventHeaderMobile__2Mgm0.ADMIN {
  .EventHeader_detailTiles__281uN .EventHeader_pagerGroup__4gvR9 {
    text-align: center;
    display: inline;
    max-width: 100%;
  }
}

.ADMIN .EventHeader_alertWrapper__2DgQW {
  margin: 0 auto;
}

// .ADMIN + .EventHeader_responseControls__6ZrCF {
//   display: none;
// }

.availabilityContainerWrap {
  padding: 10px 0px;
}

.availabilityOverviewTabsHolder {
  .tab-content {
    position: absolute !important;
    bottom: -100%;
    width: 100%;

    // max-height: 100%;
    // overflow-y: scroll;
  }
}

.eTAArrived {
  color: $green;
  font-weight: bold;
  text-transform: uppercase;
}

.availabilityTypes {
  .disabledBox {
    background-color: #007454 !important;
    box-shadow: 0 0 1px 2px #007454, 0 0 1px 3px #007454 !important;
  }
}

.pagingGroupsListItem {
  .disabledBox {
    background-color: #007454 !important;
    box-shadow: 0 0 1px 2px #007454, 0 0 1px 3px #007454 !important;
  }
}

.availabilityContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 52px;
  position: relative;
  z-index: 11;
  font-weight: 600;

  @media screen and (max-width: $tabletLandscape) {
    border-bottom: solid 1px;

    @include theme-aware("border-color", "grey1");

    @include theme-aware("background-color", "bg3");
  }

  @media screen and (min-width: $mediumScreen-max) {
    @include theme-aware("background-color", "bg2");
  }

  span {
    font-weight: normal;
    padding-left: 20px;

    @media screen and (max-width: 1300px) {
      padding-left: 5px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 330px) {
      padding: 0px 5px !important;
    }

    button {
      @media screen and (max-width: 330px) {
        margin-left: 0px;
      }
    }

    .availabilityStatus {
      display: inline-block;
      color: #000;
      border-radius: 5px;
      padding: 6px 20px;
      text-align: center;

      // line-height: 1.125rem;
      margin-right: 10px;
      text-transform: uppercase;

      span {
        text-transform: capitalize;
      }

      @media screen and (max-width: $tabletLandscape) {
        padding: 7px 20px;

        // line-height: 20px;
      }

      @media screen and (max-width: $extraSmallScreen) {
        padding: 7px 0px;
      }

      &.available {
        @include theme-aware("background-color", "lgreen");

        border: 1px solid $lgreen;

        @include theme-aware("color", "lgreen-invert");
      }
    }

    @media screen and (max-width: $extraSmallScreen) {
      display: flex;
      width: 100%;
      padding: 0 20px;

      .availabilityStatus {
        flex: auto;
      }
    }
  }
}

.skills {
  .Sub-Section-titles {
    margin-bottom: 10px;
  }

  > p.description {
    padding-bottom: 10px;
    border-bottom: 1px solid #e9e9e9;
  }

  ul.settingOptions li {
    border: 0 none;

    > div.show {
      padding: 0;

      ul {
        max-height: 290px;
        overflow-y: auto;
        padding: 4px 0;
        border-top: 2px solid rgba(90, 95, 101, 0.1);

        li {
          padding: 4px 0;
        }
      }

      .holder {
        border-top: 4px solid rgba(90, 95, 101, 0.1);
        border-bottom: 4px solid rgba(90, 95, 101, 0.1);
        margin: 0 -16px;
        padding: 0 16px;

        p {
          flex: 1;
          font-size: $f16px;
          line-height: $f28px;

          span {
            font-weight: 600;
          }
        }

        div {
          max-width: 88px;

          button {
            margin: 10px 0;

            &:last-child {
              float: right;
            }
          }
        }
      }
    }

    &:last-child > div.show .holder {
      border-bottom: 0 none;
    }
  }
}

.col-one-third .availabilityContainer {
  border-top: 1px solid $grey;

  .content {
    padding: 0;

    button {
      margin-right: 0;
    }
  }
}

// .EventHeader_eventHeaderDesktop__1PTlJ + .EventOverview_EventOverview_sections__27iys{
//   margin-top:40px;
// }
.map.col-one-third {
  margin-top: 52px;
}

.chatMessageName {
  @include theme-aware("color", "txt2");
}

.chatMessageDefaultState {
  @media screen and (max-width: $tabletLandscape) {
    @include theme-aware("background-color", "cardBg");
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  }
}

.chatMessageHoverState {
  &.SASChat_chatMessage__291Lf {
    padding-left: 16px;
    padding-right: 16px;
  }

  .SASChat_messageHolder__1OrKP {
    max-width: calc(100% - 71px);
  }

  .SASChat_message__uyVk4 {
    //width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

// login placeholder
.LoginView_login__1Ef67 {
  .box_default {
    padding-left: 0;
    padding-right: 0;
  }

  #VicLogo {
    margin: 0 auto;
    display: block;
  }
}
/* ---------------------------------
//   OVERWRITE LEAFLET STYLING   //
--------------------------------- */
.leaflet-container {
  font-family: "Source Sans Pro", sans-serif;
  border-radius: 5px;
}

.leaflet-control-layers,
.leaflet-touch .leaflet-control-layers,
.leaflet-safari .leaflet-control-layers {
  padding: 0px;
  background-color: transparent;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);

  &-separator {
    margin: 0px;
    height: 40px;
    position: relative;
    border-top: 2px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;

    &:before {
      //content: "Points of interest";
      content: "POINTS OF INTEREST";

      @include theme-aware("color", "txt");

      display: block;
      position: absolute;
      font-size: $f14px;
      line-height: $f40px;
      font-weight: 600;
      padding-left: 8px;

      //border-bottom: 1px solid #ebebeb;
      width: 100%;
    }
  }

  &-toggle {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
    background-position: right 11px center;
    text-decoration: none;
    padding-left: 2px;
    position: relative;
    width: 176px;
    display: block;
    max-height: 34px;
    background-image: url("../../assets/img/map/map-toggle.svg");
    background-size: $f19px;
    height: $f32px;
    background-position: 95% 50%;
    background-color: #ffffff;
    border-radius: 5px;

    &::before {
      content: "MAP LAYERS";

      @include theme-aware("color", "txt");

      width: 125px;
      margin: auto auto;
      position: absolute;
      margin-left: 9px;
      line-height: $f32px;
      text-align: left;
      font-size: $f14px;
      font-weight: 600;
      border-right: 0;
    }
  }

  &-list {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0px;
    margin-top: 1px;
    max-height: 395px;
    overflow: hidden;
    overflow-y: scroll;
  }

  &-base,
  &-overlays {
    @include theme-aware("color", "txt");

    label {
      padding: 8px;
      border-bottom: 1px solid #ebebeb;

      &:last-child {
        border-bottom: 0 none;
      }

      input {
        //float: right;
        width: 15px;
        height: 15px;
      }

      span {
        font-size: $f14px;
        line-height: $f18px;
        font-family: "Source Sans Pro";
      }
    }
  }

  // &-overlays {
  //   overflow-y: scroll;
  //   max-height: 230px;
  // }
}
@media print {
  .leaflet-control-layers {
    display: none;
  }

}

.leaflet-bar,
.leaflet-touch .leaflet-bar,
.leaflet-safari .leaflet-bar {
  padding: 0px;
  border-radius: 5px;
  border-width: 0;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);

  a {
    height: 34px;
    width: 34px;
    line-height: 34px;

    @include theme-aware("color", "txt");

    &:hover {
      height: 34px;
      width: 34px;
      line-height: 34px;
      background-color: $grey;
    }
  }
}

.leaflet-bar,
.leaflet-control-layers,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar,
.leaflet-safari .leaflet-control-layers,
.leaflet-safari .leaflet-bar {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
}

// Fix for Map on Safari
.leaflet-control-layers-expanded {
  padding: 0px;
}

.leaflet-touch-zoom::after {
  padding: 3rem;
  text-align: center;
}

.leaflet-safari .leaflet-bar a {
  line-height: 34px;
}

// POI stylying
.default-map {
  position: relative;

  .poi-search-box {
    position: absolute;
    z-index: 1001;
    margin: 10px;
    width: calc(100% - 20px);

    @include theme-aware("background-color", "tileBg");

    border-radius: 5px;

    button {
      float: right;
      margin-right: 10px;
    }

    label {
      font-weight: $f14px;
      padding: 16px;
      display: inline-block;
    }
  }

  .ui.selection.dropdown {
    border-radius: 0;

    .menu {
      max-height: 15.028571rem;
    }

    > .dropdown.icon {
      top: 50%;
    }

    > input.search {
      line-height: 29px;
    }
  }

  .ui.dropdown:not(.button) > .default.text {
    margin-left: 0;
    color: rgba(0, 0, 0, 0.5);
  }
  @media print {
    margin-left: 1.5cm;
    margin-right: 1.5cm;
  }
}

.fullscreen-map-mode {
  .leaflet-control-layers-list,
  .leaflet-touch .leaflet-control-layers-list,
  .leaflet-safari .leaflet-control-layers-list {
    max-height: 100vh;
    overflow: hidden;
  }

  .poi-search-box {
    position: fixed;
    top: 0;
    left: 0px;
    right: 0px;
    width: auto;
  }

  .popup_content {
    bottom: 60px;
    max-width: 330px;
    @include theme-aware("background-color", "grey2");
    @media screen and (min-width: $smallTVScreen) {
      //for Turnout tab TV screens
      position: relative;
      bottom: -50rem !important;
      left: -38rem;
    }
  }
}

/* ---------------------------------
//          FILTER STYLING         //
--------------------------------- */
.box_default {
  .ui.multiple.search.dropdown,
  .ui.selection.dropdown {
    @include theme-aware("color", "dropdownTxt");

    padding-top: 9px;
    border: 0;

    @media screen and (max-width: $smallScreen) {
      width: 100%;
    }

    // > .dropdown.icon {
    //   padding: 12px 9px;
    // }
  }
}

// Chat agency selection dropdown
.chatUserAndAgencySearch {
  .ui.dropdown {
    padding-top: 13px;

    .default.text {
      margin-left: 0 !important;
    }
  }

  .ui.search.dropdown > .text {
    padding-top: 3px;
  }

  .ui.selection.dropdown {
    padding-top: 13px;
  }
}

.chat_controls {
  @include theme-aware("background-color", "bg");
}

/* ---------------------------------
//  OVERWRITE SEMANTIC UI STYLING  //
--------------------------------- */

// icon hover
.ui.label > .delete.icon:hover {
  border: 0 none;
  color: $red;
}

.ui.multiple.dropdown > .label {
  @include theme-aware("box-shadow", "boxShadow1");
}

.ui.search.dropdown > .text {
  @include theme-aware("color", "txt2");
}

.ui.selection.dropdown {
  @include theme-aware("color", "txt2");
}

.ui.label {
  @include theme-aware("color", "txt");
}

.ui.dropdown.error > .menu > .item:hover {
  background-color: rgba(92, 94, 101, 0.05);
}

.dropdown.icon:hover,
.dropdown.icon:hover:hover {
  background-image: none;
  color: $mGreen;
  border: 0 none;
}

a.ui.label:hover,
a.ui.active.label,
a.ui.active.label:hover,
a.ui.labels .active.label:hover {
  background-color: none;
  border-color: inherit;
  background-image: none;
  color: inherit;
}

// DROPDOWN STYLES
.ui.fluid.dropdown.poi-search-box {
  width: auto;
}

.ui.fluid.dropdown > .dropdown.icon {
  @include theme-aware("color", "txt");

  font-size: 1.25rem;
  padding: 3px 9px;

  //background-image: url(/static/media/downarrow.a4b600f3.svg);
}

.ui.dropdown:not(.button) > .default.text {
  @include theme-aware("color", "txt");

  padding: 0;
  margin-left: 15px;

  @media screen and (max-width: $tabletPortrait) {
    display: none;
  }
}

.HomeView_disabled__3l2Lm .ui.dropdown:not(.button) > .default.text {
  color: #ccc;
}

@media screen and (min-width: $tabletPortrait) and (max-width: $tabletLandscape) {
  .theme-dark .ui.dropdown:not(.button) > .default.text {
    display: none;
  }
}

@media screen and (min-width: $mediumScreen-max) {
  .theme-dark,
  .theme-light {
    .HomePageView,
    .PagerHistoryView {
      .pgroupDropdown {
        min-width: 190px;
      }

      .ui.multiple.search.dropdown,
      .ui.selection.dropdown,
      .ui.multiple.search.dropdown.active,
      .ui.selection.dropdown.active,
      .ui.fluid.multiple.search.selection.dropdown {
        // height: 27.5px !important;
        min-height: 27.5px;
        margin: 0 5px;

        // margin-top: 2.5px;
        border: none;

        .menu {
          width: calc(100% + 6.5px);
          left: -2.5px;

          .item {
            white-space: normal !important;
            word-wrap: break-word !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $largeScreen) {
  .theme-dark,
  .theme-light {
    .HomePageView {
      .pgroupDropdown {
        margin-right: 8px;
      }
    }
  }
}

.ui.search.dropdown .menu,
.ui.selection.dropdown .menu {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.ui.selection.active.dropdown {
  box-shadow: none !important;
}

.disable {
  pointer-events: none;
  opacity: 0.5;
  background-color: #eee;
}

.not-allowed {
  cursor: not-allowed;
}

.ui.multiple.search.dropdown,
.ui.selection.dropdown {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  min-height: 32px;

  // height: 32px;
  border-radius: 0;
  background-color: $grey;
  background-color: transparent;
  border: 0 none;
  font-size: 0.875rem;
  border-radius: 5px;

  @include theme-aware("box-shadow", "dropdownShadow");
  @include theme-aware("background-color", "dropdownBg");

  &.active {
    border-bottom: none !important;
  }

  &:hover {
    border-color: #8c919a;

    @include theme-aware("box-shadow", "dropdownShadow");
  }

  > input.search {
    font-size: 0.875rem;

    @include theme-aware("color", "txt");

    margin-left: 0;
    margin-right: 10px;
    width: 100%;
  }

  .selected.item {
    background-color: inherit;
  }

  .menu {
    border-radius: 5px;
    border: solid 1px #8c919a;
    border-color: #8c919a !important;
    width: calc(100% + 6px);
    left: -2px;

    @include theme-aware("background-color", "dropdownBg");

    > .item {
      @include theme-aware("color", "txt2");

      border: 0px;
    }
  }
}

// .availabilityMultiGroupDropdown {
//   position: relative;

//   .pagingGroupDropdown {
//     position: absolute;
//     top: -20px;
//     left: 0;
//     right: 0;
//     width: 100%;
//   }

//   .ui.multiple.search.dropdown,
//   .ui.selection.dropdown {
//     max-height: 80px;
//     overflow-y: auto;
//     overflow-x: hidden;
//     position: relative;

//     &.active {
//       max-height: 100%;
//       overflow-x: inherit;
//       overflow-y: inherit;
//     }
//   }
// }

.default_input {
  border-radius: 5px;
}

.DashboardView_filterControlsWrapper__1qDdc {
  .agencyDropdown,
  .pgroupDropdown {
    .ui.selection.dropdown,
    .ui.multiple.search.dropdown {
      max-width: 100%;
    }
  }
}

.agencyDropdown,
.pgroupDropdown {
  display: flex;

  .show .ui.multiple.search.dropdown,
  .show .ui.selection.dropdown {
    flex: auto;
  }
}

.wrappedDropdown {
  flex-wrap: wrap;
}

.agencyDropdown .ui.multiple.search.dropdown a,
.agencyDropdown .ui.selection.dropdown a {
  white-space: nowrap;
}

.hide .ui.multiple.search.dropdown,
.hide .ui.selection.dropdown {
  display: none;
}

.show .ui.multiple.search.dropdown,
.show .ui.selection.dropdown {
  display: block;
}

@media screen and (max-width: $smallScreen) {
  .agencyDropdown,
  .pgroupDropdown {
    min-height: 33px;

    .ui.multiple.search.dropdown,
    .ui.selection.dropdown {
      margin-top: 9px;
      margin-bottom: 7px;
    }
  }
}

@media screen and (min-width: $smallScreen) and (max-width: $smallScreen-max) {
  .agencyDropdown {
    width: 220px;
    border-right: 1px solid $grey;
  }

  .pgroupDropdown {
    width: calc(100% - 220px);
  }

  .ui.multiple.search.dropdown,
  .ui.selection.dropdown {
    margin: 9px;
  }
}

@media screen and (max-width: $tabletLandscape) {
  .agencyDropdown,
  .pgroupDropdown {
    border-bottom: 1px solid $grey;
  }
}

@media screen and (min-width: $mediumScreen-max) {
  .agencyDropdown {
    .ui.multiple.search.dropdown,
    .ui.selection.dropdown {
      min-width: 100px;
      max-width: 270px;
    }
  }

  .pgroupDropdown {
    .ui.multiple.search.dropdown,
    .ui.selection.dropdown {
      min-width: 140px;
      max-width: 320px;
    }
  }

  .HomeView_filterSection__34nJp .ui.multiple.search.dropdown {
    padding-left: 15px;
    border-left: 1px solid $grey;
    min-width: 150px;
  }
}

@media screen and (min-width: 1300px) {
  .agencyDropdown {
    .ui.multiple.search.dropdown,
    .ui.selection.dropdown {
      min-width: 100px;
      max-width: 270px;
    }
  }

  .pgroupDropdown {
    .ui.multiple.search.dropdown,
    .ui.selection.dropdown {
      min-width: 140px;
      max-width: 320px;
    }
  }
}

.TurnoutSettings_body__3TGkg,
.Availability_dropdownHolder__3IEJ- {
  .ui.selection.dropdown {
    padding: 0 12px;

    .text,
    .default.text {
      margin: 0;
      margin-top: 10px;
      padding-right: 20px;
    }
  }
}

.Availability_overviewDropdownHolder__1iag4 {
  .ui.selection.dropdown {
    // border: 1px solid #e9e9e9;
    // border-radius: 5px;
    max-width: 540px;
    width: 100%;

    // line-height: 35px;
    padding: 5px;

    > .dropdown.icon {
      padding: 10px 9px;
    }
  }
}

.DashboardView_DashboardHeader__19UhI {
  .ui.dropdown:not(.button) > .default.text {
    padding-top: 10px;
    margin-left: 0;
  }
}

// .Report_pagingDropdown__24sY4 .ui.selection.dropdown .text {
//   margin-left: 15px !important;
// }

.Report_pagingDropdown__24sY4 .ui.selection.dropdown {
  padding: 10px;

  .menu {
    > .item {
      padding: 0px !important;
    }
  }
}

.Report_body__lWYTY {
  .ui.multiple.search.dropdown,
  .ui.selection.dropdown {
    max-width: 700px;
    border-radius: 5px;
    padding: 0;
    font-size: $f16px;
    letter-spacing: 0;
    line-height: $f20px;
    padding-left: 18px;
    min-width: 225px;
    min-height: 48px;

    &.error {
      box-shadow: 0 0 1px 2px #000, 0 0 1px 3px $red !important;
    }

    .search {
      margin: 0;
    }

    .default.text {
      display: inline-block;
      position: absolute;
      top: 0;
      left: 0;
      margin-left: 15px;
    }

    .text {
      line-height: 46px;
      margin: auto;

      // margin-left: 0 !important;
      // margin: 9px 0;
      // left: 15px;
      // top: 1px;
    }

    .dropdown.icon {
      padding: 8px 9px;
    }

    .menu {
      > .item {
        padding: 0px 15px !important;
      }
    }
  }
}

.GroupDetails_adminTabs__GS-jS .Availability_groupThreshold__3-KsK li:hover {
  cursor: default;

  &:after,
  &:before {
    display: none;
  }
}

/* ---------------------------------
//       COLOURS FOR THEMES       //
--------------------------------- */
.hyperlink-theme {
  @include theme-aware("color", "hyperlink");
}

// Option 1
.primaryNavBg {
  @include theme-aware("background", "primaryNavBg");
}

.secondaryNavBg {
  @include theme-aware("background", "secondaryNavBg");
}

.logoText {
  @include theme-aware("fill", "txt2");

  font-weight: 600;
}

.logoStroke {
  @include theme-aware("stroke", "txt2");
}

.txt {
  @include theme-aware("color", "txt");
}

.txt2 {
  @include theme-aware("color", "txt2");
}

.txt3 {
  @include theme-aware("color", "txt3");
}

.txt4 {
  @include theme-aware("color", "txt4");
}
.txt6 {
  @include theme-aware("color", "txt6");
}

.primaryText {
  @include theme-aware("color", "txt2");
}

.fill {
  @include theme-aware("fill", "txt3");
}

.secondaryBtn {
  @include theme-aware("background", "bg2");
}

.lgrey {
  border-radius: 5px;

  @include theme-aware("background", "grey4");

  border: 1px solid;

  @include theme-aware("border-color", "grey5");
}

.tileBgMob {
  @media screen and (max-width: 1299px) {
    @include theme-aware("background", "tileBg");
  }
}

.tileBg {
  @include theme-aware("background", "tileBg");
  @include theme-aware("color", "txt3");
}

.tileBg2 {
  @include theme-aware("background", "tileBg");
  @include theme-aware("color", "txt5");
}

.cardBg {
  @include theme-aware("background-color", "cardBg");
}

.cardBg2 {
  @include theme-aware("background-color", "cardBg2");
}

.cardBg3 {
  @include theme-aware("background-color", "cardBg3");
}

.bg2 {
  @include theme-aware("background", "bg2");
}

.bg3 {
  @include theme-aware("background", "bg3");
}

.bg4 {
  @include theme-aware("background", "bg4");
}

.bg5 {
  @include theme-aware("background", "bg5");
}
.signalrstatusBg {
  @include theme-aware("background", "signalrstatusBg");
}

.grey6 {
  @include theme-aware("background", "grey6");
}

.grey7 {
  @include theme-aware("background", "grey7");
}

.grey8 {
  @include theme-aware("background", "grey8");
}

.txt4 {
  @include theme-aware("color", "txt4");
}

textarea {
  @include theme-aware("background", "bg");
  @include theme-aware("color", "txt2");
}

.disabledTxt {
  @include theme-aware("color", "disabledTxt");
}

.disabledBox {
  @include theme-aware("background-color", "disabledColor");

  box-shadow: none !important;
}

.leaflet-control-layers-toggle,
.leaflet-touch .leaflet-control-layers-toggle,
.leaflet-safari .leaflet-control-layers-toggle {
  @include theme-aware("background-color", "tileBg");
}

.leaflet-bar a,
.leaflet-touch .leaflet-bar a,
.leaflet-safari .leaflet-bar a {
  @include theme-aware("background-color", "tileBg");
}

.leaflet-control-layers-list,
.leaflet-touch .leaflet-control-layers-list,
.leaflet-safari .leaflet-control-layers-list {
  @include theme-aware("background-color", "tileBg");
}

.mapButton {
  @include theme-aware("background-color", "tileBg");
}

.verifiedIcon {
  @include theme-aware("background-color", "lgreen");

  border: 1px solid $lgreen;

  @include theme-aware("color", "lgreen-invert");
}

.notVerifiedIcon {
  @include theme-aware("background-color", "lred");
  @include theme-aware("color", "unverified");

  border: 1px solid $red;
}

.endedTop {
  .top {
    @include theme-aware("background-color", "tileBg");
  }
}

.highContrast {
  @include theme-aware("background-color", "btnPrimaryBgColour");
  @include theme-aware("color", "txt2");
}

.textarea,
input {
  @include theme-aware("background-color", "cardBg2");
  @include theme-aware("color", "txt2");
}

.accordion_header {
  @include theme-aware("color", "txt2");
  padding: 10px 30px 0 0;
}

.border_calendar {
  @include theme-aware("border", "border");

  &:hover {
    @include theme-aware("border", "borderHover");
  }
}

.turnoutTimerBorder {
  @include theme-aware("border", "turnoutTimerBorder");
}

.refreshbtn {
  @media (min-width: $tabletPortrait) {
    button {
      @include theme-aware("background-color", "refreshButton");
    }
  }

  @media (max-width: $tabletPortrait) {
    button {
      @include theme-aware("background-color", "refreshButton");
      @include theme-aware("border-color", "refreshButtonBorder");
    }
  }
}
.closebtn {
  @media (min-width: $tabletPortrait) {
    button {
      @include theme-aware("background-color", "refreshButton");
    }
  }
  @media (max-width: $tabletPortrait) {
    button {
      @include theme-aware("background-color", "refreshButton");
      @include theme-aware("border-color", "refreshButtonBorder");
    }
  }
}

.refreshTimer {
  border: 1px solid;

  @include theme-aware("border-color", "grey9");
  @include theme-aware("background-color", "grey8");
}

.availabilityOverview {
  .loadingWrap {
    height: 25px;
    margin-bottom: 12px;
    margin-top: -10px;
  }
}

.noMessages {
  text-align: center;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  padding: 0px 10px;
}

.settingsWrapper,
.availabilityWrapper {
  position: relative;
  height: 40vh;
  width: 100%;
  margin-bottom: 30px;
}

.Availability_AvailHeader__3j1d5 {
  .btn_solid.icon_refresh {
    @include theme-aware("border-color", "hovergreen");
    @include theme-aware("background-color", "btnPrimaryBgColour");
    @include theme-aware("color", "hovergreen");

    background-image: url(../../assets/img/icons/refresh-green.svg);

    &:hover {
      background-image: url(../../assets/img/icons/refresh-white.svg);

      @include theme-aware("color", "white2white");
      @include theme-aware("background-color", "hovergreen");
    }
  }
}

.lightHighlight {
  @include theme-aware("background-color", "lightHighlight");
}

.scheduleRoportRow {
  border-top: 1px solid #e9e9e9;

  &:first-child {
    margin-top: -10px;
    border-top: 0px;
  }

  &:last-child {
    margin-bottom: -10px;
  }

  .close,
  .open {
    border-bottom: 0px;
    font-size: $f16px;
    margin: 0px 10px;
    padding-right: 40px;
    font-weight: normal;

    @include theme-aware("color", "txt");

    background-position: 100% 50%;
  }

  .Accordion_Accordion_content__1bCHy {
    border-bottom: 0px;
    margin: 0px 10px;
  }
}

@media (max-width: 367px) {
  .styled__Container-idui-popover__sc-1mloye0-0 {
    transform: translateX(-55%) translateY(-10px) scale(0.9) translateZ(0px) !important;
    ::before {
      left: 0 !important;
    }
  }

  .styled__Inner-idui-popover__sc-1mloye0-1 {
    max-width: none !important;
    max-height: none !important;
  }
}
