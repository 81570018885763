// * CSS Custom Overrides
.dhx_cal_container {
  height: calc(100vh - 238px);

  .theme-dark & {
    background-color: #222222;
  }
}

.dhx_cal_navline {
  align-items: flex-start;
  background-color: #e7e7e7;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
  padding: 3px 10px;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    position: static;
  }

  .theme-dark & {
    background-color: #1c1c1d;
  }

  div {
    top: 0;
  }

  .ui.selection.dropdown {
    background: url(../../assets/img/icons/calendar-blue.svg) no-repeat 10px
      center;
    min-height: 0;
    margin: 7px 0;
    padding: 10px 15px !important;
    padding-left: 40px !important;
  }

  .theme-light & {
    .ui.multiple.search.dropdown,
    .ui.selection.dropdown {
      background-color: #fff !important;
      box-shadow: 0 0 0 0 transparent, 0 0 0 0 transparent !important;
      border: 1px solid #007faf;
      border-radius: 5px;
      padding: 10px 15px;
      text-transform: uppercase;
    }

    .ui.dropdown:not(.button) > .default.text {
      color: #007faf;
    }
  }

  .ui.dropdown {
    @media only screen and (max-width: 1023px) {
      width: 100%;
    }

    > .text {
      color: #007faf;
      font-size: 14px;
      font-weight: 500;
      position: static;
    }

    .menu > .item {
      font-size: 14px;
      font-weight: 500;
    }

    &:not(.button) > .default.text {
      font-size: 14px;
      font-weight: 500;
      margin-left: 0;
      position: static;

      @media only screen and (max-width: 1023px) {
        display: block;
      }
    }
  }

  .button {
    background-color: #ffffff;
    border: 1px solid #007faf;
    border-radius: 5px;
    color: #007faf;
    display: flex;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    text-transform: uppercase;

    @media only screen and (max-width: 1023px) {
      background: #007faf url(../../assets/img/icons/add-white.svg) no-repeat
        center;
      bottom: 15px;
      border-radius: 50%;
      color: #fff;
      display: block;
      height: 50px;
      min-height: 50px;
      min-width: 50px;
      position: absolute;
      right: 15px;
      width: 50px;
      z-index: 1;
    }

    .theme-dark & {
      background-color: #000;
      border: 1px solid #ababab;
      color: #ababab;
    }

    span {
      @media only screen and (max-width: 1023px) {
        display: none;
      }
    }

    i {
      border: 1px solid #007faf;
      border-radius: 50%;
      display: block;
      height: 16px;
      margin-left: 10px;
      position: relative;
      width: 16px;
      top: 1px;

      @media only screen and (max-width: 1023px) {
        display: none;
      }

      .theme-dark & {
        border: 1px solid #ababab;
      }

      &:before,
      &:after {
        background-color: #007faf;
        bottom: 0;
        content: "";
        display: block;
        height: 2px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 8px;

        .theme-dark & {
          background-color: #ababab;
        }
      }

      &:after {
        transform: rotate(90deg);
      }
    }

    &:before {
      @media only screen and (max-width: 1023px) {
        font-size: 14px;
        font-weight: 600;
        color: #007faf;
        content: "Add";
        position: absolute;
        display: flex;
        left: 25%;
        top: 110%;

        .theme-dark & {
          color: #fff;
        }
      }
    }
  }
}

.dhx_cal_header {
  border-bottom: 1px solid #dadfea;
  border-top: 0;
  border-right: 0;
}

.dhx_scale_hour {
  border-bottom: 1px solid transparent;
  color: #8c97b2;
  font-size: 12px;

  .theme-dark & {
    background-color: #222222;
    color: #fff;
  }
}

.dhx_scale_bar {
  border-left: 1px solid #dadfea;
  border-bottom: 1px solid #dadfea;
  color: #8c97b2;
  font-size: 12px;

  .theme-dark & {
    background-color: #222222;
    color: #fff;
  }
}

.dhx_scale_holder {
  background-image: url(../../../src/assets/img/grid-light.png);
  border-right: 1px solid #dadfea;

  .theme-dark & {
    background-image: url(../../../src/assets/img/grid-dark.png);
  }

  &_now {
    background-color: rgba(247, 247, 247, 0.5);
    background-image: url(../../../src/assets/img/grid-light.png);

    .theme-dark & {
      background-image: url(../../../src/assets/img/grid-dark.png);
    }
  }
}

.dhx_cal_data {
  overflow-y: scroll;
  .dhx_cal_select_menu {
    .dhx_body {
      background-color: #878787;
    }
  }
}

.dhx_cal_event {
  .dhx_title {
    background-color: #ebebeb;
    height: auto;
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;

    .theme-dark & {
      background-color: #000000;
      color: #b9b9b9;
    }

    .event__ribbon {
      display: block;
      height: 4px;

      &.-emergency {
        background-color: #dc1d1f;
      }

      &.-non-emergency {
        background-color: #93298d;
      }

      &.-admin {
        background-color: #017faf;
      }
    }

    .event__triangle {
      display: inline-block;

      &:before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 3px 7px 3px;
        margin: 0 2px;
      }

      &.-local {
        &:before {
          border-color: transparent transparent #4f49e1 transparent;
        }
      }

      &.-regionshorthaul {
        &:before {
          border-color: transparent transparent #ff4ff1 transparent;
        }
      }

      &.-statelonghaul {
        &:before {
          border-color: transparent transparent #11d9d1 transparent;
        }
      }

      &.-interstatedeployment {
        &:before {
          border-color: transparent transparent #ed6724 transparent;
        }
      }

      &.-imt {
        &:before {
          border-color: transparent transparent #12e827 transparent;
        }
      }
    }
  }

  .dhx_body {
    background-color: #ebebeb;
    color: #444c63;
    font-size: 11px;
    font-weight: normal;
    line-height: 1.25;
    padding: 6px 2px 8px;
    word-break: break-word;

    .theme-dark & {
      background-color: #000000;
      color: #b9b9b9;
    }
  }

  &.dhx_cal_editor {
    background-color: #878787;
  }

  &.-unavailable {
    .dhx_title {
      background-color: #393939;
      height: 4px;
    }

    .dhx_body {
      background-color: #878787;
      color: #fff;
      padding-top: 6px !important;

      .theme-dark & {
        background-color: #878787;
        color: #000000;
      }
    }
  }
}

.scheduler {
  &__legend {
    align-items: flex-start;
    background-color: #e7e7e7;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    position: fixed;
    bottom: 0;

    @media only screen and (max-width: 1023px) {
      width: 100%;
    }
    @media only screen and (min-width: $tabletLandscape-min) and (max-width: $tabletLandscape-max) {
      width: 100%;
      margin-left: -32px;
    }

    .theme-dark & {
      background-color: #1c1c1d;
    }

    ul {
      display: flex;
      padding: 0 10px;

      @media only screen and (max-width: 1023px) {
        &:first-child {
          flex-wrap: wrap;
          padding-right: 8px;
          width: 70%;
        }

        &:last-child {
          flex-direction: column;
          padding-left: 8px;
          width: 30%;
        }

        &:only-child {
          flex-wrap: wrap;
          flex-direction: unset;
          padding-right: 8px;
          padding-left: 8px;
          width: 100%;
          height: 75px;
        }
      }

      li {
        align-items: center;
        display: flex;
        line-height: 1;
        margin-right: 10px;
        margin-bottom: 5px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__type,
  &__level {
    color: #444c63;
    font-size: 10px;
    font-weight: 600;

    .theme-dark & {
      color: #adadad;
    }

    &:before {
      content: "";
      margin-right: 5px;
    }
  }

  &__type {
    &:before {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 4.5px 7px 4.5px;
      border-color: transparent transparent #878787 transparent;
    }

    &.-local {
      &:before {
        border-color: transparent transparent #4f49e1 transparent;
      }
    }

    &.-regionshorthaul {
      &:before {
        border-color: transparent transparent #ff4ff1 transparent;
      }
    }

    &.-statelonghaul {
      &:before {
        border-color: transparent transparent #11d9d1 transparent;
      }
    }

    &.-interstatedeployment {
      &:before {
        border-color: transparent transparent #ed6724 transparent;
      }
    }

    &.-imt {
      &:before {
        border-color: transparent transparent #12e827 transparent;
      }
    }
  }

  &__level {
    &:before {
      height: 4px;
      width: 16px;
      background-color: #878787;
    }

    &.-emergency {
      &:before {
        background-color: #dc1b20;
      }
    }

    &.-non-emergency {
      &:before {
        background-color: #93298d;
      }
    }

    &.-admin {
      &:before {
        background-color: #007faf;
      }
    }
  }

  &__container {
    .react-datepicker__navigation {
      min-height: initial;
      min-width: initial;
      top: 5px;
    }
  }
}

.settings,
.columnTab {
  @media only screen and (max-width: 1023px) {
    position: static !important;
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  .theme-dark & {
    background: #2a2a2a;
  }
}

ul.MuiList-root.MuiMenu-list.MuiList-padding {
  height: 20.57142857rem;

  .theme-light & {
    background: #f5f5f5;
  }

  .theme-dark & {
    background: #2a2a2a;
  }
}

li,
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  .theme-dark & {
    color: #ffffff !important;
  }
}

.MuiSelect-outlined.MuiSelect-outlined {
  padding-right: 0px;
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  background: none;
}
