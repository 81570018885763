@import "../../../assets/sass/_var.scss";
@import "../../../assets/sass/mixin";

.icon {
  &_outline,
  &_solid,
  &_round,
  &_roundTurnout {
    line-height: 22px;
    background-repeat: no-repeat;
    margin: 4px;
    font-size: $f14px;
    font-weight: bold;
    line-height: $f14px;
    display: inline-block;
    border-radius: $radius;
    padding: 4px 8px;
    text-align: center;

    &.border {
      border: solid;
      border-color: #fff;
      border-width: 2px;
      line-height: 22px;
    }
  }
  &_outline {
    // station, event label
    text-transform: uppercase;
    border: 1px solid $grey;
    @include theme-aware("background-color", "cardBg2");
    min-width: 70px;
    text-align: left;
    font-weight: bold;

    &.pinkTxt {
      @include theme-aware("border-color", "pink1");
    }

    &.blueTxt {
      @include theme-aware("border-color", "blue1");
    }
  }
  &_solid {
    border: 0 none;
  }
  &_round {
    border-radius: 50%;
    height: 28px;
    width: 28px;
    color: white;
    font-size: 120%;
    line-height: 22px;
    padding: 0;
    margin-right: 10px;

    @media screen and (max-width: $smallScreen-max) {
      margin-right: 8px;
    }

    & span {
      font-size: 0;
    }
  }
  &_roundTurnout {
    //for Turnout tab
    border-radius: 50%;
    height: 28px;
    width: 28px;
    color: white;
    font-size: 120%;
    line-height: 22px;
    padding: 0;
    margin-right: 10px;

    @media screen and (max-width: $smallScreen-max) {
      margin-right: 8px;
    }
    @media screen and (min-width: $smallTVScreen) {
      height: 40px;
      width: 40px;
      font-size: 220%;
    }

    & span {
      font-size: 0;
    }
  }
  &_.map_marker {
    box-shadow: 0px 3px 13px -5px black;
  }
  &_verified {
    background-image: url(../../../assets/img/icons/verified.svg);
    background-position: 10px 50%;
    padding-left: 30px;
    padding: 8px 10px 8px 30px;
    font-weight: bold;
  }
  &_unverified {
    background-image: url(../../../assets/img/icons/unverified.svg);
    background-position: 10px 50%;
    padding-left: 30px;
    padding: 8px 10px 8px 30px;
    font-weight: bold;
  }
  &_post {
    background-image: url(../../../assets/img/icons/Post.svg);
    background-position: 90% 50%;
    padding-right: 30px;
    font-weight: 600;
    height: 32px;
    min-width: 84px;
    display: inline-block;
    vertical-align: top;
    text-transform: initial;
  }
  &_nav {
    text-transform: initial;
    background-image: url(../../../assets/img/icons/navigateto.svg);
    background-position: 90% 50%;
    //padding:7px 30px 7px 7px;
    width: 100%;
    transition: all 150ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }

  // For Tablet
  @media screen and (min-width: $smallScreen) and (max-width: $smallScreen-max) {
    background-position: 65% 50%;
  }

  // For Tablet
  @media screen and (min-width: $smallScreen) and (max-width: $smallScreen-max) {
    background-position: 65% 50%;
  }

  // &:hover {
  //   background-image: url(../../../assets/img/icons/navigateto-white.svg);

  //   &:hover {
  //     background-image: url(../../../assets/img/icons/navigateto-white.svg);

  //     &:before {
  //       opacity: 0.8;
  //     }

  //     &:after {
  //       top: 0;
  //     }
  //   }
  // }
  &_tick {
    background-image: url(../../../assets/img/icons/attendList_yes.svg);
  }
  &_dots {
    background-image: url(../../../assets/img/icons/attendList_other.svg);
  }
  &_cross {
    background-image: url(../../../assets/img/icons/attendList_no.svg);
  }
  &_unknown {
    background-image: url(../../../assets/img/icons/attendList_unknown.svg);
  }
  &_arrowUp {
    background-image: url(../../../assets/img/icons/arrow-up.svg);
    background-position: 50%;
  }
  &_arrowDownSolid {
    background-image: url(../../../assets/img/icons/down-arrow-solid.svg);
    background-repeat: no-repeat;
    background-position: center bottom;
    display: inline-block;
    height: 12px;
    width: 12px;
    margin: 0px 5px;
  }
  &_refresh,
  &_sort,
  &_filter,
  &_list,
  &_map {
    background-size: 15px, 0%;
    background-repeat: no-repeat;
    background-position: 85% 50%;
    font-weight: 600;
    // transition: none; // for now
    // &:hover{
    //   background-position: 85% 50%, center;
    //   background-size:15px, 15000%;
    // }

    // &:active{
    //   //background-size: 10px 10px, 100%;
    //   background-size: 15px,100%;
    //   transition: background-image 0s;
    // }
  }
  &_refresh {
    min-height: 32px;
    margin-left: 0;
    background-position: 90% 50%;
    background-image: url(../../../assets/img/icons/refresh.svg);
    padding-right: 30px;
  }
  &_list,
  &_map {
    background-repeat: no-repeat;
    width: calc(50% - 2px);
    margin: 0;
    margin-left: 5px;
    padding-right: 30px;
    height: 40px;
    line-height: $f40px;
    @media screen and (max-width: $tabletPortrait) {
      margin-left: 2px;
      line-height: 27px !important;
    }
    @media screen and (min-width: $smallScreen-max) {
      //at least tablet landscape 1024px
      margin-top: 5px;
      width: 120px;

      &:hover {
        @include theme-aware("color", "txt");
      }
    }
  }
  &_list {
    line-height: 27px;
    background-position-y: 13px;
    background-image: url(../../../assets/img/icons/listview.svg);
  }
  &_map {
    background-position-y: 10px;
    background-image: url(../../../assets/img/map/mapview.svg);
  }
  &_trash {
    padding-right: 25px;
    background-position: 90% 50%;
    background-image: url(../../../assets/img/icons/trashcan.svg);
  }
  &_trashred {
    padding-right: 25px;
    background-position: 90% 50%;
    background-image: url(../../../assets/img/icons/trashcan-red.svg);
  }
  &_trashgrey {
    padding-right: 25px;
    background-position: 90% 50%;
    background-image: url(../../../assets/img/icons/trashcan-grey.svg);
  }

  &_sort,
  &_filter {
    height: 32px;
    text-transform: uppercase;
  }
  &_sort {
    background-image: url(../../../assets/img/icons/Sort.svg);
    padding-right: 30px;

    &.flip {
      background-image: url(../../../assets/img/icons/Sort-flip.svg);
      padding-right: 30px;
    }
  }
  &_filter {
    background-image: url(../../../assets/img/icons/Filter.svg);
    padding-right: 30px;
    padding-left: 10px;

    span {
      display: block;
      line-height: 32px;
    }
  }
  &_locked {
    background-image: url(../../../assets/img/icons/locked-field.svg);
    padding-right: 20px;
    background-repeat: no-repeat;

    .theme-dark & {
      background-image: url(../../../assets/img/icons/locked-field-white.svg);
    }
  }
  &_turnoutAlert {
    background-image: url(../../../assets/img/icons/turnout_alert_icon.svg);
    width: 20px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 10px;

    .theme-dark & {
      filter: invert(1);
    }
  }


  /// edit button on homepage
  // &_edit {
  //   padding-top: 20px;
  //   width: 40px;
  //   height: 40px;
  //   font-size: $f10px;
  //   background-color: $bg;
  //   border: solid 1px $grey;
  //   transition-duration: 0s;
  //   background-position: 50% 20%;
  //   background-size: 17px;
  //   background-image: url(../../../assets/img/icons/edit.svg);
  // }

  // @media screen and (max-width: $smallScreen-max) {
  //   &_edit {
  //     padding: 0;
  //     width: 70px;
  //     height: 20px;
  //     padding-right: 20px;
  //     font-size: $f14px;
  //     background-color: #fafafa;
  //     border: none;
  //     transition-duration: 0s;
  //     background-position: 90% 48%;
  //     font-weight: 600;
  // @include theme-aware("color", "txt");
  //     background-size: 13px;
  //     background-image: url(../../../assets/img/icons/edit.svg);

  //     @media screen and (min-width: $smallScreen-max) {
  //       &:hover {
  //         background-image: url(../../../assets/img/icons/edit-white.svg);
  //       }
  //      }
  //    }
  // }

  // chat message
  &_chat {
    background-image: url(../../../assets/img/icons/chat.svg);
    background-repeat: no-repeat;
    margin-right: 11px;
    margin-top: 5px;
    font-weight: 600;
    font-size: $f13px;
    line-height: $f24px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    height: 30px;
    width: 30px;
    position: relative;

    &.event {
      &.emergency {
        span {
          color: #dc1b20;
        }
        background-image: url(../../../assets/img/icons/chatemerg.svg);
      }

      &.nonEmergency {
        span {
          color: #93298d;
        }
        background-image: url(../../../assets/img/icons/chatnonemerg.svg);
      }
    }

    .theme-dark & {
      background-image: url(../../../assets/img/icons/chatdark.svg);
    }

    span {
      display: block;
      user-select: text;
    }

    &.unread:before {
      content: "";
      position: absolute;
      display: block;
      height: 14px;
      width: 14px;
      background: linear-gradient(90deg, #00aff0 0.12%, #007dc8 99.69%);
      border-radius: 50%;
      top: -4px;
      left: -4px;
    }

    &_group {
      background-image: url(../../../assets/img/icons/group-message.svg),
        url(../../../assets/img/icons/chat.svg);
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 11px;
      margin-top: 5px;
      font-weight: 600;
      font-size: $f13px;
      line-height: $f24px;
      text-align: center;
      display: inline-block;
      vertical-align: top;
      height: 30px;
      width: 30px;
      position: relative;

      .theme-dark & {
        background-image: url(../../../assets/img/icons/group-messagedark.svg),
          url(../../../assets/img/icons/chatdark.svg);
      }
    }
  }

  &_menulink {
    background-image: url(../../../assets/img/icons/dots-vertical.svg);
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    height: 30px;
    width: 30px;
    margin: 14px;

    &.dark {
      background-image: url(../../../assets/img/icons/dots-vertical-dark.svg);
      height: 20px;
      width: 12px;
      margin: 14px 19px 14px 13px;
    }
  }
}
.theme-dark .icon {
  &_verified {
    background-image: url(../../../assets/img/icons/verified-darkmode.svg);
  }
  &_unverified {
    background-image: url(../../../assets/img/icons/unverified-darkmode.svg);
  }
  &_menulink {
    background-image: url(../../../assets/img/icons/dots-vertical.svg);
  }
}

.iconText {
  text-align: center;
  margin-top: 15%;
  color: white;
}

.icon_chat.unread:before {
  //placeholder
  animation-name: fadeAway;
  animation-delay: 7s;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

@media screen and (max-width: $smallScreen-max) {
  // max tablet lanscape
  .icon {
    &_list,
    &_map,
    &_sort,
    &_filter {
      // accessibility min btn size
      height: 30px;
    }
  }
}
@keyframes fadeAway {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.AttendanceList_Attendance_header__3nBoN {
  .icon {
    &_sort,
    &_filter {
      width: 49%;
    }
  }
}
.AttendanceList_compact__vnnRC {
  .icon_round {
    width: 25px;
    height: 25px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: $f16px;
    min-width: 25px;
  }
  .icon_outline {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// BUTTON HOVER
@media screen and (min-width: $mediumScreen-max) {
  button {
    &.icon {
      &_search {
        &:hover {
          background-image: url(../../../assets/img/icons/search-white.svg);
        }
      }
      &_post {
        &:hover {
          background-image: url(../../../assets/img/icons/Post_white.svg);
        }
      }
      &_nav {
        &:hover {
          color: #fff;
          background-color: $dgreen;
          background-image: url(../../../assets/img/icons/navigateto-white.svg);
        }
      }
      &_refresh {
        &:hover {
          background-image: url(../../../assets/img/icons/refresh-white.svg);
        }
      }
      &_sort {
        &:hover {
          background-image: url(../../../assets/img/icons/Sort-white.svg);
          background-position: 85% 50%;
          &.flip {
            background-image: url(../../../assets/img/icons/Sort-flip-white.svg);
            background-position: 85% 50%;
          }
        }
      }
      &_filter {
        &:hover {
          background-image: url(../../../assets/img/icons/Filter-white.svg);
          background-position: 85% 50%;
        }
      }
      &_edit {
        &:hover {
          background-image: url(../../../assets/img/icons/edit-white.svg);
        }
      }
      &_trash {
        &:hover {
          background-image: url(../../../assets/img/icons/trashcan-white.svg);
          padding-right: 25px;
          background-position: 90% 50%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
