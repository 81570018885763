.schedulerModal {
  background-color: #fff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.29);
  display: none;
  width: 700px;
  left: calc((100vw - 700px) / 2) !important;
  top: 10vh !important;
  padding: 20px;
  position: fixed;
  z-index: 999;
  border-radius: 5px;

  @media only screen and (max-width: 1023px) {
    padding: 15px 15px 20px 15px;
    width: calc(100% - 40px);
    left: 20px !important;
  }

  .theme-dark & {
    background-color: #222222;
  }

  h2 {
    color: #5c5e65;
    font-size: 20px;
    font-weight: bold;

    @media only screen and (max-width: 1023px) {
      margin-bottom: 0;
    }

    .theme-dark & {
      color: #fff;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: 1023px) {
      align-items: center;
      margin-bottom: 5px;
    }
  }

  &__body {
    height: calc(100vh - 330px);
    padding-bottom: 70px;
    margin-bottom: 20px;
    max-height: 600px;
    overflow-y: auto;
    background: /* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    background: /* Shadow covers */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0)
        )
        0 100%;
    .theme-dark & {
      background: transparent;
    }
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;

    @media only screen and (max-width: 1023px) {
      max-height: calc(100vh - 215px);
      padding-right: 10px;
    }
  }

  &__footer {
    display: flex;
    margin: 0 -10px;

    .schedulerModal__btn {
      margin: 0 10px !important;
    }
  }

  &__row {
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 20px;
    padding-bottom: 20px;

    &:last-of-type {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__column {
    padding-left: 40px;
    padding-right: 15px;
    width: 40%;

    @media only screen and (max-width: 1023px) {
      float: left;
      padding: 0;
      width: 50%;
    }

    ul {
      li {
        margin-bottom: 15px;

        &:first-child {
          border-bottom: 1px solid #e9e9e9;
          margin-bottom: 12px;
          padding-bottom: 15px;
        }
      }
    }

    .schedulerModal__label {
      margin-bottom: 20px;
      margin-top: 0;
      margin-right: 0;
      text-align: left;
      width: auto;
    }
  }

  &__item {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @media only screen and (max-width: 1023px) {
      flex-direction: column;
    }

    &:only-child,
    &:last-child {
      margin-bottom: 0;
    }

    &.-recurring {
      flex-wrap: wrap;
    }

    &.-column {
      @media only screen and (max-width: 1023px) {
        display: block;
      }

      &:before,
      &:after {
        content: "";
        display: table;
      }

      &:after {
        clear: both;
      }
    }

    ul {
      &.-horizontal {
        display: flex;
        padding: 0 40px;

        @media only screen and (max-width: 1023px) {
          padding: 0;
          padding-left: 5px;
        }

        li {
          margin-bottom: 0;
        }
      }

      &.-days {
        display: flex;
        flex-wrap: wrap;
        width: 70%;
        padding: 0 40px;

        @media only screen and (max-width: 1023px) {
          flex-direction: column;
          padding: 0;
          padding-left: 5px;
          width: 100%;
        }

        li {
          margin-bottom: 15px;
        }
      }
    }

    li {
      color: #5c5e65;
      font-size: 16px;
      font-weight: 600;
      margin-right: 25px;

      &:last-child {
        margin-right: 0;
      }

      .theme-dark & {
        color: #fff;
      }
    }
  }

  &__label {
    color: #5c5e65;
    font-size: 16px;
    font-weight: 600;
    margin-top: 6px;
    text-align: right;
    width: 120px;

    @media only screen and (max-width: 1023px) {
      text-align: left;
      margin-bottom: 10px;
    }

    .theme-dark & {
      color: #fff;
    }
  }

  &__radio {
    text-transform: uppercase;
  }

  &__input {
    margin: 20px 0;
    margin-left: 160px;
    max-width: 300px;
    width: 100%;

    @media only screen and (max-width: 1023px) {
      margin-left: 0;
      padding-left: 5px;
    }
  }

  &__btn {
    background-color: #fff;
    border: 0;

    .theme-dark & {
      background-color: transparent;
    }

    &.-delete {
      border: 1px solid #dc1b20;
      border-radius: 5px;
      color: #dc1b20;
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      padding: 20px 5px;
      width: 100%;

      .theme-dark & {
        color: #fff;
      }
    }

    &.-save {
      border: 1px solid #007454;
      border-radius: 5px;
      color: #007454;
      font-size: 20px;
      font-weight: 600;
      margin: 0;
      padding: 20px 5px;
      width: 100%;

      .theme-dark & {
        color: #fff;
      }
    }

    &.-close {
      height: 30px;
      margin: 0;
      margin-right: 15px;
      padding: 10px;
      position: relative;
      width: 30px;

      @media only screen and (max-width: 1023px) {
        height: 50px;
        margin-right: 0;
        min-height: 50px;
        min-width: 50px;
        width: 50px;
      }

      &:before,
      &:after {
        background-color: #007454;
        bottom: 0;
        content: "";
        height: 2px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;

        .theme-dark & {
          background-color: #fff;
        }
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }

    &:disabled {
      border: 1px solid #d6d7d9;
      color: #bdbdbd;
      cursor: not-allowed;
    }
  }

  .dropdownDate {
    color: #5c5e65 !important;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;
    font-size: 16px !important;
    max-height: 32px;
    width: 145px !important;
    display: flex !important;
    padding-right: 10px !important;

    -webkit-appearance: none;

    .theme-dark & {
      color: #fff !important;

      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }

    input {
      margin-left: -15px !important;
      margin-right: -10px !important;
      background-color: transparent;
      width: 98px;
    }
  }

  .dropdownTime {
    background-color: #f5f5f5 !important;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    width: 90px;
    min-height: 32px;
    padding: 0;

    color: #5c5e65;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: bold;
    font-size: 16px !important;

    .theme-dark & {
      color: #fff;
      background-color: transparent !important;
      box-shadow: 0 0 1px 2px #000, 0 0 1px 3px #8c919a;
    }

    input {
      margin-left: -10px;
      margin-right: -20px;
      width: 60px;
      background-color: transparent;
    }
  }

  li.react-datepicker__time-list-item {
    .theme-dark & {
      color: #5c5e65 !important;
    }
  }

  li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
    background-color: #007454 !important;
    .theme-dark & {
      color: #fff !important;
    }
  }
}
