@import "../../assets/sass/_var.scss";
@import "../../assets/sass/mixin";

.default-map {
  z-index: 100;
}

.dashboardMap {
  height: 100%;
}

.defaultMapRegular {
  width: 100%;
  position: relative;
  z-index: 15;
  top: 0;
  left: 0;

  &.detailsMap {
    height: 350px;
  }

  &.attendanceMap {
    height: 400px;
  }

  &.fullscreen {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 16;
  }

  &.eventdashboard {
    height: 100%;
  }
  &.eventturnout {
    height: calc(100% - 10px);
  }
}

.labelStyles {
  background-color: #ffffff;
  border-style: solid;
  border-width: 1.5px;
  border-radius: 5px;
  padding-bottom: 3px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: $f14px;
  box-shadow: 0px 3px 13px -5px black;
  margin-right: 20px;
}

.labelDivStyles {
  width: auto;
  height: auto;
  display: inline-block;
  position: absolute;
  z-index: 800;
  bottom: 20px;
  left: 20px;
}

.stationStyles {
  color: $blue;
  border-color: $blue;
  float: left;
}

.eventStyles {
  color: $pink;
  border-color: $pink;
  float: left;
}

.youStyles {
  color: $purple;
  border-color: $purple;
}

.leaflet-routing-container-hide {
  display: none;
}

.marker-cluster-people {
  background-image: url("../../assets/img/icons/peopleCluster.svg");
  border-radius: 44px;
  background-position: center;
  background-repeat: no-repeat;
}

.pointOfInterest {
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0 !important;
}

.popup {
  position: relative;
  display: none;
  width: 100%;
  height: 100%;

  .popup_background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .popup_content {
    z-index: 900;
    position: absolute;
    bottom: 10px;

    left: 0;
    right: 0;
    margin: auto;

    @include theme-aware("background-color", "cardBg");
    font-size: 0.875rem;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
    border-radius: 5px;
    display: flex;
    max-width: calc(100% - 20px);
    margin-right: auto;
    // @media screen and (min-width: $smallTVScreen) {
    //   position: relative;
    // }
    .content {
      padding: 8px;
      width: 100%;

      .title {
        font-weight: 600;
        margin: 0;
        font-size: $f16px;
      }

      .icon_section {
        display: flex;
        align-items: center;
        padding-top: 8px;

        p {
          padding-left: 8px;
        }
      }
    }

    .controls {
      border-left: solid 1px;
      @include theme-aware("border-color", "grey2dgrey");
      cursor: pointer;

      .navigate_button,
      .access_button {
        padding: 4px 8px 4px 4px;

        button {
          margin: 0;
          @include theme-aware("background-color", "grey8");
          border-color: transparent;
          width: 35px;
          height: 35px;
          margin: 5px;
          padding: 9px;
          min-width: 35px !important;
        }

        p {
          text-transform: uppercase;
          text-align: center;
          font-weight: 600;
          margin: 0;
        }
      }

      .one_section {
        .navigate_button {
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
        }
      }
      .two_section {
        display: flex;
        flex-direction: column;
        .navigate_button {
          display: flex;
          align-items: center;
          height: 50%;
          border-bottom: solid 1px;
          @include theme-aware("border-color", "grey2dgrey");

          p {
            padding-left: 4px;
          }
        }
        .access_button {
          display: flex;
          align-items: center;
          height: 50%;
          text-transform: none;
          text-decoration: none;
          color: inherit;

          p {
            padding-left: 4px;
          }
        }
      }
    }
  }

  &.visible {
    display: block;
  }
}

.default-map {
  .SESUnits {
    background-image: url("../../assets/img/map/poi_SESUnits.svg");

    &-multi {
      background-image: url("../../assets/img/map/poi_SESUnits_multi.svg");
    }
  }

  .ambulance {
    background-image: url("../../assets/img/map/poi_ambulance.svg");

    &-multi {
      background-image: url("../../assets/img/map/poi_ambulance_multi.svg");
    }
  }

  .marker {
    background-image: url("../../assets/img/map/poi_marker.svg");

    &-multi {
      background-image: url("../../assets/img/map/poi_marker_multi.svg");
    }
  }

  .fireStation {
    background-image: url("../../assets/img/map/poi_fireStation.svg");

    &-multi {
      background-image: url("../../assets/img/map/poi_fireStation_multi.svg");
    }
  }

  .fireStationForest {
    background-image: url("../../assets/img/map/poi_fireStationForest.svg");

    &-multi {
      background-image: url("../../assets/img/map/poi_fireStationForest_multi.svg");
    }
  }

  .hospital {
    background-image: url("../../assets/img/map/poi_hospital.svg");

    &-multi {
      background-image: url("../../assets/img/map/poi_hospital_multi.svg");
    }
  }

  .emergency {
    background-image: url("../../assets/img/map/poi_emergency.svg");

    &-multi {
      background-image: url("../../assets/img/map/poi_emergency_multi.svg");
    }
  }
}

.hiddenControlLayers {
  position: absolute;
  width: 177px;
  height: 33px;
  z-index: 1000;
  margin: 0px;
  top: 10px;
  right: 10px;
  background-color: transparent;
  padding: inherit;
  border-width: 0px;
}

// below is for pager event banner

.pagerHeader {
  display: flex;
  flex-direction: column;
  min-height: 100px;
  border-bottom: solid 1px $grey;
  padding-bottom: 12px;
  width: 100%;
  @include theme-aware("background-color", "tileBg");
  border-radius: 5px;

  &.emergency {
    .top {
      background: $emg;

      .alertType {
        color: #fff;
      }
    }

    &.ended {
      .top {
        border-bottom: solid 2px $red;
      }
    }
  }

  &.nonEmergency {
    .top {
      background: $nonemg;
    }

    &.ended {
      .top {
        border-bottom: solid 2px #93328e;
      }
    }
  }

  &.admin {
    .top {
      background: $admin;
    }

    &.ended {
      .top {
        border-bottom: solid 2px $blue;
      }
    }
  }

  &.ended {
    .top {
      background: transparent;

      .alertType {
        .emergency {
          color: #dc1b20;
        }

        .urgent {
          color: $yellow;
        }

        .nonEmergency {
          color: #93328e;
        }

        .admin {
          color: #007faf;
        }

        .update {
          @include theme-aware("color", "txt");
        }
      }

      .timeStamp {
        @include theme-aware("color", "txt");
      }
    }

    // .timer {
    //   span {
    //     color: #000 !important;
    //   }
    // }
  }

  .top {
    display: flex;
    width: 100%;
    align-items: center;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    .controls {
      margin-left: auto;
      margin-left: auto;
      margin-bottom: -30px;
      padding-right: 16px;
      z-index: 2;
    }
  }

  .bottom {
    display: flex;
    width: 100%;
  }

  .agencyLogo {
    margin: 10px 0 -15px 5px;
    height: 35px;
    width: 35px;
    padding: 3px;
    text-align: center;
    background: #fff;
    border-radius: 5px;

    img {
      height: 100%;
      width: auto;
    }
  }

  .alertType {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    margin-left: 10px;
    margin-right: 16px;
  }

  .timeStamp {
    display: flex;
    justify-content: flex-end;
    font-size: $f14px;
    font-weight: 600;
    color: #fff;
    margin-right: 16px;
    margin-left: auto;

    .time {
      padding-right: 10px;
    }

    span {
      white-space: nowrap;
    }
  }
}

.bottom {
  display: flex;
  width: 100%;

  .pagerGroup {
    font-weight: 600;
    margin: 5px 0;
    color: #000;
    margin-left: 50px;
  }
}

.details {
  display: flex;
  align-items: center;

  .pagerDetails {
    padding-right: 16px;
    padding-left: 16px;
    line-height: normal;
    word-break: break-word;
    @include theme-aware("color", "txt4");
  }
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 16px;
  align-items: center;

  .inactiveIcon {
    span {
      border-radius: 5px;
      padding: 3px 6px;
      font-weight: 600;
    }
  }
}
