@import "./mixin";
@import "./var";

@media screen and (min-width: $smallScreen-max) {
  .main-content-holder {
    text-align: center;
    //padding: 16px 28px;
    padding: 0 16px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    max-width: $extraLargeScreen;
    border-radius: 8px;
    @include theme-aware("background-color", "bg3");
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  }
  .col-one-third {
    text-align: left;
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
    padding: 0 15px 20px 15px;
  }
  .col-half {
    text-align: left;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 0 15px 20px 15px;
  }
  .col-two-third {
    text-align: left;
    width: 66.67%;
    display: inline-block;
    vertical-align: top;
  }
}
@media screen and (min-width: $tabletLandscape-min) and (max-width: $tabletLandscape-max) {
  .main-content-holder {
    text-align: center;
    //padding: 16px 28px;
    padding: 0 16px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
    max-width: $extraLargeScreen;
    border-radius: 8px;
    @include theme-aware("background-color", "bg3");
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  }
  .col-one-third {
    text-align: left;
    width: 33.33%;
    display: inline-block;
    vertical-align: top;
    padding: 0 15px 20px 15px;
  }
  .EventDetails_section.col-one-third {
    width: 33.33%;
  }
  .settings.col-one-third {
    width: 100%;
  }
  .profile_settings.col-one-third {
    width: 100%;
  }
  .col-half {
    text-align: left;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: 0 15px 20px 15px;
  }
  .col-two-third {
    text-align: left;
    width: 66.67%;
    display: inline-block;
    vertical-align: top;
  }

  .col-full-tablet-landscape {
    text-align: left;
    width: 100%;
    display: inline-block;
    vertical-align: top;
    padding: 0 15px 20px 15px;
  }
}

.col-one-third {
  @media screen and (width: $tabletLandscape) {
    padding: 0 16px 20px 16px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  @media screen and (max-width: $smallScreen-max) {
    margin: 18px;
  }

  &.only-child {
    text-align: center;
    margin: 0 auto;
    display: block;
    width: 100%;
    max-width: 600px;
    @media (max-width: $tabletPortrait) {
      padding: 0 35px;
    }
  }
}
