@import "../../../assets/sass/_var.scss";
@import "../../../assets/sass/mixin";

.closeAlertControls {
  pointer-events: none;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: $bg;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  width: 80%;
  min-width: 290px;
  max-width: 350px;
  height: 0;
  max-height: 65%;
  opacity: 0;
  transition: ease all 0.2s;

  &.visible {
    pointer-events: auto;
    height: auto;
    opacity: 1;
    z-index: 1001;
    position: fixed;
    transition: ease opacity 0.2s;
    transition-delay: 0.2s;

    .content {
      opacity: 1;
      transition: ease opacity 0.2s;
      transition-delay: 0.2s;
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    z-index: 1000;

    span {
      @include theme-aware("color", "txt");
      font-size: larger;
      font-weight: bold;
      text-align: center;
      line-height: normal;
      margin: 0.25rem 0.75rem;
    }
    .subtitleText {
      font-size: small;
      font-weight: 500;
      color: #de3700;
    }

    .controls {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;
      width: 100%;

      button {
        @include theme-aware("border-color", "grey9");
        width: 90%;
        min-height: 50px;
        margin: 10px 0;
        font-size: larger;
      }
    }
  }
  @media (max-height: $extraSmallScreen) {
    top: 25%;
    .content {
      .controls {
        flex-direction: row;
        padding: 5px 15px;
        :first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.darkMask {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: ease all 0.2s;
  z-index: 1000;

  &.visible {
    pointer-events: auto;
    opacity: 1;
    transition: ease opacity 0.2s;
    transition-delay: 0.2s;
  }
}
