@import "../../assets/sass/_var.scss";

.qual {
  margin: 0;
  margin-top: 15px;
  list-style: none;
  padding: 0;
  padding-bottom: 15px;
  //border-bottom: 1px solid $grey;

  li {
    padding: 0;
    font-size: $f14px;
    line-height: $f24px;
  }
}
