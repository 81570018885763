@import "../../assets/sass/_var.scss";

div + .groupThreshold {
  padding-top: 0;
}
.loadingWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dateSelect {
  span {
    min-width: auto !important;
  }
}
.groupThreshold {
  li {
    background-repeat: no-repeat;
    background-position: 95% 50%;
    height: 44px;
    vertical-align: middle;
    border-bottom: 1px solid #e9e9e9;
    // border-bottom: 1px solid #5a5f65;
    position: relative;
    .icon {
      position: absolute;
      right: 12px;
      top: 0;
      bottom: 0;
      margin: auto;
      height: 14px;
    }
    // &:after {
    //   content: url(../../assets/img/icons/Threshold-NotMet.svg);
    //   position: absolute;
    //   right: 12px;
    //   top: 0;
    //   bottom: 0;
    //   margin: auto;
    //   height: 14px;
    // }

    &:hover,
    &.selected {
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        top: 0px;
        left: -3px;

        width: 100%;
        height: calc(100% - 3px);
        display: block;
        border: 3px solid #5a5f65;
        border-radius: 6px;
        z-index: 1;
      }
      &:after {
        content: "";
        position: absolute;
        right: -15px;
        top: 0;
        bottom: 0;
        margin: auto;

        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;

        border-left: 15px solid #5a5f65;
      }
    }
    &.header {
      background-image: none;

      .primaryText {
        font-size: $f16px;
        line-height: $f30px;
        &:after {
          display: none;
        }
      }

      &:hover {
        cursor: default;
        &:before,
        &:after {
          display: none;
        }
      }
    }
    &.reached {
      background-color: $lgreen;
      color: #000;
      .primaryText {
        font-weight: normal;
        font-size: $f12px;
      }
      // &:after {
      //   content: url(../../assets/img/icons/Threshold-Met.svg);
      //   position: absolute;
      //   right: 10px;
      // }
    }
    .primaryText {
      padding: 8px;
      font-size: $f14px;
      line-height: $f30px;
      min-width: 33%;
      display: inline-block;
      font-weight: 600;

      &.dayofweek {
        padding-left: 24px;
        font-weight: 600;
        font-size: $f14px;
      }
    }
  }
  + button {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 16px;
    width: calc(100% - 30px) !important;
  }
  &.hrs li {
    background-position: 15% 50%;
  }
}
.subtitle {
  font-size: $f16px;
  font-weight: 600;
  line-height: $f37px;
  height: 37px;
  border-bottom: 1px solid $grey;

  span {
    width: 50%;
    display: inline-block;
  }
}
ul.results {
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  p {
    margin: 0;
    font-size: 12px;
  }
  > li {
    border-bottom: 1px solid $grey;
  }
  li {
    border-radius: 5px;
    text-align: left;
    padding: 6px 13px;
    width: 100%;

    @media screen and (max-width: $smallScreen-max) {
      padding: 6px 8px;
    }

    span {
      vertical-align: top;
      span {
        display: block;
        width: 100%;
        text-align: left;
      }
    }
    .person {
      display: inline-block;
      padding-right: 12px;
      margin-top: 4px;
      width: calc(100% - 44px);
      line-height: $f20px;

      .qualification {
        font-size: $f14px;
      }
      .name {
        font-size: $f16px;
        font-weight: 600;
      }
    }
  }

  .moreButton,
  .hideButton {
    cursor: pointer;
    line-height: $f14px;
    font-size: $f12px;
    color: $mGreen;
    padding: 0 4px;
  }

  .hideButton {
    position: absolute;
    top: 4px;
    left: 110px;
  }
}

.skillList {
  position: relative;
  margin-left: 0px;

  &.open {
    &:before {
      content: "Qualifications";
      display: block;
      color: #5a5f65;
      font-size: $f16px;
      font-weight: 600;
      //line-height: $f44px;
      margin-top: 6px;
      margin-bottom: 4px;
    }
    li {
      padding: 0;
      // @include theme-aware("color", "txt");
      font-size: $f14px;
      line-height: $f24px;
      display: block;
    }
  }
  &.hide {
    display: none;
  }
  &.show {
    display: block;
  }
  &.close {
    padding-top: 4px;
    font-size: $f14px;

    &:before {
      display: none;
    }

    li {
      color: #767676;
      vertical-align: top;
      display: inline-block;
      height: 13px;
      width: auto;
      padding: 0;
      margin: 0;
      white-space: nowrap;
      font-size: $f12px;
      line-height: $f12px;

      @media screen and (width: $tabletLandscape) {
        max-width: 75px;
      }
      &:after {
        padding-right: 4px;
        content: ",";
        display: inline-block;
        //background-color: #fff;
        color: #ccc;
      }
      &:last-child:after {
        content: "...";
      }
    }
  }
}

.overview {
  width: 100%;
  display: flex;
  li {
    flex: 1;
    justify-content: space-between;
    position: relative;

    &:first-child {
      padding-left: 11px;
      span.timeLabels {
        margin-left: 17px;
      }
    }
    &:last-child {
      padding-right: 11px;
    }
    span.timeLabels,
    &.midnight:before,
    &.morning:before,
    &.midday:before,
    &.evening:before {
      border-left: 1px solid $grey;
      position: absolute;
      display: block;
      top: -25px;
      left: 0;
      padding-left: 4px;
      margin-left: 6px;
      white-space: nowrap;
      padding-right: 20px;
    }

    &.midnight:before,
    &.morning:before,
    &.midday:before,
    &.evening:before {
      content: " ";
      height: 26px;
      top: -36px;
    }

    span.timeLabels {
      font-size: $f12px;
      font-weight: 600;
      line-height: $f15px;
      &.midnight {
        border-left: 2px solid #000;
      }
    }

    &.midnight:before {
      border-left: 2px solid #000;
    }
  }
  &.week {
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
    li {
      span.primaryText {
        top: 0 !important;
      }
      &:first-child {
        padding-left: 8px;
        .header {
          margin-left: -11px;
        }
      }
      &:nth-child(7) {
        border-right: 1px solid #cfcfd1;
        .primaryText {
          margin-right: 10px;
        }
        .header {
          border-right: 0 none;
        }
      }
      &:nth-child(8) .primaryText {
        margin-left: 10px;
      }
      &:last-child {
        //padding-left: 8px;
        border-right: 0 none;

        .header {
          margin-right: -11px;
          border-right: 0 none;
        }
      }
      .header {
        display: block;
        border: 1px solid $grey;
        border-left: 0 none;
        text-align: center;
        line-height: $f28px;
        text-transform: uppercase;
        &:last-child {
          border-right: 0 none;
        }
      }
      .primaryText {
        margin: 16px 3px;
        position: relative;
        display: block;
        line-height: $f16px;
        border: 1px solid rgba(92, 94, 101, 0.25);
        border-radius: 5px 5px 3px 5px;
        text-align: center;
        text-transform: uppercase;

        font-size: $f12px;
        font-weight: 600;
        line-height: $f15px;

        //margin-left: 3px;
        img {
          position: absolute;
          left: -1px;
        }
        &.reached {
          border: 1px solid #d7df28;
          background-color: #d7df28;
          img {
            top: -1px;
          }
        }
      }
    }
  }
}
