@import "../../../assets/sass/_var.scss";
@import "../../../assets/sass/mixin";

.loadingContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollableContent {
  max-height: calc(100% - 32px);
  overflow-y: auto;
}

.Attendance_section {
  margin: 0 auto;
  position: relative;
  height: 100%;
  max-height: calc(100% - 52px);
  .filterControls {
    position: absolute;
    top: 50px;
    width: 94%;
    background-color: #fff;
    z-index: 15; // map is 15, full screen is 16
    padding: 18px;
    padding-bottom: 10px;
    padding-top: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);

    border-radius: 5px;
    border-color: #181818;
    border-width: 4px;
    border-style: none none solid none;

    p {
      border-bottom: 1px solid $grey;
      padding-bottom: 8px;
      font-size: $f14px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: $f24px;
    }

    .holder {
      display: flex;
      border-bottom: 1px solid $grey;
      line-height: 40px;
      @include theme-aware("color", "txt");
      flex-direction: column;

      span.label {
        white-space: nowrap;
        font-size: $f16px;
        font-weight: 600;
        line-height: $f44px;
        padding-right: 18px;
        width: 40%;
      }

      &.small_screen {
        border-bottom: none;
      }
    }

    .dropDownItem {
      min-height: 33px;
      display: flex;
      flex-direction: row;

      .dropdownLabel {
        flex: auto;
        margin-top: 8px;
        margin-bottom: 8px;
        line-height: 32px;
        min-width: 35%;
        font-size: 0.9rem;
        font-weight: 600;
        margin-left: 4px;
        white-space: nowrap;
      }

      .dropdown {
        display: block;
        width: 100%;
        margin: 8px 4px 8px 0 !important;
      }

      .multi {
        display: flex;
        width: calc(100% - 1px);
        margin: 4px 0;
        padding: 4px;
        font-size: $f14px;
        //line-height: 26px;
        background-repeat: no-repeat;
        background-position: 95% 50%;

        white-space: nowrap;
        @media screen and (max-width: $tabletPortrait) {
          line-height: 32px;
          font-weight: normal;
        }
        .mobileTxt,
        .desktopTxt {
          display: none;
        }

        > span {
          flex: auto;
        }

        i {
          font-size: 1.25rem;
          @include theme-aware("color", "txt");
        }
      }
    }

    .destination_options {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      width: 100%;

      &.small_screen {
        border-bottom: 1px solid $grey;
      }
    }

    .dropdownLabel {
      font-size: $f16px;
      font-weight: 600;
      line-height: $f44px;
      padding-right: 18px;
      + div {
        margin-top: 7px !important;
      }
    }
    .filterButtons {
      padding-top: 7px;
    }
    .closeBtn {
      float: right;
    }
    .radio {
      display: inline-block;
      position: relative;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding-left: 35px;
      margin: 12px 0;
      line-height: 20px;
      flex: auto;

      height: 20px;
      width: auto;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 5px;
        height: 20px;
        width: 20px;
        box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
        background-color: $grey;
        border-radius: 50%;
        transition: ease all 0.1s;
      }

      input:checked ~ .checkmark {
        background-color: $mGreen;
      }
      // @media (min-width: $smallScreen-max) {
      //   &:hover input ~ .checkmark {
      //     background-color: #ccc;
      //   }
      // }
    }
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    &.attending {
      &.av {
        padding: 9px 2px;
        background-color: #fff;
        border-radius: $radius;
        @media screen and (min-width: $smallScreen-max) {
          min-height: 170px;
        }
        .person {
          line-height: $f28px;
        }
        .ETA {
          display: none;
        }
      }
      .person {
        width: calc(100% - 40px - 85px);
      }
      &.compact {
        // compact view icon styling in icon.module.scss
        padding: 0 8px 0 8px;
        li {
          display: flex;
        }

        .person {
          padding: 0;
          margin-top: 0;
          max-width: 36%;

          .name {
            font-size: $f16px;
            line-height: $f24px;
          }
        }
        .ETA {
          text-align: right;
          flex: auto;
          max-width: 100%;
          margin-top: 0;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          
          @media screen and (max-width: 359px) {
            flex-wrap: wrap;
          }

          span {
            width: 70px;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    li {
      border-radius: 5px;
      text-align: left;
      //border-bottom: 2px solid #f5f5f5;
      // background-color: #fff;
      padding: 6px 13px;
      //display: flex;
      width: 100%;
      @media (min-width: $smallTVScreen) {
        //for Turnout tab TV screens
        padding: 15px 15px;
      }
      @media screen and (max-width: $smallScreen-max) {
        padding: 6px 8px;
      }

      span {
        vertical-align: top;
        span {
          display: block;
          width: 100%;
          text-align: center;
        }
      }
      .person {
        display: inline-block;
        padding-right: 12px;
        margin-top: 4px;
        line-height: $f20px;

        .qualification {
          font-size: $f14px;
        }
        .name {
          font-size: $f16px;
          font-weight: 600;
          text-align: left;
          overflow-wrap: anywhere;
        }
      }
      .ETA {
        display: inline-block;
        max-width: 100px;
        margin-right: 0;
        text-align: center;
        float: right;

        & > span:first-child {
          text-align: center;
          font-weight: 600;
          letter-spacing: 0.5px;
          line-height: $f18px;
          font-size: $f14px;

          img {
            margin-right: 4px;
          }
        }
        .greenTxt {
          font-weight: bold;
        }
      }
    }

    .moreButton,
    .hideButton {
      cursor: pointer;
      line-height: $f14px;
      font-size: $f12px;
      color: $mGreen;
      padding: 0 4px;
    }

    .hideButton {
      position: absolute;
      top: 4px;
      left: 110px;
    }
  }
  .noSkills {
    margin-top: -4px;
    margin-right: 80px;
    margin-left: 42px;
  }
  .attending .skillList {
    // margin-top: -20px;
    &.close {
      margin-right: 80px;
    }
  }

  .skillList {
    margin-left: 42px;
    &.dashboard {
      .hideButton {
        left: 100px;
      }
    }
    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }
    &.open {
      &:before {
        content: "Qualifications";
        display: block;
        color: #5a5f65;
        font-size: $f16px;
        font-weight: 600;
        //line-height: $f44px;
        margin-bottom: 6px;
      }
      li {
        padding: 0;
        @include theme-aware("color", "txt");
        font-size: $f14px;
        line-height: $f24px;
        display: block;
      }
    }
    &.close {
      //max-height: 40px;
      overflow: hidden;
      font-size: $f14px;

      &:before {
        display: none;
      }

      li {
        color: #767676;
        vertical-align: top;
        display: inline-block;
        width: auto;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        line-height: $f12px;

        &:after {
          padding-right: 4px;
          content: ",";
          display: inline-block;
          color: #ccc;
        }
        // &:nth-last-child(2):after {
        //   display: none;
        // }
      }
    }
  }
}

.attendance {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;

  > div {
    text-align: left !important;
  }
}

.map {
  .h3 {
    display: none;
    color: $grey;
  }
  padding-bottom: 10px;
}

.center {
  text-align: center;
}

.hide {
  display: none;
}
