@import "../../../assets/sass/_var.scss";
@import "../../../assets/sass/mixin";

.eventHeaderMobile,
.eventHeaderDesktop {
  position: relative;
  width: 100%;
  // background: $emg;
  padding: 20px 20px 2rem 20px;
  color: #fff;
}

.restartModule {
  display: flex;
  position: relative;
  max-width: 350px;
  min-height: 80px;
  margin: 0 auto;
  margin-top: -78px;
  padding: 16px 0 0;

  button {
    margin: 0 auto;
  }

  @media (max-width: $smallScreen) {
    min-height: 70px;
    margin-top: -43px;
    margin-bottom: 8px;
  }
}

.refreshButtonWrapper {
  position: absolute;
  left: 0;
  bottom: -60px;

  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 98;
  }
}

.closeButtonWrapper {
  position: absolute;
  right: 0;
  bottom: -60px;

  &.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 98;
  }
}

.eventHeaderMobile {
  @media (max-width: $smallScreen-max) and (min-width: $tabletPortrait) {
    .refreshButtonWrapper {
      bottom: -28px !important;
    }
    .closeButtonWrapper {
      bottom: -28px !important;
    }
  }
  &.ENDED {
    @media (min-width: $smallScreen) and (max-width: $smallScreen-max) {
      margin-bottom: 36px;
    }
  }

  .refreshButtonWrapper {
    bottom: -36px;
  }

  .closeButtonWrapper {
    bottom: -36px;
  }

  @media (max-width: $tabletPortrait) {
    .refreshButtonWrapper {
      bottom: auto;
      top: 45px;
      left: 0;
      right: auto;
      z-index: 10;
    }
    .closeButtonWrapper {
      bottom: auto;
      top: 45px;
      left: auto;
      right: 0;
      z-index: 10;
    }
  }

  // @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  //   // ONLY IPHONE X -  FOR THE NOTCH
  //   margin-top: -65px;
  //   padding-top: 80px;
  // }
  .eventID {
    position: relative;
    display: flex;
    justify-content: center;

    h3 {
      font-size: $f18px;
      line-height: 1.2rem;
      padding-right: 15px;
      margin: 0;
    }
  }

  .alertType {
    padding: 0.75rem 0 0.75rem;

    h2 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: $f28px;
      margin: 0;
      text-align: center;
    }
  }

  .noTimer {
    text-align: center !important;
  }

  .detailTiles {
    //max-width: 300px;
    margin: 0 auto;
    padding: 0.75rem 0;
    border-top: $border;
    border-bottom: $border;
    display: flex;
    text-align: center;
    justify-content: center;
    max-width: 350px;

    .timer {
      position: relative;
      min-width: 50%;
      padding-right: 20px;
      margin-right: 20px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &::after {
        content: "";
        display: block;
        position: absolute;
        right: 0;
        top: -10%;
        width: 1px;
        height: 120%;
        background-color: rgba(255, 255, 255, 0.61);
      }
    }

    .pagerGroup {
      max-width: 50%;
      width: 100%;
      justify-content: center;
      font-size: $f20px;
      display: flex;
      flex-direction: column;
      text-align: left;

      .agency {
        font-weight: bold;
        padding-bottom: 4px;
      }
    }
  }

  .attendanceCount {
    padding: 1rem 0 0.75rem;
    display: flex;
    justify-content: center;
    max-width: 100%;
    .attendingBlock:not(:empty) {
      padding: 0 0.5rem;
      display: flex;
      overflow: hidden;
      max-width: 33.33%;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      .count {
        font-weight: bold;
        padding-right: 5px;
      }
    }
    @media (max-width: 400px) {
      font-size: 14px;
      width: 100%;
      .attendingBlock:not(:empty) {
        max-width: 40%;
      }
    }
  }
}

.eventHeaderDesktop {
  margin-bottom: 35px;
  .eventID {
    display: flex;
    justify-content: center;

    h3 {
      font-size: $f24px;
      font-weight: bold;
      line-height: 1.2rem;
      padding-right: 15px;
      margin: 0;
    }
  }
  .headerWrapper {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 1600px;
    padding: 1rem 2rem;
    margin: 0 auto;

    &.admin {
      justify-content: center;
    }

    .timer {
      width: 33%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 20px;
    }

    .alertWrapper {
      width: 33%;
      .alertType {
        h2 {
          text-transform: uppercase;
          font-weight: bold;
          font-size: $f30px;
          margin: 0;
          text-align: center;
        }
      }
    }

    .attendanceCount {
      width: 33%;
      font-size: $f20px;
      padding-left: 20px;

      .attendingBlock {
        padding: 0 0.5rem;
        display: inline-block;

        .count {
          font-weight: bold;
          padding-right: 5px;
        }
      }

      @media (max-width: 1310px) {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

// Response controls
.responseControls {
  display: flex;
  position: relative;
  max-width: 350px;
  min-height: 80px;
  margin: 0 auto;
  margin-top: -78px;
  padding: 16px 0 0;
  margin-bottom: 10px;

  @media (max-width: $smallScreen-max) {
    margin-top: -43px;
  }

  .controlsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    z-index: 2;

    .availabilityBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 33%;

      .responseButton {
        cursor: pointer;
      }

      .label {
        font-weight: bold;
        font-size: $f16px;
        @include theme-aware("color", "txt");
        opacity: 0;
        height: 0;
        transition: ease all 0.3s;

        &.active {
          padding-top: 0.5rem;
          opacity: 1;
          height: 25px;
        }
      }
    }
  }

  .responseModalWrapper {
    position: absolute;
    top: 1rem;
    left: 2.1rem;
    right: 2.1rem;
    width: 0;
    opacity: 0;
    transition: ease all 0.5s;
    pointer-events: none;

    &.open {
      width: 80%;
      opacity: 1;
      pointer-events: auto;

      .attendanceResponse {
        .response {
          opacity: 1;
          transition: ease opacity 0.3s;
          transition-delay: 0.3s;
        }
      }

      .closeResponse {
        opacity: 1;
        transition: ease opacity 0.3s;
        transition-delay: 0.3s;
      }
    }

    .attendanceResponse {
      padding-left: 3rem;
      border-radius: 25px;
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 1;

      .response {
        font-weight: bold;
        width: 80%;
        text-align: center;
        opacity: 0;

        .responseMessage {
          opacity: 0;

          &.visible {
            opacity: 1;
            transition: ease opacity 0.3s;
          }
        }
      }
    }
    .changeResponseBlock {
      position: absolute;
      top: 0;
      left: 25%;
      pointer-events: none;
      z-index: 4;

      svg {
        opacity: 0;
        padding: 0.3rem;
        margin: 0 0.5rem;
      }

      &.visible {
        pointer-events: auto;
        svg {
          opacity: 1;
          padding: 0.3rem;
          margin: 0 0.5rem;
          transition: ease opacity 0.5s;

          &:last-child {
            transition-delay: 0.1s;
          }
        }
      }
    }
    .closeResponse {
      opacity: 0;
      position: absolute;
      right: 1rem;
      top: 0;
      height: 52px;
      display: flex;
      z-index: 9;
      cursor: pointer;

      &.showChangeResponse {
        display: flex;
        flex-direction: column;

        .label {
          font-size: 10px;
          font-weight: bold;
        }

        img {
          padding: 0.7rem 0 0;
          width: 18px;
          margin: 0 auto;
        }
      }
      img {
        padding: 1rem 0.65rem;
      }
    }
  }
}

.locationControlsContainer {
  opacity: 0;
  height: 0;
  transition: ease all 0.5s;

  &.open {
    opacity: 1;
    height: 45px;
  }

  .locationControlsWrapper {
    .locationControls {
      display: flex;
      justify-content: space-evenly;
      margin: 0 auto;
      padding: 6px 0;
      border-radius: 5px;
      padding-right: 12px;

      @media screen and (max-width: 1367px) {
        max-width: 400px;
        width: fit-content;
      }
      @media screen and (min-width: $tabletLandscape) {
        margin-bottom: 10px;
        max-width: 400px;
        width: fit-content;
      }
      max-width: 300px;
      padding-left: 1em;

      .heading {
        font-weight: 600;
        display: flex;
        align-items: center;
        width: 40%;
        min-width: 150px;

        img {
          padding-left: 10px;
          padding-right: 5px;
        }
      }

      .locationSelector {
        position: relative;
        //width: 100%;
        cursor: pointer;

        .locationWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          font-size: $f14px;

          .locationIndicator {
            // width: calc(100% - 40px);
            margin-right: 10px;
            padding: 0 0.5rem;
            text-align: center;
            .indicator {
              margin: 0;
              text-align: center;
              cursor: pointer;

              span {
                cursor: pointer;
              }
            }
          }

          .icon {
            width: 30px;
            text-align: center;
            transition: ease transform 0.2s;
            img {
              width: 12px;
            }
            &.rotated {
              transform: rotate(180deg);
            }
          }
        }

        .locationSelection {
          position: absolute;
          top: 25px;
          width: 100px;
          left: -5px;
          padding: 5px 0;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          z-index: 15;
          opacity: 0;
          height: 0;
          transition: ease all 0.2s;
          pointer-events: none;

          &.noTransition {
            display: none;
          }

          &.visible {
            opacity: 1;
            height: 48px;
            pointer-events: auto;

            .content {
              opacity: 1;
              transition: ease opacity 0.2s;
              transition-delay: 0.2s;
              margin-left: 9px;
              span {
                text-align: center;
              }
            }
          }

          .content {
            opacity: 0;
          }
        }
      }

      .ETA {
        font-weight: 600;
        padding: 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          margin-right: 4px;
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .locationControlsContainer {
    .locationControlsWrapper {
      .locationControls {
        .heading {
          min-width: auto;
          width: auto;
          padding-right: 5px;
          // img {
          //   display: none;
          // }
        }
        .locationSelector {
          .locationWrapper {
            .locationIndicator {
              padding: 0;
              // width: calc(100% - 20px);
            }

            .icon {
              width: 20px;
            }
          }
          .locationSelection {
            margin-left: -9px;
          }
        }
      }
    }
  }
}

@media (max-width: 350px) {
  .locationControlsContainer {
    .locationControlsWrapper {
      .locationControls {
        .heading {
          img {
            display: none;
          }
        }
      }
    }
  }
}

// Location tracking
.locationTrackingControls {
  pointer-events: none;
  position: absolute;
  top: -8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: $bg;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  padding: 0 0.75rem 0.5rem;
  width: 100%;
  min-width: 290px;
  max-width: 350px;
  height: 0;
  opacity: 0;
  transition: ease all 0.2s;

  &.visible {
    pointer-events: auto;
    height: auto;
    opacity: 1;
    z-index: 100;

    .content {
      opacity: 1;
      transition: ease opacity 0.2s;
      transition-delay: 0.2s;
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0;

    span {
      @include theme-aware("color", "txt");
    }

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border-bottom: solid 1px $grey;

      span {
        font-weight: bold;
        font-size: $f20px;
      }

      button {
        width: 100px;
        min-height: 30px;
        margin: 10px 0;
      }
    }

    .controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;
      width: 100%;

      span {
        font-weight: bold;
      }
    }

    .disclaimer {
      font-size: $f12px;
    }
  }
}

.closeAlertControls {
  pointer-events: none;
  position: absolute;
  top: 30%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: $bg;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  padding: 0.75rem;
  width: 80%;
  min-width: 290px;
  max-width: 350px;
  height: 0;
  max-height: 65%;
  opacity: 0;
  transition: ease all 0.2s;

  &.visible {
    pointer-events: auto;
    height: auto;
    opacity: 1;
    z-index: 100;
    position: fixed;
    transition: ease opacity 0.2s;
    transition-delay: 0.2s;

    .content {
      opacity: 1;
      transition: ease opacity 0.2s;
      transition-delay: 0.2s;
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    z-index: 99;

    span {
      @include theme-aware("color", "txt");
      font-size: larger;
      font-weight: bold;
      text-align: center;
      line-height: normal;
      margin: 0.25rem 0.75rem;
    }

    .controls {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 0;
      width: 100%;

      button {
        @include theme-aware("border-color", "grey9");
        width: 90%;
        min-height: 50px;
        margin: 10px 0;
        font-size: larger;
      }
    }
  }
  @media (max-height: $extraSmallScreen) {
    top: 25%;
    .content {
      .controls {
        flex-direction: row;
        padding: 5px 15px;
        :first-child {
          margin-right: 15px;
        }
      }
    }
  }
}

.darkMask {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: ease all 0.2s;
  z-index: 98;

  &.visible {
    pointer-events: auto;
    opacity: 1;
    transition: ease opacity 0.2s;
    transition-delay: 0.2s;
  }
}

.etaLoader {
  margin-top: -8px;
  transform: scale(0.7);
  margin-left: -5px;
  width: 45px;
}
