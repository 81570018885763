@import "../../../assets/sass/_var";
@import "../../../assets/sass/_mixin";

.navigationItem {
  position: relative;
  text-decoration: none;
  color: inherit;
  span {
    cursor: pointer;
  }
  &.activeNav {
    font-weight: 600;
  }
}

.logo {
  a {
    text-decoration: none;
  }
}

.MobileNavigation {
  // @media only screen
  // and (device-width : 375px)
  // and (device-height : 812px)
  // and (-webkit-device-pixel-ratio : 3) {
  // // ONLY IPHONE X -  FOR THE NOTCH
  // padding-top: 45px;

  // }
  .PrimaryNavigation,
  .SecondaryNavigation {
    position: relative;
    display: flex;
  }

  .PrimaryNavigation {
    justify-content: space-between;
    height: 50px;

    // @media only screen
    // and (device-width : 375px)
    // and (device-height : 812px)
    // and (-webkit-device-pixel-ratio : 3) {
    // // ONLY IPHONE X -  FOR THE NOTCH
    // margin-top: -45px;
    // padding-top: 45px;

    //  }

    .logo {
      -webkit-text-size-adjust: 100%;
      position: absolute;
      //top: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 140px;
      margin-top: 3px;
      a:any-link {
        text-decoration: none;
      }
      svg {
        width: 140px;
      }
    }
  }

  .SecondaryNavigation {
    align-items: center;
    height: 44px;
    display: flex;

    .navigationItem {
      display: flex;
      width: 33.33%;
      justify-content: center;
      padding: 12.75px 0 12.75px;
      // &:first-child {
      //   justify-content: flex-start;
      // }

      // &:nth-child(3) {
      //   justify-content: flex-end;
      // }

      .content {
        display: flex;

        span {
          padding-right: 12px;
          @media screen and (max-width: 350px) {
            font-size: $f14px;
          }
        }

        .icon {
          position: relative;

          svg {
            width: 16px;
            height: 16px;
          }

          &.newMessage {
            &::after {
              position: absolute;
              top: -5px;
              left: -5px;
              content: "";
              display: block;
              background: linear-gradient(90deg, #00aff0 0.12%, #007dc8 99.69%);
              border-radius: 50%;
              height: 8px;
              width: 8px;
              padding: 2px;
            }
          }
        }
      }

      &.activeNav {
        &::after {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  &.EventNavigation {
    position: relative;
    width: 100%;

    .backButton {
      z-index: 10;
      position: absolute;
      //top: 0;
      left: 0;
      fill: #fff;
    }

    .hamburger {
      position: absolute;
      //top: 0;
      right: 0;
    }
  }

  .backButton {
    margin: 18px 20px;
    width: 23px;
    cursor: pointer;
    fill: $dgrey;
  }

  // Hamburger Icon
  .hamburger {
    z-index: 10;
    margin: 14px 20px;
    min-width: 25px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    overflow: visible;

    &.primary {
      .hamburgerBox {
        .hamburgerInner {
          background-color: $dgrey;

          &:before,
          &:after {
            background-color: $dgrey;
          }
        }
      }
    }

    &.active {
      position: fixed;
      //top: 0;
      right: 0;
      margin: 14px 20px;
      z-index: 100;
      .hamburgerBox {
        .hamburgerInner {
          transform: rotate(45deg);
          transition-delay: rotate 0.12s;
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition: background-color ease 0.5s;
          background-color: $dgrey;

          &:before {
            top: 0;
            transition: top 75ms ease, opacity 75ms ease 0.12s,
              background-color ease 0.5s;
            opacity: 0;
            background-color: $dgrey;
          }

          &:after {
            bottom: 0;
            transition: bottom 75ms ease,
              transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s,
              background-color ease 0.5s;
            transform: rotate(-90deg);
            background-color: $dgrey;
          }
        }
      }
    }

    .hamburgerBox {
      position: relative;
      display: inline-block;
      width: 25px;
      height: 24px;

      .hamburgerInner {
        margin-top: 10px;
        transition-duration: 0.075s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        transition-property: transform;
        background-color: #fff;
        position: absolute;
        width: 25px;
        height: 2.5px;
        display: block;

        &:before,
        &:after {
          width: 25px;
          height: 2.5px;
          background-color: #fff;
          border-radius: 5px;
          position: absolute;
          transition-property: transform;
          transition-duration: 0.15s;
          transition-timing-function: ease;
          content: "";
          display: block;
        }

        &:before {
          transition: top 0.075s 0.12s ease, opacity 0.075s ease,
            background-color ease 0.5s;
          top: -7px;
        }

        &:after {
          transition: bottom 0.075s 0.12s ease,
            transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19),
            background-color ease 0.5s;
          bottom: -7px;
        }
      }
    }
  }

  // Mobile Menu
  .SlideoutNavigation {
    top: 0;

    // @media only screen
    // and (device-width : 375px)
    // and (device-height : 812px)
    // and (-webkit-device-pixel-ratio : 3) {
    // // ONLY IPHONE X -  FOR THE NOTCH
    // top: 45px;
    // }

    width: 100vw;
    position: fixed;
    left: 0;
    z-index: 99;
    transform: translateX(100%);
    transition: transform 0.4s ease-in-out;
    height: 100%; //fix iPhoneX issue

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      transform: translateX(-100%);
      opacity: 0;
      pointer-events: none;
      transition: ease opacity 0.5s;
      position: fixed;
      top: 0;
      left: 0;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    &.open {
      transform: translateX(0%);

      &:before {
        opacity: 1;
      }

      .wrapper {
        opacity: 1;
      }

      .header {
        opacity: 1;
      }

      .navigationWrapper {
        .navigationItem {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            .content {
              opacity: 1;
              transform: translateX(0);
            }
          }
        }
      }
    }

    .wrapper {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: ease opacity 0.3s;
    }

    .header {
      display: flex;
      align-items: center;
      height: 55px;
      opacity: 0;
      transition: ease all 0.5s;
      transition-delay: 0.3s;

      .logout {
        display: flex;
        justify-content: center;
        width: 130px;

        .logoutIcon {
          display: flex;
          align-items: center;
          border-radius: 5px;
          font-size: $f14px;
          font-weight: bold;
          padding: 0.5rem;
          margin: 1rem;
          height: 22px;

          span {
            cursor: pointer;
          }

          img {
            padding-left: 10px;
          }
        }
      }

      .logo {
        a:any-link {
          text-decoration: none;
        }
        -webkit-text-size-adjust: 100%;
        text-align: center;
        width: 100%;
        margin-right: 65px;

        svg {
          width: 140px;
        }
      }
    }

    .navigationWrapper {
      height: calc(100% - 55px);
      padding: 2rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .navigationItem {
        border-bottom: solid 1px $grey;
        margin: 0 20px;
        position: relative;

        &.activeNav {
          &::after {
            display: none;
          }
        }

        &::before {
          height: 104%;
          margin-top: -4px;
          width: 7px;
          display: block;
          content: "";
          position: absolute;
          left: -20px;
          background-color: #01a1e4;
          opacity: 0;
          transition: ease opacity 0.2s;
        }

        &:focus,
        &.activeNav {
          outline: none;
          &::before {
            opacity: 1;
          }
        }

        &:last-child {
          border-bottom: none;
        }

        .icon {
          width: 55px;
        }

        span {
          font-weight: 600;
          font-size: $f18px;
        }

        .content {
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          opacity: 0;
          transition: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1),
            opacity 0.4s ease;
          animation-timing-function: ease-in-out;
        }

        &:nth-child(1) {
          .content {
            transform: translateX(30%);
            transition-delay: 0.1s;
          }
        }

        &:nth-child(2) {
          .content {
            transform: translateX(35%);
            transition-delay: 0.18s;
          }
        }

        &:nth-child(3) {
          .content {
            transform: translateX(40%);
            transition-delay: 0.21s;
          }
        }

        &:nth-child(4) {
          .content {
            transform: translateX(45%);
            transition-delay: 0.25s;
          }
        }

        &:nth-child(5) {
          .content {
            transform: translateX(52%);
            transition-delay: 0.27s;
          }
        }

        &:nth-child(6) {
          .content {
            transform: translateX(60%);
            transition-delay: 0.29s;
          }
        }

        &:nth-child(7) {
          .content {
            transform: translateX(69%);
            transition-delay: 0.3s;
          }
        }
      }
    }
  }
}

.Navigation {
  width: 100%;
  position: relative;

  &.Tablet {
    .topBar {
      height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        -webkit-text-size-adjust: 100%;
        padding: 0 16px;
        svg {
          width: 150px;
        }
      }

      .logout {
        margin: 0 20px;
      }
    }

    .primaryNavigation {
      height: 44px;
      margin: 12px 20px 0;
      position: relative;
    }
  }

  &.Desktop {
    .navigationWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1600px;
      margin: 0 auto;
      position: relative;

      .logo {
        a:any-link {
          text-decoration: none;
        }
        -webkit-text-size-adjust: 100%;
        width: 20%;
      }

      .navigation {
        width: 65%;
      }

      .logout {
        width: 15%;
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;

    .navigationItem {
      font-size: $f18px;
      display: flex;
      padding: 14px 0 14px;

      .chatIcon {
        color: #fff;
        font-weight: bold;
        background: linear-gradient(90deg, #00aff0 0.12%, #007dc8 99.69%);
        border-radius: 50%;
        height: 14px;
        width: 14px;
        padding: 2px;
        text-align: center;
        margin: -10px 0 0 5px;
      }

      .settingsIcon {
        padding-left: 10px;
      }

      a {
        text-decoration: none;

        &:focus,
        &:hover,
        &:link,
        &:visited,
        &:active {
          @include theme-aware("color", "txt");
        }

        &:focus {
          font-weight: bold;
        }
      }
    }
  }

  .logout {
    height: 30px;
    display: flex;
    justify-content: center;

    .logoutIcon {
      display: flex;
      align-items: center;
      border-radius: 5px;
      font-size: $f14px;
      font-weight: bold;
      padding: 0 0.5rem;
      margin: 0;
      border: 0;
      transition: ease all 0.3s;

      span {
        cursor: pointer;
      }

      &:hover {
        background: $bgHover;
      }

      img {
        padding-left: 10px;
      }
    }
  }
}
