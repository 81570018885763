@import "../../assets/sass/_var.scss";
@import "../../assets/sass/mixin";

.loadingContainer {
  height: 563px;
  display: flex;
  align-items: center;
  justify-content: center;
}
form.addSASUser {
  p {
    margin-top: 0;
    font-size: $f16px;
    font-weight: 600;
    line-height: $f30px;
  }
  label {
    font-size: $f12px;
    font-weight: 600;
    line-height: $f17px;
    padding-left: 6px;
  }
  button[type="submit"] {
    background: $mGreen;
    color: #fff;
    font-size: $f14px;
    line-height: $f18px;
    height: 30px;
    min-width: 78px;
    transition: ease all 0.2s;
  }
  input:not([type="radio"]),
  input:not([type="checkbox"]) {
    width: 100%;
    height: 40px;
    border: 1px solid #8c919a;
    border-radius: 5px;
    margin-bottom: 7px;
    @include theme-aware("color", "txt");
    font-size: $f16px;
    line-height: $f20px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .radio {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 35px;
    line-height: 20px;
    flex: auto;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid $grey;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 5px;
      height: 20px;
      width: 20px;
      box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
      background-color: $grey;
      border-radius: 50%;
      transition: ease all 0.1s;
    }

    input:checked ~ .checkmark {
      background-color: $mGreen;
    }
  }
  .separator {
    border-bottom: 1px solid #979797;
    display: block;
    margin-top: 8px;
    margin-bottom: 16px;
  }
  .checkboxHolder {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $grey;
  }
  .checkbox {
    height: 100px;
    padding-left: 0;
    .imgHolder {
      padding: 0;
      height: 86px;
      width: 86px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: #ffffff;
      box-shadow: inset -1px -1px 0 0 $grey, inset 0 -1px 0 0 #e9e9e9,
        2px 2px 0 0 rgba(0, 0, 0, 0.03);
      img {
        height: 60px;
      }
    }
    input {
      position: absolute;
      visibility: hidden;
      height: 0;
      width: 0;

      &:checked + .imgHolder {
        display: flex;
        border: 5px solid #007454; // A border under 5px is not noticeable in dark mode.
        border-radius: 5px;
        background-color: #ffffff;
        box-shadow: inset -1px -1px 0 0 $grey, inset 0 -1px 0 0 #e9e9e9,
          2px 2px 0 0 rgba(0, 0, 0, 0.03);
      }
    }
    .hidden {
      font-size: 0;
    }
  }
}
