@import "../../assets/sass/var";
@import "../../assets/sass/mixin";

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.EventChat_header {
  text-align: left;
  margin-bottom: 12px;
  @media screen and (max-width: $smallScreen-max) {
    height: 45px;
  }

  h2 {
    margin: 0;
    display: inline-block;
    border-bottom: 0 none;
    padding-bottom: 0px;
  }
  div {
    text-align: right;
    float: right;
    button {
      margin: 0px 0px 0px 1px;
    }
  }
}

@media screen and (max-width: $mediumScreen-max) {
  .threadHeader {
    padding: 0 24px !important;
    height: fit-content !important;
    min-height: 52px;
    margin: 10px 0 10px 0;
  }
}

.title {
  display: flex;
  flex-direction: column;

  span {
    font-size: $f16px;
  }

  h2 {
    font-size: $f18px;
    font-weight: 600;
  }
}

.contentWrapper {
  max-height: calc(100% - 52px);
  height: 100%;
  overflow-y: scroll;

  @media screen and (max-width: $mediumScreen-max) {
    padding: 0 32px 32px !important;
    margin-bottom: 0 !important;

    &.eventChatContent {
      padding: 0 16px 32px !important;
    }
  }
}

.messagesHolder {
  overflow-y: auto;
}

.messagesHolder:not(:empty) {
  min-height: 180px;
}

.changeThreadStatus {
  padding-right: 5px;
}

.eventOriginalMessageWrapper {
  display: flex;

  @media screen and (min-width: $tabletLandscape) {
    margin-left: 20px;
  }

  .icon {
    height: 30px;
    width: 30px;
    margin: 28px 11px 0 -25px;

    span {
      height: 30px;
      width: 30px;
    }
  }
}

.chatHeader {
  align-items: center;
  display: flex;
  width: 100%;
  text-align: left;
  padding-bottom: 4px;

  @media screen and (max-width: $smallScreen-max) {
    height: 45px;
  }
  // enter your msg / reply to text above text box
  label {
    flex: auto;
    font-size: $f16px;
    line-height: $f20px;
    text-transform: initial;
    font-weight: normal;
    @include theme-aware("color", "txt");
  }
  // character limit text
  span {
    font-size: $f12px;
    line-height: $f20px;
    padding-top: 3px;
  }
}

.chatMessageHeader {
  align-items: center;
  display: flex;
  width: 100%;
  text-align: left;

  span {
    font-size: $f12px;
    line-height: $f20px;
    font-weight: 400;
    padding-top: 3px;
  }

  .messageCount {
    margin-left: auto;

    .count {
      padding-right: 3px;
    }
  }
}
// Text input for chat
.messageInput_holder {
  padding: 8px 0;
  border-radius: 5px;

  textarea {
    font-family: "Source Sans Pro", sans-serif;
    width: 100%;
    height: 75px;
    border-radius: 5px;
    @include theme-aware("color", "txt");

    font-size: $f16px;
    line-height: $f20px;
    padding: 12px;
    border: 1px solid #8c919a;
  }

  div {
    p {
      width: calc(100% - 108px);
      display: inline-block;
      text-align: left;
      font-size: $f12px;
      line-height: $f15px;

      margin: 0;
      margin-top: 8px;
    }
  }
}

// send text button
.postTxtBtn {
  display: flex;
  width: 100%;
  p {
    flex: auto;
    font-size: $f12px;
    line-height: $f15px;
    margin: 0;
    margin-top: 8px;
  }
}
// Chat reply Subject line styling
.repliesHeading {
  padding: 12px 0 12px 0;
  text-align: left;

  span {
    font-weight: 600;
    text-align: left;
    user-select: text;
  }
}

.RE {
  position: relative;
  font-size: $f12px;
  // border-top: solid 2px $dgrey;
  // border-bottom: solid 2px $dgrey;
  margin-right: 8px;

  &::before {
    content: "";
    background: $dgrey;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  &::after {
    content: "";
    background: $dgrey;
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: -1px;
    left: 0;
  }
}

.originalMessageHeading {
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  padding-top: 12px;

  span {
    font-weight: 600;
    margin-bottom: 12px;
  }
}

.chatMessage {
  text-align: left;
  border-radius: 5px;
  padding: 6px 16px;
  position: relative;
  z-index: 1;
  @media screen and (max-width: $tabletLandscape) {
    margin: 12px 20px 12px 20px;
  }
  @media screen and (min-width: $tabletLandscape) {
    margin: 12px 5px 12px 5px;
  }

  &:last-child {
    border-bottom: none;
  }

  &.originalMessage {
    border-bottom: none;
    padding: 10px 0 0 25px;
    .messageHolder {
      max-width: none;
    }
    .timeStamp {
      width: 100%;
      margin-left: 0;
      display: flex;
      justify-content: space-between;

      .original {
        font-weight: 600;
      }
    }

    &.originalChat {
      padding: 0;
    }
  }
}
.chatMessageTableLayout {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  .iconWrapperCell{
    display: table-cell;
    width: 17%;
    padding-right: 10px;
    vertical-align: top;
  }
}
.chatMessageTimeStamp {
  font-size: 0.75rem;
  line-height: 1.5rem;
}

.chatReplies {
  margin: 0;
  padding: 3px 6px;
}

.chatIconWrapper {
  display: inline-block;
  vertical-align: top;
  height: 30px;
  width: 30px;
  margin-right: 13px;
  margin-left: -3px;
}

.inactive {
  .chatIconWrapper {

    .closedLabel {
      font-size: $f10px;
      color: $dgrey;
      margin-left: -1px;
    }
  }
}
.statusCellSize {
  display: inline-block;
  height: 1.5rem;
  line-height: 1.5rem;
}

.messageHeader {
  display: flex;

  .deleteButton {
    margin: -6px -16px -20px auto;

    svg {
      cursor: pointer;
      margin: 13px 15px;
    }
  }
}

.originalMessageHeading {
  .messageHolder {
    max-width: 100%;
  }
}

.messageHolder {
  display: inline-block;
  max-width: calc(100% - 41px);
  width: 100%;

  &.chatThread {
    .message,
    .subjectLine {
      overflow: visible;
      white-space: inherit;
      text-overflow: inherit;
    }

    .name {
      font-weight: 600;
    }
  }

  .emergency {
    color: #dc1b20 !important;
  }

  .nonEmergency {
    color: #93298d !important;
  }

  &.replyToSender {
    .contentWrapper {
      display: flex;
      width: 100%;
      align-items: center;
      // .content {
      // }
      .buttonSection {
        margin-left: auto;
      }
    }
  }

  .name {
    display: block;
    font-size: $f16px;
    // font-weight: 600;
    line-height: $f18px;
  }

  .message {
    display: block;
    font-size: $f16px;
    line-height: $f20px;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.overflow {
      overflow: inherit;
      white-space: inherit;
      text-overflow: inherit;
    }
  }
  .pagingGroup {
    display: block;
    font-weight: 600;
    font-size: $f14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .subjectLine {
    display: block;
    font-weight: 600;
    font-size: $f16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.EventChat_section {
  &.hasMessages {
    height: 100%;
  }
}
@media (max-width: $tabletPortrait) {
  .EventChat_section {
    padding: 0;

    // .Section-titles {
    h2 {
      width: calc(100% - 65px);
      // line-height: 1.3;
    }
    // }
  }
  .sortButton {
    display: none;
  }
}

.errorTextField {
  border-color: $red !important;
}

.errorMessage {
  color: $red;
  font-size: $f12px;
}

.newChatWrapper {
  .SASChat {
    padding-top: 25px;
  }
  .messageSentWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 446px;

    span {
      font-size: $f18px;
      font-weight: 600;
    }
  }
  .messageInput_holder {
    padding: 8px 0;
    border-radius: 5px;

    input {
      min-height: 35px;
    }

    textarea {
      min-height: 250px;
    }

    textarea,
    input {
      font-family: "Source Sans Pro", sans-serif;
      width: 100%;
      border-radius: 5px;
      @include theme-aware("color", "txt");
      font-size: $f16px;
      line-height: $f20px;
      padding: 12px;
      border: 1px solid #8c919a;
    }

    .messageBody {
      padding-top: 8px;
    }

    .postTxtBtn {
      padding-top: 8px;
      display: flex;
      width: 100%;
      p {
        flex: auto;
        font-size: $f12px;
        line-height: $f15px;
        margin: 0;
        margin-top: 8px;
      }
    }
  }
}

.userSearchHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  min-height: 52px;
  padding-right: 16px;
  width: 100%;

  .content,
  .searchParameters {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .searchParameters:not(:empty) {
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    padding: 4px;
    margin-bottom: -20px;
    flex-wrap: wrap;
    z-index: 9;
    max-height: 120px;
    overflow-y: scroll;
  }

  .content {
    justify-content: space-between;
  }

  .searchParameterItem {
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 8px;
    border: 1px solid $grey;
    border-radius: 5px;
    margin: 4px;
    cursor: pointer;

    svg {
      width: 10px;
      height: 10px;
      margin-left: 8px;
    }
  }
}
