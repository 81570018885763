@import "../../assets/sass/_var.scss";
@import "../../assets/sass/mixin";

// Timer
.timer {
  .stopwatch {
    text-align: center;
    display: flex;

    .stopwatch_display {
      font-size: $f18px;
      letter-spacing: 2.6px;
      padding-bottom: 0;
      font-weight: 600;
      color: #fff;
    }
    .heading {
      padding-top: 0;
      padding-left: 4px;
      font-size: $f18px;
      font-weight: normal;
      color: #fff;
      letter-spacing: 0.05rem;
    }
  }

  @media (min-width: $smallScreen-max) {
    .stopwatch {
      .heading {
        padding-top: 5px;
        font-size: $f20px;
        line-height: $f20px;
        letter-spacing: 0.08rem;
      }

      .stopwatch_display {
        font-size: $f30px;
      }
    }
  }

  @media (max-width: 400px) {
    .stopwatch {
      flex-direction: column;
    }
  }
}

// Alert banner

.AlertBannerWrapper {
  border-bottom: solid 8px rgba(0, 0, 0, 0.3);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99999;
  transform: translateY(-100%);
  // opacity: 0;
  transition: ease-in-out all 0.5s;

  &.open {
    // opacity: 1;
    transform: translateY(0%);
  }

  .alertBanner {
    width: 100%;
    padding: 8px 8px 40px 8px;
    color: #fff;

    &.emergency {
      background: $emg;
    }

    &.nonEmergency {
      background: $nonemg;
    }

    &.admin {
      background: $admin;
    }

    .alertHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: solid 1px #fff;
      margin-bottom: 18px;
      padding: 0 16px 4px 16px;
    }

    .bannerContent {
      max-width: 400px;
      margin: 0 auto;
    }

    .eventID {
      position: relative;
      display: flex;
      justify-content: center;

      h3 {
        font-size: $f18px;
        line-height: 1.2rem;
        padding-right: 15px;
        margin: 0;
      }
    }

    .alertType {
      padding: 0.75rem 0 0.75rem;

      h2 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: $f28px;
        margin: 0;
        text-align: center;
      }
    }

    .detailTiles {
      //max-width: 300px;
      margin: 0 auto;
      padding: 0.75rem 0;
      border-top: $border;
      border-bottom: $border;
      display: flex;
      text-align: center;
      justify-content: space-between;
      max-width: 350px;

      .timer {
        position: relative;
        min-width: 50%;
        padding-right: 20px;
        margin-right: 20px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        &::after {
          content: "";
          display: block;
          position: absolute;
          right: 0;
          top: -10%;
          width: 1px;
          height: 120%;
          background-color: rgba(255, 255, 255, 0.61);
        }
      }

      .pagerGroup {
        max-width: 50%;
        width: 100%;
        justify-content: center;
        font-size: $f20px;
        display: flex;
        flex-direction: column;
        text-align: left;

        .agency {
          font-weight: bold;
          padding-bottom: 4px;
        }
      }
    }

    .attendanceCount {
      padding: 1rem 0 0.75rem;
      display: flex;
      justify-content: center;
      max-width: 100%;
      .attendingBlock:not(:empty) {
        padding: 0 0.5rem;
        display: flex;
        overflow: hidden;
        max-width: 33.33%;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        .count {
          font-weight: bold;
          padding-right: 5px;
        }
      }
    }
  }

  // Response controls
  .responseControls {
    display: flex;
    position: relative;
    max-width: 350px;
    min-height: 80px;
    margin: 0 auto;
    margin-top: -43px;
    padding: 1rem 0;

    .controlsWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      z-index: 2;

      .availabilityBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 33%;

        .responseButton {
          cursor: pointer;
        }

        .label {
          font-weight: bold;
          font-size: $f16px;
          @include theme-aware("color", "txt");
          opacity: 0;
          height: 0;
          transition: ease all 0.3s;

          &.active {
            padding-top: 0.5rem;
            opacity: 1;
            height: 25px;
          }
        }
      }
    }

    .responseModalWrapper {
      position: absolute;
      top: 1rem;
      left: 2.1rem;
      right: 2.1rem;
      width: 0;
      opacity: 0;
      transition: ease all 0.5s;
      pointer-events: none;

      &.open {
        width: 80%;
        opacity: 1;
        pointer-events: auto;

        .attendanceResponse {
          .response {
            opacity: 1;
            transition: ease opacity 0.3s;
            transition-delay: 0.3s;
          }
        }

        .closeResponse {
          opacity: 1;
          transition: ease opacity 0.3s;
          transition-delay: 0.3s;
        }
      }

      .attendanceResponse {
        padding-left: 3rem;
        border-radius: 25px;
        height: 52px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 1;

        .response {
          font-weight: bold;
          width: 80%;
          text-align: center;
          opacity: 0;

          .responseMessage {
            opacity: 0;

            &.visible {
              opacity: 1;
              transition: ease opacity 0.3s;
            }
          }
        }
      }
      .changeResponseBlock {
        position: absolute;
        top: 0;
        left: 25%;
        pointer-events: none;
        z-index: 4;

        svg {
          opacity: 0;
          padding: 0.3rem;
          margin: 0 0.5rem;
        }

        &.visible {
          pointer-events: auto;
          svg {
            opacity: 1;
            padding: 0.3rem;
            margin: 0 0.5rem;
            transition: ease opacity 0.5s;

            &:last-child {
              transition-delay: 0.1s;
            }
          }
        }
      }
      .closeResponse {
        opacity: 0;
        position: absolute;
        right: 1rem;
        top: 0;
        height: 52px;
        display: flex;
        z-index: 9;
        cursor: pointer;

        &.showChangeResponse {
          display: flex;
          flex-direction: column;

          .label {
            font-size: 10px;
            font-weight: bold;
          }

          img {
            padding: 0.7rem 0 0;
            width: 18px;
            margin: 0 auto;
          }
        }
        img {
          padding: 1rem 0.65rem;
        }
      }
    }
  }
}

// Pager Message Styling

.pagerMessage {
  width: 100%;
  position: relative;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #000;

  span {
    cursor: pointer;
  }

  &:first-child {
    margin: 0 0 12px;
  }

  .AttendanceBanner {
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 13px;
    min-height: 25px;

    .icon {
      width: 16px;
      height: 16px;
      margin: 0 16px 0 0;
    }

    .heading {
      font-weight: 600;
      text-transform: uppercase;
      font-size: $f14px;
      color: $green;
    }

    &.ended {
      background-color: $bg;
      width: calc(100% + 2rem);
      margin-left: -1rem;
      padding-left: calc(45px + 1rem);
      border-bottom: none;
    }
  }

  .MessagesBanner {
    width: 100%;
    display: flex;
    align-items: center;
    border-top: solid 1px $grey;
    margin: 10px 0 0 0;
    padding: 5px 0 5px 45px;
    min-height: 25px;

    .content {
      font-weight: 600;
      text-transform: uppercase;
      font-size: $f14px;

      &.newMessage {
        display: flex;
        align-items: center;

        .chatIcon {
          color: #fff;
          font-weight: bold;
          background: linear-gradient(90deg, #00aff0 0.12%, #007dc8 99.69%);
          border-radius: 50%;
          height: 14px;
          width: 14px;
          padding: 2px;
          text-align: center;
          margin-right: 12px;
        }
      }
    }
  }

  .ribbonEnded {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    min-width: 7px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background: $grey;
  }

  .pagerContent {
    width: 100%;
    text-align: left;

    .pagerHeader {
      display: flex;
      flex-direction: column;
      min-height: 30px;

      &.emergency {
        .top {
          background: $emg;

          .alertType {
            color: #fff;
          }
        }

        &.ended {
          .top {
            border-bottom: solid 2px $red;
          }
        }
      }

      &.nonEmergency {
        .top {
          background: $nonemg;
        }

        &.ended {
          .top {
            border-bottom: solid 2px #93328e;
          }
        }
      }

      &.admin {
        .top {
          background: $admin;
        }

        &.ended {
          .top {
            border-bottom: solid 2px $blue;
          }
        }
      }

      &.ended {
        .top {
          background: #fff;

          .alertType {
            .emergency {
              color: #dc1b20;
            }

            .urgent {
              color: $yellow;
            }

            .nonEmergency {
              color: #93328e;
            }

            .admin {
              color: #007faf;
            }

            .update {
              @include theme-aware("color", "txt");
            }
          }

          .timeStamp {
            @include theme-aware("color", "txt");
          }
        }
      }

      .top {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
      }

      .bottom {
        display: flex;
        width: 100%;
      }

      .agencyLogo {
        margin: 10px 0 -15px 5px;
        height: 35px;
        width: 35px;
        padding: 3px;
        text-align: center;
        background: #fff;
        border-radius: 5px;

        img {
          height: 100%;
          width: auto;
        }
      }

      .alertType {
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        margin-left: 10px;
      }
    }

    .pagerGroup {
      font-weight: 600;
      margin: 5px 0;
      margin-left: 50px;
    }

    .timeStamp {
      display: flex;
      justify-content: flex-end;
      font-size: $f14px;
      margin-left: auto;
      font-weight: 600;
      color: #fff;
      margin-right: 16px;

      .time {
        padding-right: 10px;
      }

      span {
        white-space: nowrap;
      }
    }

    .pagerDetails {
      line-height: 1.25rem;
      margin: 6px 25px 0;
    }

    .pagerDetailsUpdate {
      padding-top: 0.5rem;
      line-height: 1.25rem;
    }

    .footerWrapper {
      display: flex;
      justify-content: space-between;
      margin: 10px 16px 16px 25px;

      .timer {
        width: 100%;
        font-size: $f14px;

        .stopwatch {
          display: flex;
          .stopwatch_display {
            font-weight: 600;
            padding-right: 12px;
          }
        }
      }

      .inactiveIcon {
        span {
          border-radius: 5px;
          padding: 3px 6px;
          font-weight: 600;
        }
      }
    }
    .respond {
      width: 100%;
      font-size: $f14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      svg {
        height: 16px;
        width: 16px;
        margin-left: 6px;
      }
    }
  }

  &.desktop {
    .pagerContent {
      display: flex;
      justify-content: space-between;

      .AttendanceBanner {
        margin-right: -1px;
        width: calc(100% - 1px);
      }

      .pagerHeader {
        display: flex;
        // flex-direction: row;
        min-width: 190px;
        padding-bottom: 6px;

        .content {
          display: flex;
        }

        &.emergency {
          background: $emg;

          .alertType {
            color: #fff;
          }

          &.ended {
            .alertType {
              width: 100%;
              > span {
                padding-bottom: 6px;
                border-bottom: solid 2px $red;
              }
            }
          }
        }

        &.nonEmergency {
          background: $nonemg;

          &.ended {
            .alertType {
              width: 100%;
              > span {
                padding-bottom: 6px;
                border-bottom: solid 2px #93328e;
              }
            }
          }
        }

        &.admin {
          background: $admin;

          &.ended {
            .alertType {
              width: 100%;
              > span {
                padding-bottom: 6px;
                border-bottom: solid 2px $blue;
              }
            }
          }
        }

        &.ended {
          background: #fff;

          .alertType {
            .emergency {
              color: #dc1b20;
            }

            .urgent {
              color: $yellow;
            }

            .nonEmergency {
              color: #93328e;
            }

            .admin {
              color: #007faf;
            }

            .update {
              @include theme-aware("color", "txt");
            }
          }

          .timeStamp {
            @include theme-aware("color", "txt");
          }
        }

        .agencyLogo {
          margin: 6px;
        }

        .alertType {
          display: flex;
          flex-direction: column;
          padding-top: 8px;
          margin-left: 6px;

          .timer {
            padding-top: 10px;
            .stopwatch_display {
              font-size: $f18px;
              letter-spacing: 2.6px;
            }
            .heading {
              font-weight: normal;
            }
          }
        }

        .inactiveIcon {
          span {
            border-radius: 5px;
            padding: 3px 6px;
            font-weight: 600;
            text-transform: capitalize;
          }
        }
      }
      .pagerInformation {
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: none;
        padding: 6px 6px 6px 0;

        .pagerGroup {
          margin-top: 0;
          margin-left: 25px;
        }

        .pagerDetails {
          margin-bottom: 0;
          margin: 0 25px;
        }
      }
      .pagerTime {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px 12px 12px;

        .timeStamp {
          @include theme-aware("color", "txt");
          margin-right: 0;
        }
      }
    }
  }
}
