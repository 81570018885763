@import "../../assets/sass/_var.scss";
@import "../../assets/sass/mixin";
.main_content_holder {
  text-align: center;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  @include theme-aware("background-color", "bg3");
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  @media screen and (min-width: $smallTVScreen) {
    font-size: xx-large;
    line-height: 1.25;
  }
}
.col_one_fourth {
  text-align: left;
  width: 20%;
  display: inline-block;
  vertical-align: top;
  padding: 0 15px 20px 15px;
}
.col_three_fourth {
  text-align: left;
  width: 80%;
  display: inline-block;
  vertical-align: top;
}
.col_full_screen {
  text-align: left;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}
.hideComponentForPrint {
  display: none;
}
.showComponentForPrint {
  display: block;
  visibility: hidden;
}
.headerForPrint {
  position: absolute;
  margin-bottom: 6mm;
  margin-top: 10mm;
  left: 0;
  height:auto;
  width: 100%;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: black;
  text-align: center;
  @media print {
    position: static;
    display:block;
    visibility: visible;
    page-break-after: always;
}
}

.noView {
  p {
    text-align: center;
  }
}
.timerLoading {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.fullPageLoader {
  width: 100%;
  height: calc(100vh - 85px);
}

.fullScreenWrapper {
  .turnoutSignalRStatus {
    margin-top: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    font-weight: 600;
  }
  &.fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    .turnoutWrapper {
      height: calc(100vh - 40px);
    }
    .showFullScreenContainer {
      display: block;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      .showNavButton {
        cursor: pointer;
        height: 44px;
        z-index: 9;

        .button {
          display: flex;
          font-size: $f16px;
          font-weight: 600;
          color: $mGreen;
          line-height: 40px;

          @media (min-width: $smallTVScreen) {
            width: 300px !important;
            height: 50px !important;
            font-size: 1.5rem !important;
          }

          span {
            margin-top: 1px;
          }
        }

        img {
          margin-left: 8px;
          width: 15px;
          height: 40px;

          @media screen and (min-width: $smallTVScreen) {
            margin-left: 15px;
            width: 25px;
            height: 40px;
          }
        }
      }
    }
    .buttonContainer {
      display: none;
    }
    .content_holder_no_shadow {
      margin-top: 40px !important;
    }
    .turnoutNav {
      top: -40px;
      height: 40px;
    }
  }
}
.showFullScreenContainer {
  display: none;
}
.turnoutWrapper {
  position: relative;
  height: calc(100vh - 140px);
}
.fullScreenMap {
  .activeEventWrapper {
    position: absolute;
    top: 0;
    left: -35%;
    width: 135%;
  }
}
.turnoutNav {
  position: absolute;
  right: 0;
  top: -56px;
  width: 100%;
  content: "";
  display: block;
  height: 56px;
  box-shadow: inset 0 -10px 10px -5px rgba(0, 0, 0, 0.05);
  .buttonContainer {
    margin-left: auto;
    margin-right: auto;
    // max-width: 1600px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 54px;
    padding: 0 16px;
    @media screen and (min-width: $smallTVScreen) {
      align-items: flex-end;
    }
  }

  .tabs {
    width: 25%;
  }
}

.loadingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
}

.content_holder_no_shadow {
  height: 100%;
  position: relative;
  box-shadow: none !important;
  border-radius: 0px !important;
  margin-top: 56px !important;
  padding-right: 0 !important;

  .noAvailability {
    position: relative;
    height: 100%;
  }
  // Resizing on a big screen
  @media screen and (min-width: $smallTVScreen) {
    margin-top: 80px !important;
  }
}

.pagerEventsColumn {
  position: relative;
  min-width: 385px;
}

.loadingEvents {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10px;
}

.content_wrapper {
  height: calc(100% - 10px);
  display: flex;

  .pagerEvents {
    height: 100%;
    max-height: calc(100% - 10.19rem);
    overflow-y: auto;
    overflow-x: hidden;
  }
  @media print {
    .pagerEvents {
      display: none;
    }
  }

  .turnoutContent {
    height: 100%;
    padding-bottom: 0px;

    .timer {
      justify-content: space-between;
      align-items: center;
      height: 150px;
      margin-bottom: 12px;
      margin-right: 4.3px;
      border-radius: 5px;
      @include theme-aware("border", "turnoutTimerBorder");
    }
  }

  .eventDetailsSection {
    position: relative;
  }
}

.noGroupWrapper {
  width: 100%;
  .noGroupsMessage {
    text-align: center;
  }
}

.activeEventHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 55px;

  .buttons {
    display: flex;
    padding-left: 3px;
  }
  .attendance {
    // width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;

    > div {
      text-align: left !important;
    }
  }
}

.eventDetails {
  width: 100%;
  display: flex;
  min-height: 60%;

  .eventDetailsMap {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    @media (min-width: $smallTVScreen) {
      zoom: 1.8;
    }
    @media print {
      width: 100%;
      display: block;
      height: 100%; // specify a height
      text-align: center;
    
      > * { // select all direct children
        margin: auto; // vertically center direct children
      }
    }
  }

  .eventDetailsAttendance {
    height: 100%;
    max-height: 100%;

    &.eventLockedState {
      margin-top: -55px;
      height: calc(100% + 55px);

      &.locked {
        margin-top: 0;
      }
    }

    .attendance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      > div {
        text-align: left !important;
      }
    }
  }
}

.secondMapPrint {
  width: 100%;
  display: flex;
  min-height: 60%;
  visibility: hidden;
  position: absolute;
  height: 0;
  break-inside: avoid !important;

  .eventDetailsMap {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    @media (min-width: $smallTVScreen) {
      zoom: 1.8;
    }
    @media print {
      width: 207mm;
      display: block;
      height: 100%; // specify a height
      text-align: center;
    
      > * { // select all direct children
        margin: auto; // vertically center direct children
      }
    }
  }

  .eventDetailsAttendance {
    height: 100%;
    max-height: 100%;

    &.eventLockedState {
      margin-top: -55px;
      height: calc(100% + 55px);

      &.locked {
        margin-top: 0;
      }
    }

    .attendance {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;

      > div {
        text-align: left !important;
      }
    }
  }
  @media print {
    position: static;
    visibility: visible;
    break-inside: avoid !important;
  }
}



.pagerEventLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}


.eventContainer {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  @media print {
    display: block;
  }
}

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  @media print {

    display: block;
    flex-direction: column;
    break-inside: avoid;
  }
}

.eventDetailsAttendance {
  width: 100mm;
  overflow-y: auto;
  min-height: 100px;
  border-bottom: solid 1px $grey;
  padding-bottom: 12px;
  border-radius: 5px;
  @media (min-width: $smallTVScreen) {
    width: 20%;
  }
  @media print {
    display: none;
  }

  &.emergency {
    .top {
      background: $emg;

      .alertType {
        color: #fff;
      }
    }

    border: solid 1px $red;
  }

  &.nonEmergency {
    .top {
      background: $nonemg;
    }
    border: solid 1px #93328e;
  }

  &.admin {
    .top {
      background: $admin;
    }
    border: solid 1px #007faf;
  }

  .alertType {
    font-weight: bold;
    color: #fff;
    margin: 0.45rem 0 0.45rem 1rem;
    @media (min-width: $smallTVScreen) {
      margin: 0.15rem 0 0.15rem 1rem;
    }
  }

  .top {
    display: flex;
    width: 100%;
    align-items: center;
    position: sticky;
    z-index: 2;
    top: 0;
  }
}
.eventDetailsAttendancePrint {
  position: absolute;
  width: 100mm;
  overflow-y: auto;
  min-height: 100px;
  border-bottom: solid 1px $grey;
  padding-bottom: 12px;
  border-radius: 5px;
  @media (min-width: $smallTVScreen) {
    width: 20%;
  }
  @media print {
    position: static;
    page-break-before: always;
    display: block;
    visibility: visible;
    width: 100%;
  }

  &.emergency {
    .top {
      background: $emg;

      .alertType {
        color: #fff;
      }
    }

    border: solid 1px $red;
  }

  &.nonEmergency {
    .top {
      background: $nonemg;
    }
    border: solid 1px #93328e;
  }

  &.admin {
    .top {
      background: $admin;
    }
    border: solid 1px #007faf;
  }

  .alertType {
    font-weight: bold;
    color: #fff;
    margin: 0.45rem 0 0.45rem 1rem;
    @media (min-width: $smallTVScreen) {
      margin: 0.15rem 0 0.15rem 1rem;
    }
  }

  .top {
    display: flex;
    width: 100%;
    align-items: center;
    position: sticky;
    z-index: 2;
    top: 0;
  }
}

.eventNavInstructionsPrint {
  position: absolute;
  margin-top: 0.5rem;
  width: 100mm;
  overflow-y: auto;
  min-height: 100px;
  border-bottom: solid 1px $grey;
  padding-bottom: 12px;
  border-radius: 5px;
  page-break-after: always;

  @media (min-width: $smallTVScreen) {
    width: 20%;
  }
  .instructionlist {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
    .instructionRow {
      display: flex;
      align-items: center;
    }
    .spanIcon {
      display: flex;
      align-items: center;
    }
    .spanAction {
      padding-left: 0.5rem;
      font-weight: 600;
    }
    .instructionIcon {
      height: 23px;
      width: 23px;
      font-size: 120%;
      line-height: 1.125rem;
    }
    .instructionText {
      padding-left: 2.1rem;
      order: 3;
      width: 100%;
    }
  }
  @media print {
    position: static;
    display: block;
    visibility: visible;
    width: 100%;
  }

  &.emergency {
    .top {
      background: $emg;

      .alertType {
        padding-right: 1rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #fff;
      }
    }

    border: solid 1px $red;
  }

  &.nonEmergency {
    .alertType {
      padding-right: 1rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .top {
      background: $nonemg;
    }

    border: solid 1px #93328e;
  }

  &.admin {
    .alertType {
      padding-right: 1rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .top {
      background: $admin;
    }

    border: solid 1px #007faf;
  }

  .alertType {
    font-weight: bold;
    color: #fff;
    margin: 0.45rem 0 0.45rem 1rem;

    @media (min-width: $smallTVScreen) {
      margin: 0.15rem 0 0.15rem 1rem;
    }
  }

  .top {
    display: flex;
    width: 100%;
    align-items: center;
    position: sticky;
    z-index: 2;
    top: 0;
  }
}
.activeEventWrapper {
  // height: 100%;
  border: solid 1px transparent;

  &.eventLockedState {
    border-radius: 5px;
    &.locked {
      &.urgent {
        border: solid 1px $yellow;
      }

      //   &.emergency {
      //   border: solid 1px $red;
      // }

      // &.nonEmergency {
      //   border: solid 1px #93328e;
      // }

      // &.admin {
      //   border: solid 1px $blue;
      // }

      &.update {
        border: solid 1px;
        @include theme-aware("border-color", "txt");
      }

      // .eventDetails {
      //   height: calc(100% - 113px);
      // }
    }
  }
}

.pagerHeader {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  border-bottom: solid 1px $grey;
  padding-bottom: 12px;
  border-radius: 5px;
  width: 100%;
  .marginTopForPrint {
    margin-top: 3mm;
  }
  @media print {
    min-height: initial;
  }
  &.emergency {
    border: solid 1px $red;
    .top {
      background: $emg;

      .alertType {
        color: #fff;
      }
    }

    &.ended {
      .top {
        border-bottom: solid 2px $red;
      }
    }
  }

  &.nonEmergency {
    border: solid 1px $lpurple;
    .top {
      background: $nonemg;
    }

    &.ended {
      .top {
        border-bottom: solid 2px $lpurple;
      }
    }
  }

  &.admin {
    border: solid 1px $blue;
    .top {
      background: $admin;
    }

    &.ended {
      .top {
        border-bottom: solid 2px $blue;
      }
    }
  }

  &.ended {
    .top {
      background: transparent;

      .alertType {
        .emergency {
          color: #dc1b20;
        }

        .urgent {
          color: $yellow;
        }

        .nonEmergency {
          color: #93328e;
        }

        .admin {
          color: #007faf;
        }

        .update {
          @include theme-aware("color", "txt");
        }
      }

      .timeStamp {
        @include theme-aware("color", "txt");
      }
    }

    // .timer {
    //   span {
    //     color: #000 !important;
    //   }
    // }
  }

  .top {
    display: flex;
    width: 100%;
    align-items: center;

    .controls {
      margin-left: auto;
      margin-left: auto;
      margin-bottom: -30px;
      padding-right: 16px;
      z-index: 2;
    }
  }

  .bottom {
    display: flex;
    width: 100%;
  }

  .agencyLogo {
    margin: 10px 0 -15px 5px;
    height: 35px;
    width: 35px;
    padding: 3px;
    text-align: center;
    background: #fff;
    border-radius: 5px;

    img {
      height: 100%;
      width: auto;
    }
    @media (min-width: $smallTVScreen) {
      width: 50px;
      height: 50px;
    }
  }

  .alertType {
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    margin-left: 10px;
    margin-right: 16px;
  }

  .timeStamp {
    display: flex;
    justify-content: flex-end;
    font-size: $f14px;
    font-weight: 600;
    color: #fff;
    margin-right: 16px;
    margin-left: auto;

    .time {
      padding-right: 10px;
    }

    span {
      white-space: nowrap;
    }
    @media (min-width: $smallTVScreen) {
      font-size: xx-large;
    }
  }
}

.pagerGroup {
  font-weight: 600;
  margin: 5px 0;
  color: #000;
  margin-left: 50px;
}

.details {
  display: flex;
  align-items: center;

  .pagerDetails {
    padding-right: 16px;
    padding-left: 16px;
    line-height: normal;
    // font-size: 3.5rem;
    font-weight: 600;
    letter-spacing: -0.15rem;
    word-break: break-word;
    @media (min-width: $smallTVScreen) {
      font-size: 5rem;
    }
    @media print {
      line-height: initial;
      font-weight: normal;
      letter-spacing: normal;
    }
  }
}

.footerWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-left: 16px;
  align-items: center;

  .inactiveIcon {
    span {
      border-radius: 5px;
      padding: 3px 6px;
      font-weight: 600;
    }
  }
}

// .timeStamp {
//   display: flex;
//   justify-content: flex-end;
//   font-size: $f14px;
//   font-weight: 600;
//   color: #fff;
//   margin-right: 16px;

//   .time {
//     padding-right: 10px;
//   }

//   span {
//     white-space: nowrap;
//   }
// }

.controlsAndDetails {
  display: flex;
  align-items: center;

  .buttons {
    display: flex;
    min-width: 280px;
  }

  .pagerDetails {
    padding-right: 16px;
    padding-left: 16px;
    line-height: normal;
    word-break: break-all;
    font-size: x-large;
    @media (min-width: $smallTVScreen) {
      font-size: 3.5rem;
      font-weight: bold;
    }
  }
}

.filterControlsWrapper {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;

  .filterControls {
    height: 100%;
    margin: auto;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    padding-top: 0;
    text-align: left;
    background-color: #fff;
    padding: 16px;
    margin-right: 15px;

    p {
      border-bottom: 1px solid $grey;
      font-size: 0.875rem;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 30px;
      margin: 0;
      @media screen and (min-width: $smallTVScreen) {
        font-size: x-large;
      }
    }

    .holder {
      border-bottom: 1px solid $grey;
      display: flex;
      flex-direction: column;
      width: 100%;

      &:first-child {
        border-bottom: 1px solid $grey;
      }

      label {
        margin-top: 12px;
        min-height: 33px;
        white-space: nowrap;
        margin: 7px 10px 5px;
        font-size: 0.875rem;
        @media screen and (min-width: $smallTVScreen) {
          font-size: x-large;
        }
      }
    }

    .filterButtons {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;

      button {
        margin: 0;
        margin-top: 15px;
      }
    }

    .dropDownItem {
      border-bottom: 1px solid $grey;
      min-height: 33px;
      display: flex;
      align-items: center;
      width: 100%;
      border-right: none;

      &:last-child {
        border-bottom: none;
      }

      .dropdownLabel {
        flex: auto;
        margin-top: 8px;
        margin-bottom: 8px;
        line-height: 32px;
        min-width: 90px;
        font-size: 14px;
        font-weight: 600;
        margin-left: 16px;
        white-space: nowrap;
        @media screen and (min-width: $smallTVScreen) {
          font-size: x-large;
        }
      }

      .dropdown {
        padding-top: 0;
        padding-bottom: 0;
        margin: 0;
        min-height: 32px;
        border-radius: 0;
        background-color: transparent;
        border: 0 none;
        font-size: 0.875rem;
        display: block;
        margin-top: 9px;
        margin-bottom: 7px;
        cursor: text;
        width: 100%;
      }

      .multi {
        display: flex;
        width: calc(100% - 1px);
        margin: 4px 0;
        padding: 4px;
        font-size: $f14px;
        //line-height: 26px;
        background-repeat: no-repeat;
        background-position: 95% 50%;

        white-space: nowrap;
        @media screen and (max-width: $tabletPortrait) {
          line-height: 32px;
          font-weight: normal;
        }
        .mobileTxt,
        .desktopTxt {
          display: none;
        }

        > span {
          flex: auto;
        }

        i {
          font-size: 1.25rem;
          @include theme-aware("color", "txt");
        }
      }
    }

    .buttonWrapper {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .holder.disabled {
    pointer-events: none;
    color: #ccc;
  }
}
.header {
  display: flex;
  align-items: center;

  .expandedTimerHeader {
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    min-width: 20%;
    @include theme-aware("border", "turnoutTimerBorder");
    margin-right: 4.3px;
    border-radius: 5px;

    &.underLimit {
      background-color: $turnoutTimerGreen;
    }
    &.underTarget {
      background-color: $turnoutTimerYellow;
    }
    &.underExceeded {
      background-color: $turnoutTimerRed;
    }
    &.overExceeded {
      background-color: inherit;
    }
  }
  @media print {
    min-width: 160mm;
    .expandedTimerHeader {
      display: none;
    }
  }
}

.timerHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  margin-bottom: 12px;
  border-radius: 5px;
  @include theme-aware("border", "turnoutTimerBorder");

  &.underLimit {
    background-color: $turnoutTimerGreen;
  }
  &.underTarget {
    background-color: $turnoutTimerYellow;
  }
  &.underExceeded {
    background-color: $turnoutTimerRed;
  }
  &.overExceeded {
    background-color: inherit;
  }
}


.radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  line-height: 20px;
  flex: auto;
  height: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 5px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 50%;
    transition: ease all 0.1s;
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
  }
}

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  color: #5a5f65;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 5px;
    height: 18px;
    width: 18px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 5px;
    transition: ease all 0.1s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
    box-shadow: 0 0 1px 2px $mGreen, 0 0 1px 3px $mGreen;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}

.visibility_hidden {
  visibility: hidden;
}

.turnoutNavBtns {
  display: flex;

  .settings_btn {
    width: 32px !important;
    height: 32px !important;
    background-position: 50% 50%;
    @media screen and (min-width: $smallTVScreen) {
      width: 100px !important;
      height: 50px !important;
      background-position: 50% 50%;
      background-size: 30%;
    }
  }
  .hideMenu {
    @media screen and (min-width: $smallTVScreen) {
      width: 300px !important;
      height: 50px !important;
      font-size: 1.5rem !important;
    }
  }
  .iconHideNav {
    background-image: url(../../assets/img/icons/hide_nav_icon.svg);
    padding-right: 35px;
    background-position: 80% 50%;
    @media screen and (min-width: $smallTVScreen) {
      padding-right: 50px;
      background-position: 80% 43%;
      background-size: 34px, 0%;
    }
  }
  .iconPrint {
    background-image: url(../../assets/img/icons/print.svg);
    padding-right: 35px;
    background-position: 80% 50%;
    background-size: 18px; 
    @media screen and (min-width: $smallTVScreen) {
      padding-right: 50px;
      background-position: 80% 43%;
      background-size: 18px, 0%;
    }
  }
}
.filter_btn {
  @media screen and (min-width: $smallTVScreen) {
    width: 300px !important;
    height: 50px !important;
    font-size: 1.5rem !important;
  }
}
.iconFilter {
  background-image: url(../../assets/img/icons/Filter.svg);
  padding-right: 30px;
  padding-left: 10px;
  background-size: 15px, 0%;
  background-repeat: no-repeat;
  background-position: 85% 50%;
  font-weight: 600;

  span {
    display: block;
    line-height: 32px;
  }
  @media screen and (min-width: $smallTVScreen) {
    background-size: 20px, 0%;
  }
}
.largebtn {
  font-size: x-large !important;
  font-weight: 600 !important;
  height: 40px !important;
  width: 108px !important;
}


