@import "../../assets/sass/_var.scss";

.loadingContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.availabilityColumn {
  height: 100%;
}

.availabilityWrapper {
  @media (max-width: $tabletPortrait) {
    height: calc(100vh - 94px);
  }

  @media (min-width: $tabletPortrait) and (max-width: $tabletLandscape) {
    height: calc(100vh - 112px);
  }

  @media (min-width: $tabletLandscape) {
    height: calc(100vh - 98px);
    margin: 0 auto;
  }
}

.refreshButtonWrapper button {
  height: 48px;
  margin: 0;
  margin-left: 18px;

  @media screen and (min-width: $smallScreen-max) {
    margin-top: 0;
    background-position: 80% 50%;
    padding-left: 18px !important;
    padding-right: 48px !important;
  }
  @media screen and (max-width: $smallScreen-max) {
    margin: 18px;
    margin-bottom: 0;
    width: calc(100% - 18px - 18px);
  }
}
.AvailHeader {
  position: relative;
  margin-left: 18px;
  padding-top: 30px;
  text-align: left;
  justify-content: space-between;
  @media screen and (min-width: $smallScreen-max) {
    display: flex;
    margin-left: 64px;
    margin-right: 55px;
    padding-bottom: 15px;
    + div {
      margin-left: 18px;
      margin-right: 18px;
      height: calc(100% - 157px);
    }
  }
  @media screen and (max-width: $smallScreen-max) {
    padding-top: 18px;
    margin-left: 0px;
    button {
      position: absolute;
      right: 0px;
      top: 18px;
      margin-top: 0;
    }
  }
  > div.holder {
    width: 100%;
  }
  .HeadingHolder {
    display: flex;
    + p {
      margin: 0;
      max-width: 33.33%;
    }
  }
  p span:not(.Achieved):not(.NotAchieved) {
    &:after {
      content: ", ";
      display: inline-block;
    }
    &:last-child:after {
      display: none;
    }
  }
  h2 {
    flex: 1;
    font-size: $f18px;
    font-weight: 600;
    line-height: $f30px;
    margin: 0;
    min-width: 270px;
    max-width: 40%;
    padding-right: 20px;
  }

  .iconKey {
    @media screen and (min-width: $smallScreen-max) {
      max-width: 55%;
    }
    margin-top: 0;
    margin-top: 4px;
    font-size: $f14px;
    font-weight: 600;
    line-height: $f20px;
    @media screen and (max-width: $smallScreen) {
      span:first-child {
        display: block;
      }
    }
    @media screen and (max-width: $mediumScreen-max) {
      max-width: 100%;
    }
  }
  .Achieved,
  .NotAchieved {
    vertical-align: middle;

    padding-right: 10px;
    &:before {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
    }
  }
  .Achieved {
    @media screen and (min-width: $smallScreen-max) {
      padding-left: 10px;
    }
    color: $green;
    &:before {
      content: url(../../assets/img/icons/Threshold-Met.svg);
    }
  }
  .NotAchieved {
    padding-left: 10px;
    &:before {
      content: url(../../assets/img/icons/Threshold-NotMet.svg);
    }
  }

  @media screen and (min-width: $tabletLandscape-min) {
    + div {
      margin: 0 18px;
      margin: 0 42px;
      display: flex;
      height: calc(100% - 114px);
    }
  }
  .rightAligned {
    display: flex;
    min-width: 305px;
  }
  button {
    margin-top: 0;
    margin-left: 16px;
  }
}
.missingQuail {
  border-top: 1px solid #5a5f65;
  border-bottom: 1px solid #5a5f65;
  padding: 8px;
  margin-bottom: 8px;
  // &.reached {
  //   background-color: #d7df28;
  // }
  img {
    float: left;
    margin-right: 8px;
  }
  p {
    margin: 0;
    span {
      margin-left: 4px;
      //display: inline-block;
      &:after {
        content: ",";
        display: inline-block;
      }
      &:last-child:after {
        display: none;
      }
    }
  }
}
.absolute {
  @media screen and (min-width: $smallScreen-max) {
    width: 210px;
    position: absolute;
    top: 15px;
    left: 74px;
  }
}
.dropdownHolder {
  vertical-align: top;
  // background-color: #fff;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  z-index: 2;
  @media screen and (min-width: $smallScreen-max) {
    .title {
      font-size: $f12px;
      line-height: $f14px;
    }
  }
  @media screen and (max-width: $smallScreen-max) {
    margin: 0 0 18px 0;

    .title {
      font-size: $f14px;
      line-height: $f16px;
    }
  }
  @media screen and (max-width: $smallScreen-max) {
    width: 100%;
  }

  .title {
    display: block;
    padding: 4px 12px;
    border-bottom: 1px solid $grey;
    width: 100%;
  }
}

.mobileDropdownHolder {
  display: flex;
  align-items: center;

  button {
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: 17px;
    min-width: 0;
    min-height: 0;
    padding: 0;
    margin: 0 -2px 18px 18px;
  }
}

.overviewDropdownHolder {
  padding-top: 24px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;

  .title {
    text-align: left;
    font-size: $f14px;
    font-weight: 600;

    margin-right: 16px;
    line-height: $f20px;
    padding: 4px;
    max-width: 150px;
  }
  button {
    height: 48px;
    margin: 0;
    margin-left: 18px;
    padding-left: 18px;
    padding-right: 48px;
    background-position: 80% 50%;
  }
  .contentRight {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .pagingGroupDropdown {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;

    .dropdown {
      width: 50%;
    }
  }
  ol {
    position: inherit !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
  }
}

.DayOverviewBG {
  margin-left: 5%;
  margin-right: 2%;
  padding-top: 7px;
  overflow-x: hidden;

  li {
    margin-right: 3px;
  }
  .row {
    //width: 100%;
    display: flex;
    border-bottom: 1px solid $grey;
    min-height: 58px;
    align-items: center;

    span {
      width: 15%;
      padding-right: 20px;
      display: inline-block;
      text-align: left;
      font-size: $f16px;
      font-weight: 600;
      // line-height: $f30px;
    }
  }
}
.WeekOverviewBG {
  margin-left: 5%;
  margin-right: 2%;
  .row {
    border-bottom: 1px solid #e9e9e9;
    display: flex;
    &:first-child {
      border-top: 0;
    }
  }

  .PagingGroupName {
    text-transform: uppercase;
    display: flex;
    width: 15%;
    align-items: center;

    font-size: $f16px;
    font-weight: 600;
    line-height: $f30px;
  }
}
.updatesDesc {
  margin-top: 5px;
}
.overviewHeader {
  margin-top: -14px;
}
