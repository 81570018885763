// COLORS
$themes: (
  light: (
    // light mode
    bg: #fafafa,
    bg2: #fafafa,
    bg3: #fafafa,
    bg4: #fff !important,
    bg5: #fff,
    bg6: #e9e9e9,
    signalrstatusBg: #6D6F76,
    border: 3px solid #fff,
    turnoutTimerBorder: 1px solid #000,
    borderHover: 3px solid #5c5e65,
    tableBorder: #d3d3d3,
    tileBg: #fff !important,
    // The important is needed on tileBg for ended pager events on home and pager history
    // tileBg: #fff,
    cardBg: #fff,
    cardBg2: #fff,
    cardBg3: #fff,
    chatBg: #fff,
    chatBg2: #f5f5f5,
    primaryNavBg: #fff,
    secondaryNavBg: transparent !important,
    dropdownTxt: #5c5e65 !important,
    txt: #5c5e65,
    txt2: #5a5f65,
    txt3: #000 !important,
    txt4: #000 !important,
    txt5: #5c5e65,
    txt6: #ffffff,
    btnPrimaryBgColour: transparent,
    btnPrimaryBorderColour: #d6d7d9,
    grey1: #e9e9e9,
    grey2: #d6d7d9,
    grey3: #e9e9e9,
    grey4: #f5f5f5,
    grey5: #f5f5f5,
    grey6: #f5f5f5,
    grey7: #e9e9e9,
    grey8: #f5f5f5 !important,
    grey9: #d6d7d9,
    grey10: #f5f5f5,
    green1: #007454,
    hovergreen: #007454 !important,
    hoverred: #dc1b20 !important,
    lgreen: #d7df28,
    lgreen-invert: #000,
    lred: #dc1b20,
    emg1: linear-gradient(90deg, #dc1b20 0%, #a51e1e 100%),
    nonemg1: linear-gradient(90deg, #93298d 0%, #6f286b 100%),
    admin1: linear-gradient(90deg, #007faf 0%, #056b91 100%),
    ended1: linear-gradient(90deg, #5a5f65 0%, #5c5e65 100%),
    emg2: linear-gradient(90deg, #dc1b20 0%, #a51e1e 100%),
    nonemg2: linear-gradient(90deg, #93298d 0%, #6f286b 100%),
    admin2: linear-gradient(90deg, #007faf 0%, #056b91 100%),
    ended2: linear-gradient(90deg, #5a5f65 0%, #5c5e65 100%),
    emg3: #fff,
    nonemg3: #fff,
    admin3: #fff,
    ended3: #fff,
    emg4: hsla(0, 0%, 100%, 0.61),
    nonemg4: hsla(0, 0%, 100%, 0.61),
    admin4: hsla(0, 0%, 100%, 0.61),
    ended4: hsla(0, 0%, 100%, 0.61),
    pink1: #e9e9e9,
    blue1: #e9e9e9,
    invisibleBorder: 0px,
    invisibleBorder1: 0px,
    white2white: #fff!important,
    grey2dgrey: #e9e9e9,
    lightHighlight: rgba(235, 235, 235, 0.24),
    boxShadow1: 0 0 0 1px rgba(34, 36, 38, 0.15) inset,
    disabledTxt: #d6d7d9,
    disabledColor: #e9e9e9,
    dropdownShadow: (0 0 1px 2px #fff, 0 0 1px 3px #8c919a) !important,
    dropdownBg: #f5f5f5 !important,
    refreshButton: transparent !important,
    refreshButtonBorder: #fff !important,
    refreshIcon: url(../../assets/img/icons/refresh.svg),
    hyperlink: #0000ee,
    unverified: #fff,
  ),
  dark: (
    // dark mode
    bg: #0f0f0f,
    bg2: #1c1c1d !important,
    bg3: #0f0f0f,
    bg4: #2a2a2c !important,
    bg5: #0f0f0f,
    bg6: #0f0f0f,
    signalrstatusBg: #ffffff,
    border: 3px solid #1c1c1d,
    borderHover: 3px solid #ababab,
    turnoutTimerBorder: 1px solid #fff,
    tableBorder: #2a2a2c,
    tileBg: #1c1c1d !important,
    cardBg: #1c1c1d,
    cardBg2: #000,
    cardBg3: #2a2a2c,
    chatBg: #000,
    chatBg2: #000,
    primaryNavBg: #000,
    secondaryNavBg: #1c1c1d !important,
    dropdownTxt: #fff !important,
    txt: #ababab,
    txt2: #fff,
    txt3: #ababab !important,
    txt4: #fff !important,
    txt5: #ababab,
    txt6: #000000,
    btnPrimaryBgColour: #0f0f0f,
    btnPrimaryBorderColour: #007454,
    grey1: #2a2a2c,
    grey2: #fff,
    grey3: transparent,
    grey4: #2a2a2c,
    grey5: transparent,
    grey6: #2a2a2c,
    grey7: #000,
    grey8: #000 !important,
    grey9: #ababab,
    grey10: #1c1c1d,
    green1: #fff,
    hovergreen: #007454!important,
    hoverred: #dc1b20 !important,
    lgreen: #000,
    lgreen-invert: #d7df28,
    lred: #000,
    emg1: #000,
    nonemg1: #000,
    admin1: #000,
    ended1: #000,
    emg2: linear-gradient(to bottom, #dc1b20, rgba(0, 0, 0, 0)) 1 100%,
    nonemg2: linear-gradient(to bottom, #93298d, rgba(0, 0, 0, 0)) 1 100%,
    admin2: linear-gradient(to bottom, #007faf, rgba(0, 0, 0, 0)) 1 100%,
    ended2: linear-gradient(to bottom, #5a5f65, rgba(0, 0, 0, 0)) 1 100%,
    emg3: #dc1b20,
    nonemg3: #93298d,
    admin3: #007faf,
    ended3: #5a5f65,
    emg4: #dc1b20,
    nonemg4: #93298d,
    admin4: #007faf,
    ended4: #5a5f65,
    pink1: #e11c78,
    blue1: #007faf,
    invisibleBorder: 5px,
    invisibleBorder1: 1px,
    white2white: #fff!important,
    grey2dgrey: #5a5f65,
    lightHighlight: #0f0f0f,
    boxShadow1: 0 0 0 1px rgba(255, 255, 255, 0.15) inset,
    disabledTxt: #5c5e65,
    disabledColor: #8c919a,
    dropdownShadow: (0 0 1px 2px #000, 0 0 1px 3px #8c919a) !important,
    dropdownBg: #2a2a2c,
    refreshButton: #2a2a2c !important,
    refreshButtonBorder: #5a5f65 !important,
    refreshIcon: url(../../assets/img/icons/refresh-white.svg),
    hyperlink: #00b6ee,
    unverified: #dc1b20,
  ),
);

$txt: #5c5e65;
$bg: #fafafa; // light mode
$bgHover: #e0e0e0;
$txt: #5c5e65; // EMV Dark Grey // font color grey // AA on white BG
$mGreen: #007454; // link green

// response btn
$green: #538316; // attending green // AA white text
$yellow: #feb913; // other yellow // AA txt grey for large text ONLY!
$red: #dc1b20; // unavailable red // AA white for large text ONLY!
// other btn
$lgreen: #d7df28; // available status green
$pink: #e11c78; // to event pink
$blue: #007faf; // to station blue
$purple: #5c358e; // youon map purple
$lgrey: #f5f5f5; // light grey background
$grey: #e9e9e9; // unverified btn
$dgreen: #007454; // toggles, buttons, checkboxes, etc
$dgrey: #5a5f65; // button border
$bgrey: #d6d7d9; // secondary button border
// event header gradient
$lred: #dc1b20; // light red
$dred: #a51e1e; // dark red
$lpurple: #93298d; // light purple
$dpurple: #6f286b; // dark purple
$lblue: #01a1e4; // light purple
$dblue: #007faf; // dark purple
$emg: linear-gradient(
  90deg,
  #dc1b20 0%,
  #a51e1e 100%
); // red emergency event banner
$nonemg: linear-gradient(
  90deg,
  #93298d 0%,
  #6f286b 100%
); // purple non-emergency event banner
$admin: linear-gradient(
  90deg,
  #007faf 0%,
  #056b91 100%
); // blue admin event banner
$ended: linear-gradient(
  90deg,
  #5a5f65 0%,
  #5c5e65 100%
); // blue admin event banner

$turnoutTimerGreen: #238823;
$turnoutTimerYellow: #FFBF00;
$turnoutTimerRed: #D2222D;

// borders
$borderMain: solid 1px #fff;
$border: solid 1px rgba(255, 255, 255, 0.61);

// default
$shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
$radius: 5px;

// z-index
$backTop: 10;

//FONT SIZES
$f6px: 0.25rem;
$f8px: 0.5rem;
$f9px: 0.5625rem;
$f10px: 0.625rem;
$f11px: 0.6875rem;
$f12px: 0.75rem;
$f13px: 0.8125rem;
$f14px: 0.875rem;
$f15px: 0.9375rem;
$f16px: 1rem;
$f17px: 1.0625rem;
$f18px: 1.125rem;
$f19px: 1.1875rem;
$f20px: 1.25rem;
$f21px: 1.3125rem;
$f22px: 1.375rem;
$f23px: 1.4375rem;
$f24px: 1.5rem;
$f25px: 1.5625rem;
$f26px: 1.625rem;
$f27px: 1.6875rem;
$f28px: 1.75rem;
$f29px: 1.8125rem;
$f30px: 1.875rem;
$f31px: 1.9375rem;
$f32px: 2rem;
$f33px: 2.0625rem;
$f34px: 2.125rem;
$f35px: 2.1875rem;
$f36px: 2.25rem;
$f37px: 2.3125rem;
$f38px: 2.375rem;
$f39px: 2.4375rem;
$f40px: 2.5rem;
$f41px: 2.5625rem;
$f42px: 2.625rem;
$f43px: 2.6875rem;
$f44px: 2.75rem;
$f45px: 2.8125rem;
$f46px: 2.875rem;
$f47px: 2.9375rem;
$f48px: 3rem;
$f49px: 3.0625rem;

// Screen SIZES
$tinyScreen: 365px;
$extraSmallScreen: 425px;
$smallScreen: 767px;
$tabletPortrait: 768px;
$smallScreen-max: 1023px;
$tabletLandscape: 1024px;
$mediumScreen-max: 1025px;
$largeScreen: 1300px;
$extraLargeScreen: 1600px;
$smallTVScreen: 3072px;
$largeTVScreen: 7200px;
$tabletLandscape-min: 1024px;
$tabletLandscape-max: 1299px;
:export {
  $extraSmallScreen: $extraSmallScreen;
  smallScreen: $smallScreen;
  mediumScreen: $smallScreen-max;
  largeScreen: $largeScreen;
  extraLargeScreen: $extraLargeScreen;
}
