@import "../../assets/sass/var";

.ErrorBanner {
  position: fixed;
  top: 0;
  left: 0;
  background: $dgrey;
  pointer-events: none;
  padding: 0 20px 0;
  width: 100%;
  height: 0;
  // opacity: 0;
  transition: ease all 0.4s;
  z-index: 99999;

  &.visible,
  &.open {
    padding-bottom: 4px;
    pointer-events: auto;
    height: auto;
    opacity: 1;
    z-index: 100;

    &.onTop {
      z-index: 1000;
    }

    .content {
      opacity: 1;
      transition: ease opacity 0.2s;
      transition-delay: 0.4s;
    }
  }

  .content {
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    opacity: 0;

    .errorMessage {
      width: 100%;
      padding: 2px 0;
      color: #fff;

      .bold {
        color: #fff;
        font-weight: 600;
        padding-right: 8px;
      }
    }
  }
}
