@import "../../assets/sass/var";

.availabilityDashboard {
  table {
    border-collapse: separate;
    border: none;
    border-spacing: 0;
    border-top: 0;
    height: 1px;
    table-layout: fixed;
    // white-space: nowrap;
    width: 100%;

    th,
    td {
      border-top: 1px solid;
      border-left: 1px solid;
      @include theme-aware("border-color", "tableBorder");
      vertical-align: top;
      width: 50px;
    }

    th {
      @include theme-aware("background-color", "grey10");
      padding: 0;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    td {
      @include theme-aware("background-color", "bg3");
      height: 100%;
      padding: 0;
    }

    thead,
    tbody {
      th {
        position: sticky;
        left: 0;
        z-index: 5;

        &:first-child {
          position: sticky;
          left: 0;
          width: 210px;
          z-index: 2;
          border-right: 1px solid;
          @include theme-aware("border-color", "tableBorder");

          @media only screen and (max-width: $extraSmallScreen) {
            width: 130px;
          }
        }
      }
    }

    thead {
      th {
        &:first-child {
          z-index: 9;
          border-bottom: 1px solid;

          ul {
            li {
              font-weight: 600;
              text-align: right;

              &:last-child {
                border-bottom: 0;
                font-size: 14px;
              }

              @media only screen and (max-width: $extraSmallScreen) {
                font-size: 14px;
              }
            }
          }
        }

        ul {
          position: relative;

          li {
            border-bottom: 1px solid;
            @include theme-aware("border-color", "tableBorder");
            @include theme-aware("color", "txt");
            font-size: 16px;
            padding: 5px;
            text-align: center;

            &.-required {
              @include theme-aware("background-color", "grey10");
              border-bottom: 0;
              font-size: 12px;
            }
          }
        }
      }
    }

    tbody {
      th {
        background: white;
        padding: 5px 10px;
      }
    }

    p {
      margin: 0;
    }
  }

  &__header {
    align-items: center;
    @include theme-aware("background-color", "bg6");
    border-left: 2px solid #d3d3d3;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    padding: 10px 15px;
    border-radius: 5px 5px 0 0;

    @media only screen and (max-width: $smallScreen-max) {
      @include theme-aware("background-color", "grey1");
    }

    @media only screen and (min-width: $tabletLandscape) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media only screen and (min-width: 1400px) {
      margin-left: 209px;
    }

    & > div {
      margin-bottom: 20px;
      width: 100%;

      @media only screen and (min-width: $tabletLandscape) {
        width: 50%;
      }

      @media only screen and (min-width: 1400px) {
        margin-bottom: 10px;
        width: 33.333333%;
      }

      &:last-child {
        margin-bottom: 10px;

        @media only screen and (min-width: $tabletLandscape) {
          width: 100%;
        }

        @media only screen and (min-width: 1400px) {
          width: 33.333333%;
        }
      }
    }

    .-date {
      font-size: 18px;
    }

    .-radio {
      text-transform: uppercase;

      ul {
        display: flex;

        li {
          margin-right: 20px;

          &:last-child {
            margin-right: 10px;
          }
        }
      }
    }

    .-dropdown {
      align-items: center;
      display: flex;
      text-transform: uppercase;

      span {
        margin-right: 20px;
      }
    }
  }

  &__container {
    max-height: calc(100vh - 335px);
    min-height: 300px;
    overflow: auto;
    width: 100%;

    @media screen and (min-width: $tabletLandscape) and (max-width: 1400px) {
      max-height: calc(100vh - 480px);
    }
    @media screen and (min-width: 1400px) {
      max-height: calc(100vh - 400px);
    }

    &:focus {
      box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
      outline: 0;
    }
  }

  &__status {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 100%;

    li {
      flex: 1 1 auto;
    }

    .-unavailable {
      background-color: #878787;
    }
    .-emergency {
      background-color: #dc1b20;
    }
    .-non-emergency {
      background-color: #93298d;
    }
    .-admin {
      background-color: #007faf;
    }
    .-notLocal {
      background-color: #d7df28;
    }
  }

  &__table-header {
    @include theme-aware("color", "txt");

    &.-time {
      font-weight: 500;
      text-align: center;
    }

    .-achieved {
      background-color: #d7e027;
    }
  }

  &__name {
    @include theme-aware("color", "txt");
    font-size: 16px;
    font-weight: 600;
    text-align: right;
  }

  &__qualification {
    @include theme-aware("color", "txt");
    text-align: right;
  }

  &__icon {
    position: relative;

    .icon {
      background: url(../../assets/img/icons/no-notification.svg) no-repeat;
      background-size: cover;
      height: 16px;
      left: 0;
      position: absolute;
      top: 0;
      width: 16px;

      .theme-dark & {
        background: url(../../assets/img/icons/no-notification-white.svg)
          no-repeat;
        background-size: cover;
        height: 16px;
        left: 0;
        position: absolute;
        top: 0;
        width: 16px;
      }
    }
  }

  &__btn {
    align-items: center;
    border: 0;
    display: flex;
    justify-content: flex-end;
    @include theme-aware("color", "txt");
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    text-align: right;
    width: 100%;

    &.-expanded {
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: #007454 transparent transparent transparent;
        margin-left: 10px;
      }
    }

    &.-collapsed {
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent #007454 transparent;
        margin-left: 10px;
      }
    }
  }

  // TODO: Merge CSS once 3314 is merged
  &__legend {
    align-items: flex-start;
    background-color: #e7e7e7;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    border-radius: 0 0 3px 3px;

    .theme-dark & {
      background-color: #1c1c1d;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;

      li {
        align-items: center;
        color: #444c63;
        display: flex;
        font-size: 10px;
        font-weight: 600;
        line-height: 1;
        margin-right: 10px;
        margin-bottom: 5px;

        &:first-child {
          margin-right: auto;

          &:before {
            display: none;
          }

          i {
            position: static !important;
            margin-right: 10px !important;
          }

          .availabilityDashboard__icon {
            align-items: center;
            display: flex;
            position: relative;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        .theme-dark & {
          color: #adadad;
        }

        &:before {
          background-color: #878787;
          height: 4px;
          content: "";
          margin-right: 5px;
          width: 16px;
        }

        &.-emergency {
          &:before {
            background-color: #dc1b20;
          }
        }

        &.-non-emergency {
          &:before {
            background-color: #93298d;
          }
        }

        &.-admin {
          &:before {
            background-color: #007faf;
          }
        }

        &.-notLocal {
          &:before {
            background-color: #d7e027;
          }
        }
      }
    }
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  top: 0;
  left: 0;
}

.dashboardLoadingContainer {
  display: flex;
  flex-direction: row;
  height: 30vh;
  border: 1px solid;
  @include theme-aware("border-color", "tableBorder");
  overflow: hidden;
}

.loadingSidebar {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  width: 210px;
  z-index: 2;
  border-right: 2px solid;
  @include theme-aware("border-color", "tableBorder");
  @include theme-aware("background-color", "grey10");
  height: 30vh;

  @media only screen and (max-width: $extraSmallScreen) {
    width: 156px;
  }
}

.loadingHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 2;
  border-bottom: 2px solid;
  @include theme-aware("border-color", "tableBorder");
  @include theme-aware("background-color", "grey10");
  height: 29px;
  margin-bottom: 11vh;
}

.loadingIcon {
  align-items: center;
  display: flex;
  width: 79%;
  flex-direction: column;
  @include theme-aware("background-color", "bg3");
}

.noFilterContainer {
  display: flex;
  flex-direction: row;
  height: 10vh;
  border: 1px solid;
  @include theme-aware("border-color", "tableBorder");
  overflow: hidden;
}

.noFilter {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 79%;
  flex-direction: column;
  @include theme-aware("background-color", "bg3");
}
