@import "../../../assets/sass/_var.scss";
@import "../../../assets/sass/mixin";

.reportWrapper {
  height: calc(100vh - 82px);
}
.buttonContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: $extraLargeScreen;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 54px;
  padding: 0 16px;
}

.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DownloadReportPage {
  padding: 70px;
  height: calc(100vh - 150px);
  padding: 70px;
  text-align: center;
  p {
    text-align: center;
  }
}
.tabs {
  text-align: left;
  margin-left: 20px;
  a.link {
    display: inline-block;
    font-size: $f18px;
    letter-spacing: 0;
    line-height: $f24px;
    text-align: center;
    padding: 13px;
    min-height: 52px;
    text-decoration: none;
  }
}
.error {
  box-shadow: 0 0 1px 2px #000, 0 0 1px 3px $red !important;

  border: solid 1px $red !important;
  border-color: $red !important;
}
.disclaimer {
  color: $red;
  animation-fill-mode: forwards;
  animation-name: fade;
}
.disclaimerUnregisteredUsers {
  color: $red;
  animation-fill-mode: forwards;
  animation-name: fade;
  margin-left: 3rem;
  display: flex;
}
.errorWrapper {
  display: flex;
  flex-direction: column;
  .errorMessageBlock {
    width: 100%;

    span {
      color: $red;
    }
  }
}
.validationErrorMessage {
  width: 100%;
  text-align: center;
  padding: 12px 0;
}
.disabled {
  background-color: #fff;
  float: right;
  // margin-top: 12px;
}

.box_default {
  max-width: 1600px;
  margin: 0 auto;
  height: calc(100% - 66px);
  overflow-y: scroll;

  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  @include theme-aware("background-color", "cardBg");
  padding: 16px 70px;
  margin-bottom: 12px;
}

.sectionTitle {
  font-size: $f20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: $f27px;

  h2 {
    margin-bottom: 0px;
  }
}
.title,
.subTitle {
  padding: 8px 0;
  vertical-align: top;
  display: inline-block;
  width: 360px;
  font-size: $f16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: $f27px;
}
.subTitle {
  min-width: 130px;
  width: 130px;
}

.row,
.subRow {
  border-bottom: 1px solid #e9e9e9;
  &.noUnderline {
    border-bottom: none;
  }
}
.row {
  padding: 14px 0;
  > .body {
    display: inline-block;
    width: calc(100% - 360px);
  }
}

.subBodyUnregisteredUsers {
  padding: 8px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: calc(100% - 130px);
}

.subBody {
  padding: 8px 0;
  display: flex;
  justify-content: center;
  width: calc(100% - 130px);

  &.dropdownHolder {
    display: inline-block;
    width: 100%;
  }
  .firstDate {
    margin-right: 15px !important;
  }

  input {
    min-width: 155px !important;
    padding-right: 8px !important;
  }
}

.body,
.subBody {
  max-width: 800px;

  button {
    &:first-child {
      margin-right: 10px;
      margin-left: 0px;
    }
  }
}

.bodyWrap {
  width: 100% !important;
  max-width: 100% !important;
  margin: 20px 0px 10px 0px;
}

.subBodyWrap {
  width: 100% !important;
  max-width: 100%;
}

.subBodyWrap > * {
  margin-right: 20px !important;
  margin-left: 0px !important;

  &:last-child {
    margin-right: 0px !important;
  }
}

.pagingGroupDropdown {
  max-width: 670px !important;
}
.disabledDropdown {
  width: 100%;
  cursor: not-allowed;
  .pagingGroupDropdown {
    pointer-events: none;
  }
}

.pagingDropdown,
.agencyDropdown {
  display: inline-block;
}

.reportRowItem {
  max-width: 200px !important;
}

.reportRowDetails {
  margin-bottom: 7px;
  &:last-child {
    margin-bottom: 0px;
  }
}
.reportDelete {
  float: right;
}
.currentSchedules {
  margin: 20px 0px;
}

.currentSchedulesRow {
  border-top: 1px solid #e9e9e9;
  padding-top: 10px;
  .title {
    width: 100%;
  }
}

.scheduleRow {
  .close {
    border-bottom: 0px;
  }
}

.searchbox {
  border: 1px solid #8c919a;
  border-radius: 5px;
  color: #5a5f65;
  font-size: $f16px;
  line-height: $f30px;
  padding: 5px 8px;
  width: 100%;
}
.body {
  label {
    flex: auto;
    justify-content: space-between;
    display: inline-block;

    font-size: $f16px;
    margin-right: 40px;
  }
}
.subBody {
  label {
    flex: auto;
    justify-content: space-between;
    display: inline-block;

    font-size: $f16px;
    font-weight: normal;
  }
  .spacer {
    display: inline-block;
    padding: 12px 20px;
    text-align: center;
  }
  input[type="date"] {
    margin: 0px;
    width: 100%;
    font-size: $f16px;
    text-align: center;
    border: 1px solid #767676;
  }
  button {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.row.multi {
  > .body {
    width: 100%;
  }
  .subRow {
    display: flex;
    &.noUnderline {
      border-bottom: 0 none;
    }
  }

  .title {
    width: 230px;
  }
}

.errorMsg {
  padding-left: 130px;
  span {
    margin-right: 8px;
  }
}

.radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  flex: auto;
  line-height: $f30px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    //top: 0;
    top: 6px;
    left: 5px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 50%;
    transition: ease all 0.1s;
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
  }
}

.generateButtons {
  margin: 10px 0px 0px 0px;
}

.noLineHeight {
  label {
    &:last-child {
      line-height: 1;
    }
  }
}

.invalid_ASN_error {
  color: #dc1b20;
  margin-top: 5px;
  margin-left: 360px;
}

.asnInput {
  max-width: 320px !important;
  border-radius: 5px;
  margin-right: 15px;
  height: 40px;
  padding-bottom: 9.5px;
  padding-left: 14px;
  padding-right: 29.4px;
  padding-top: 9.5px;
  border: 0px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
}
