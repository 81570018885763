@import "../../assets/sass/_var.scss";
@import "../../assets/sass/mixin";

.labelDivStyles {
  // map btn holder
  height: auto;
  position: absolute;
  justify-content: space-between;
  z-index: 800;
  bottom: 0;
  left: 10px;
  right: 10px;
  float: left;
  .label {
    transition: all 0.3s ease;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    display: inline-block;
    padding: 10px 0;
    height: 44px;
    &:first-child {
      margin-left: 0px;
    }

    span {
      //display: flex;
      height: 33px;
      width: auto;
      background: white;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
      border-radius: 5px;
      line-height: inherit;
    }
  }

  .labelStyles {
    // map btn style
    span {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
      border-radius: 5px;
      font-weight: bold;
      font-size: $f14px;
      width: auto;
      margin: 0;
      padding: 5px;
      cursor: pointer;
      transition: all 0.3s ease;
      @include theme-aware("color", "txt");
      line-height: 22px;
      display: flex;
    }
  }
  @media print {
    right:auto;
    position: absolute;
    float: left;
    left: 10px;
  }
}

.fsStyles {
  position: relative;
  z-index: 1000;
  max-width: 57px;
  top: 90px;

  button {
    margin-left: 11px;
    margin-top: 0;
    margin-bottom: 10px;
    background: white;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.28);
    border-radius: 5px;
    min-width: 34px;
    height: 34px;
    padding: 0px;
    text-align: center;
    vertical-align: middle;
    img {
      vertical-align: middle;
      line-height: 34px;
    }
    &:hover {
      background-color: $grey;
    }
  }
}
@media print {
  .fsStyles {
    display: none;
  }
}

.resetStyles {
  padding: 10px 10px;

  span {
    line-height: 40px;
    min-width: 34px;
    height: 33px;
    top: 158px;
  }
}

.searchStyles {
  padding: 10px 10px;

  span {
    line-height: 40px;
    min-width: 34px;
    height: 33px;
    top: 70px;
  }
}

.leaflet-marker-icon span {
  z-index: 1000;
}

.icon {
  height: 22px;
  width: 22px;
  margin-right: 5px;
}

//turnout button expand/collapse
.turnoutBtn {
  text-align: center;
  padding-top: 5px !important;
  svg {
    fill: #5c5e65;
    width: 20px;
    height: 20px;
  }
}