@import "../../assets/sass/_var.scss";
@import "../../assets/sass/_mixin.scss";

.section_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  span {
    font-weight: 600;
  }

  div {
    width: calc(100% - 60px);
  }
}

.settingsMainContentWrapper {
  @media (max-width: $tabletPortrait) {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 94px);
  }

  @media (min-width: $tabletPortrait) and (max-width: $smallScreen-max) {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 112px);
  }

  @media (min-width: $smallScreen-max) {
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 98px);
  }

  .adminColumn {
    position: relative;
    // overflow: auto;

    @media (min-width: $tabletLandscape) {
      height: 100%;
      overflow-y: hidden;
    }
    .scrollableContent {
      @media (min-width: $tabletLandscape) {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 55px);
      }
    }
  }
}

.agencyTitles {
  display: flex;
  align-items: center;
  border-bottom: solid 1px $grey;
  font-weight: bold;
  letter-spacing: 0;
  text-align: left;
  min-height: 44px;
  display: flex;
  align-items: center;

  img {
    height: 30px;
  }

  h3 {
    margin-bottom: 0;
    padding-left: 8px;
  }
}

.asn_input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: solid 1px $grey;
  padding: 4px 0;

  .content {
    display: flex;
    flex-direction: column;
    width: 50%;

    .title {
      font-weight: 600;
      font-size: $f16px;
    }
    span {
      font-size: $f14px;
    }

    input {
      margin: 4px 0;
      padding: 4px 8px;
      box-sizing: border-box;
      height: 25px;
      width: 100%;
      border: 1px solid #8c919a;
      border-radius: 5px;
      text-transform: uppercase;
    }
  }
}

.viperProfiles {
  display: flex;
  margin-bottom: 10px;
  padding: 10px 0px;
  border-bottom: 1px solid;
  button {
    margin-left: auto;
    margin-top: 0px;
    order: 2;
  }
  .asn {
    margin-bottom: 10px;
  }
}

.loadingContainerMobile {
  height: 50px !important;
  margin-top: 20px;
}

.addASN {
  display: flex;
  margin-bottom: 10px;

  input {
    height: 30px;
    border: 1px solid;
    padding: 0px 10px;
  }

  button {
    margin-left: auto;
    margin-top: 0px;
    order: 2;
  }
}

.resetASNModal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.API_error_text {
  color: #dc1b20;
}

.duplicate_ASN_error {
  color: #dc1b20;
  margin-top: 5px;
}

.unlinkAgencyModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin: 0 0 auto;
  width: 100%;
  left: 0;
  height: 0;
  opacity: 0;
  transition: ease height 0.2s, ease opacity 0.2s;

  /*
  @media (max-width: $smallScreen-max) {
    width: 100%;
    left: 0;
  }

  @media (min-width: $smallScreen-max) {
    width: 90%;
    left: 15px;
  }
  */

  &.open {
    height: auto;
    z-index: 9;
    opacity: 1;
    transition: ease height 0.2s, ease opacity 0.2s;

    .buttonHolder {
      opacity: 1;
      transition-delay: 0.3s;
    }
  }

  .buttonHolder {
    opacity: 0;
    display: flex;
    transition: ease opacity 0.2s;
  }
}

.edit_password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;

  .content {
    display: flex;
    flex-direction: column;
    width: 50%;

    &.sas_only {
      width: 100%;
    }

    .title {
      font-weight: 600;
      font-size: $f16px;
    }
    span {
      font-size: $f14px;
    }

    input {
      border: 1px solid #8c919a;
      box-sizing: border-box;
      border-radius: 5px;
      line-height: 1.875rem;
      margin: 10px 0px;
      padding: 0px 10px;
    }
  }
}

.password_status {
  font-size: $f12px;
  white-space: pre;

  &.success {
    color: #007454;
  }
}

.password_requirements {
  list-style-type: square;
  list-style-position: inside;
}

.backButton {
  button {
    position: inherit;
    right: 30px;
    top: 20px;
    z-index: 2;
  }
}

.title {
  font-weight: 600;
  font-size: $f16px;
}

ul {
  &.pagingGroups {
    max-height: 200px;
    overflow-y: auto;
  }
  &.groupList {
    max-height: 40vh;
    overflow-y: auto;
  }
}

.dropdownAgencyUnlink {
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  border-bottom: 1px solid $grey;
  position: relative;

  .btnHolder {
    display: flex;
    align-items: center;
  }
}

.agencyNavigationDefault {
  padding-bottom: 8px;
  border-bottom: solid 1px $grey;

  .buttons {
    padding: 16px 0 6px;
  }
}

.hideshowPagingGroups {
  padding-bottom: 8px;
  border-bottom: solid 1px $grey;
}

.selectAll {
  padding: 8px 4px;
  font-size: $f14px;
  border-bottom: solid 1px $grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.loadingContainer {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.muteNotificationsSettings {
  display: flex;
  align-items: center;

  .muteNotificationsDropdown {
    max-width: 100% !important;
    width: 100% !important;
    min-height: 40px !important;
    border: 1px solid $grey !important;
    border-radius: 5px !important;
    @include theme-aware("color", "dropdownTxt");
    @include theme-aware("background-color", "tileBg");
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    // color: $txt !important;
    font-size: 1rem !important;
    line-height: 1.25rem !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    margin-right: 18px !important;

    > i {
      padding: 7px 9px !important;
    }
  }
}

.versionNumber {
  padding-top: 15px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0px;
  font-size: $f14px;
}
