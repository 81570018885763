@import "../../../assets/sass/mixin";
@import "../../../assets/sass/var";

@media screen and (max-width: $smallScreen-max) {
  .tab-list {
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;
    //max-height: 55px;
  }

  .tab-list-item {
    @include theme-aware("background-color", "grey8");
    flex: auto;
    list-style: none;
    //margin-bottom: -1px;
    padding: 0.5rem 0rem;

    font-size: $f16px;
    //line-height: $f20px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    width: 33.33%;
    min-height: 52px;
    justify-content: center;
    color: inherit;
    text-decoration: none;
  }

  .tab-list-active {
    font-weight: bold;
    // border-bottom: 4px solid #01A1E4;
  }
}

@media screen and (max-width: 344px) {
  .tab-list-active {
    font-weight: normal; // Needed for smaller phones like iPhone 5
  }
}

.dashboard {
  .tabs,
  .tab-content {
    height: 100% !important;
  }

  &.no_navigation {
    .tab-list {
      display: none !important;
    }
  }

  > div > .tabs {
    > .tab-list {
      padding: 0;
      margin: 0;
      display: flex;
      // position: relative;
      width: 25%;
      position: absolute;
      top: -54px;
      left: 0;
      height: 52px;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   width: 110vw;
      //   height: 1px;
      //   background-color: #fff;
      //   top: 64px;
      //   left: -200px;
      // }
      > .tab-list-item {
        white-space: nowrap;
        background-color: #f5f5f5;
        flex: auto;
        list-style: none;
        //margin-bottom: -1px;
        padding: $f14px $f12px;

        font-size: $f18px;
        //line-height: $f20px;
        line-height: 24px;
        text-align: center;
        cursor: pointer;
        width: 33.33%;
        min-height: 52px;
        justify-content: center;
        color: inherit;
        text-decoration: none;

        &.tab-list-active {
          font-weight: bold;
          // border-bottom: 4px solid #01A1E4;
        }
      }
    }
  }
}

.slider {
  height: 3px;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: $lblue;
  transition: left 0.3s ease-in-out;
  border-radius: 1px;
}

.columnTab {
  &.Overview {
    position: relative;
  }
  .tabs {
    margin-bottom: 12px;
  }
  .BtnHolder,
  .tab-list {
    @include theme-aware("background-color", "grey6");
    border-radius: 5px 5px 0 0;
    text-align: center;
  }
  .tab-list {
    margin: 0;
    display: flex;
    justify-content: center;

    &:after {
      display: none;
    }

    .tab-list-item {
      text-transform: uppercase;
      @include theme-aware("background-color", "grey7");
      @include theme-aware("color", "txt");
      background-repeat: no-repeat;
      margin-top: 8px;
      margin-left: 4px;
      margin-right: 4px;
      font-size: $f14px;
      font-weight: bold;
      line-height: $f40px;
      min-height: 40px;
      min-width: 120px;
      display: inline-block;
      border-radius: $radius;
      margin-bottom: 5px;
      cursor: pointer;
      text-align: center;
      border: 0 none;
      &.btn_solid:hover {
        @include theme-aware("color", "txt");
      }
    }

    .tab-list-active {
      @include theme-aware("background-color", "tileBg");
      margin-bottom: 0;
      min-height: 45px;
      border-radius: 5px 5px 0 0;

      @media screen and (max-height: $smallScreen) {
        min-height: 49px;
      }
    }
  }
  .tab-content {
    @include theme-aware("background-color", "bg4");
    box-shadow: $shadow;
    border-radius: 5px;
    padding-bottom: 15px;
  }
  @media screen and (max-width: $tabletLandscape) {
    &.availability {
      .btn_back {
        position: absolute;
        right: 18px;
        margin-top: 18px;
      }
    }
  }
  &.availability {
    @media screen and (min-width: $smallScreen-max) {
      position: relative;
      padding-top: 25px;
      border-radius: 8px;

      .tab-content {
        box-shadow: none;
        // min-height: 60vh;
        height: calc(100% - 65px);
        position: relative;

        .col-one-third {
          padding-bottom: 0;
          max-height: 100%;
          overflow-y: scroll;
        }
      }
      > .tabs {
        height: 100%;
        margin-bottom: 0;
        // padding-bottom: 40px;
        > .tab-list {
          // margin-left: 64px;
          padding-left: 220px;
          // max-width: 630px;
          // @include theme-aware("background-color", "grey2dgrey");
          border-radius: 5px 4px 0 0;
          justify-content: flex-end;
          background-color: transparent !important;

          > .tab-list-item {
            color: #5a5f65;
            text-decoration: none;
            height: 37px;
            min-height: 30px;
            border-color: transparent;
            border-radius: 5px 5px 0 0;
            // @include theme-aware("background-color", "grey10");
            margin: 12px 6px 0px 6px;
            // @include theme-aware("background-color", "grey7");
            padding: 0 20px;
            width: auto;

            &.tab-list-active {
              min-height: 30px;
              @include theme-aware("background-color", "bg4");
              box-shadow: $shadow;
            }
          }
        }

        .tabs {
          .tab-list {
            background-color: transparent !important;
            justify-content: left;
            margin: 0;
            margin-bottom: 48px;
            // background-color: #f5f5f5;
            border-radius: 5px;
            padding-top: 24px;
            padding-left: 5%;
            position: absolute;
            z-index: 2;
            top: 0;

            .tab-list-item {
              @include theme-aware("background-color", "grey8");
              @include theme-aware("border-color", "grey7");
              box-sizing: border-box;
              min-width: 90px;
              border: 1px solid $grey;
              border-radius: 0;
              margin: 0;
              &:first-child {
                border-radius: 5px 0px 0px 5px;
                border-right: 0 none;
              }
              &:last-child {
                border-left: 0 none;
                border-radius: 0px 3px 3px 0px;
              }
              &.tab-list-active {
                @include theme-aware("background-color", "tileBg");
              }
            }
          }
          .tab-content {
            margin-top: 40px;
          }
        }
      }
    }
  }
  &.attendance {
    .tabs {
      height: 100%;
      > .tab-list {
        background-color: transparent !important;
        padding: 15px 10px 0 10px;
        overflow: hidden;

        > .tab-list-item {
          color: #5a5f65;
          text-decoration: none;
          border-color: transparent;
          border-radius: 5px 5px 0 0;
          margin: 0 5px;
          width: auto;
          min-height: 45px;
          background-position-y: center;

          &.tab-list-active {
            @include theme-aware("background-color", "bg2");
            box-shadow: $shadow;
          }
        }
      }

      > .tab-content {
        box-shadow: none;
        padding: 10px;
        @include theme-aware("background-color", "bg2");
      }
    }
  }
}
.availability {
  .Sub-Section-titles {
    margin-bottom: 0;
  }
  .text {
    margin-top: 8px;
    &.default {
      margin-left: 0;
    }
  }
}
@media screen and (min-width: $tabletLandscape-min) and (max-width: $tabletLandscape-max) {
  .main-content-holder.settings .tab-list {
    padding: 0;
    margin: 0;
    display: flex;
    position: relative;
    //max-height: 55px;
  }

  .main-content-holder.settings .tab-list-item {
    @include theme-aware("background-color", "grey8");
    flex: auto;
    list-style: none;
    //margin-bottom: -1px;
    padding: 0.5rem 0rem;

    font-size: $f16px;
    //line-height: $f20px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    width: 33.33%;
    min-height: 52px;
    justify-content: center;
    color: inherit;
    text-decoration: none;
  }

  .tab-list-active {
    font-weight: bold;
    // border-bottom: 4px solid #01A1E4;
  }
}
