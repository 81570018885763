@import "../../assets/sass/var";
.searchbox {
  border: 1px solid #5a5f65;
  border-radius: 5px;
  font-size: $f16px;
  line-height: $f20px;
  padding: 11px;
  padding-left: 38px;
  background-image: url(../../assets/img/icons/search-green.svg);
  background-position: 7px 50%;
  background-repeat: no-repeat;
  width: 100%;
}
.addMember {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  p.label {
    padding: 9px 0;
    margin: 0;
    font-size: $f16px;
    font-weight: 600;
    line-height: $f27px;
  }
  .addMemberHolder {
    padding: 8px 34px 21px 34px;
    margin: 50px -8px 0 -8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
  }
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;

  &.searchResults {
    // Use this for a list of search results
    margin-bottom: 10px;
    border-top: 1px solid $grey;
    overflow-y: auto;
    max-height: 400px;
    &.mobile {
      max-height: 200px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    li {
      cursor: pointer;
      font-size: $f16px;
      font-weight: 600;
      line-height: $f30px;
      padding: 11px 0;
      border-bottom: 1px solid $grey;
      position: relative;

      &:after {
        content: "";
        background-position: 50% 50%;
        background-image: url(../../assets/img/icons/addGreen.svg);
        background-repeat: no-repeat;
        transition: ease all 0.2s;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 30px;
        width: 30px;
        border: 1px solid #d6d7d9;
        background-color: #fff;
        border-radius: 5px;
      }
      &:hover:after {
        background-image: url(../../assets/img/icons/addWhite.svg);
        background-color: $mGreen;
      }
      &.iconRemove {
        &:after {
          background-image: url(../../assets/img/icons/remove-red.svg);
          background-position: 50% 50%;
        }
        &:hover {
          &:after {
            background-color: $red;
            background-image: url(../../assets/img/icons/remove-white.svg);
          }
        }
      }
    }
  }
}

.noTabs {
  margin-bottom: 20px;
}

.membersList {
  > button {
    width: calc(100% - 30px) !important;
    padding: 10px;
    margin-left: 15px;
    background-color: #fff;
  }
  ul {
    margin-left: 15px;
    margin-right: 15px;
    li {
      padding-left: 9px;

      > div:first-child {
        min-height: 50px;

        .center {
          justify-content: center;
          display: flex;
          flex-direction: column;
        }
        label {
          margin-bottom: 15px;
          border-bottom: 1px solid $grey;
        }
      }
      .btnHolder {
        display: flex;     
        button {
          flex: auto;
          max-width: 100%;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-left: 15px;
          }
        }
      }
      .option {
        padding-left: 15px;
        padding-right: calc(15px + 8px);
        max-height: 0;
        padding: 0;
        overflow: hidden;
      }
      &.isOpen {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
        border-bottom: 0 none;

        .center {
          padding-left: 8px;
          + button {
            background-image: url(../../assets/img/icons/icon-optionClose.svg);
          }
        }
        .btnHolder {
          padding-top: 8px;
          padding-bottom: 15px;
        }
        .option {
          max-height: 200px;
        }
        .checkbox {
          padding-top: 8px;
          padding-bottom: 8px;
          margin-bottom: 8px;
          border-top: 1px solid $grey;
        }
      }
    }
  }

  .checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-left: 35px;
    color: #5a5f65;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: 1px solid $grey;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      cursor: pointer;
      position: absolute;
      top: 8px;
      left: 5px;
      height: 18px;
      width: 18px;
      box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
      background-color: $grey;
      border-radius: 5px;
      transition: ease all 0.1s;

      &:after {
        content: "";
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    input:checked ~ .checkmark {
      background-color: $mGreen;
      box-shadow: 0 0 1px 2px $mGreen, 0 0 1px 3px $mGreen;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }
  }
}
.radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  flex: auto;
  line-height: $f30px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 6px;
    left: 5px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 50%;
    transition: ease all 0.1s;
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
  }
}
.adminColumn {
  position: relative;

  @media (min-width: $tabletLandscape) {
    height: 100%;
  }
  .scrollableContent {
    @media (min-width: $tabletLandscape) {
      overflow-y: auto;
      overflow-x: hidden;
      height: calc(100% - 55px);
    }
  }
}
.adminTabs {
  button {
    margin: 15px;
    width: 100%;
  }
}

.thresholdType {
  margin: 20px 0px !important;
}

.adminAvailabilityBtn {
  max-width: 91%;
}

.description {
  font-size: 1rem;
}

.success {
  color: $mGreen;
}

.fail {
  color: $red;
}

.otherBox {
  border: 1px solid #8c919a;
  border-radius: 5px;
  line-height: 1.875rem;
  margin: 8px 10px 0px 0px;
  width: 90%;
  padding: 0px 8px;
}

.paginationLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
}
