@import "../../assets/sass/var";
@import "../../assets/sass/mixin";

.HomepageContainer {
  text-align: center;
  vertical-align: top;
  // padding-bottom: 50px;
  position: relative;
  z-index: 10;
  margin: 0 auto;
  @include theme-aware("background-color", "txt");

  .content {
    max-height: calc(100% - 60px);
    overflow-y: auto;

    @media (max-width: $largeScreen) {
      max-height: calc(100% - 50px);
    }

    &.paddingTop {
      padding-top: 54px;
    }
  }

  .scrollable {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .unscrollable {
    overflow-y: hidden;
  }

  .holder {
    display: flex;
  }
  .content {
    margin-top: 12px;
    padding: 0 20px 20px;
  }

  .loadingContainer {
    // position: absolute;

    //top: 80px;
    //top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;

    //width: 100vw;
    //height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .pagerEventLoader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .contentHeader {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;

    .heading {
      h2 {
        line-height: 32px;
        margin: 0;
        font-size: $f24px;
      }
    }
    button {
      margin-bottom: 0;
      margin-left: 3px;

      @media screen and (min-width: $largeScreen) {
        margin-top: 11px;
      }
    }
    .delete_btn {
      width: 32px !important;
      height: 32px !important;
      background-position: 50% 50%;
      @media screen and (max-width: $largeScreen) {
        min-width: 14px !important;
      }
    }
  }

  .controls {
    display: flex;
    font-weight: 600;
    font-size: $f14px;

    @media (max-width: $largeScreen) {
      position: absolute;
      top: 39px;
      left: 0;
      z-index: 99;
      width: 100%;
    }
    .tooltip {
      position: relative;
    }
    .tooltip .tooltiptext {
      visibility: hidden;
      width: 220px;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;

      /* Position the tooltip */
      position: absolute;
      z-index: 1;
      top: 100%;
      transform: translateX(-99%);
    }

    .tooltip:hover .tooltiptext {
      visibility: visible;
    }
  }

  .filterSection {
    text-align: right;
    margin-top: 6px;
    display: flex;
    //margin-bottom: 7px;

    button {
      height: 32px;
      margin: 0;

      &:first-child {
        margin: 0;
        margin-right: 4px;

        &:hover {
          @include theme-aware("color", "txt");
          // background-image: url(../../assets/img/icons/Filter.svg);
          // cursor: default;
        }

        &.isActive {
          z-index: 1;
          position: relative;
        }
      }
      &:last-child {
        margin: 0;
        margin-left: 4px;
      }
    }
  }

  .filterControls {
    text-align: left;
    @media screen and (max-width: 1299px) {
      padding: 5px;
    }

    label {
      white-space: nowrap;
      margin: 7px 10px 5px;
      font-size: 0.875rem;

      @media screen and (min-width: $tabletPortrait) {
        &:first-child {
          padding-right: 15px;
          border-right: 1px solid $grey;
        }
      }
    }
    > button {
      border: 1px solid #007454;
    }

    .filterButtons {
      display: flex;
      width: auto;
      align-items: center;
      justify-content: flex-end;

      // .closeBtn {
      //   display: none;
      // }

      // @media (max-width: 1125px) {
      //   .closeBtn {
      //     display: block;
      //   }
      // }
    }
  }

  .dropdownLabel {
    flex: auto;
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 30px;
    font-size: 14px;
    font-weight: 600;
    margin-left: 16px;
    white-space: nowrap;
    width: auto;

    @media screen and (min-width: $mediumScreen-max) {
      display: none;
      line-height: 26px;
    }
    @media screen and (max-width: $smallScreen) {
      line-height: 32px;
      min-width: 90px;
    }
  }

  .multi {
    display: flex;
    width: calc(100% - 1px);
    margin: 4px 0;
    padding: 4px;
    font-size: $f14px;
    //line-height: 26px;
    background-repeat: no-repeat;
    background-position: 95% 50%;

    white-space: nowrap;
    @media screen and (max-width: $tabletPortrait) {
      line-height: 32px;
      font-weight: normal;
    }
    .mobileTxt,
    .desktopTxt {
      display: none;
    }

    > span {
      flex: auto;

      // &:first-child{
      //   font-weight: 600;
      //   @media screen and (max-width:$smallScreen){
      //     width: 100px;
      //   }
      // }
    }

    i {
      font-size: 1.25rem;
      @include theme-aware("color", "txt");
    }
    //background-image: url(../../../assets/img/icons/downarrow.svg);
  }

  .holder.disabled {
    pointer-events: none;
    color: #ccc;
  }

  @media screen and (max-width: $smallScreen) {
    p + .holder {
      border-bottom: 1px solid $grey;
    }
    .holder {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .filterControls {
      text-align: left;

      label {
        margin-top: 12px;
        min-height: 33px;

        &:first-child {
          border-bottom: 1px solid $grey;
        }
      }

      button {
        margin: 0;
        margin-top: 15px;
      }
    }
  }

  @media screen and (min-width: $mediumScreen-max) and (max-width: $largeScreen) {
    .filterSection,
    .filterControls {
      align-items: center;
    }
  }

  @media screen and (min-width: $largeScreen) {
    .filterSection,
    .filterControls {
      padding: 2px 0;
    }
  }

  @media screen and (min-width: $mediumScreen-max) {
    .filterSection,
    .filterControls {
      display: flex;
    }
    .holder {
      flex: auto;
    }
    .multi .desktopTxt {
      padding-left: 5px;
      display: inline-block;
    }
  }
  @media screen and (min-width: $tabletPortrait) and (max-width: $tabletLandscape) {
    p + .holder {
      border-bottom: 1px solid;
    }
    .holder {
      //margin: 5px auto;
      //padding: 5px;

      label:first-child {
        width: 210px;
        max-width: 210px;
        margin-right: 15px;
      }
    }
  }
  @media screen and (min-width: $tabletPortrait) and (max-width: 1299px) {
    .filterControls {
      .holder {
        display: flex;
        text-align: left;

        label {
          flex: auto;
        }
      }

      .dropdown-holder {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: $tabletLandscape) {
    .filterControls {
      padding: 18px;
      padding-top: 0;
      width: calc(100% - 40px);
      p {
        border-bottom: 1px solid $grey;
        font-size: $f14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 30px;
        margin: 0;
      }
      > button {
        height: 40px;
      }
    }
    .multi .mobileTxt {
      display: block;
    }
  }
  @media screen and (min-width: $mediumScreen-max) {
    .filterControls {
      p {
        display: none;
      }
      label {
        flex: auto;
      }
      > button {
        max-width: 150px;
        margin: 5px;
      }
    }
  }
  @media screen and (min-width: $mediumScreen-max) and (max-width: 1299px) {
    .filterControls {
      width: calc(100% - 40px);
    }
  }
  @media screen and (max-width: 1299px) {
    .filterControls {
      margin: auto;
      border-radius: 5px;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    }
  }
  @media screen and (min-width: 1300px) {
    padding: 0 15px;
    margin: 0 1rem 1rem;
    max-width: $extraLargeScreen;
    border-radius: 8px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);

    .filterControls button {
      font-size: $f14px;
      text-transform: uppercase;
      color: rgba(0, 0, 0, 0.87);
      margin: 0px;
      color: $mGreen;
      padding: 2px 10px;

      &:hover {
        background-color: $mGreen;
        color: #fff;
      }
    }
  }

  @media screen and (min-width: 1300px) {
    margin: 0 auto 1rem;

    .filterControls label:first-child {
      margin-right: 0;
    }
  }
}

.radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  line-height: 20px;
  flex: auto;
  height: 20px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 5px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 50%;
    transition: ease all 0.1s;
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
  }
}

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 5px;
    height: 18px;
    width: 18px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 5px;
    transition: ease all 0.1s;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
    box-shadow: 0 0 1px 2px $mGreen, 0 0 1px 3px $mGreen;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}

.noMessages {
  text-align: center;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
