@import "../../../assets/sass/_var.scss";
@import "../../../assets/sass/mixin";

.turnoutWrapper {
  height: calc(100vh - 82px);
}
.buttonContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: $extraLargeScreen;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding: 0 16px;
}

.searchbox {
  border: 1px solid #8c919a;
  background-color: #a0a0a013 !important;
  line-height: $f30px;
  padding: 3px 10px;
  width: 5em;
  border-radius: 8px;
  font-family: "Source Sans Pro", sans-serif;
  font-size: $f14px;
}

.box_default {
  max-width: 1600px;
  margin: 0 auto;
  height: calc(100% - 66px);
  overflow-y: scroll;

  border-radius: 5px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
  @include theme-aware("background-color", "cardBg");
  padding: 16px 70px;
  margin-bottom: 12px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #e9e9e9;
}

.sectionTitle {
  font-size: $f20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: $f27px;

  h2 {
    margin-bottom: 0px;
  }
}

.title,
.subTitle {
  padding: 8px 0;
  vertical-align: top;
  display: inline-block;
  width: 360px;
  font-size: $f16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: $f27px;
}

.subTitle {
  min-width: 130px;
  width: 130px;
}

.row,
.subRow {
  border-bottom: 1px solid #e9e9e9;
  &.noUnderline {
    border-bottom: none;
  }
}
.row {
  padding: 14px 0;
  > .body {
    display: inline-block;
    width: calc(100% - 360px);
  }
}

.subBody {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .separator {
    width: 1em;
  }

  .labelInput {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  // custom input range
  input[type="range"] {
    background: transparent;
    --thumbSize: 18px;
    --trackSize: 8px;
    --thumbBg: #fff;
    --trackBg: #e9e9e9;
    --progressBg: #007454;
  
    /* webkit progress workaround */
    --webkitProgressPercent: 0%;
  }
  
  input[type="range"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: var(--thumbSize);
    width: 100%;
    margin: 0;
    padding: 0;
  }
  input[type="range"]:focus {
    outline: none;
  }
  
  /* Thumb */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  input[type="range"]::-moz-range-thumb {
    -moz-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  input[type="range"]::-ms-thumb {
    -ms-appearance: none;
    appearance: none;
    width: var(--thumbSize);
    height: var(--thumbSize);
    background-color: var(--thumbBg);
    border-radius: calc(var(--thumbSize) / 2);
    border: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
    cursor: pointer;
  }
  
  /* Track */
  input[type="range"]::-webkit-slider-runnable-track {
    height: var(--trackSize);
    background-image: linear-gradient(
      90deg,
      var(--progressBg) var(--webkitProgressPercent),
      var(--trackBg) var(--webkitProgressPercent)
    );
    border-radius: calc(var(--trackSize) / 2);
  }
  input[type="range"]::-moz-range-track {
    height: var(--trackSize);
    background-color: var(--trackBg);
    border-radius: calc(var(--trackSize) / 2);
  }
  input[type="range"]::-ms-track {
    height: var(--trackSize);
    background-color: var(--trackBg);
    border-radius: calc(var(--trackSize) / 2);
  }
  
  /* Progress */
  input[type="range"]::-moz-range-progress {
    height: var(--trackSize);
    background-color: var(--progressBg);
    border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
  }
  input[type="range"]::-ms-fill-lower {
    height: var(--trackSize);
    background-color: var(--progressBg);
    border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
  }

  .subRow {
    input {
      height: 30px;
      border: 1px solid #b4b4b4;
      padding: 0 10px;
      margin: 9px;
    }
  }

  .input {
    padding-right: 8px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inputName {
      margin-right: 10px;
      margin-left: 10px;
      white-space: nowrap;
    }
  }

  .range {
    margin: 1em 0;
  }

  .repeats {
    margin-left:10px;
  }
}

.attendanceOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .input {
    padding-top: 8px;
    padding-right: 8px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .inputName {
      margin-right: 10px;
      margin-left: 10px;
      white-space: nowrap;
    }
  }
}

.dropdownItem {
  min-width: 255px;
}
.dropdownShortItem {
  min-width: 4em !important;
}

.dropdownTime {
  border: 0;
  border-radius: 5px;
  width: 90px;
  min-height: 32px;
  padding: 0;

  &.background {
    background: #a0a0a013;
    border-radius: 8px;
  }

  color: #5c5e65;
  font-family: "Source Sans Pro", sans-serif;
  font-size: $f14px !important;

  input {
    margin-left: -10px;
    margin-right: -20px;
    width: 60px;
    background-color: transparent !important;
  }
}

.body,
.subBody {
  max-width: 1200px;

  button {
    &:first-child {
      margin-right: 10px;
      margin-left: 0px;
    }
  }
}

.body {
  label {
    flex: auto;
    justify-content: space-between;
    display: inline-block;

    font-size: $f16px;
  }
}

.subBody {
  label {
    flex: auto;
    justify-content: space-between;
    display: inline-block;

    font-size: $f16px;
    font-weight: normal;
  }
}

.errorWrapper {
  display: inline-block;
}

.phoneticsContainer {
  display: flex;
  flex-direction: column;

  .addNewLine {
    justify-content: flex-end;
  }

  ul {
    list-style: none;
  }

  .subRow {
    input {
      min-width: 250px;
    }
  }
}

.row.multi {
  > .body {
    width: 100%;
  }
  .subRow {
    display: flex;
    align-items: center;
    &.noUnderline {
      border-bottom: 0 none;
    }
  }

  .title,
  .subTitle {
    width: 230px;
  }
}

.radio {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 35px;
  flex: auto;
  line-height: $f30px;
  margin-right: 40px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    //top: 0;
    top: 6px;
    left: 5px;
    height: 20px;
    width: 20px;
    box-shadow: 0 0 1px 2px #fff, 0 0 1px 3px #8c919a;
    background-color: $grey;
    border-radius: 50%;
    transition: ease all 0.1s;
  }

  input:checked ~ .checkmark {
    background-color: $mGreen;
  }
}

// Validation
.invalidInput {
  border: 1px solid red !important;
  border-radius: 5px;
}

.errorMsg {
  color: $red;
}
