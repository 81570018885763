@import "../../assets/sass/var";

.searchbox {
  border: 1px solid #8c919a;
  border-radius: 5px;
  color: #5a5f65;
  font-size: $f16px;
  line-height: $f30px;
  padding: 5px 8px;
  width: 100%;
}
.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label {
  color: #5a5f65;
  font-size: $f14px;
  font-weight: 600;
  line-height: $f18px;
  margin: 0;
  margin-bottom: 5px;
  padding-top: 19px;
}
.dropdownHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0;
  flex-wrap: wrap;

  .dropdownLabel {
    font-weight: 600;
    margin: 5px 10px;
    font-size: 16px;
    width: 7em;
  }
  > span {
    line-height: $f44px;
    word-wrap: break-word;
  }

  > div {
    padding-top: 0 !important;

    > i {
      padding: 3px 9px !important;
    }
  }
}

.addASN {
  display: flex;
  margin-bottom: 10px;

  input {
    height: 30px;
    border: 1px solid;
    padding: 0px 10px;
  }

  button {
    margin-left: auto;
    margin-top: 0px;
    order: 2;
  }
}

.viperProfiles {
  display: flex;
  margin-bottom: 10px;
  padding: 10px 0px;
  border-bottom: 1px solid;
  button {
    margin-left: auto;
    margin-top: 0px;
    order: 2;
  }
  .asn {
    margin-bottom: 10px;
  }
}

.resetASNModal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdownWrapper {
  min-width: 50%;
  font-size: 16px;
  width: calc(100% - 10rem);
}

.duplicate_ASN_error {
  color: #dc1b20;
  margin-top: 5px;
}
.error {
  border: solid 1px red !important;
  border-radius: 5px !important;
}

.btnHolder {
  display: flex;
  align-items: center;

  button {
    margin-right: 16px;
  }
}

.description {
  margin-bottom: 0px;
  margin-top: -5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.paginationLoader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;

  &.searchResults {
    // Use this for a list of search results
    margin-bottom: 10px;
    border-top: 1px solid $grey;
    overflow-y: auto;
    //height: calc(100% - 54px);

    &:last-child {
      margin-bottom: 0;
    }
    li {
      cursor: pointer;
      font-size: $f16px;
      font-weight: 600;
      line-height: $f30px;
      padding: 11px 0;

      border-bottom: 1px solid $grey;
      background-repeat: no-repeat;
      background-position: 95% 50%;
      background-size: 9px 15px;
      background-image: url(../../assets/img/icons/forwards-arrow.svg);
    }
  }

  .password_reset {
    .reset_success {
      color: $green;
      font-style: italic;
    }
    .reset_fail {
      color: $red;
      font-style: italic;
    }
  }

  &.groupSearchResults {
    // Use this for a list of search results
    margin-bottom: 10px;
    border-top: 1px solid $grey;
    overflow-y: auto;
    max-height: 220px;
    &.mobile {
      max-height: 200px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    li {
      cursor: pointer;
      font-size: $f16px;
      font-weight: 600;
      line-height: $f30px;
      padding: 11px 0;
      border-bottom: 1px solid $grey;
      position: relative;

      &:after {
        content: "";
        background-position: 50% 50%;
        background-image: url(../../assets/img/icons/addGreen.svg);
        background-repeat: no-repeat;
        transition: ease all 0.2s;
        position: absolute;
        right: 10px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 30px;
        width: 30px;
        border: 1px solid #d6d7d9;
        background-color: #fff;
        border-radius: 5px;
      }
      &:hover:after {
        background-image: url(../../assets/img/icons/addWhite.svg);
        background-color: $mGreen;
      }
      &.iconRemove {
        &:after {
          background-image: url(../../assets/img/icons/remove-red.svg);
          background-position: 50% 50%;
        }
        &:hover {
          &:after {
            background-color: $red;
            background-image: url(../../assets/img/icons/remove-white.svg);
          }
        }
      }
    }
  }
}

.userRoles {
  li {
    padding: 6px 0;
  }
}

.adminViewMainContentWrapper {
  height: calc(100vh - 98px);

  @media (max-width: $tabletPortrait) {
    height: calc(100vh - 94px);
  }

  @media (min-width: $tabletPortrait) and (max-width: $smallScreen-max) {
    height: calc(100vh - 112px);
  }

  @media (max-width: $tabletLandscape) {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .adminColumn {
    position: relative;
    // overflow: auto;

    @media (min-width: $tabletLandscape) {
      height: 100%;
      overflow-y: hidden;
    }
    .scrollableContent {
      @media (min-width: $tabletLandscape) {
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100% - 55px);
      }
    }
  }

  .resultsWrapper {
    height: calc(100% - 55px);

    @media (max-width: $tabletLandscape) {
      height: calc(70vh - 55px);
    }
    @media (min-width: $tabletLandscape) {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.profile {
  margin: 0;
  margin-top: 15px;
  list-style: none;
  padding: 0;
  padding-bottom: 15px;
  //border-bottom: 1px solid $grey;

  li {
    padding: 0;
    font-size: $f14px;
    line-height: $f24px;
  }
}

.pagingGroupRow {
  padding: 0;
  min-height: 80px;

  .center {
    justify-content: center;
    display: flex;
    flex-direction: column;

    .contents {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
    }
  }

  span {
    margin-bottom: 10px;
  }

  .fail {
    color: $red;
  }
}

.addGroupHolder {
  border-bottom: 1px solid $grey;
  margin-bottom: 13px;

  span {
    padding: 9px 0;
    margin: 0;
    font-size: $f16px;
    font-weight: 600;
    line-height: $f27px;
  }

  input {
    margin-top: 9px;
  }
}

.noSearchDesc {
  margin-bottom: 9px;
}

.success {
  color: $mGreen;
}

.fail {
  color: $red;
}

.removeGroupModal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchCheckboxes {
  height: 46px;

  span {
    margin-top: 9px;
  }

  label {
    line-height: 1rem;
    margin-left: 10px;
    height: inherit;
    display: flex;
    align-items: center;
  }
}

.title {
  font-weight: 600;
  font-size: $f16px;
}

