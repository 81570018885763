.errorPage {
  padding: 8px 0 44px;
  text-align: center;
  width: 100%;

  h1 {
    line-height: 2rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  .tryAgain {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
}
