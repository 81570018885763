@import "../../assets/sass/var";

.reminded {
  font-size: $f16px;
  font-weight: 600;
  line-height: $f27px;
  margin-bottom: 18px;
}

.vibration {
  font-size: $f16px;
  font-weight: 600;
  line-height: $f27px;
  border-bottom: 1px solid $grey;
  padding-bottom: 9px;
}

.Title {
  margin-bottom: 8px !important;
  min-height: 47px;

  h3 {
    line-height: 1.5rem !important;
  }

  button {
    position: absolute;
    right: 35px;
    top: 8px;
    z-index: 2;
  }
}

.loadingContainerMobile {
  height: 50px !important;
  margin-top: 20px;
}

.dnd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;

  .content {
    display: flex;
    flex-direction: column;

    &.sas_only {
      width: 100%;
    }

    .title {
      font-weight: 600;
      font-size: $f16px;
    }
    span {
      font-size: $f14px;
    }

    input {
      margin: 4px 0;
      padding: 4px 8px;
      box-sizing: border-box;
      height: 25px;
      width: 100%;
      border: 1px solid #8c919a;
      border-radius: 5px;
      background-color: #ffffff;
    }
  }
}

.loadingContainer {
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  height: 324px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media (max-width: $smallScreen-max) {
  .Title {
    button {
      position: inherit;
      right: 30px;
      top: 20px;
      z-index: 2;
    }
  }
}

.borderBottom {
  border-bottom: solid 1px $grey;
}

.notificationsHeading {
  border-bottom: solid 1px $grey;
  span {
    font-size: $f14px;
  }
}

.alertType {
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px $grey;
  padding: 3px 0;

  &:last-child {
    border-bottom: none;
  }
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-weight: 600;

    .emergency {
      color: $red;
    }

    .nonEmergency {
      color: $purple;
    }

    .admin {
      color: $blue;
    }
  }
  .content {
    padding-left: 16px;

    .disabled {
      color: #767777;
    }

    .section {
      display: flex;
      .title {
        font-weight: 600;
        width: 7rem;
      }
    }

    p {
      margin-top: 0;
    }
  }
}

.API_error_text {
  color: #dc1b20;
}
